export const productsSearch = {
  b2b: 'Professionista',
  b2bTitle: 'RICERCA PRODOTTO PROFESSIONISTA',
  b2c: 'Privato',
  b2cTitle: 'CERCA IL PRODOTTO PiU’ ADATTO A TE',
  filePlaceholder: "Seleziona un file...",
  fileDropPlaceholder: "Metti un file qui...",
  browse: "Cerca file",
  upload: "Carica nuova struttura",
  start: "Inizio",

  filters: {
    byNameOrCode: 'Cerca per nome o codice prodotto o descrizione',
    productNameCode: 'Nome o codice prodotto o descrizione',

    bySerialAndColor: 'Cerca per serie e colore',
    serialCode: 'Codice serie',
    colorCode: 'Codice colore',

    byFilters: 'Cerca per caratteristiche',
    areas: 'Settore',
    typologies: 'Tipologia prodotto',
    families: 'Famiglia chimica',
    dilutions: 'Diluizione',
    dryings: 'Essiccazione',
    appMethods: 'Metodo Applicativo'
  },

  combos: {
    areas: {
      edil: 'EDILIZIA (CONSTRUCTION)',
      metal: 'INDUSTRIA DEL METALLO',
      wood: 'INDUSTRIA DEL LEGNO',
      edil_metal_wood: 'EDILIZIA-METALLO-LEGNO',
      coil: 'COIL',
      plastic: 'PLASTICA TUTTI I SETTORI',
      paste: 'PASTE, CONVERTER, CATALIZZATORI X RIVENDITA',
      corrosion: 'ANTICORROSIONE, NAVALE, ECC',
      techinal_management: 'DIREZIONE TECNICA',
      tintometry: 'TINTOMETRIA',
      removal: 'ELIMINAZIONE',
    },
    typologies: {
      dye: 'TINTE, PATINE, CONCENTRATI PER LEGNO, INCHIOSTRI',
      paste: 'PASTE CONCENTRATE COLORANTI',
      background: 'FONDI E PRIMER ANTICORROSIVI e NON',
      intermediate: 'INTERMEDI e BASE COAT',
      washable: 'LAVABILI',
      various: 'VARIE',
      catalyst: 'CATALIZZATORI ISOCIANICI',
      thinner: 'DILUENTI',
      converter: 'CONVERTER',
      hardeners: 'INDURENTI EPOSSIDICI',
      back: 'BACK',
      impregnant: 'IMPREGNANTI',
      coat: 'TOP COAT',
      raw_material: 'MATERIE PRIME',
      wp_floors_resins: "IMPERMEABILIZZANTI, PAVIMENTI, RESINE",
      additives: "ADDITIVI",
      auxiliaries: "AUSILIARI",
    },
    families: {
      acrylic: 'ACRILICI',
      pvdf: 'PVDF / FLUORURATI',
      epoxy: 'EPOSSIDICI',
      ketone: 'CHETONICHE',
      polyester: 'POLIESTERI',
      isocyanates: 'ISOCIANATI',
      alkyl: 'ALCHIDICI',
      nitrocellulose: 'NITROCELLULOSICI',
      unsaturated: 'POLIESTERI INSATURI',
      clorocianuro: 'CLOROCIANURICI',
      phenolic: 'FENOLICHE',
      silicones: 'SILICONICI',
      urethane: 'UREICI',
      vinyl: 'VINILICI',
      polyester_melamine: 'POLIESTERI / MELAMINICI',
      additives: "ADDITIVI",
      auxiliaries: "AUSILIARI",
    },
    dilutions: {
      water: 'ALL\'ACQUA',
      dust: 'POLVERE',
      solvent: 'A SOLVENTE',
      noSolvent: 'SENZA A SOLVENTE',
      liquid_uv: 'LIQUIDO UV 100%'
    },
    dryings: {
      air: '1K ARIA',
      catalyzed: '2K CATALIZZATO',
      electrophoretic: 'ELETTROFORETICO',
      oven: 'A FORNO',
      curing: 'UV CURING'
    },
    appMethods: {
      brush: 'pennello',
      roller: 'rullo',
      sprinkle: 'air-spray',
      airMix: 'air-mix',
      airLess: 'air-less',
      spatula: 'spatola',
      sponge: 'spugna',
      rollerCoating: 'roller coating',
      curtainCoater: 'velatrice',
      immersion: 'immersione',
      trowel: 'frattazzo',
      cloth: 'straccio',
      conventional: 'spruzzo ad aria',
      flowCoatings: 'flow-coatings',
      hplv: 'hplv',
      electrostaticSpray: 'disco & elettrostatica',
      electrophoresisNS: 'elettroforesi ns',
      anode: 'IMMERSIONE ELETTROFORESI ANODICA',
      cathode: 'IMMERSIONE ELETTROFORESI CATODICA'
    }
  },

  filterWarning: 'Imposta almeno un filtro di ricerca',
  results: 'TORNARE AI RISULTATI DELLA RICERCA',
  noResults: 'Nessun risultato tra i prodotti a listino/scorta, richiedi informazioni ad adw_alcea@alcea.com o alla pagina contatti',

  code: 'Codice',
  productCode: 'Codice prodotto',
  brand: 'Marchio',
  color: 'Colore',
  packaging: 'Confezione',
  characteristics: 'Caratteristiche',
  pkgAvailable: 'DISPONIBILE A MAGAZZINO',
  shelf: 'conservabilità',
  months: 'mesi',
  squareMetersPerLitre: 'mq/l',
  squareMetersPerKilogram: 'mq/kg',
  dryingIcon: 'essiccazione',

  specificWeight: 'Peso specifico',
  unitValue: 'Q.tà Unitaria',

  actions: "",

  internal_use: 'uso interno',
  external_use: 'uso esterno',
  external_internal_use: 'uso interno/esterno',

  dilution_water: 'Diluizione acqua',
  dilution_solvent: 'Diluizione solvente',

  tds: {
    title: 'TDS',
    download: 'Visualizza le lingue disponibili per scaricare la scheda TDS',
    availableLanguages: 'Lingue disponibili',
    notFound: 'Nessuna lingua disponibile',
  },
};
