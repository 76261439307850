<template>
  <section>
    <div class="private-area-row-150 text-uppercase bb">
      <h3>{{ $t("formUser.personalInformation") }}</h3>
    </div>

    <div class="private-area-box">
      <div class="form-row">
        <label class="col-md-3 col-form-label" for="firstname">{{ $t("formUser.firstName") }}</label>
        <span id="firstname">{{ form.firstname }}</span>
      </div>

      <div class="form-row">
        <label class="col-md-3 col-form-label" for="lastName">{{ $t("formUser.lastName") }}</label>
        <span id="lastName">{{ form.lastname }}</span>
      </div>

      <div class="form-row">
        <label class="col-md-3 col-form-label" for="telephone">{{ $t("formUser.telephone") }}</label>
        <span id="telephone">{{ form.telephone }}</span>
      </div>

      <div class="form-row">
        <label class="col-md-3 col-form-label" for="country">{{ $t("formUser.country") }}</label>
        <span id="country">{{ form.country }}</span>
      </div>

      <div class="form-row" style="border-bottom: none">
        <label class="col-md-3 col-form-label" for="zipCode">{{ $t("formUser.zipCode") }}</label>
        <span id="zipCode">{{ form.zipCode }}</span>
      </div>
    </div>

    <div class="private-area-row-150 text-uppercase by">
      <h3>{{ $t("formUser.companyInformation") }}</h3>
    </div>

    <div class="private-area-box">
      <div class="form-row">
        <label class="col-md-3 col-form-label" for="businessName">{{ $t("formUser.businessName") }}</label>
        <span id="businessName">{{ form.businessName }}</span>
      </div>

      <div class="form-row">
        <label class="col-md-3 col-form-label" for="businessWebsite">{{ $t("formUser.businessWebsite") }}</label>
        <span id="businessWebsite">{{ form.businessWebsite }}</span>
      </div>

      <div class="form-row"  v-if="!isInternalUser">
        <label class="col-md-3 col-form-label" for="businessType">{{ $t("formUser.businessType") }}</label>
        <span id="businessType">{{ form.businessType }}</span>
      </div>

      <div  v-if="!isInternalUser" class="form-row">
        <label class="col-md-3 col-form-label" for="businessDepartment">{{ $t("formUser.businessDepartment") }}</label>
        <span id="businessDepartment">{{ form.businessDepartment }}</span>
      </div>

      <div v-if="!isInternalUser" class="form-row" style="border-bottom: none" >
        <label class="col-md-3 col-form-label" for="businessRole">{{ $t("formUser.businessRole") }}</label>
        <span id="businessRole">{{ form.businessRole }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import TelephoneComponent from "./global-components/telephone-component.vue";

export default {
  name: "PrivateAreaCompany",
  components: { TelephoneComponent },
  props: {
    formProp: Object(),
    user: Object(),
    countries: Array(),
    sectors: Array(),
    invalidlist: Array(),
    telephoneProp: Object(),
    types: Array(),
    departments: Array(),
    roles: Array(),
    isInternalUser: Boolean
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userRole: (state) => state.auth.user.role,
      request: (state) => state.request.request.info,
    }),
    telephone: {
      get() {
        return this.$refs.telephone.telephone;
      },
    },
    invalid: {
      get() {
        return this.invalidlist;
      },
      set(invalidated) {
        this.$emit("input", invalidated);
      },
    },
    form: {
      get() {
        return this.formProp;
      },
      set(form) {
        this.$emit("input", form);
      },
    },
  },
  methods: {
    validationState(contextRef) {
      return this.invalid.find(
          (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
          ? false
          : null;
    },

    validationError(contextRef) {
      if (this.invalid.length > 0) {
        let find =
            this.invalid.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
  },
};
</script>
