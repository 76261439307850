import conf from "@/util/conf";

export function debounce(fn, delay) {
  let timeout = null;
  return function (func, delayMs) {
    clearTimeout(timeout);

    var args = arguments;
    var that = this;
    timeout = setTimeout(() => {
      func.apply(that, args);
    }, delayMs || conf.value('VUE_APP_DEBOUNCING_TIME'));
  };
}
