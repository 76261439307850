export const formUser = {
  gender:{
    mr:'Sig',
    mrs: 'Sig.ra',
    select:'Sig/Sig.ra'
  },
  name: "Nome",
  firstName: "Nome",
  lastName: "Cognome",
  country: "Stato",
  zipCode: "CAP",
  province: "Provincia",
  sectorInterest: "Settore d'interesse",
  private: "Privato",
  company: "Azienda",
  telephone: "Telefono",
  phonePrefix:"Prefisso",
  repeatPasswod: "Ripeti password",
  authorizeGdpr:'Autorizzo il trattamento dei dati secondo la "Informativa sul trattamento dei dati personali per clienti e fornitori',
  textLinkGdpr:'ex art. 13 D. Lgs. n. 196/2003 e Regolamento Europeo UE n. 679/2016"',
  subscribeToNewsletter: "Voglio essere aggiornato/a sulle novità Alcea mediante newsletter",
  textLinkNewsletterGdpr: "ai sensi dell'art. 13 del Regolamento UE 679/2016",
  authorizeCommercial:"Autorizzo il trattamento dei miei dati personali per ricerche di mercato",
  notificationMail:"Non hai ancora attivato le notifiche di accesso via email!\n<br/>" +
    "Vuoi attivarle ora?<br/><br/>" +
    "<b>Nota: puoi cambiare questa impostazione dal tuo profilo in qualsiasi momento</b>",
  businessName:"Ragione sociale",
  businessWebsite:"Sito Web / Pagina social",
  businessType:"Tipo di azienda",
  businessRole:"Funzione",
  businessDepartment:"Dipartimento",
  personalInformation:"Informazioni Personali",
  companyInformation:"Informazioni Aziendali",
  notificationMailSwitch:"Ricevi email di avviso ad ogni login",
  email: "Indirizzo email",
  repeatEmail: "Ripeti indirizzo email",
  intersted: "Interesse",
  link: "Non hai un sito web?",
  null: {
    country: "Stato",
    intersted: "Interesse",
    businessRole:"Funzione"
  }
};
