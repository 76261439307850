import Vue from "vue";
import VueI18n from "vue-i18n";
import { languages } from '../localization/index'
import conf from "@/util/conf";

Vue.use(VueI18n);
const langs = languages

export default new VueI18n({
  locale: conf.value('VUE_APP_I18N_LOCALE'),
  fallbackLocale: conf.value('VUE_APP_I18N_FALLBACK_LOCALE'),
  messages: Object.assign(langs),
  silentTranslationWarn: true
});
