import http from "@/util/http-common";
import store from "@/store/index";

class OrderService {
  getUrl(url) {
    let customer = store.getters.getCustomerCode;
    console.log('CUSTOMER used',customer);
    return store.getters.getUserRole == "COMMERCIAL"
    ? "v1/public/commercial" + `${url}?customerCode=${customer}` : 
      store.getters.getUserRole == "AGENT"
      ? "v1/public/agent" + `${url}?customerCode=${customer}`
      : "v1/public/customer" + url;
  }
  getOrders(page, size, sortBy, sortDir,filters) {
    let url = this.getUrl("/orders");
    let params = {
      size: size,
      page: page - 1,
      sort: sortBy + "," + sortDir,
    };
    filters.status ? params['status'] = filters.status : params['status'] = null
    filters.orderCode ? params['orderCode'] = filters.orderCode : null;
    filters.createdOnFrom ? params['createdOnFrom'] = filters.createdOnFrom : null;
    filters.createdOnTo ? params['createdOnTo'] = filters.createdOnTo : null;
    filters.serial ? params['serial'] = filters.serial : null;
    filters.color ? params['color'] = filters.color : null;
    filters.pkg ? params['pkg'] = filters.pkg : null;
    filters.description ? params['description'] = filters.description : null;
    return http.get(url, { params });
  
  }
  // async getSingleOrder(id) {
  //   return http.get(this.getUrl(`/order-requests/${id}`));
  // }
}
export default new OrderService();
