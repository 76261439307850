<template>
  <div class="step-bar-wrapper container my-4">
    <div class="step-bar position-relative row align-items-center my-2 mx-0">
      <b-progress style="height: 2px; width: 100%" :value="styleBar"></b-progress>

      <b-button
          type="button"
          class="btn-sm rounded-pill position-absolute"
          :variant="index <= curretStep.position ? 'primary' : 'secondary'"
          v-for="(step, index) in steps"
          @click="setStep(step, index)"
          :key="'step' + index"
          :style="{
          width: '1.8rem',
          height: '1.8rem',
          left: 'calc(' + (100 * index) / (steps.length - 1) + '% - 0.9rem)',
        }">
        {{ index + 1 }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "stepBar",

  props: {
    steps: Object(),
    curretStep: Object(),
    completed: Array(),
  },

  computed: {
    styleBar() {
      return (100 * this.curretStep.position) / (this.steps.length - 1);
    },
  },

  methods: {
    setStep(step, index) {
      if (this.completed.includes(step)) {
        this.$emit("setStep", index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.step-bar-wrapper {
  height: 45px;

  .step-bar {
    height: 100%;
  }
}
</style>
