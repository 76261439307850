export const formCheckout = {
    shippingAddress: "Lieferadresse",
    deliveryDateRequested: "Gewünschtes Lieferdatum:",
    acceptMultipleShipping: "Mehrfachlieferungen akzeptieren",
    customerOrderCode: "Kundenspezifische Bestellnummer",
    shippingNote: "Versandhinweise",
    genericNote: "Allgemeine Hinweise",
    totalOrder: "Geschätzter Betrag der Bestellung",
    totalOffer: "Geschätzter Betrag des Angebots"
};
