<template>
  <div>
    <h3 v-if="$route.name === 'CustomerRequest'">
      {{ $t("titles.confirmRequest") }}
    </h3>

    <b-list-group>
      <b-list-group-item v-if="form.customerCode">
        <label class="font-weight-bold col-sm-5">{{ $t("labels.customerCode") }}</label>
        <span class="col-sm-7">{{ form.customerCode }}</span>
      </b-list-group-item>

      <b-list-group-item v-if="form.customerCodeOld">
        <label class="font-weight-bold col-sm-5">{{ $t("labels.customerCodeOld") }}</label>
        <span class="col-sm-7">{{ form.customerCodeOld }}</span>
      </b-list-group-item>

      <b-list-group-item>
        <label class="font-weight-bold col-sm-5">{{ $t("formUser.businessName") }}</label>
        <span class="col-sm-7">{{ form.businessName }}</span>
      </b-list-group-item>

      <b-list-group-item>
        <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.vatNumber") }}</label>
        <span class="col-sm-7">{{ form.vatNumber }}</span>
      </b-list-group-item>

      <b-list-group-item v-if="italian">
        <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.sdiCode") }}</label>
        <span class="col-sm-7">{{ form.sdiCode }}</span>
      </b-list-group-item>

      <b-list-group-item v-if="italian">
        <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.pec") }}</label>
        <span class="col-sm-7">{{ form.pec }}</span>
      </b-list-group-item>


      <template v-if="isUserAgentOrCommercial">
        <b-list-group-item>
          <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.marketSegmentation") }} 1</label>
          <span class="col-sm-7">{{ $t(marketSegmentation1) }}</span>
        </b-list-group-item>

        <b-list-group-item v-if="marketSegmentation2">
          <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.marketSegmentation") }} 2</label>
          <span class="col-sm-7">{{ $t(marketSegmentation2) }}</span>
        </b-list-group-item>

        <b-list-group-item v-if="marketSegmentation3">
          <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.marketSegmentation") }} 3</label>
          <span class="col-sm-7">{{ $t(marketSegmentation3) }}</span>
        </b-list-group-item>

        <b-list-group-item v-if="marketSegmentation4">
          <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.marketSegmentation") }} 4</label>
          <span class="col-sm-7">{{ $t(marketSegmentation4) }}</span>
        </b-list-group-item>

        <b-list-group-item v-if="retailSegmentation">
          <label class="font-weight-bold col-sm-5">{{ $t("retailer.type") }}</label>
          <span class="col-sm-7">
              <span v-if="form.isRetailerOfMetal">{{ $t("retailer.metal") }}</span>
              <span v-if="form.isRetailerOfWood">{{ $t("retailer.wood") }}</span>
              <span v-if="form.isRetailerOfConstruction">{{ $t("retailer.construction") }}</span>
              <span v-if="form.isRetailerOfBodywork">{{ $t("retailer.bodywork") }}</span></span>
        </b-list-group-item>

        <b-list-group-item v-if="retailSegmentation">
          <label class="font-weight-bold col-sm-5">{{ $t("retailer.ownership") }}</label>
          <span class="col-sm-7 text-left">
              <b-icon-check-circle class="" v-if="form.isRetailerOfOwnership"/>
              <b-icon-x-circle class="" v-if="!form.isRetailerOfOwnership"/>
          </span>
        </b-list-group-item>

        <b-list-group-item v-if="retailSegmentation">
          <label class="font-weight-bold col-sm-5">{{ $t("retailer.shop") }}</label>
          <span class="col-sm-7">
              <span v-if="form.shopWindows">{{ $t("retailer.shopWindows") }}: {{ form.surface }} {{ $t("retailer.mq") }}</span>
              <br/>
              <span v-if="form.surface">{{ $t("retailer.surface") }}: {{ form.surface }}</span>
          </span>
        </b-list-group-item>

        <b-list-group-item>
          <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.marketCompetitor") }} 1</label>
          <span class="col-sm-7">{{ form.marketCompetitor1 }}</span>
        </b-list-group-item>

        <b-list-group-item v-if="form.marketCompetitor2">
          <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.marketCompetitor") }} 1</label>
          <span class="col-sm-7">{{ form.marketCompetitor2 }}</span>
        </b-list-group-item>

        <b-list-group-item>
          <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.totalConsumption") }}</label>
          <span class="col-sm-7">{{ (form.totalConsumption == null ? 'ND' : form.totalConsumption + " €") }}</span>
        </b-list-group-item>

        <b-list-group-item>
          <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.alceaPotential") }}</label>
          <span class="col-sm-7">{{ (form.alceaPotential == null ? 'ND' : form.alceaPotential + " €") }}</span>
        </b-list-group-item>
      </template>


      <b-list-group-item>
        <label class="font-weight-bold col-md-5" for="businessAddress">{{ $t("formCustomer.businessAddress") }}</label>
        <span class="col-sm-7">
          <card-address id="businessAddress"
                        :isShippingAddress="false"
                        :invalidErrors="invalidErrors.businessAddress ? invalidErrors.businessAddress.invalid : []"
                        :address="form.businessAddress">
          </card-address>
        </span>
      </b-list-group-item>

      <b-list-group-item>
        <label class="font-weight-bold col-md-5">{{ $t("formCustomer.legalAddress") }}</label>
        <span class="col-sm-7">
          <card-address id="legalAddress"
                        :isShippingAddress="false"
                        :address="legalAddress"
                        :invalidErrors="invalidErrors.legalAddress ? invalidErrors.legalAddress.invalid : []">
          </card-address>
        </span>
      </b-list-group-item>

      <b-list-group-item>
        <label class="font-weight-bold col-md-5">{{ $t("formCustomer.shippingAddresses") }}</label>
        <span class="col-sm-7">
          <card-address v-for="(ad, index) in shippingAddresses"
                        :key="index"
                        id="shippingAddresses"
                        :isShippingAddress="true"
                        :address="ad"
                        :dismissable="index > 0"
                        :invalidErrors="invalidErrors.shippingAddresses && invalidErrors.shippingAddresses[index] ? invalidErrors.shippingAddresses[index].invalid : []">
          </card-address>
        </span>
      </b-list-group-item>

      <!--b-list-group-item>
        <label class="font-weight-bold col-md-5">{{ $t("formCustomer.purchasingContact") }} {{ $t(role(form.purchasingContact.role)) }}</label>
        <span class="col-sm-7">
          <card-contact id="purchasingContact"
                        ref="purchasingContact"
                        :contact="form.purchasingContact"
                        :invalidErrors="invalidErrors.purchasingContact ? invalidErrors.purchasingContact.invalid : []"
                        :assignmentValue="'purchasingContact'"
                        :form="form">
          </card-contact>
        </span>
      </b-list-group-item>

      <b-list-group-item>
        <label class="font-weight-bold col-md-5">{{ $t("formCustomer.administrativeContact") }} {{ $t(role(form.administrativeContact.role)) }}</label>
        <span class="col-sm-7">
          <card-contact id="administrativeContact"
                        ref="administrativeContact"
                        :contact="form.administrativeContact"
                        :invalidErrors=" invalidErrors.administrativeContact ? invalidErrors.administrativeContact.invalid : []"
                        :assignmentValue="'administrativeContact'"
                        :form="form">
          </card-contact>
        </span>
      </b-list-group-item-->

      <!--b-list-group-item v-if="isUserAgentOrCommercial && form.contacts.length"
                         class="row" v-for="(contact, index) in form.contacts" :key="index">
        <label class="font-weight-bold col-md-5">{{ $t(department(contact.department)) }} {{ $t(role(contact.role)) }}</label>
        <span class="col-sm-7">
          <card-contact :contact="contact"
                        :invalidErrors="invalidErrors.contacts ? invalidErrors.contacts.invalid : []"
                        :form="form">
          </card-contact>
        </span>
      </b-list-group-item-->

      <b-list-group-item v-if="isUserAgentOrCommercial">
        <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.tntNextRequired") }}</label>
        <span class="col-sm-7 text-left">{{ $t(form.tntNextRequired ? 'labels.yes' : 'labels.no') }}</span>
      </b-list-group-item>

      <b-list-group-item>
        <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.billing") }}</label>
        <span class="col-sm-7">
          <span v-for="billingEmail in form.billingEmails">{{ billingEmail }},</span>
        </span>
      </b-list-group-item>

      <b-list-group-item>
        <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.transportDocument") }}</label>
        <span v-for="transportDocumentEmail in form.transportDocumentEmails">{{ transportDocumentEmail }},</span>
      </b-list-group-item>

      <b-list-group-item>
        <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.msds") }}</label>
        <span v-for="msdsEmail in form.msdsEmails">{{ msdsEmail }},</span>
      </b-list-group-item>

      <b-list-group-item>
        <label class="font-weight-bold col-sm-5">{{ $t("formCustomer.testSheets") }}</label>
        <span v-for="testSheetsEmail in form.testSheetsEmails">{{ testSheetsEmail }},</span>
      </b-list-group-item>

      <b-list-group-item v-if="form.bankName" style="border-bottom: none;">
        <label class="font-weight-bold col-sm-5">{{ form.bankName }}</label>
        <span class="col-sm-7">{{ form.iban }}</span>
      </b-list-group-item>

      <!-- last item ti fix mobile ui -->
      <b-list-group-item variant="light" class="d-none d-sm-block p-0" style="border-bottom: none;">
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import CardContact from './card-contact.vue';
import cardAddress from './card-address.vue';
import { businessRole } from '@/commons/business-roles';
import { businessDepartments } from '@/commons/business-departments';
import { segmentationTypes } from '@/commons/segmentations-types';

export default {
  name: 'confirmRequest',

  components: {
    CardContact,
    cardAddress,
  },

  props: {
    form: Object(),
    completed: Boolean(),
    invalidErrors: Array(),
  },

  data() {
    return {
      self: 'ConfirmRequest',
    };
  },

  computed: {
    fields() {
      if (this.isUserAgentOrCommercial) {
        return [
          'Anagraphics',
          'CompanyPerspective',
          'Addresses',
          'ContactsAgent',
          'ConfirmRequestAgent',
        ];
      } else {
        return [
          'Anagraphics',
          'Addresses',
          'Contacts',
          'BankInformation',
          'ConfirmRequest',
        ];
      }
    },

    italian() {
      return this.form.vatNumber ? this.form.vatNumber.startsWith('IT') : false;
    },

    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === 'AGENT' || this.$store.getters.getUserRole === 'COMMERCIAL'
    },

    marketSegmentation1() {
      let find = this.form.marketSegmentation1 ? segmentationTypes.find((g) => g.value === this.form.marketSegmentation1) : null;
      return find ? find.text : 'non indicata';
    },

    marketSegmentation2() {
      return this.form.marketSegmentation2 ? segmentationTypes.find((g) => g.value === this.form.marketSegmentation2).text : null;
    },

    marketSegmentation3() {
      return this.form.marketSegmentation3 ? segmentationTypes.find((g) => g.value === this.form.marketSegmentation3).text : null;
    },

    marketSegmentation4() {
      return this.form.marketSegmentation4 ? segmentationTypes.find((g) => g.value === this.form.marketSegmentation4).text : null;
    },

    retailSegmentation() {
      let rs = false;
      this.form.marketSegmentation1 === 'RSL' ? (rs = true) : null;
      this.form.marketSegmentation2 === 'RSL' ? (rs = true) : null;
      this.form.marketSegmentation3 === 'RSL' ? (rs = true) : null;
      this.form.marketSegmentation4 === 'RSL' ? (rs = true) : null;
      return rs;
    },

    businessAddress () {
      return this.form.addresses.find((a) => 'SA' === a.code);
    },

    legalAddress () {
      return this.form.addresses.find((a) => 'SL' === a.code);
    },

    shippingAddresses () {
      return this.form.addresses.filter((a) => a.isShipping);
    },
  },
  methods: {
    role(contactRole) {
      return contactRole ? businessRole.find((r) => r.value === contactRole).text : 'formUser.businessRole';
    },

    department(contactDepartment) {
      return businessDepartments.find((d) => d.value === contactDepartment) ? businessDepartments.find((d) => d.value === contactDepartment).text : 'formUser.businessDepartment';
    },

    validationState(contextRef) {
      return this.invalidErrors.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) ? false : null;
    },

    validationError(contextRef) {
      let find = this.invalidErrors.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase());
      return find ? this.$i18n.t(find.error) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group-item {
  border-radius: 0;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
  }
}
</style>
