<template>
  <div class="card border invite-card" v-if="inviteList.length > 0">

    <!-- title -->
    <h3>{{ $t("titles.invitationsUser") }}</h3>

    <feedback-alert class="w-100"
                    :status="feedbackStatus"
                    :message="feedbackMessage"
                    @clearStatus="clearStatus">
    </feedback-alert>

    <invite-received-item v-for="(invite, index) in inviteList"
                          :key="index"
                          :invitation="invite"
                          @laoadInvites="loadInvites"
                          @notFound="notFound">
    </invite-received-item>
  </div>
</template>

<script>
import { debounce } from "@/util/helpers.js"
import FeedbackAlert from "../../components/feedback-alert.vue"
import CardInvitationCustomer from "../../components/card-invitation-customer.vue"
import InviteReceivedItem from "@/components/invite/invite-received-item";

export default {
  name: "InviteReceivedList",

  components: {
    InviteReceivedItem,
    CardInvitationCustomer,
    FeedbackAlert
  },

  data() {
    return {
      page_index: 0,
      n_rows: 10,
      today: new Date().toISOString().split("T")[0],
      inviteList: [],
      feedbackStatus: null,
      feedbackMessage: "",
    }
  },

  methods: {
    clearStatus() {
      this.feedbackStatus = null
      this.feedbackMessage = ""
    },

    onScroll() {
      if (document.body.scrollHeight - 1500 < window.scrollY) {
        if (
            this.total > 0 &&
            (this.page_index + 1) * this.n_rows < this.total
        ) {
          this.nextDebounder()
        }
      }
    },

    loadInvites() {
      this.getInviteList(this.page_index)
          .then((result) => (this.inviteList = result))
    },

    async getInviteList(page_index) {
      return this.$store.dispatch("getInvitationsUser", { page: page_index, row: this.n_rows })
          .then((response) => {
            //console.log(response)
            return response
          })
          .catch((err) => {
            console.error(err)
            return []
          })
    },

    getNext() {
      let page_index = this.page_index + 1
      this.getInviteList(page_index).then((result) => (this.inviteList = this.inviteList.concat(result)))
    },

    nextDebounder: debounce(function () {
      this.getNext()
    }),

    notFound() {
      this.feedbackStatus = "FailDismissable"
      this.feedbackMessage = "NotFound"
    },

    refuseSuccess() {
      this.feedbackStatus = "SuccessDismissable"
      this.feedbackMessage = "refuseSuccess"
    },

    acceptSuccess() {
      this.feedbackStatus = "SuccessDismissable"
      this.feedbackMessage = "acceptSuccess"
    },

    deleteSuccess() {
      this.feedbackStatus = "SuccessDismissable"
      this.feedbackMessage = "deleteSuccess"
    },

    deleteFail() {
      this.feedbackStatus = "FailDismissable"
      this.feedbackMessage = "deleteFail"
    },
  },

  mounted() {
    this.loadInvites()
    document.addEventListener("scroll", this.onScroll)
  },

  destroyed() {
    document.removeEventListener("scroll", this.onScroll)
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.sendSuccess) {
      next((vm) => {
        vm.feedbackStatus = "SuccessDismissable"
        vm.feedbackMessage = "sendInviteSuccess"
        vm.$router.replace({ query: {} })
      })
    } else if (to.query.sendFail) {
      next((vm) => {
        vm.feedbackStatus = "FailDismissable"
        vm.feedbackMessage = "deleteFail"
        vm.$router.replace({ query: {} })
      })
    } else {
      next({ query: {} })
    }
  },
}
</script>

<style>
.invite-card {
  padding: 20px;
  margin: 10px 20px;
}
</style>
