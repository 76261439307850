<template>
  <div :class="{ 'ps-detail-container' : !viewInLine }">


    <b-container fluid>
      <b-row>
        <b-col cols="12" md="6" class="px-0 bb">
          
          <b-breadcrumb>
            <b-breadcrumb-item v-for="item in bradcrumbItems" :key="item.text"
              :active="item.active || false" 
              :to="item.to || null"
              :text="item.text" 
              @click="breadcrumbAction(item)"></b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
        <b-col cols="12" md="6" class="px-0 bb">
          <!-- back + customer -->
          <products-search-customer v-if="viewInLine" :back-function="closeModal">
            <template v-slot:title>
              <span class="h2 text-uppercase m-0">{{ $t("actions.back") }}</span>
            </template>
          </products-search-customer>
        </b-col>
      </b-row>

      <!-- code + brand + short descr + close modal -->
      <b-row v-if="!viewInLine" class="ps-detail-header bb">
        <b-col class="d-flex justify-content-between ps-detail-title">
          {{ formatProductCode(product.id) }} {{ product.brand }} {{ getShortDesc(product) }}

          <b-button v-if="!viewInLine" @click.stop="closeModal" variant="link">
            <b-icon icon="x"></b-icon>
          </b-button>
        </b-col>
      </b-row>

      <b-row class="bb">
        <b-col>
          <h1>{{ product.brand }}</h1>
          <h2>{{ formatProductCode(product.id) }}</h2>
          <p>{{ getShortDesc(product) }}</p>
        </b-col>
      </b-row>

      <!-- image & description & color + packaging -->
      <b-row class="ps-detail-cp">

        <!-- image -->
        <b-col class="ps-detail-image-wrap" order-md="2">
          <img :src="getPathImg(product)" :alt="product.productId" class="ps-detail-img"/>
        </b-col>

        <b-col cols="12" md="9" order-md="1" class="d-flex justify-content-between flex-column px-0">
          <!-- long descr -->
          <div class="ps-detail-description">{{ getLongDesc(product) }}</div>

          <b-container fluid>
            <!-- color -->
            <b-row class="ps-detail-item-50 bb bt">
              <b-col cols="12" md="2" class="ps-detail-item-50 text-uppercase">
                <b>{{ $t('productsSearch.color') }}</b>
              </b-col>
              <b-col cols="12" md="10" class="ps-detail-content-50 pr-md-0">
                <template v-if="colors.length === 1">
                    <template v-if="colors[0].descr">
                      {{ colors[0].descr }} -
                    </template>
                    {{ colors[0].value }}
                </template>

                <b-form-select v-else v-model="selectedColor" class="ps-detail-select">
                  <b-form-select-option v-for="color in colors" :value="color.value" :key="color.value">{{ color.descr }} - {{ color.value }}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>

            <!-- packaging -->
            <b-row class="ps-detail-item-50 bb">
              <b-col cols="12" md="2" class="ps-detail-item-50 text-uppercase">
                <b>{{ $t('productsSearch.packaging') }}</b>
              </b-col>
              <b-col cols="12" md="10" class="ps-detail-content-50 pr-md-0">
                <template v-if="pkgs.length === 1">
                  <template v-if="pkgs[0].descr">
                    {{ pkgs[0].descr }} -
                  </template>
                  {{ pkgs[0].value }}
                </template>
                <b-select v-else v-model="selectedPkg" class="ps-detail-select">
                  <option v-for="pkg in pkgs" :value="pkg.value" :key="pkg.value">{{ pkg.descr }} - {{ pkg.value }}</option>
                </b-select>
              </b-col>
            </b-row>

          </b-container>
          <b-container fluid>
            <!-- buy -->
            <b-row v-if="showBuy">
              <b-col class="p-0">
                <adw-button class="w-100 text-center"
                      @button-click="buy(product)">
                      <h3>{{ $t("actions.buy") }}</h3>
                </adw-button>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>


    <!-- categories -->
    <b-container fluid class="bb" v-if="product.cat5 || product.cat1 || product.cat2 || product.cat3 || product.cat4">
      <b-row>
        <b-col cols="6" md="auto" class="ps-detail-category text-uppercase" v-if="product.cat5">
          <p><b>{{ $t("productsSearch.filters.areas") }}</b></p>
          <p class="ps-detail-small">{{ getArea(product.cat5) }}</p>
        </b-col>
        <b-col cols="6" md="auto" class="ps-detail-category text-uppercase" v-if="product.cat1">
          <p><b>{{ $t("productsSearch.filters.typologies") }}</b></p>
          <p class="ps-detail-small">{{ getTypology(product.cat1) }}</p>
        </b-col>
        <b-col cols="6" md="auto" class="ps-detail-category text-uppercase" v-if="product.cat2">
          <p><b>{{ $t("productsSearch.filters.families") }}</b></p>
          <p class="ps-detail-small">{{ getFamily(product.cat2) }}</p>
        </b-col>
        <b-col cols="6" md="auto" class="ps-detail-category text-uppercase" v-if="product.cat3">
          <p><b>{{ $t("productsSearch.filters.dilutions") }}</b></p>
          <p class="ps-detail-small">{{ getDilution(product.cat3) }}</p>
        </b-col>
        <b-col cols="6" md="auto" class="ps-detail-category text-uppercase" v-if="product.cat4">
          <p><b>{{ $t("productsSearch.filters.dryings") }}</b></p>
          <p class="ps-detail-small">{{ getDrying(product.cat4) }}</p>
        </b-col>
      </b-row>
    </b-container>


    <!-- product characteristics icons -->
    <template v-if="product.characteristicsIcons.length > 0">
      <div class="ps-detail-item-50 text-uppercase bb mb-3 px-3">
        <b>{{ $t('productsSearch.characteristics') }}</b>
      </div>

      <b-container fluid>
        <b-row align-h="start">
          <b-col class="ps-detail-icon" cols="4" md="auto" v-for="characteristic in product.characteristicsIcons">
            <img :src="getPathIcon(characteristic.icon)" :alt="characteristic.icon"/>
            <br/>{{ $t(characteristic.desc) }}
          </b-col>

          <b-col class="ps-detail-icon" cols="4" md="auto" style="text-align: center" v-if="product.expires && product.expires > 0">
            <img :src="getPathIcon('SCADENZA')" alt="SCADENZA"/>
            <div>{{ $t('productsSearch.shelf') }} {{ product.expires }}{{ $t('productsSearch.months') }}</div>
          </b-col>

          <b-col class="ps-detail-icon" cols="4" md="auto" style="text-align: center" v-if="(product.resalt && product.resalt > 0) || (product.resakg && product.resakg > 0)">
            <img :src="getPathIcon('RESA')" alt="RESA"/>
            <div v-if="product.resalt && product.resalt > 0">{{ product.resalt }} {{ $t('productsSearch.squareMetersPerLitre') }}</div>
            <div v-if="product.resakg && product.resakg > 0">{{ product.resakg }} {{ $t('productsSearch.squareMetersPerKilogram') }}</div>
          </b-col>

          <b-col class="ps-detail-icon" cols="4" md="auto" style="text-align: center" v-if="product.drying">
            <img :src="getPathIcon('ESSICCAZIONE')" alt="ESSICCAZIONE"/>
            <div>{{ $t('productsSearch.dryingIcon') }} {{ product.drying }}</div>
          </b-col>
        </b-row>
      </b-container>
    </template>


    <!-- product characteristics data -->
    <div class="alcea-color">
      <b-table hover
               stacked="sm"
               v-if="characteristicsData.length > 0"
               head-variant="alcea-color"
               :fields="characteristicsCols"
               :items="characteristicsData">

        <template v-slot:cell(actions)="{ item }">
          <adw-button class="text-center border-0"
                @button-click="buyItem(item)">
                <h3>{{ $t("actions.buy") }}</h3>
          </adw-button>
        </template>
      </b-table>
    </div>


    <!-- TDS -->
    <div ref="tds-title" class="ps-detail-item-50 text-uppercase border-bottom mb-3 px-3">
      <b>{{ $t('productsSearch.tds.title') }}</b>
    </div>

    <b-btn @click="getTDSAvailableLanguages" v-b-toggle.collapse-1>{{ $t('productsSearch.tds.download') }} 
      <span class="when-opened">
        <b-icon icon="chevron-down" />
      </span>
      <span class="when-closed">
        <b-icon icon="chevron-right" />
      </span>
     </b-btn>
      <b-collapse id="collapse-1" class="mt-2">
        <div class="ps-detail-tds  px-3">
          <div v-if="isTdsDownloadActive">
            <div>{{ $t('productsSearch.tds.availableLanguages') }}</div>
            <products-search-tds-choice :langs="tdsLangs"></products-search-tds-choice>
          </div>
        </div>
      </b-collapse>

    <!-- modal to open the buy action -->
    <b-modal v-if="showBuy"
             size="lg"
             scrollable
             hide-header
             hide-footer
             centered
             body-class="p-0"
             ref="productsSearchBuyModal">
      <products-search-buy :product="productToBuy"
                           @closeModal="$refs.productsSearchBuyModal.hide()">
      </products-search-buy>
    </b-modal>

    <!-- modal to open login action -->
    <b-modal 
             size="lg"
             scrollable
             hide-header
             hide-footer
             centered
             body-class="p-3"
             ref="loginModal"
             >
      <login-redirect @login='goToLogin' @closeModal="$refs.loginModal.hide()"> </login-redirect>
    </b-modal>

  </div>
</template>

<script>
import { productsSearchB2BCombos } from "@/commons/products-search-b2b-combos"
import ProductsSearchService from "@/services/api-services/products-search-service"
import ProductsSearchBuy from "@/views/ProductsSearch/ProductsSearchBuy";
import productsSearchTdsChoice from "@/views/ProductsSearch/ProductsSearchTdsChoice"
import ProductsSearchCustomer from "@/components/products-search/products-search-customer";
import LoginRedirect from "@/components/login-redirect";
import AdwButton from "@/components/global-components/adw-button";
import { createGetParams } from '@/helpers';

export default {
  name: 'ProductsSearchDetail',

  props: {
    originPage: '',
    productSelected: {},
    productsList: {},
    anchorToTds: Boolean,
    viewInLine: {
      required: false,
      default: false
    },
    showBuy: {
      required: false,
      default: true
    },
  },

  components: {
    ProductsSearchCustomer,
    ProductsSearchBuy,
    productsSearchTdsChoice,
    LoginRedirect,
    AdwButton
  },

  data() {
    return {
      categories: productsSearchB2BCombos.categories,
      intExtIcons: productsSearchB2BCombos.intExtIcons,
      dilutionIcons: productsSearchB2BCombos.dilutionIcons,
      applicationIcons: productsSearchB2BCombos.applicationIcons,
      characteristicsCols: productsSearchB2BCombos.characteristicsCols.map(c => {
        c.label = this.$t(c.label)
        return c
      }),

      selectedColor: null,
      selectedPkg: null,
      characteristicsData: [],
      product: {},
      colors: [],
      pkgs: [],

      productToBuy: null,

      isTdsDownloadActive: false,
      tdsLangs: [],

      showLogin: false,
      bradcrumbItems: [
        
      ]
    }
  },

  created() {
    this.product = this.productSelected
    this.product.characteristicsIcons = this.getCharacteristicsIcons()
    this.selectedColor = this.product.id.color
    this.selectedPkg = this.product.id.pkg
    this.loadColors()
    this.loadPkgs()
    this.loadData()

    if(['b2b', 'b2c'].includes(this.originPage)) {
      this.bradcrumbItems.push({
        text: this.$t('breadcrumb.productSearch'),
        to: { path: '/products-search' }
      })

      this.bradcrumbItems.push({
        text: this.originPage == 'b2c' ? this.$t('breadcrumb.private') : this.$t('breadcrumb.professional'),
        clickAction: this.closeModal
      });
    }

    if(this.originPage == 'cart' || this.originPage == 'orderedProduct') {
      this.bradcrumbItems.push({
        text: this.$t('breadcrumb.profileCustomer'),
        to: { path: '/profile-customer' }
      })
      
      this.bradcrumbItems.push({
        text: this.originPage == 'cart' ? this.$t('breadcrumb.cart') : this.$t('breadcrumb.orderedProducts'),
        clickAction: this.closeModal
      });
    }
      

      this.bradcrumbItems.push({
        text: this.product.brand + ' ' + this.formatProductCode(this.product.id),
        active: true
      });
      
  },

  watch: {
    selectedColor() {
      this.updateProduct()
    },

    selectedPkg() {
      this.updateProduct()
    }
  },

  methods: {
    breadcrumbAction(bItem) {
      console.log('breadClick ! ! ! ')
      if(bItem.clickAction) 
        bItem.clickAction();
    },
    closeModal() {
      this.$emit('closeModal')
    },

    getShortDesc(p) {
      return this.getDesc(p, 'ShortDesc')
    },

    getLongDesc(p) {
      return this.getDesc(p, 'LongDescr')
    },

    getColorDesc(p) {
      return this.getDesc(p, 'ColorDesc')
    },

    getDesc(p, label) {
      const desc = p[this.$store.getters.getUser.language + label]
      if (desc) {
        return desc
      }
      return p['it' + label]
    },

    formatProductCode(id) {
      return id.serial + '-' + id.color + '-' + id.pkg
    },

    getArea(val) {
      return this.$t(this.categories.areas[val])
    },

    getTypology(val) {
      return this.$t(this.categories.typologies[val])
    },

    getFamily(val) {
      return this.$t(this.categories.families[val])
    },

    getDilution(val) {
      return this.$t(this.categories.dilutions[val])
    },

    getDrying(val) {
      return this.$t(this.categories.dryings[val])
    },

    getCharacteristicsIcons() {
      let icons = []

      if (this.product.applications) {
        this.product.applications.split(',').forEach(app => {
          if (this.applicationIcons[app]) {
            icons.push(this.applicationIcons[app])
          }
        })
      }

      if (this.intExtIcons[this.product.intest]) {
        icons.push(this.intExtIcons[this.product.intest])
      }

      if (this.dilutionIcons[this.product.diluent]) {
        icons.push(this.dilutionIcons[this.product.diluent])
      }

      return icons
    },

    loadData() {
      this.characteristicsData = []
      this.productsList
          .filter(p => p.id.color === this.product.id.color)
          .forEach(p => {
            this.characteristicsData.push({
              code: this.formatProductCode(p.id),
              desc: this.getColorDesc(p),
              specWeight: p.dna,
              unitValue: p.um + ' ' + p.qta + this.getProductAvailableMsg(p)
            })
          })

      if(this.anchorToTds) {
        let element = this.$refs['tds-title'];
        let top = element ? element.offsetTop : 0;
        console.log('anchorToTds', top)
        window.scrollTo(0, top);
        this.getTDSAvailableLanguages()
      }
    },

    loadColors() {
      this.colors = []

      const _tmp = {}
      this.productsList
          .filter(p => p.id.pkg === this.product.id.pkg)
          .forEach(p => {
            _tmp[p.id.color] = {
              value: p.id.color,
              descr: this.getColorDesc(p)
            }
          })

      this.colors = Object.values(_tmp).sort(function (p1, p2) {
        return p1.descr.localeCompare(p2.descr)
      })
    },

    loadPkgs() {
      this.pkgs = []

      const _tmp = {}
      this.productsList
          .filter(p => p.id.color === this.product.id.color)
          .forEach(p => {
            const _avail = this.getProductAvailableMsg(p)
            _tmp[p.id.pkg] = {
              value: p.id.pkg,
              descr: p.um + ' ' + p.qta + _avail
            }
          })

      this.pkgs = Object.values(_tmp).sort(function (p1, p2) {
        try {
          const _val1 = parseFloat(p1.descr.split(' ')[1])
          const _val2 = parseFloat(p2.descr.split(' ')[1])
          return _val1 - _val2
        } catch (err) {
          console.error(err)
          return 0
        }
      })
    },

    updateProduct() {
      this.product = this.productsList
          .filter(p => p.id.color === this.selectedColor)
          .filter(p => p.id.pkg === this.selectedPkg)[0]

      this.product.characteristicsIcons = this.getCharacteristicsIcons()
      this.loadColors()
      this.loadPkgs()
      this.loadData()
    },

    getPathIcon(val) {
      const images = require.context('@/assets/products-search/icon/', false, /\.png$/)
      try {
        return images("./" + val + ".png")
      } catch (err) {
        return ''
      }
    },

    getPathImg(product) {
      
      if(product.id.productClass != 3) {
        return null;
      }

      const defaultPkg = "ALC"
      const images = require.context('@/assets/products-search/product/', false, /\.png$/)
      try {
        return images("./" + product.lat + ".png")
      } catch (err) {
        if (product.cat3 === 'W') {
          return images("./" + defaultPkg + "W.png")
        } else if (product.cat3 === 'S') {
          return images("./" + defaultPkg + "S.png")
        } else {
          return images("./" + defaultPkg + ".png")
        }
      }
    },

    getProductAvailableMsg(p) {
      return p.tsc === 'S' ? ' ' + this.$t('productsSearch.pkgAvailable') : ''
    },

    buyItem(item) {
      console.log('buyItem', item)

      // retrieve the product by color and package
      const itemSplit = item.code.split('-')
      const product = this.productsList
          .filter(p => p.id.color === itemSplit[1])
          .filter(p => p.id.pkg === itemSplit[2])[0]
      this.buy(product)
    },

    buy(product) {
      console.log('buy', product)
      this.productToBuy = product

      // show product selected into a modal
      this.$refs.productsSearchBuyModal.show()
    },

    getTDSAvailableLanguages() {
      ProductsSearchService.getTDSAvailableLanguages({
        serial: this.product.id.serial,
        color: this.product.id.color
      })
          .then((response) => {
            console.log('getTDSAvailableLanguages', response)
            this.tdsLangs = []
            response.data.forEach(v => this.tdsLangs.push({ id: v.id, lang: v.language, code: v.product }))
            console.log('this.tdsLangs', this.tdsLangs)
            this.isTdsDownloadActive = true
          })
          .catch((error) => {
            console.log(error)

            this.$refs.loginModal.show()
            this.isTdsDownloadActive = false
          })
    },

    goToLogin() {
      const redirectLink = '/products-search' + '?' + createGetParams(this.$route.query) + '&' + createGetParams({color: this.productSelected.id.color}) + '&' + createGetParams({showTds: true});

      console.log(redirectLink);

      this.$router.push({
        path: '/login', 
        query: {redirectLink: redirectLink},
      })
    }
  }
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>