import http from "@/util/http-common";

class AgentService {
  async getMyCustomerProfiles(page, size, sortBy, sortDir) {
    let params = {
      size: size,
      page: page,
      sort: sortBy + "," + sortDir,
    };
    return http.get(`v1/public/agent/customer-profiles`, { params });
  }

  async getSearchCustomerProfile(search) {
    return http.get(
      `v1/public/agent/customer-profiles?customerCode=${search}&businessName=${search}`
    );
  }

  async getSingleCustomerProfile(code) {
    return http.get(`v1/public/agent/customer-profiles/${code}`);
  }

  async getMyCustomerRequest(page, size, sortBy, sortDir) {
    let params = {
      size: size,
      page: page,
      sort: sortBy + "," + sortDir,
    };
    return http.get(
      `v1/public/agent/customer-requests/enter-customer-requests/types/managed`,
      { params }
    );
  }

  async getCustomerRequestDraft(id) {
    return http.get(`v1/public/agent/customer-requests/enter-customer-requests/types/managed/drafts/${id}`);
  }

  async draftCustomerRequest(form) {
    return http.put(
      `v1/public/agent/customer-requests/enter-customer-requests/types/managed/drafts`,
      form
    );
  }

  async deleteCustomerRequest(id) {
    return http.delete(
      `v1/public/agent/customer-requests/enter-customer-requests/types/managed/drafts/${id}`
    );
  }

  async postCustomerRequest(form) {
    return http.post(
      `v1/public/agent/customer-requests/enter-customer-requests/types/managed/pendings`,
      form
    );
  }

  async postEditRequest(form) {
    return http.post(
      `v1/public/agent/customer-profiles/customer-requests/update-customer-requests/pendings`,
      form
    );
  }

  async checkCustomerEditPending(code) {
    return http.head(
      `v1/public/agent/customer-profiles/${code}/customer-requests/update-customer-requests/pendings`
    );
  }
}

export default new AgentService();
