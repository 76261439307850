export const product = {
  searchProducts: "Rechercher produits",
  productCode: "Code produit",
  productNameCode: "Nom ou code produit ou description",
  brand: "Marqu",
  color: "Couleur",
  serialCode: "Code serie",
  colorCode: "Code couleur",
  packaging: "Emballage",
  pkgAvailable: "EN STOCK",
  area: "Secteur",
  typology: "Typologie",
  family: "Famille",
  dilution: "Diluition",
  dilution_water: "dilution à l'eau",
  dilution_solvent: "dilution au solvant",
  drying: "Séchage",
  dryingIcon: "Séchage",
  appMethod: "Methode d'app.",
  results: "REVENIR AU RÉSULTAT DE LA RECHERCHE",
  noResults:
    "Votre recherche n'a donné aucun résultat. Si vous avez besoin d’informations ou avez une question, veuillez contacter le service clientèle: alcea@alcea.com",
  characteristics: "Caractéristiques ",
  downloadTds: "TÉLÉCHARGER LA FT EN FORMAT PDF",
  loginToDownloadTds: "Connectez-vous pour télécharger la ft",
  noTdsFound: "Aucun TDS trouvé",
  code: "Code",
  description: "Description",
  specificWeight: "Rechercher produits",
  unitValue: "Quantité unitaire",
  months: "mois",
  usage: "utilisation",
  internal_use: "usage intérieur",
  external_use: "usage extérieur",
  external_internal_use: "usage intérieur/extérieur",
  shelf: "conservation",
  squareMeters: "mc",
  squareMetersPerLitre: "mc/l",
  squareMetersPerKilogram: "mc/kg",
  buttons: {
    downloadTds: "TÉLÉCHARGEMENT FT",
  },
  combos: {
    interested: {
      metalIndustry: "Metal Industry",
      woodIndustry: "Wood industry",
      coilCoating: "Coil Coating",
      construction: "Construction",
      homeLine: "Home line",
      tintometrics: "Tintometrics",
    },
    areas: {
      edil: "PRODUITS (BATIMENTS)",
      metal: "INDUSTRIE DU MÉTAL",
      wood: "INDUSTRIE DU BOIS",
      edil_metal_wood: "BUILDING-METAL-WOOD",
      coil: "COIL COATING",
      plastic: "PLASTURGIE TOUS SECTEURS",
      paste:
        "PATÊS PIGMENTAIRES, BASES INCOLORES, CATALYSEURS POUR DISTRIBUTEURS",
      corrosion: "ANTICORROSION, MARINE, ETCC",
    },
    typologies: {
      dye: "TEINTES POUR BOIS, PATINES, TEINTES CONCENTRÉES POUR BOIS, ENCRES",
      paste: "RECHERCHER PRODUITS",
      background: "PRIMAIRE ANTICORROSION",
      intermediate: "SEMI-FINIS",
      washable: "PEINTURES BATIMENT",
      various: "DIVERS",
      catalyst: "CATALYSEURS",
      thinner: "DILUANTS",
      converter: "BASES INCOLORE",
      dust: "PEINTURE EN POUDRE (THERNOLAQUAGE)",
      back: "REVENIR",
      impregnant: "LASURES",
      coat: "COUCHE DE FINITION",
      raw_material: "RAW MATERIALS",
      wp_floors_resins: "PRODUITS D’IMPERMEABILISATION, SOLS, RESINES",
    },
    families: {
      acrylic: "ACRYLIQUES",
      pvdf: "PVDF",
      epoxy: "ÉPOXY",
      ketone: "KETONE",
      polyester: "POLYESTER/ISOCYANATE",
      alkyl: "GLYCÉRO",
      nitrocellulose: "NITROCELLULOSIQUE",
      unsaturated: "POLYÉSTER",
      clorocianuro: "CLOROCIANURO",
      phenolic: "PHENOLIQUES",
      silicones: "SILICONES",
      urethane: "URETHANE",
      vinyl: "VINILIQUES",
      polyester_melamine: "POLYESTER/MELAMINE",
    },
    dilutions: {
      water: "BASE HYDRO",
      dust: "POUDRE",
      solvent: "SOLVANTÉES",
      noSolvent: "SANS SOLVENTE",
    },
    dryings: {
      air: "MONOCOMPOSANT",
      catalyzed: "BICOMPOSANT",
      electrophoretic: "ÉLÉCTROPHORESE",
      oven: "AU FOUR",
      curing: "UV RÉTICULANT",
    },
    appMethods: {
      brush: "pinceau",
      roller: "rouleau",
      sprinkle: "air-spray",
      airMix: "air-mix",
      airLess: "air-less",
      spatula: "spatule",
      sponge: "éponge",
      rollerCoating: "application au rouleau",
      curtainCoater: "machine à rideau",
      immersion: "application par trempé",
      trowel: "lisseuse",
      cloth: "chiffon",
      conventional: "pistolet pneumatique",
      flowCoatings: "flow-coat",
      hplv: "hplv",
      electrostaticSpray: "electrostatique",
      electrophoresisNS: "électrophorèse ns",
      anode: "ÉLÉCTROPHORESE ANODIQUE",
      cathode: "ÉLÉCTROPHORESE CATHODIQUE",
    },
  },
};
