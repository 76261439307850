<template>
  <section>
    <div class="private-area-row-100 px-3 text-uppercase border-bottom justify-content-between">
      <h3>{{ $t("titles.personalInformation") }}</h3> 
      <small>* Campi bbligatori</small>
    </div>

    <div class="private-area-box">
      <b-form-group>
        <b-form-select v-model="form.gender">
          <b-select-option v-for="g in genders" :value="g.value" :key="g.value">
            {{ $t("formUser.gender." + g.text) }}
          </b-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group id="name" :invalid-feedback="validationError('firstname')">
        <b-form-input
            class="form-control"
            id="firstname"
            ref="firstname"
            v-model.trim="form.firstname"
            :placeholder="$t('formUser.firstName') + ' *'"
            :state="validationState('firstname')">
        </b-form-input>
      </b-form-group>

      <b-form-group :invalid-feedback="validationError('lastname')">
        <b-form-input
            class="form-control"
            id="lastName"
            ref="lastName"
            v-model.trim="form.lastname"
            :placeholder="$t('formUser.lastName') + ' *'"
            :state="validationState('lastname')">
        </b-form-input>
      </b-form-group>

      <b-form-group :invalid-feedback="validationError('username')">
        <b-form-input
            class="form-control"
            id="username"
            ref="username"
            v-model.trim="form.username"
            type="email"
            :placeholder="$t('formUser.email') + ' *'"
            :state="validationState('username')">
        </b-form-input>
      </b-form-group>

      <b-form-group :invalid-feedback="validationError('confirmusername')">
        <b-form-input
            class="form-control"
            id="confermaEmail"
            ref="confirmUsername"
            v-model.trim="confirmusername.value"
            type="email"
            :placeholder="$t('formUser.repeatEmail') + ' *'"
            :state="validationState('confirmusername')">
        </b-form-input>
      </b-form-group>

      <telephone-component
          ref="telephone"
          :formType="form.type"
          :invalid="invalid"
          :telephoneProp="telephoneProp"
          v-model="form.telephone">
      </telephone-component>

      <b-form-group :invalid-feedback="validationError('country')">
        <b-form-select
            ref="country"
            v-model="form.country"
            required
            :state="validationState('country')">
          <b-select-option selected disabled :value="null">
            {{ $t("formUser.country") }} *
          </b-select-option>
          <b-select-option
              v-for="country in countries"
              :value="country.value"
              :key="country.key">
            {{ country.label }}
          </b-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group class="mb-0 pb-3" :invalid-feedback="validationError('zipCode')">
        <b-form-input
            class="form-control"
            id="zipCode"
            ref="zipCode"
            v-model.trim="form.zipCode"
            :placeholder="$t('formUser.zipCode') + ' *'"
            :state="validationState('zipCode')">
        </b-form-input>
      </b-form-group>

      <template v-if="form.type === 'P'">
        <b-form-group class="mb-0 pb-3" :invalid-feedback="validationError('interested')">
          <b-form-select v-model="form.interested" :state="validationState('interested')">
            <b-select-option selected disabled :value="null">
              {{ $t("formUser.intersted") }} *
            </b-select-option>
            <b-select-option v-for="se in sectors" :value="se.value" :key="se.key">
              {{ $t(se.text) }}
            </b-select-option>
          </b-form-select>
        </b-form-group>
      </template>
    </div>
  </section>
</template>

<script>
import TelephoneComponent from './global-components/telephone-component.vue';

export default {
  name: "form-private",
  components: { TelephoneComponent },
  props: {
    formPrv: Object(),
    countries: Array(),
    sectors: Array(),
    genders: Array(),
    telephoneProp: Object(),
    confirmusername: Object(),
    invalid: Array(),
  },
  data() {
    return {};
  },
  methods: {
    validationError(contextRef) {
      if (this.invalidList.length > 0) {
        let find =
            this.invalidList.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
        ;
      }
    },
    //validate onFocus//Blur
    validationState(contextRef) {
      return this.invalidList.find(
          (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
          ? false
          : null; //context.$on("focus", this.facused=contextRef)
    },
  },
  computed: {
    telephone: {
      get() {
        return this.$refs.telephone.telephone
      }
    },
    invalidList: {
      get() {
        return this.invalid;
      },
      set(invalidated) {
        this.$emit("input", invalidated);
      },
    },
    form: {
      get() {
        return this.formPrv;
      },
      set(form) {
        this.$emit("complete", form);
      },
    },
    repeatusername: {
      get() {
        return this.confirmusername;
      },
      set(repeatusername) {
        //console.log(this.confirmusername, ' and ', this.repeatusername, ' and ', repeatusername);
        this.$emit("complete", repeatusername);
      },
    },
  },
  mounted() {
  },
};
</script>
<style>
</style>
