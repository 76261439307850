<template>
  <div>

    <!-- customer info -- >
    <div class="pc-title left adw-pointer" @click.stop="$router.push('/profile-customer')">
      <h3 :title="customerInfo.businessName">{{ customerInfo.businessName }}</h3>
      <h2 class="mt-2">{{ $t("labels.customerCode") }}: {{ customerInfo.customerCode }}</h2>
    </div> -->

    <!-- customer selected section -->
    <div class="pc-links">
      <!-- link: agent section -->
      <h3 class="back"
          @click.stop="$router.push('/profile-agent')"
          v-if="isUserAgentOrCommercial">
        {{ $t("titles.backToAgent") }}
      </h3>

      <!-- link: customer profile -->
      <h3 @click.stop="$router.push('/profile-customer')"
          :class="$route.path === '/profile-customer' ? 'selected' : ''">
        {{ $t("titles.profileCustomer") }}
      </h3>

      <!-- link: customer invitations -->
      <h3 @click.stop="$router.push('/profile-customer/invite-list')"
          v-if="userType === 'C' && !isUserAgentOrCommercial"
          :class="$route.path === '/profile-customer/invite-list' ? 'selected' : ''">
        {{ $t("titles.invitations") }}
      </h3>

      <!-- link: customer ordered products -->
      <h3 @click.stop="$router.push('/profile-customer/ordered-products')"
          :class="$route.path === '/profile-customer/ordered-products' ? 'selected' : ''">
        {{ $t("titles.orderedProducts") }}
      </h3>

      <!-- link: cart -->
      <h3 @click.stop="$router.push('/profile-customer/cart')"
          :class="$route.path === '/profile-customer/cart' ? 'selected' : ''">
        {{ $t("titles.cart") }} <b-badge pill class="rounded float-right ml-auto mr-2" variant="primary" v-if="cartItems > 0">{{cartItems}}</b-badge>

      </h3>

      <!-- link: customer offer requests -->
      <h3 @click.stop="$router.push('/profile-customer/request-offers')"
          :class="$route.path === '/profile-customer/request-offers' ? 'selected' : ''">
        {{ $t("subtitles.requestOffers") }}
      </h3>

      <!-- link: customer order requests -->
      <h3 @click.stop="$router.push('/profile-customer/request-orders')"
          :class="$route.path === '/profile-customer/request-orders' ? 'selected' : ''">
        {{ $t("subtitles.requestOrders") }}
      </h3>

      <!-- link: customer orders -->
      <h3 @click.stop="$router.push('/profile-customer/orders')"
          :class="$route.path === '/profile-customer/orders' ? 'selected' : ''">
        {{ $t("titles.orders") }}
      </h3>
      <!-- link: customer service (contacts) -->
      <h3 @click.stop="$router.push('/contact-area')"
          :class="$route.path === '/contact-area' ? 'selected' : ''">
        {{ $t("titles.contactArea") }}
      </h3>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "profile-sidebar",

  computed: {
    ...mapState({
      customerInfo: (state) => state.customer.customerinfo,
      userType: (state) => state.auth.user.type,
    }),

    cartItems() {
      return this.$store.getters.getTotalProductsInCart;
    },


    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },
  },
}
</script>
