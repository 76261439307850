<template>
  <div class="ps-item-container bx by">

    <!-- name -->
    <h6 class="ps-item-name">
      {{ productSelected.name }}
    </h6>

    <div class="ps-item-code">
      <!-- code -->
      {{ productSelected.code }}

      <!-- brand -->
      <span v-if="productSelected.brand != null">{{ productSelected.brand }}</span>
    </div>

    <!-- product image -->
    <div class="ps-item-img"><img :src="getPathImg()" :alt="productSelected.name"/></div>

    <!-- go to detail -->
    <b-col class="ps-bottom bt">
      <b-row>
        <b-col class="ps-item-label text-uppercase">
          <b>{{ $t('actions.showProduct') }}</b>
        </b-col>
        <b-col class="flex-grow-0 bl ps-item-button">
          <span>+</span>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
export default {
  name: 'ProductsSearchItem',

  props: ['productSelected'],

  data() {
    return {
      defaultImage: 'ALC',
    }
  },

  methods: {
    getPathImg() {
      const images = require.context('@/assets/products-search/product/', false, /\.png$/)
      try {
        return images("./" + this.productSelected.image + ".png")
      } catch (err) {
        return images("./" + this.defaultImage + ".png")
      }
    }
  }
}
</script>
