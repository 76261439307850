export const faq = {
    demo1Title: "<strong>DEMO 1</strong> La registrazione ADW. Come mi registro?",
    demo1Body: "Per effettuare la registrazione al sito cliccare su <strong>ACCEDI</strong>  e poi <strong>REGISTRATI</strong>",
    demo2Title: "<strong>DEMO 2</strong> Il catalogo ALCEA. La ricerca prodotti",
    demo2Body: "Una volta registrato come azienda puoi ricercare i prodotti ALCEA nella sezione <strong>PROFESSIONISTA</strong>.<br/>Vi sono 3 modalità di ricerca. Per nome o codice prodotto o descrizione, per serie e colore ed in ultimo per caratteristiche. Ti consigliamo comunque la prima modalità di ricerca ovvero per codice anche incompleto. All'interno della pagina seleziona il prodotto di tuo interesse e si aprirà la scheda prodotto. Attraverso delle semplici immagini ti verrà offerta una descrizione del prodotto, potrai visualizzare i lattaggi disponibili e scaricare la scheda tecnica in diverse lingue.<br/>",
    demo3Title: "<strong>DEMO 3</strong> Sono già cliente ALCEA. Come posso richiedere l’attivazione del mio profilo cliente?",
    demo3Body: "Se ti sei già registrato sulla piattaforma ALCEA e sei un nostro cliente vai nella tua area privata e fai richiesta di attivazione cliccando <strong>AREA PRIVATA</strong>. Inserisci il tuo codice cliente che trovi sulle conferme d’ordine o in fattura e richiedi l’abbinamento. Via mail riceverai conferma dell’ attivazione della tua area privata dove troverai lo storico dei prodotti da te acquistati ed il tuo carrello.<br/>",
    demo4Title: "<strong>DEMO 4</strong> I prodotti ALCEA. Come posso ordinare un prodotto?",
    demo4Body: "Una volta che il tuo profilo cliente è stato attivato potrai accedere allo storico dei prodotti. Qui trovi tutte le informazioni relativamente ai prodotti da te acquistati . La ricerca è semplice. Puoi scorrere l’elenco prodotti e selezionare il prodotto di tuo interesse oppure puoi inserire il codice in alto nella pagina e cliccare sul bottone <strong>CERCA</strong> a destra.<br/><br/>Per procedere nell’acquisto clicca Il tasto in basso a destra <strong>AGGIUNGI AL CARRELLO</strong>. Una volta nel carrello definisci le quantità per ogni articolo e solo selezionando i prodotti di tuoi interesse il tasto <strong>RICHIEDI ORDINE</strong> in basso a destra si attiva per procedere.<br/>Seleziona il tuo indirizzo di consegna, specifica se vuoi nel campo dedicato il tuo numero d’ordine. Seleziona la data di evasione preferibile della tua richiesta d’ordine. Ti consigliamo di selezionare sempre spedizione frazionabile. Per comunicare con noi puoi utilizzare i campi note di spedizione relative alla consegna ( per esempio orari , festività o altro ) e note generiche dove puoi scriverci in merito ai prodotti acquistati. Se vuoi modificare una quantità puoi tornare al carrello cliccando <strong>TORNA AL CARRELLO</strong>. Ricordati in questo caso di aggiornare il tuo carrello una volta modificato. A conclusione riceverai una mail di conferma della tua richiesta d’ordine e successivamente ti verrà inviato il documento in formato pdf come avviene abitualmente.<br/>",
    demo5Title: "<strong>DEMO 5</strong> Il carrello ALCEA. Non trovo un prodotto, cosa faccio?",
    demo5Body: "Se all’interno della sezione prodotti ordinati non trovo il prodotto di mio interesse mi devo spostare in <strong>RICERCA PRODOTTI IN ALTO NELLA PAGINA</strong> e cliccare la sezione professionista. Digitare il nome o il codice del prodotto utilizzando il motore di ricerca. Una volta trovato il prodotto, clicca <strong>ACQUISTA</strong> per aggiungerlo al tuo carrello. Il prodotto verrà trasferito in automatico<br/>",
    demo6Title: "<strong>DEMO 6</strong> Le offerte ALCEA. Richiedere offerta per prezzo non assegnato",
    demo6Body: "Mentre stai formalizzando un ordine dal tuo carrello  potrai vedere alcuni prodotti per i quali non ti è stato assegnato un prezzo. Non preoccuparti. Metti in ogni caso il prodotto nel carrello, seleziona la quantità, clicca  il prodotto e procedi con l’offerta compilando i campi obbligatori e cliccando richiedi offerta. Ti  risponderemo quanto prima.<br/>Può capitarti anche di non trovare nel tuo storico il prodotto di tuo interesse.  Consulta il nostro catalogo cliccando su ricerca prodotti in alto nella pagina, Individua il prodotto di tuo interesse, clicca acquista e aggiungilo al carrello. Come vedrai sarà possibile da qui solo la funzione richiedi offerta e compila i campi obbligatori per procedere<br/>Per consultare le tue richieste d’offerta clicca sulla sezione <strong>RICHIESTE OFFERTE</strong>. Una volta che la tua richiesta è stata accettata  potrai procedere direttamente con il tuo ordine cliccando su richiedi ordine.<br/>",
};