export const formCheckout = {
  shippingAddress: "Dirección de expedición",
  deliveryDateRequested: "Fecha de entrega preferida",
  acceptMultipleShipping: "Acepta expediciones a cuenta",
  customerOrderCode: "Código de pedido personalizado",
  shippingNote: "Notas de expedición",
  genericNote: "Notas generales",
  totalOrder: "Total estimado del pedido",
  totalOffer: "Total estimado de la oferta"
};
