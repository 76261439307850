<template>
  <div>
    <b-form v-if="!submitted">
      <b-container fluid>
        <b-row>

          <!-- left section -->
          <b-col class="px-0" style="background-color: #bc997d;">
            <div class="registration-background"></div>
          </b-col>

          <!-- left section -->
          <b-col cols="12" md="8" class="private-area-left bck px-0">
            <div class="private-area-row-100 px-3 text-uppercase by">
              <h3>{{ $t("titles.register") }}</h3>
            </div>
            <div class="bb">
              <div class="private-area-box">
                <div class="p-2">Seleziona la tipologia di profilo</div>

                <!-- choose a type -->
                <b-form-group class="mb-0 pb-3">
                  <b-form-radio-group class="w-100 pt-2 pl-5"
                                      id="btn-radios-2"
                                      v-model="form.type"
                                      :options="options"
                                      name="radio-btn-outline">
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>


            <!-- form for common data -->
            <form-private v-if="form.type"
                          ref="private"
                          :formPrv="form"
                          :countries="countries"
                          :sectors="sectors"
                          :genders="genders"
                          :telephone="telephone"
                          :confirmusername="confirmusername"
                          :invalid="invalid">
            </form-private>

            <!-- form for company data -->
            <form-company v-if="form.type === 'C'"
                          :formCmp="form"
                          :types="types"
                          :departments="departments"
                          :roles="roles"
                          :invalid="invalid">
            </form-company>

            <!-- check boxes -->
            <template v-if="form.type">
              <b-form-group class="bt pl-3 pt-3">
                <b-form-checkbox id="privacyAccepted"
                                 v-model="form.privacyAccepted"
                                 name="privacyAccepted"
                                 :state="validationState('privacyAccepted')">
                  {{ $t("formUser.authorizeGdpr") }}
                  <a :href="$t('iubenda.linkPrivacy')" target="_blank" title="Privacy Policy">{{ $t('formUser.textLinkGdpr') }}</a><br/>
                </b-form-checkbox>

                <b-form-checkbox id="newsletter"
                                 v-model="form.newsletterSubscribed"
                                 name="newsletter"
                                 class="mt-1"
                                 :state="validationState('newsletterSubscribed')">
                  {{ $t("formUser.subscribeToNewsletter") }}, {{ $t("formUser.textLinkNewsletterGdpr") }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit button -->
              <b-container fluid>
                <b-row align-h="end">
                  <b-col cols="12" md="6" class="px-0">
                    <adw-button class="w-100"
                          @button-click="submit">
                          <h3>{{ $t("actions.signUp") }}</h3>
                    </adw-button>
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <div class="row justify-content-center">
      <div class="col-md-8">
        <feedback-alert v-if="submitted"
                        :status="feedbackStatus"
                        :message="feedbackMessage"
                        @clearStatus="clearStatus"
                        @back="back">
        </feedback-alert>
      </div>
    </div>
  </div>
</template>

<script>
import FormCompany from "../components/form-company.vue";
import FeedbackAlert from "../components/feedback-alert.vue";
import FormPrivate from "../components/form-private.vue";
import AdwButton from "@/components/global-components/adw-button";

import countries from "../commons/countries";
import { interestedPrivate } from "../commons/interested-private";
import { businessDepartments } from "../commons/business-departments";
import { businessTypes } from "../commons/business-types";
import { businessRole } from "../commons/business-roles";
import Validator from "../services/validation-service/validator-service";
import ErrorManager from "../services/errors-service/errors-manager";

export default {
  name: "Register",

  components: {
    FormPrivate,
    FormCompany,
    FeedbackAlert,
    AdwButton,
  },

  data() {
    return {
      submitted: false,
      form: {
        privacyAccepted: false,
        type: null,
        gender: "F",
        firstname: "",
        lastname: "",
        username: "",
        telephone: null,
        country: null,
        zipCode: "",
        interested: null,
        language:
            navigator.language.replace("-", "_") ||
            navigator.userLanguage.replace("-", "_"),
        businessName: "",
        businessWebsite: "",
        businessType: null,
        businessDepartment: null,
        businessRole: null,
        newsletterSubscribed: false,
      },

      genders: [
        { text: "mrs", value: "F" },
        { text: "mr", value: "M" },
      ],
      confirmusername: { value: "" },
      telephone: {
        telephonePrefix: null,
        number: "",
      },
      sectors: interestedPrivate,
      types: businessTypes,
      countries: countries,
      departments: businessDepartments,

      feedbackMessage: "",
      feedbackStatus: null,
      invalid: [],
      otherError: { name: null, msg: null },
    };
  },

  computed: {
    options() {
      return [
        { text: this.$i18n.t("formUser.private"), value: "P" },
        { text: this.$i18n.t("formUser.company"), value: "C" },
      ];
    },
    // encodedUrl() {
    //   if (
    //     this.form.businessWebsite == "" ||
    //     this.form.businessWebsite == null
    //   ) {
    //     return null;
    //   } else {
    //     try {
    //       if (
    //         !this.form.businessWebsite.startsWith("http://") &&
    //         !this.form.businessWebsite.startsWith("https://")
    //       ) {
    //         this.form.businessWebsite = "https://" + this.form.businessWebsite;
    //       }
    //       return new URL(this.form.businessWebsite).origin;
    //     } catch (e) {
    //       return e;
    //     }
    //   }
    // },
    roles() {
      if (this.form.businessDepartment) {
        return businessRole.filter((r) =>
            businessDepartments
                .find((d) => d.value == this.form.businessDepartment)
                .roles.includes(r.value)
        );
      }
    },
  },

  methods: {
    back() {
      if (this.feedbackStatus == "Success") {
        this.$router.push("/login");
      }
      this.submitted = false;
    },
    addInvalid(invalidField, validation) {
      let invalidElement = { name: invalidField, error: validation.error };
      this.invalid.push(invalidElement);
    },

    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },

    validationState(contextRef) {
      return this.invalid.find(
          (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
          ? false
          : null;
    },

    validationError(contextRef) {
      if (this.invalid.length > 0) {
        let find =
            this.invalid.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
        ;
      }
    },
    submit(event) {
      this.otherError = { name: null, msg: null };
      event.preventDefault();
      //this.form.businessWebsite = this.encodedUrl;
      let extravalues = {
        telephone: {
          name: "telephonePrefix",
          value: this.$refs.private.telephone,
        },
        username: {
          name: "confirmusername",
          value: this.confirmusername.value,
        },
        zipCode: { name: "country", value: this.form.country },
        businessWebsite: {
          name: "encodedUrl",
          value: this.form.businessWebsite,
        },
      };

      let formType;
      let UserType = this.form.type;

      if (UserType === "P") {
        formType = "formUserPrivate";
      } else if (UserType === "C") {
        formType = "formUserCompany";
      }

      try {
        let validation = Validator.validateForm(
            formType,
            this.form,
            extravalues
        );
        this.invalid = validation.invalid;
      } catch (e) {
        console.error(e);
        this.otherError.name = "validation";
        this.otherError.msg = e;
        this.feedbackStatus = "Fail";
        this.feedbackMessage = ErrorManager.feedback(error);

        this.invalid.push(this.otherError);
      }
      if (this.invalid.length > 0 || this.otherError.msg) {
        console.error("possible 409");
        console.error(this.invalid);
        console.error(this.otherError);
      } else {
        this.submitted = true;
        this.postForm();
      }
    },
    async postForm() {
      this.feedbackStatus = "Sending";
      this.feedbackMessage = "";
      await this.$store
          .dispatch("register", this.form)
          .then(() => {
            this.submitted = true;
            this.feedbackStatus = "Success";
            this.feedbackMessage = "SuccessRegiatration";
          })
          .catch((error) => {
            this.submitted = true;
            console.error(error);
            this.otherError.name = "registration";
            this.otherError.msg = error;
            this.feedbackStatus = "Fail";
            this.feedbackMessage = ErrorManager.feedback(error);
          });
    },
  },
};
</script>
