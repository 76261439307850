<template>
  <b-card class="my-1" no-body :v-show="expirationDays >= 1">
    <b-card-body>
      <div class="row">
        <div class="col">
          <span class="font-weight-bold">{{ $t("invitation.sentTo") }}:</span>
          <span class="">{{ invitation.inviteeEmail }}</span>
        </div>
      </div>
      <div class="row justify-content-between p-0 align-items-center">
        <div class="col-md-auto">
          <span class="font-weight-bold">{{ $t("labels.expireIn") }}:</span>
          <span class="">{{ expirationDays.toFixed(0) }}</span>
        </div>
        <div class="col-md-auto">
          <span class="">
            {{ $t("requestStatus." + invitation.status) }}
            <b-icon
              font-scale="0.8"
              aria-hidden="true"
              icon="circle-fill"
              :style="statusclass"
            >
            </b-icon
          ></span>
        </div>
      </div>
      <div
        class="row justify-content-right mt-1 mx-auto"
        v-if="invitation.status != 'ACCEPTED'"
      >
        <b-button
          variant="outline-danger"
          class="col-md-auto center danger"
          @click="deleteInvite(invitation.inviteeEmail)"
        >
          <b-icon aria-hidden="true" icon="x" />{{
            $t("actions.delete")
          }}</b-button
        >
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "CardInvitation",
  props: { invitation: Object() },
  data() {
    return {
      expand: false,
      //   inviterUsername: String(),
      //   vatNumber: String(),
      //   status: String(),
      //   expiration: Number(),
    };
  },
  computed: {
    ...mapState({
      requestStatus: (state) => state.request.request.status,
      userRole: (state) => state.auth.user.role,
      userType: (state) => state.auth.user.type,
    }),
    statusclass() {
      if (this.invitation.status == "PENDING") {
        return { color: "yellow" };
      }
      if (this.invitation.status == "ACCEPTED") {
        return { color: "green" };
      }
      if (this.invitation.status == "REFUSED") {
        return { color: "red" };
      }
    },
    expirationDays() {
      return (
        (new Date(this.invitation.expiration) - new Date()) / (1000 * 3600 * 24)
      );
    },
  },
  methods: {
    deleteInvite(invitee) {
      this.$store
        .dispatch("deleteInvitation", invitee)
        .then((r) => {
          this.$router.go();
          this.$emit("laoadInvites");        
          this.$emit("deleteSuccess");
        })
        .catch((err) => {
          if (err.status == 404) {
           this.$emit('notFound')
          }               
          this.$emit("deleteFail");
        });
    },
  },
};
</script>