import AuthService from "../services/api-services/auth-service";
import TokenService from "../services/api-services/token-service";
import * as jwt from "jose";

const user = localStorage.getItem("user");
const initialState = user
  ? { status: { loggedIn: true, firstlogin: false }, user: user }
  : { status: { loggedIn: false, firstlogin: false }, user: {} };

export const auth = {
  state: initialState,
  actions: {
    login({ commit, dispatch }, userlog) {
      dispatch("logout");
      return AuthService.login(userlog.username, userlog.password).then(
        (response) => {
          console.log("logged in ");
          const user = response.data;
          const parsedAccessToken = JSON.parse(localStorage.getItem("user"))
            .accessToken;
          commit("loginSuccess", user);
          commit("updateToken", parsedAccessToken);
          return Promise.resolve(response);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("customerLoadOut", { root: true });
      commit("clearOut", { root: true });
      commit("loadOut", { root: true });
      commit("clearOrders", { root: true });
      commit("clearInvitations", { root: true });
      commit("LoadCartEmpty", { root: true });
      commit("logout");
    },
    refreshToken({ commit }) {
      return TokenService.getAccessToken().then(
        (rs) => {
          const { accessToken } = rs.data;
          commit("updateToken", accessToken);
          commit("updateUser", accessToken);
          return Promise.resolve(rs);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = {};
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = {};
    },
    updateToken(state, accessToken) {
      TokenService.updateLocalAccessToken(accessToken);
      const version = jwt.decodeJwt(accessToken).tokenVersion <= 1;
      state.status.loggedIn = true;
      state.status.firstlogin = version;
      state.user.accessToken = accessToken;
    },
    updateUser(state, accessToken) {
      const user = jwt.decodeJwt(accessToken);

      state.user.username = user.sub;
      state.user.roleTds = user["role-tds"];
      state.user.roleSds = user["role-sds"];
      state.user.role = user.role;
      state.user.type = user.type;
      //state.user.agentCode = user.agentCode;
      state.user.office = user.office;
      state.user.email = user.sub;
      state.user.language = user.language;
      //state.user.audience = user.audience;
      state.user.firstname = user.firstname;
      state.user.lastname = user.lastname;
      console.log(state.user);
    },
    updateUserToCustomer(state) {
      state.user.role = "CUSTOMER";
    },
  },
  getters: {    
    getUserLogged: (state) => state.status.loggedIn,
    getUser: (state) => state.user,
    getUserRole: (state) => state.user.role,
    getUserType: (state) => state.user.type,
    getTdsRole: (state) => state.user.roleTds,
    getSdsRole: (state) => state.user.roleSds,
    getToken: (state) => state.user.accessToken,
  },
};
