<template>
  <section>
    <div class="private-area-row-100 px-3 text-uppercase by">
      <h3>{{ $t("titles.companyInformation") }}</h3>
    </div>

    <div class="private-area-box">
      <b-form-group :invalid-feedback="validationError('businessName')">
        <b-form-input
            class="form-control"
            id="region"
            v-model="form.businessName"
            :placeholder="$t('formUser.businessName') + '*'"
            :state="validationState('businessName')">
        </b-form-input>
      </b-form-group>

      <b-form-group :invalid-feedback="validationError('businessWebsite')">
        <b-form-input
            class="form-control"
            id="website"
            type="url"
            v-model="form.businessWebsite"
            :placeholder="$t('formUser.businessWebsite') + '*'"
            :state="validationState('businessWebsite')">
        </b-form-input>
        <b-link @click="openDialog()">{{ $t('formUser.link') }}</b-link><br/>
      </b-form-group>

      <b-form-group :invalid-feedback="validationError('businessType')">
        <b-form-select v-model="form.businessType" :state="validationState('businessType')">
          <b-select-option selected disabled value="null">
            {{ $t("formUser.businessType") }} *
          </b-select-option>
          <b-select-option
              v-for="type in types"
              :value="type.value"
              :key="type.value">
            {{ $t(type.text) }}
          </b-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group :invalid-feedback="validationError('businessDepartment')">
        <b-form-select
            v-model="form.businessDepartment"
            @change="form.businessRole = null"
            :state="validationState('businessDepartment')">
          <b-select-option selected disabled value="null">
            {{ $t("formUser.businessDepartment") }} *
          </b-select-option>
          <b-select-option
              v-for="department in departments"
              :value="department.value"
              :key="department.value">
            {{ $t(department.text) }}
          </b-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group class="mb-0 pb-3" :invalid-feedback="validationError('businessRole')">
        <b-form-select
            v-model="form.businessRole"
            :disabled="!form.businessDepartment"
            :state="validationState('businessRole')">
          <b-select-option selected disabled value="null">
            {{ $t("formUser.businessRole") }} *
          </b-select-option>
          <b-select-option v-for="rl in roles" :value="rl.value" :key="rl.key">
            {{ $t(rl.text) }}
          </b-select-option>
        </b-form-select>
      </b-form-group>

      <b-modal
          size="lg"
          scrollable
          hide-header
          hide-footer
          centered
          body-class="p-3"
          ref="webSiteDialog"
          >
      <b-container fluid >
        <!-- header -->
        <b-row class="ps-buy-header bb px-0">
          <!-- title -->
          <b-col class="d-flex justify-content-between ps-buy-title">
            <div style="width: 46px;"></div>
              {{ $t('titles.RegistrationWithoutWS') }}
            
            <!-- close modal -->
            <b-button @click.stop="closeDialog()" variant="link">
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="ps-buy-header" align-h="between">
          <b-col>
            {{ $t("subtitles.registrationsWithouWs") }} <a :href="`mailto:${email}`"> {{ email }} </a> {{ $t("subtitles.registrationsWithouWs2")}}
          </b-col>
        </b-row>
      </b-container>
      </b-modal>
    
    </div>
  </section>

</template>

<script>
export default {
  name: "form-company",
  props: {
    formCmp: Object(),
    types: Array(),
    departments: Array(),
    roles: Array(),
    invalid: Array(),
  },
  data() {
    return {
      webSiteDialog: false,
      email: "sales@alcea.com"
    };
  },
  methods: {
    validationError(contextRef) {
      if (this.invalidList.length > 0) {
        let find =
            this.invalidList.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
        ;
      }
    },
    validationState(contextRef) {
      return this.invalidList
          .map((invEl) => {
            //console.log(contextRef == invEl.name);
            return invEl.name.toLowerCase();
          })
          .includes(contextRef.toLowerCase())
          ? false
          : null;
    },
    openDialog() {
      this.$refs.webSiteDialog.show();
    },
    closeDialog() {
      this.$refs.webSiteDialog.hide();
    }
  },
  computed: {
    invalidList: {
      get() {
        return this.invalid;
      },
      set(invalidated) {
        this.$emit("input", invalidated);
      },
    },
    form: {
      get() {
        return this.formCmp;
      },
      set(form) {
        this.$emit("complete", form);
      },
    },
  },
};
</script>
<style>
</style>
