<template>
    <div :class="buttonClass" @click="buttonClick">
        <slot/>

        <span class="adw-button-arrow"><b-icon-arrow-right-short font-scale="2.2" /></span>
    </div>
</template>

<script>
export default {
    name: "AdwButton",

    methods: {
        buttonClick(e) {
            this.$emit('button-click', e)
        }
    },
    computed: {
        buttonClass () {
            return 'adw-button ' + this.class
        }
    }

}
</script>

<style lang="scss">
@import "../../assets/scss/custom-vars.scss";

.adw-button {
  height: 50px;
  color: BLACK;
  background: white;
  text-align: center;
  border: var(--border);
  text-transform: uppercase;
  display: flex;
  align-items: stretch;
  cursor: pointer;

  &:hover:not(.disabled) {
    background-color: var(--yellow);
    color: var(--black)
  }

  > *:not(.adw-button-arrow) {
    flex-grow: 1;
    align-self: center;
  }

  h3 {
    padding-top: 5px;
  }

  > .adw-button-arrow {
    color: BLACK;
    background-color: var(--yellow);
    //border-left: var(--border);
    width: 50px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.disabled {
    background-color: $gray-500;
    color: var(--white);
    cursor: default;
  }

  &.disabled > .adw-button-arrow {
    background-color: $gray-500;
  }

  &.black {
    color: white;
    background-color: black;
  }

  &.black > .adw-button-arrow {
    background-color: black;
    color: white;
  }

  &.link {
    color: var(--black);
    background-color: transparent;
    border: none;
    text-decoration: underline;

    h3 {
      font-size: 22px;
    }
  }

  &.link > .adw-button-arrow {
    display: none;
  }

  &.link:hover {
    background-color: transparent;
    color: var(--black);
    text-decoration: none;
  }
}

</style>