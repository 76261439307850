class ErrorManager {
  //feedback for error in field
  //(fieldName, error)
  //missing 0 campo obbligatorio
  //invalid peronalized mezzage or campo invalido 
  //TODO traduzioni
  
  //feedback for dispatch action
  feedback(error,root) {
    let feedback;
    if (error.status ) {
      feedback = error.error;
      if (error.status === 401) {
        console.log("UNAUTHORIZED 401");
        feedback = "Unauthorized";
      }
      if (error.status === 403) {
        console.log("UNAUTHORIZED 403");
        feedback = "Unauthorized";
      }
      if (error.status === 400) {
        console.log("BAD REQUEST 400");
        feedback = "BadRequest";
      }
      if (error.status === 404) {
        console.log("NOT FOUND 404");
        feedback = "NotFound";
      }
      if (error.status === 409) {
        console.log("CONFLICT 409");
        feedback = "Conflict";
      }
      if (error.status===500||error.status===502) {
        console.log("SERVER ERROR",error.status);
        feedback='ServerError'
      }
    } else {
      feedback = error;
    }
    return feedback;
  }
}

export default new ErrorManager();
