export const titles = {
  welcome: "Welcome to ADW!",
  login: "Login",
  faq: "Video Tutorial",
  profile: "Profile",
  profileCustomer: "Customer profile",
  profileUser: "User profile",
  register: "Registration",
  forgotPassword: "Password recovery",
  newPassword: "New Password",
  confirmRegistration: "Confirm registration",
  privateArea: "Private area",
  personalInformation: "Personal informations",
  companyInformation: "Company Information",
  notificationEmail: "Login notifications",
  anagraphicInfo: "Personal information",
  addresses: "Addresses",
  contacts: "Contacts",
  bankInformation: "Bank information",
  confirmRequest: "Confirmation required",
  becomeCustomerInvitation: "Do you want to become an Alcea customer?",
  becomeCustomer: "Become a customer",
  becomeCustomerText: "Fill out the request to become our customer, you will have access to all the products in our catalogs and you can request orders and offers directly from your private area.",
  confirmNewRequest: "Create a new request?",
  invitationsUser: "Invitations received to become a customer",
  invitationsCustomer: "Invitations sent",
  newInvite: "New Invitation",
  invitations: "Invitations",
  selectClient: "Select a customer to manage orders and offers",
  newRequest: "Requests for new customers",
  businessTypology: "Company typology",
  resalesInfo: "Resale information",
  outlook: "Outlooks",
  areaAgent: "My Customers",
  editCustomer: "Request Edit Profile",
  orderedProducts: "Ordered products",
  customerRequests: "Requests for orders / offers",
  backToAgent: "Change customer",
  orders: "Orders",
  cart: "Cart",
  notAssigned: "Price not assigned",
  checkoutOrder: "Order request checkout",
  checkoutOffer: "Offer request checkout",
  productSearch: "Product search",
  contactUs: "Contacts"
};
