<template>
  <section>
    <!-- TOASK link help when error -->
    <b-alert
        id="FailDismissable"
        variant="danger"
        :class="alertClass"
        :show="status === 'FailDismissable'"
        fade>
        <span v-if="mail"> {{ $t("alert." + message) }} <a :href="`mailto:${email}`"> {{ mail }} </a>. </span>
        <span v-else> {{ $t("alert." + message) }} </span> 
      <button
          type="button"
          @click="hide()"
          id="closeAlertFail"
          class="close"
          aria-hidden="true"
          aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </b-alert>

    <b-alert variant="danger" :class="alertClass" :show="status === 'Fail'">
      <h4 class="alert-heading">{{ $t("alert.somethingWrong") }}</h4>
      {{ $t("alert." + message) }}
      <b-button variant="link" v-if="statusButton" @click="$emit('back')">
        {{ $t("actions.back") }}
      </b-button>
    </b-alert>

    <b-alert variant="info" :class="alertClass" :show="status === 'Sending'">
      {{ $t("alert.sending") }}
    </b-alert>

    <b-alert variant="success" :class="alertClass" :show="status === 'Success'">
      <h4 class="alert-heading">{{ $t("alert.operationSuccess") }}</h4>
      {{ $t("alert." + message) }}
      <b-button variant="link" v-if="statusButton" @click="$emit('back')">
        {{ $t("actions.back") }}
      </b-button>
    </b-alert>

    <b-alert variant="info" :class="alertClass" :show="status === 'InfoSuccess'">
      <h4 class="alert-heading">{{ $t("alert.operationSuccess") }}</h4>
      {{ $t("alert." + message) }}
      <b-button variant="link" v-if="statusButton" @click="$emit('back')">
        {{ $t("actions.back") }}
      </b-button>
    </b-alert>

    <b-alert
        id="SuccessDismissable"
        variant="success"
        :class="alertClass"
        :show="status === 'SuccessDismissable'"
        fade>
      {{ $t("alert." + message) }}
      <button
          type="button"
          id="closeAlertSuccess"
          @click="hide()"
          class="close"
          aria-hidden="true"
          aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </b-alert>
  </section>
</template>

<script>
export default {
  name: "form-response",
  props: {
    status: String(),
    message: String(),
    mail: String(),
    alertClass: String(),
  },

  methods: {
    hide() {
      this.$emit('clearStatus')
    },
  },

  computed: {
    statusButton() {
      return ["Fail", "Success"].includes(this.status);
    },
  },
};
</script>
