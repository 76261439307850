<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <!-- navbar -->
    <div id="nav" class="adw-app-nav">
      <app-navbar class=""/>
    </div>

    <div class="adw-app-content">
      <!-- main content page -->
      <router-view></router-view>

      <!-- footer -->
      <app-footer/>
    </div>
  </div>
</template>

<script>
import AppNavbar from "./components/global-components/app-navbar.vue"
import AppFooter from "./components/global-components/app-footer.vue"
import "./assets/scss/custom-vars.scss"
import 'leaflet/dist/leaflet.css';

export default {
  name: "app",

  components: {
    AppNavbar,
    AppFooter
  },

  mounted() {
    this.initIubendaBanner(this.$i18n.locale, this.$t('iubenda.siteId'), this.$t('iubenda.cookiePolicyId'));
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Karla');
@import "./assets/scss/custom-vars.scss";

:root {
  --border: 1px SOLID BLACK;
}

// MAIN

html,
body {
  font-family: Karla, sans-serif;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
  font-weight: 200;
}

h4 {
  margin: 0;
}

h5 {
  margin: 0;
}

li {
  list-style-type: none;
}

// NAVBAR

.adw-app-nav {
  position: fixed;
  width: 100%;
  z-index: 500;
  overflow: unset;
  background: white;
}

.adw-app-content {
  margin-top: 60px;
}

// SELECTOR MENU

.private-area-selector {
  min-height: 50px;
  margin: 0;
  border-left: var(--border);
  border-bottom: var(--border);
  background-color: white;
  position: sticky;
  top: 100px;
  z-index: 100;
}

.private-area-selector > div {
  padding-top: 5px;
  text-align: center;
  text-transform: uppercase;
  border-right: none;
  border-bottom: var(--border);
  cursor: pointer;
}

.private-area-selected {
  background-color: var(--yellow);
}

@include respond-above(sm) {
  .adw-app-content {
    margin-top: 100px;
  }

  .private-area-selector > div {
    border-right: var(--border);
    border-bottom: none;
  }
}

// LOGIN

@include respond-above(sm) {
}

.login-background {
  position: relative;
  min-height: 600px;
  cursor: default;
}

.login-background::before {
  content: "";
  background-image: url('assets/img/login.jpg');
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: brightness(0.6);
}

.login-container {
  padding-top: 7%;
}

.login-overlay {
  width: 600px;
  max-width: 100%;
  padding: 20px;
}

.login-box {
  background-color: var(--white);
  border: var(--border);
}

.login-title {
  background-color: var(--yellow);
  text-transform: uppercase;
  text-align: center;
  border-bottom: var(--border);
  margin-bottom: 0;
}

.login-small {
  color: white;
}

.login-input {
  //border-bottom: var(--border);

  input {
    border: 0;
    color: black;
    font-size: 20px;
    padding-top: 25px;
    background: none !important;
    box-shadow: none !important;
  }

  input::placeholder {
    color: black;
    font-weight: normal;
    font-size: 20px;
  }

  .invalid-feedback {
    padding-top: 10px;
    padding-left: 20px;
  }
}

// PROFILE USER

.private-area-username {
  font-size: $font-size-base * 1.25;
}

.private-area-box {

  .form-row {
    min-height: 50px;
    margin: 0;
    align-items: center;
    border-bottom: var(--border);
  }

  .col-form-label {
    padding-left: 10px;
  }

  span,
  input, input:focus,
  select, select:focus {
    border: none;
    border-radius: 2px;
    box-shadow: none;
    outline: none;
  }
}

.private-area-action-box {
  border-top: var(--border);
  height: 100px;
}

.private-area-action {
  display: flex;
  height: 50px;
}

.private-area-action-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: var(--yellow);
  border-bottom: var(--border);
  text-transform: uppercase;

  h3 {
    padding-left: 10px;
    margin: 0;
  }

  span {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: var(--border);
  }

  &.black {
    color: white;
    background-color: black;
  }
}

@include respond-above(sm) {
  .private-area-username {
    font-size: $h1-font-size;
  }

  .private-area-box {
    border-left: var(--border);
    margin-left: 30%;
  }

  .private-area-action {
    border-left: var(--border);
    margin-left: 30%;
  }
}

.private-area-content {
  margin: 0;
}

.private-area-left {
  padding: 0;
  border-left: var(--border);
  border-right: var(--border);
}

.bck {
  background-color: var(--gray) !important;
}

.private-area-right {
  padding: 0;
  border-right: var(--border);
}

.private-area-row-100 {
  height: 100px;
  display: flex;
  align-items: center;
}

.private-area-row-150 {
  height: 150px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.private-area-row-200 {
  height: 200px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.private-area-background {
  background-image: url('assets/img/private-area.jpg');
  background-position: center;
  background-size: cover;
  height: 350px;
  cursor: default;
}

// REGISTRATION

.registration-background {
  background-image: url('assets/img/login.jpg');
  background-size: cover;
  height: 100%;
  cursor: default;
}

// PROFILE AGENT

.pa-card {
  height: 150px;
  border-right: var(--border);
  border-bottom: var(--border);
  padding-top: 10px;
  cursor: default;
}

// table filters
.table-filter-wrap {
  background-color: var(--gray);
  border-bottom: var(--border);
  border-top: var(--border);
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

// PROFILE CUSTOMER

.pc-container {

  .item-2 {
  }

  .item {
  }

  table {
    th {
      background-color: var(--gray);
      text-transform: uppercase;
      border-bottom: var(--border);

      div {
        white-space: nowrap;
      }
    }

    td {
      //border-bottom: var(--border);
    }
  }
}

.pc-title {
  height: 150px;
  padding: 10px 20px;
  border-bottom: var(--border);
  background-color: var(--yellow);

  h5 {
    overflow: auto;
    text-overflow: ellipsis;
    padding-bottom: 5px;
  }

  &.left {
    background-color: var(--gray) !important;
  }
}

.pc-subtitle {
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px SOLID BLACK;
}

.pc-links {
  h3 {
    height: 50px;
    padding-left: 20px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    border-bottom: var(--border);
    cursor: pointer;
  }

  .back {
    background-color: black;
    color: white;
  }

  .selected {
    background-color: var(--yellow);
  }
}


@include respond-above(sm) {
  .pc-container {
    display: flex;
    flex-wrap: wrap;

    .item-2 {
      flex: 0 0 25%;
      max-width: 25%;
      border-left: var(--border);
    }

    .item {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 75%;
      border-left: var(--border);
      border-right: var(--border);
    }

    table {
      th {
        border-bottom: var(--border);

        div {
          white-space: nowrap;
        }
      }

      td {
        //border-bottom: var(--border);
      }
    }
  }
}

// OFFERS / ORDERS

.oo-header {
  min-height: 100px;
  border-top: var(--border);
  //border-bottom: var(--border);
}

.oo-no-result {
  padding: 40px;
  text-align: center;
}


// MISC

.adw-pointer {
  cursor: pointer;

  * {
    cursor: pointer;
  }
}


.bt {
  border-top: var(--border);
}

.bb {
  border-bottom: var(--border);
}

.bl {
  border-left: var(--border);
}

.br {
  border-right: var(--border);
}

.bx {
  border-left: var(--border);
  border-right: var(--border);
}

.by {
  border-top: var(--border);
  border-bottom: var(--border);
}
</style>
