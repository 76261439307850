<template>
  <div>
    <span>{{ $t('formUser.gender.' + gender) }} {{ contact.firstName }} {{ contact.lastName }}</span>
    <br/>
    <span>{{ contact.email }}</span>
    <br/>
    <span>{{ contact.phoneNumber }}</span>
  </div>
</template>

<script>
import countries from '../../commons/countries';
import { businessRole } from '@/commons/business-roles';
import { businessDepartments } from '@/commons/business-departments';

export default {
  name: 'cardContact',

  props: {
    contact: Object(),
    form: Object(),
    assignmentValue: String(),
    invalidErrors: Array(),
    disabled: Boolean(),
  },

  data() {
    return {
      type: 'C',
      countries: countries,
      genders: [
        { text: 'mrs', value: 'F' },
        { text: 'mr', value: 'M' },
      ],
    };
  },

  computed: {
    gender() {
      return this.contact.gender ? this.genders.find((g) => g.value === this.contact.gender).text : 'select';
    },

    role() {
      return this.contact.role ? this.roles.find((r) => r.value === this.contact.role).text : 'formUser.businessRole';
    },

    roles() {
      let department;
      if (this.assignmentValue === 'purchasingContact') {
        department = 'PRC';
      }
      if (this.assignmentValue === 'administrativeContact') {
        department = 'ADM';
      }
      return businessRole.filter((r) => businessDepartments.find((d) => d.value === department).roles.includes(r.value));
    },

    telephone: {
      get() {
        return this.$refs.telephone.telephone;
      },
    },
  },

  methods: {
    validationState(contextRef) {
      return this.invalidErrors.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) ? false : null;
    },

    validationError(contextRef) {
      if (this.invalidErrors.length > 0) {
        let find = this.invalidErrors.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
  },
};
</script>
