export const roles = {
  generalManagement: "General Management",
  property: "property",
  director: "director",
  departmentHead: "Department Head",
  operator: "operator",
  manager: "manager",
  buyer: "buyer",
  officeWorker: "Office Worker",
  designer: "designer"
};
