export const roles = {
  generalManagement: "direz generale",
  property: "proprietario",
  director: "direttore",
  departmentHead: "caporeparto",
  operator: "operatore",
  manager: "responsabile",
  buyer: "buyer",
  officeWorker: "impiegato",
  designer: "progettista",
};
