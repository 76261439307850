export const segmentationTypes =[
    {value:'NA', text:'nessuna tipologia'},
    { value:'MTI',  text:'types.metalIndustry'},
    { value:'WDI',  text:'types.woodIndustry'},
    { value:'HOL',  text:'types.homeLine'},
    { value:'RSL',  text:'types.resales'},
    { value:'CNS',  text:'types.construction'},
    { value:'TNT',  text:'types.tintometrics'},
    { value:'CLC',  text:'types.coilCoating'},
]

