<template>
  <section>
    <div class="login-box" style="margin-top: -30px" v-if="!invalidQuery">
      <h2 class="login-title">{{ $t("titles." + title) }}</h2>
    </div>

    <!-- feedback -->
    <feedback-alert
        class="w-100"
        :status="feedbackStatus"
        :message="feedbackMessage"
        @clearStatus="clearStatus"
        @back='toLogin'>
    </feedback-alert>

    <div class="mb-1">
      <b-card v-if="!invalidQuery" bg-variant="light">
        <p id="requirements"></p>
      </b-card>
    </div>

    <section class="row justify-content-center align-items-center">
      <b-form v-if="!invalidQuery" @submit.prevent="confirm" class="col-md-4">
        <b-form-group
            class="my-2 w-100"
            :invalid-feedback="validationError('password')">
          <b-form-input
              id="password"
              v-model="form.password"
              type="password"
              :placeholder="$t('labels.password') + ' *'"
              :state="validationState('password')">
          </b-form-input>
        </b-form-group>
        <b-form-group
            class="my-2 w-100"
            :invalid-feedback="validationError('rerepeatPassword')">
          <b-form-input
              id="repeatpassword"
              v-model="form.rerepeatPassword"
              type="password"
              :placeholder="$t('labels.repeatPassword') + ' *'"
              :state="validationState('rerepeatPassword')"
          ></b-form-input>
        </b-form-group>

        <adw-button class="my-4"
            @button-click="confirm">
            <h3>{{ $t("actions." + title) }}</h3>
        </adw-button>
      </b-form>
    </section>
  </section>
</template>

<script>
import Vue from "vue";
import Validator from "../services/validation-service/validator-service";
import feedbackAlert from "./feedback-alert.vue";
import AdwButton from "@/components/global-components/adw-button";

export default {
  name: "fromPassword",

  components: {
    feedbackAlert,
    AdwButton,
  },

  props: {
    title: String(),
    invalidQuery: Boolean(),
    errorMsg: String(),
    feedbackStatus: String(),
    feedbackMessage: String(),
  },

  data() {
    return {
      form: {
        password: "",
        rerepeatPassword: "",
      },
      invalidErrors: [],
      otherError: {name: null, msg: null},

      username: this.$route.query.username,
      tempCode: this.$route.query.token,
    };
  },
  computed: {
    user() {
      return {
        username: this.username,
        tempCode: this.tempCode,
        password: {
          newPassword:
              this.form.password == this.form.rerepeatPassword
                  ? this.form.password
                  : null,
        },
      };
    },
  },
  mounted() {
    Vue.nextTick(() => {
      let text = this.$i18n.t("labels.passwordRequirements");
      if (this.title == "confirmRegistration") {
        text = this.$i18n.t("labels.passwordRequirementsRegistration") + text;
      }
      document.getElementById("requirements").innerHTML = text;
    });
  },
  methods: {
    validationState(contextRef) {
      return this.invalidErrors.find(
          (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
          ? false
          : null;
    },

    validationError(contextRef) {
      if (this.invalidErrors.length > 0) {
        let find =
            this.invalidErrors.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
        ;
      }
    },

    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },
    toLogin() {
      this.$router.push('/login')
    },
    confirm() {
      this.otherError.name = null;
      this.otherError.msg = null;
      let extravalues = {
        rerepeatPassword: {name: "password", value: this.form.password},
      };
      try {
        let validation = Validator.validateForm(
            "newPassword",
            this.form,
            extravalues
        );
        this.invalidErrors = validation.invalid;
      } catch (e) {
        console.error(e);
        this.otherError.name = "validation";
        this.otherError.msg = e;
        this.invalidErrors.push(this.otherError);
      }
      if (this.invalidErrors.length > 0 || this.otherError.msg) {
        console.error("possible 409");
        console.error(this.invalidErrors);
        console.error(this.otherError);
      } else {
        this.send();
      }
    },
    send() {
      this.$emit("send", this.user);
    },
  },
};
</script>
