import http from "@/util/http-common";
import store from "@/store/index";

class CheckoutService {
  getUrl(url) {
    let customer = store.getters.getCustomerCode;
    console.log("CUSTOMER used", customer);
    return store.getters.getUserRole === "COMMERCIAL"
      ? "v1/public/commercial" + `${url}?customerCode=${customer}` :
      store.getters.getUserRole === "AGENT"
        ? "v1/public/agent" + `${url}?customerCode=${customer}`
        : "v1/public/customer" + url;
  }

  getOrders(page, size, sortBy, sortDir, filters) {
    let url = this.getUrl("/order-requests");

    let params = {
      size: size,
      page: page - 1,
      sort: sortBy + "," + sortDir,
    };

    filters.serial ? params['serial'] = filters.serial : null;
    filters.color ? params['color'] = filters.color : null;
    filters.pkg ? params['pkg'] = filters.pkg : null;
    filters.status ? params['status'] = filters.status : null;
    filters.description ? params['description'] = filters.description : null;
    filters.createdOnFrom ? params['createdOnFrom'] = filters.createdOnFrom : null;
    filters.createdOnTo ? params['createdOnTo'] = filters.createdOnTo : null;

    console.info("getOrders call", url, params);
    return http.get(url, { params });
  }

  getOffers(page, size, sortBy, sortDir, filters) {
    let url = this.getUrl("/offer-requests");

    let params = {
      size: size,
      page: page - 1,
      sort: sortBy + "," + sortDir,
    };

    filters.serial ? params['serial'] = filters.serial : null;
    filters.color ? params['color'] = filters.color : null;
    filters.pkg ? params['pkg'] = filters.pkg : null;
    filters.status ? params['status'] = filters.status : null;
    filters.description ? params['description'] = filters.description : null;
    filters.createdOnFrom ? params['createdOnFrom'] = filters.createdOnFrom : null;
    filters.createdOnTo ? params['createdOnTo'] = filters.createdOnTo : null;

    console.info("getOffers call", url, params);
    return http.get(url, { params });
  }

  async getSingleOffer(id) {
    return http.get(this.getUrl(`/offer-requests/${id}`));
  }

  async redoOrderRequest(id) {
    return http.get(this.getUrl(`/order-requests/redo/${id}`));
  }

  async postNewOrder(form) {
    return http.post(this.getUrl("/order-requests"), form);
  }

  async postNewOrderFromOffers(form) {
    return http.post(
      this.getUrl("/order-requests/from-offer-request-accepted"),
      form
    );
  }

  async postNewOffer(form) {
    return http.post(this.getUrl("/offer-requests"), form);
  }

  async postRetryCheckoutOffer(id) {
    return http.post(this.getUrl(`/offer-requests/rejected-or-accepted-but-expired/${id}`));
  }
}

export default new CheckoutService();
