<template>
  <div>

    <!-- customer info -- >
    <div class="pc-title left adw-pointer" @click.stop="$router.push('/profile-customer')">
      <h3 :title="customerInfo.businessName">{{ customerInfo.businessName }}</h3>
      <h2 class="mt-2">{{ $t("labels.customerCode") }}: {{ customerInfo.customerCode }}</h2>
    </div> -->

    <!-- customer selected section -->
    <div class="pc-links">

      <!-- link: customer profile -->
      <h3 @click.stop="$router.push('/sds/site')"
          :class="$route.path === '/sds/site' ? 'selected' : ''">
        {{ $t("titles.sdsSearchWarehouse") }}
      </h3>

      <!-- link: customer invitations -->
      <h3 @click.stop="$router.push('/sds/batch')"
          :class="$route.path === '/sds/batch' ? 'selected' : ''">
        {{ $t("titles.sdsSearchBatch") }}
      </h3>

      <!-- link: customer ordered products -->
      <h3 @click.stop="$router.push('/sds/customer')"
          :class="$route.path === '/sds/customer' ? 'selected' : ''">
        {{ $t("titles.sdsSearchCustomer") }}
      </h3>


      <!-- link: customer offer requests -->
      <h3 @click.stop="$router.push('/sds/document')"
          :class="$route.path === '/sds/document' ? 'selected' : ''">
        {{ $t("titles.sdsSearchDocument") }}
      </h3>

      <!-- link: customer offer requests -->
      <h3 @click.stop="$router.push('/sds/product')"
          v-if="userCanSeeSearchProduct"
          :class="$route.path === '/sds/product' ? 'selected' : ''">
        {{ $t("titles.sdsSearchProduct") }}
      </h3>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "sds-sidebar",

  computed: {
    ...mapState({
      customerInfo: (state) => state.customer.customerinfo,
      userType: (state) => state.auth.user.type,
    }),

    cartItems() {
      return this.$store.getters.getTotalProductsInCart;
    },


    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },
    isUserAdminOrIt() {
      return this.$store.getters.getUserRole === "ADMIN" || this.$store.getters.getUserRole === "IT" || this.$store.getters.getUserRole === "ALCEA"
    },
    userCanSeeSearchProduct() {
      return this.isUserAdminOrIt || this.$store.getters.getSdsRole === "EMPLOYEE"
    },
  },
}
</script>
