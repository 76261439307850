import CustomerRequest from "@/views/CustomerRequest.vue";

export default {
  path: "/customer-request",
  name: "CustomerRequest",
  component: CustomerRequest,
  meta: {
    sector: 'PrivateArea',
    requiresAuth: true,
    roles: ['USER', 'AGENT', 'COMMERCIAL']
  }
};
