export const requestStatus = {
  requestStatus: "Request status",
  agentRequestCustomerText: "Fill out the request for a new customer, you will have access to all the products in the catalogs and you can request orders and offers directly for them.",
  becomeCustomerText: "Fill out the request to become our customer, you will have access to all the products in our catalogs and you can request orders and offers directly from your private area.",
  text: "Request to become a customer",
  confirmNewRequest: "Delete the previous request to create a new one?",
  NONE: "none",
  DRAFT: "draft",
  PENDING: "Pending",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  CLOSED: "Closed",
  EXPIRED: "Expired",
  NULL: "Select status",
  null: "Select status",
  customerExisting: {
     title: "Is your company already an alcea customer?",
     titleShort: "Match customer",
     text: "To make a direct association via customer code"
  },
  acceptedText: "Congratulations, your request has been accepted. Click on the link below to complete the procedure.",
  rejectedText: "Unfortunately your request has been declined. Click on the link below to return to the initial state."
};
