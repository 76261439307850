<template>
  <div class="container">
    <section class="row justify-content-center">
      <b-icon aria-hidden="true" icon="exclamation-triangle-fill">{{ $t('errors.NotFoundPage') }}</b-icon>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized'
};
</script>
