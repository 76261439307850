<template>
  <div>



    <b-col>
      <!-- table -->
      <b-row>
        
        <!-- feedback -->
        <feedback-alert class="w-100"
                        alert-class="mb-0"
                        :status="feedbackStatus"
                        :message="feedbackMessage"
                        @clearStatus="clearStatus">
        </feedback-alert>
        <b-container fluid class="table-filter-wrap">
          <b-collapse id="collapse-filters" v-model="showFilter">
          <b-row>
            <b-col>
              <strong>Criteri di ricerca:</strong>
            </b-col>
          </b-row>
          <b-row>

            <!-- ProductCode -->
            <b-col cols="12" sm="6" lg="3">
              <label class="font-weight-bold  mb-0">{{ $t("product.productCode") }}</label>
              <div>
                <b-input-group>
                  <b-form-input name="productCode"
                                id="serialCode"
                                v-model="filters.serial"
                                maxlength="4"
                                type="search"
                                :placeholder="$t('product.orderedProducts.serial')"
                                @keyup.enter="getRows">
                  </b-form-input>

                  <b-form-input id="colorCode"
                                name="productCode"
                                v-model="filters.color"
                                maxlength="4"
                                type="search"
                                :placeholder="$t('product.orderedProducts.color')"
                                @keyup.enter="getRows">
                  </b-form-input>

                  <b-form-input id="packaging"
                                name="productCode"
                                v-model="filters.pkg"
                                maxlength="2"
                                type="search"
                                :placeholder="$t('product.orderedProducts.pkg')"
                                @keyup.enter="getRows">
                  </b-form-input>
                </b-input-group>
              </div>
            </b-col>

            <!-- description -->
            <b-col cols="12" sm="6" lg="2" xl="3">
              <label class="font-weight-bold  mb-0">{{ $t("product.description") }}</label>
              <div>
                <b-form-input name="description"
                              v-model="filters.description"
                              type="search"
                              :placeholder="$t('product.orderedProducts.description')"
                              @keyup.enter="getRows">
                </b-form-input>
              </div>
            </b-col>

            <!-- description -->
            <b-col cols="12" sm="6" lg="2" >
              <label class="font-weight-bold mb-0">{{ $t("product.brand") }}</label>
              <div>
                <b-form-input name="brandFilter"
                              id="brandFilter"
                              v-model="filters.brand"
                              type="search"
                              :placeholder="$t('product.orderedProducts.brand')"
                              @keyup.enter="getRows">
                </b-form-input>
              </div>
            </b-col>

            <!-- preferred -->
            <b-col cols="12" sm="6" lg="2">
              <label>&nbsp;</label>
              <div class="ml-1">
                <b-checkbox name="onlyPreferred"
                            id="onlyPreferred"
                            v-model="filters.onlyPreferred"
                            @change="search"
                >
                  <label align="left">{{ $t("product.preferred") }}</label>
                </b-checkbox>
              </div>
            </b-col>

            <!-- search -->
            <b-col cols="12" lg="3" xl="2" class="pt-1" >
              <adw-button class="mr-2"
                          @button-click="search" >
                <h3 class="px-1">
                  {{ $t("actions.search") }}
                </h3>
              </adw-button>

            </b-col>

          </b-row>
          </b-collapse>
          <b-row class="d-md-none">
            <b-col class="px-0">
              <b-btn class="btn-block"
                     variant="primary"
                     aria-controls="collapse-filters"
                     :aria-expanded="showFilter ? 'true' : 'false'"
                     @click="showFilter = !showFilter" >
                <span v-if="!showFilter" ><b-icon icon="arrow-down" aria-hidden="true"></b-icon> {{ $t("actions.showFilters") }}</span>
                <span v-if="showFilter" ><b-icon icon="arrow-up" aria-hidden="true"></b-icon> {{ $t("actions.hideFilters") }}</span>
              </b-btn>
            </b-col>
          </b-row>
        </b-container>
        <div class="table-responsive mb-0" ref="dataTableWrap">
          <div class="custom-table-sticky-header">
          <b-table :items="products"
                   :keys="fields"
                   hover
                   :fields="fields"
                   ref="dataTable"
                   :busy="isTableLoading"
                   :filter="filters"
                   :filter-included-fields="filterOn"
                   :sort-by.sync="paginationInfo.sortBy"
                   :sort-desc.sync="paginationInfo.sortDesc"
                   :sort-direction="paginationInfo.sortDirection"
                   no-local-sorting
                   stacked="md"
                   show-empty
                   class="pc-filtered-table"
                   :tbody-tr-class="selectedClass"
                   @sort-changed="sortChange">


            <template #thead-top="data">
              <b-tr>
                <b-td colspan="12">
                  <!-- filters -->

                </b-td>
              </b-tr>
            </template>

            <template #empty="">
              <h2 class="text-center">{{ $t("labels.emptyTable") }}</h2>
            </template>

            <template #emptyfiltered="">
              <h2 class="text-center">{{ $t("labels.emptyTable") }}</h2>
            </template>

            <template #head(selected)="">
              <b-checkbox :disabled="products.length < 1"
                          v-model="selectAll"
                          @click.stop
                          @change="selectAllRows">
              </b-checkbox>
            </template>

            <template #cell(selected)="data">
              <b-checkbox :checked="!!selected.find((i) => i.productCode === data.item.productCode)"
                          @click.stop
                          :disabled="data.item.status != 'AVAILABLE'"
                          @change="rowSelected(data.item)">
              </b-checkbox>
            </template>

            <template #cell(productCode)="data">
              <span class="adw-pointer text-nowrap" @click="gotoDetail(data.item)">
                {{ data.item.productCode }}
              </span>
            </template>

            <template #cell(description)="data">
              <span class="adw-pointer" @click="gotoDetail(data.item)">
                {{ data.item && data.item.findDescriptionPerLang ? data.item.findDescriptionPerLang($i18n.locale, "IT") : data.item.pdtDesc }}
              </span>
            </template>

            <template #cell(brand)="data">
              <span class="adw-pointer" @click="gotoDetail(data.item)">
                {{ data.item ? data.item.brand : "" }}
              </span>
            </template>

            <template #cell(unitQty)="data">
              <span>
                {{ data.item.qta + " " + data.item.um }}
              </span>
            </template>

            <template #cell(unitPrice)="data" @click.stop>
              <span v-if="data.item.unitPrice">
                {{ data.item.unitPrice.toFixed(2) }} €/{{data.item.um}}
              </span>
              <span v-else-if="!data.item.unitPrice">
                {{ $t("product.notAssigned") }}
                <b-icon-info-circle aria-hidden="true" @click="$refs.notAssignedModal.show()"></b-icon-info-circle>
              </span>
            </template>

            <template #cell(priceListCode)="data" @click.stop>
              <span>
                {{ data.item.priceListCode ? $t("product.priceList." + data.item.priceListCode) : '-' }} 
              </span>
            </template>

            <template #cell(discounts)="data" @click.stop>
              <span>
                {{ data.item.discountPct1||'0' }}+{{ data.item.discountPct2||'0' }} % 
              </span>
            </template>

            <template #cell(finalUnitPrice)="data" @click.stop>
              <span v-if="data.item.finalUnitPrice">
                {{ data.item.finalUnitPrice.toFixed(2) }}  €/{{data.item.um}} 
              </span>
              <span v-else-if="!data.item.finalUnitPrice">
                {{ $t("product.notAssigned") }}
                <b-icon-info-circle aria-hidden="true" @click="$refs.notAssignedModal.show()"></b-icon-info-circle>
              </span>
            </template>

            <template #cell(status)="data" @click.stop>
              <span :class="(data.item.status === 'AVAILABLE' ? 'text-success' : 'text-danger') + ' font-weight-bold'">
                {{ data.item.statusLabel }}
              </span>
            </template>

            <template #cell(download)="data" @click.stop>
              <b-button variant="link">
                <b-icon-arrow-down @click.stop @click.prevent="tdsLanguages(data.item.id.serial, data.item.id.color)"/>
              </b-button>
            </template>

            <template #cell(preferred)="data">
              <b-btn variant="link" @click="togglePreference(data.item)">
                <b-icon v-if="data.item.preferred" aria-hidden="true" icon="star-fill"></b-icon>
                <b-icon v-if="!data.item.preferred" aria-hidden="true" icon="star"></b-icon>
              </b-btn>
            </template>
          </b-table>
          </div>
        </div>
      </b-row>

      <!-- pagination & co. -->
      <b-row class="justify-content-between pt-2 border-top">
        <!-- pagination -->
        <b-col cols="12" md="4" class="my-2">
          <b-pagination v-model="paginationInfo.page"
                        :total-rows="paginationInfo.totalElements"
                        :per-page="paginationInfo.row"
                        @change="changePage"
                        align="left">
          </b-pagination>
        </b-col>

        <!-- elements per page -->
        <b-col cols="12" md="4" class="my-2">
          <b-form-group class="row mb-1 font-weight-bold"
                        label-cols="auto"
                        label-align="left"
                        label-align-sm="right"
                        :label="$t('labels.perPage')"
                        label-text-align="left"
                        label-for="selectPage">
            <b-form-select id="selectPage"
                           name="selectPage"
                           :options="perPageOptions"
                           v-model="paginationInfo.row"
                           @change="getRows"
                           style="width: 100px">
            </b-form-select>
          </b-form-group>
        </b-col>

        <!-- add to cart -->
        <b-col cols="12" md="4" class="my-2">
          <div class="justify-content-end">

            <adw-button class="mr-2"
                @button-click="addToCart" 
                :class="selected.length === 0 || addToCartDisabled ? 'disabled' : ''"
                :disabled="addToCartDisabled" >
                <span class="px-1">
                  <b-icon-cart/>
                  {{ $t("actions.add") }} {{ selected.length }}
                  {{ selected.length === 1 ? $t("actions.itemToCart") : $t("actions.itemsToCart") }}
                </span>
            </adw-button>

          </div>
        </b-col>
      </b-row>
    </b-col>


    <!-- modal to open the TDS selection -->
    <b-modal
        :title="$t('product.modal.downloadTds')"
        hide-footer
        ref="productTransalations"
        id="product-translations">
      <b-tabs content-class="mt-3">
        <b-tab v-for="version in versions"
               :key="version.id"
               :title="$t('languages.' + version.language)"
               :active="selectedLanguage === version.language">
          <b-button @click.prevent="dowloadTds(version)">{{ $t("product.downloadTds") }}</b-button>
        </b-tab>
      </b-tabs>
    </b-modal>


    <!-- modal to open the product detail -->
    <products-search-detail-w ref="productsSearchDetailW" origin-page="orderedProduct"></products-search-detail-w>

    <b-modal ref="notAssignedModal"
             id="notAssignedModal"
             centered
             ok-only
             :title="$t('titles.notAssigned')"
             @ok="$refs.notAssignedModal.hide()"
             :ok-title="$t('actions.ok')">
      <p class="my-4" id="notAssignedModalText">
        {{ $t("labels.notAssignedText") }}
      </p>
    </b-modal>
    
  </div>
</template>

<script>
import { mapState } from "vuex";
import TdsService from "@/services/api-services/tds-service";
import FeedbackAlert from "@/components/feedback-alert.vue";
import AdwButton from "@/components/global-components/adw-button";
import ErrorManager from "@/services/errors-service/errors-manager";
import ProductsSearchDetailW from "@/views/ProductsSearch/ProductsSearchDetailW";

export default {
  name: "OrdersAndPurchases",

  components: {
    ProductsSearchDetailW,
    FeedbackAlert,
    AdwButton,
  },

  data() {
    return {
      products: [],
      isTableLoading: true,

      customerFields: [
        {
          key: "selected",
          label: "",
          sortable: false,
        },
        {
          key: "productCode",
          label: this.$i18n.t("product.code"),
          sortKey: "productCodeString",
          sortable: true,
        },
        {
          key: "description",
          label: this.$i18n.t("product.description"),
          sortKey: this.$i18n.locale.substring(0, 2).toLowerCase() + "ColorDesc",
          sortable: true,
        },
        {
          key: "brand",
          label: this.$i18n.t("product.brand"),
          sortable: true,
        },
        {
          key: "unitQty",
          label: this.$i18n.t("product.unitQty"),
          sortable: true,
        },
        {
          key: "finalUnitPrice",
          label: this.$i18n.t("product.unitPrice"),
          sortable: false,
          tdClass: "",
        },
        {
          key: "inStock",
          label: this.$i18n.t("product.inStock"),
          sortable: false,
          class: "text-center",
          formatter: value => {
            return this.$i18n.t(value ? "labels.yes" : "labels.no");
          }
        },
        {
          key: "status",
          label: this.$i18n.t("product.status"),
          sortable: false,
          class: "text-center"
        },
        {
          key: "download",
          label: this.$i18n.t("product.tds"),
          sortable: false,
          selectable: false,
          class: "text-center",
        },
        {
          key: "preferred",
          label: this.$i18n.t("product.preferred"),
          selectable: false,
          sortable: false,
          class: "text-center",
        },
      ],
      agentCommercialFields: [
      {
          key: "selected",
          label: "",
          sortable: false,
        },
        {
          key: "productCode",
          label: this.$i18n.t("product.code"),
          sortKey: "productCodeString",
          sortable: true,
        },
        {
          key: "description",
          label: this.$i18n.t("product.description"),
          sortKey: this.$i18n.locale.substring(0, 2).toLowerCase() + "ColorDesc",
          sortable: true,
        },
        {
          key: "brand",
          label: this.$i18n.t("product.brand"),
          sortable: true,
        },
        {
          key: "unitQty",
          label: this.$i18n.t("product.unitQty"),
          sortable: true,
        },
        {
          key: "unitPrice",
          label: this.$i18n.t("product.unitPrice"),
          sortable: false,
          tdClass: "",
        },
        {
          key: "priceListCode",
          label: this.$i18n.t("product.priceListCode"),
          sortable: false,
          tdClass: "",
        },
        {
          key: "discounts",
          label: this.$i18n.t("product.discounts"),
          sortable: false,
          tdClass: "",
        },
        {
          key: "inStock",
          label: this.$i18n.t("product.inStock"),
          sortable: false,
          class: "text-center",
          formatter: value => {
            return this.$i18n.t(value ? "labels.yes" : "labels.no");
          }
        },
        {
          key: "status",
          label: this.$i18n.t("product.status"),
          sortable: false,
          class: "text-center"
        },
        {
          key: "download",
          label: this.$i18n.t("product.tds"),
          sortable: false,
          selectable: false,
          class: "text-center",
        },
        {
          key: "preferred",
          label: this.$i18n.t("product.preferred"),
          selectable: false,
          sortable: false,
          class: "text-center",
        },
      ],
      filters: {
        serial: null,
        color: null,
        pkg: null,
        brand: null,
        description: null,
        inStock: null,
        status: null,
        onlyPreferred: false,
      },

      perPageOptions: [10, 20, 50, 100],
      exportField: {},
      exportWorksheet: "export",
      exportLoadingState: false,
      versions: [],
      selectedLanguage: "IT",
      currentTranslations: {},

      filter: null,
      filterOn: [],
      selected: [],
      selectAll: false,
      feedbackStatus: null,
      feedbackMessage: "",

      product: null,
      addToCartDisabled: false,
      showFilter: true
    };
  },

  computed: {
    ...mapState({
      userRole: (state) => state.auth.user.role,
      customerCode: (state) => state.customer.customerinfo.customerCode,
    }),

    fields() {
      return this.userRole=='CUSTOMER' ? this.customerFields : this.agentCommercialFields;
    },

    paginationInfo: {
      get() {
        return this.$store.getters.getPaginationInfo;
      },
      set(pagination) {
        this.$store.commit("updatePaginationInfo", pagination);
      },
    },
  },

  methods: {
    selectedClass(item, type) {
      if (item && type === "row") {
        if (this.selected.includes(item)) {
          return "b-table-row-selected table-active";
        } else {
          return "";
        }
      } else {
        return null;
      }
    },

    clear() {
      this.filters = {
        serial: null,
        color: null,
        pkg: null,
        description: null,
        brand: null,
        inStock: null,
        status: null,
      };
      this.getRows();
    },

    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },

    showModal() {
      this.$refs.productTransalations.show();
    },

    tdsLanguages(serial, color) {
      TdsService.getTdsLanguages(serial, color)
          .then((response) => {
            this.versions = response.data;
            if (this.versions.length == 0) {
              this.Fail("missingTds");
            } else {
              this.showModal();
            }
          })
          .catch((error) => {
            this.Fail(ErrorManager.feedback(error));
          });
    },

    async dowloadTds(version) {
      const id = version.id;
      const code = version.product;

      await TdsService.getTds(version.id)
          .then((response) => {
            console.log("TDS LANG", response.data);
            let blob = new Blob([response.data], { type: "application/pdf" });
            const data = window.URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.href = data;
            let fileName =
                "TDS-" +
                code.substr(0, 4).trim() +
                "-" +
                code.substr(4, 9).trim() +
                "-" +
                version.language.toUpperCase() +
                ".pdf";
            link.download = fileName.trim();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((e) => {
            console.log(e);
            this.Fail("TdsError");
            this.$refs.productTransalations.hide();
          })
          .finally(() => {
            this.$refs.productTransalations.hide();
          });
    },

    Fail(message) {
      this.feedbackStatus = "FailDismissable";
      this.feedbackMessage = message;
    },

    Success(message) {
      this.feedbackStatus = "SuccessDismissable";
      this.feedbackMessage = message;
    },

    changePage(page) {
      this.paginationInfo.page = page;
      let updatePag = this.paginationInfo;
      this.$store.commit("updatePaginationInfo", updatePag);
      this.getRows();
    },

    sortChange(ctx) {
      this.paginationInfo.sortBy = ctx.sortBy;
      this.paginationInfo.sortDesc = ctx.sortDesc;
      this.paginationInfo.sortDirection = ctx.sortDesc ? "desc" : "asc";
      let updatePag = this.paginationInfo;
      this.$store.commit("updatePaginationInfo", updatePag);
      this.getRows();
    },

    getRows() {
      this.isTableLoading = true;
      let dispatch = "getOrderedProducts";

      this.$store
          .dispatch(dispatch, this.filters)
          .then((response) => {
            this.products = response;
            this.$refs.dataTable.refresh();
          })
          .catch((error) => {
            console.log(error);
            this.Fail(ErrorManager.feedback(error));
          })
          .finally(() => {
            if (this.products.length > 0) {
              this.selectAll = this.products.every((p) => this.selected.find((s) => s.productCode === p.productCode));
            } else {
              this.selectAll = false;
            }
            this.isTableLoading = false;
          });
    },

    search() {
      this.paginationInfo.page = 1
      this.getRows()
    },

    rowSelected(item) {
      let find = false;
      this.selected.forEach((s, index) => {
        if (s.productCode == item.productCode) {
          find = true;
          this.selected.splice(index, 1);
        }
      });
      if (!find) {
        this.selected.push(item);
      }
      this.selectAll = this.products.every((p) => this.selected.includes(p));
    },

    selectAllRows() {
      if (this.selectAll) {
        this.products.forEach((or) => {
          if (!this.selected.find((s) => s.productCode == or.productCode) && or.status == 'AVAILABLE') {
            this.selected.push(or);
          }
        });
      } else {
        this.products.forEach((or) => {
          let index = this.selected.findIndex(
              (s) => s.productCode == or.productCode
          );
          this.selected.splice(index, 1);
        });
      }
    },

    addToCart() {

      if(this.selected.length == 0)
        return;

      if(this.addToCartDisabled)
        return;

      this.addToCartDisabled = true;


      this.$store
          .dispatch("addProductsCart", this.selected)
          .then(() => {
            this.Success("productsInCart");
            this.selected = [];
            this.scrollToTop();
          })
          .catch((error) => this.Fail(ErrorManager.feedback(error)))
          .finally(() => {
            this.addToCartDisabled = false;
          });
    },
    
    scrollToTop() {
      window.scrollTo(0,0);
    },

    togglePreference(item) {
      this.$store
          .dispatch("togglePreference", item)
          .then((response) => {
            let find = this.products.find((p) => p.id == item.id);
            this.products.find((p) => p.id == item.id).preferred = !find.preferred;
          })
          .catch((error) => this.Fail(ErrorManager.feedback(error)));
    },

    gotoDetail(product, link) {
      this.$refs.productsSearchDetailW.gotoDetail(product, null, link)
    }
  },

  created() {
    this.getRows();
  },
};
</script>
