<template>
  <div>
    <b-container fluid class="oo-header py-2" :style="backgroundStyle(offer)">
      <b-row>
        <!-- title -->
        <b-col>
          <h4 class="text-uppercase">{{ $t("offerInfo.offerRequest") }}</h4>
        </b-col>

        <!-- creation date -->
        <b-col>
          {{ $t("offerInfo.createdOn") }}: {{ formatStringDate(offer.createdOn) }}
        </b-col>

        <!-- delivery date -->
        <b-col>
          {{ $t("orderInfo.deliveryDate") }}: {{ formatStringDate(offer.deliveryDateRequested) }}
        </b-col>

        <!-- status -->
        <b-col class="text-right text-uppercase">
          {{ $t("offerInfo.requestStatus." + offer.status) }}
        </b-col>
      </b-row>

      <b-row>
        <!-- id -->
        <b-col>
          <h4>{{ offer.id }}</h4>
        </b-col>

        <!-- multiple shipping -->
        <b-col>
          {{ $t("orderInfo.acceptMultipleShipping") }}: {{ $t(offer.acceptMultipleShipping ? 'labels.yes' : 'labels.no') }}
        </b-col>

        <b-col>
          <div v-if="offer.status === 'ACCEPTED'">
            <b-button @click="requestOrder" variant="link">{{ $t("actions.requestOrder") }}</b-button>
            <br/>{{ $t("offerInfo.expireIn") }} {{ formatStringDate(offer.expirationDate) }}
          </div>

          <div v-if="offer.status === 'EXPIRED'">
            <b-button @click="retryCheckoutOffer" variant="link">{{ $t("actions.requestNewOffer") }}</b-button>
            <br/>{{ $t("offerInfo.isExpired") }} {{ formatStringDate(offer.expirationDate) }} {{ $t("offerInfo.notValid") }}
          </div>

          <div v-if="offer.status === 'REJECTED'">
            <b-button @click="retryCheckoutOffer" variant="link">{{ $t("actions.requestNewOffer") }}</b-button>
          </div>
        </b-col>

        <b-col class="text-right ">
          <b-btn variant="link"  v-b-toggle :href="'#offer-request-items-' + offer.id" @click.prevent="itemsOpened = !itemsOpened">{{ $t("labels.products") }}: {{ items.length }} <b-icon-chevron-down :rotate="itemsOpened ? 180 : 0" /></b-btn>
        </b-col>
      </b-row>
    </b-container>
    <b-collapse :id="'offer-request-items-' + offer.id" >
      <b-table
          :items="items"
          :keys="fields"
          :fields="fields"
          class="mb-0"
          sort-by="sortOrder"
          ref="dataTable"
          stacked="sm"
          show-empty>
        <template #empty="">
          <h5 class="text-center">{{ $t("formCheckout.emptyOffer") }}</h5>
        </template>

        <template #cell(productCode)="data">
          <span>{{ data.item.productCode }}</span>
        </template>
        
        <template #cell(description)="data">
          <span>{{ data.item && data.item.findDescriptionPerLang ? data.item.findDescriptionPerLang($i18n.locale, "IT") : data.item.pdtDesc }}</span>
        </template>
        
        <template #cell(unitPrice)="data">
          <span v-if="data.item.unitPrice">{{ data.item.unitPrice.toFixed(2) }} €/{{data.item.um}}</span>
          <span v-else-if="!data.item.unitPrice">
            {{ $t("product.notAssigned") }}
            <b-icon-info-circle aria-hidden="true" @click="$refs.notAssignedModal.show()"></b-icon-info-circle>
          </span>
        </template>

        
        <template #cell(unitQty)="data">
          <span>{{ data.item.unitQty }} {{data.item.um}}</span>
        </template>

        <template #cell(rowTotalPrice)="data">
          <span v-if="data.item.unitPrice">{{ (data.item.unitPrice * data.item.unitQty * data.item.quantity).toFixed(2) }} €</span>
          <span v-else-if="!data.item.unitPrice"> - </span>
        </template>

        <template v-if="offer.status === 'ACCEPTED'" #custom-foot>
          <b-tr>
            <b-td colspan="3" style="border-bottom: none;"></b-td>
            <b-td colspan="4" class="text-right px-5 bl text-uppercase" :style="backgroundStyle(offer)">
              <strong class="px-2">{{ $t("formCheckout.totalOrder") }} </strong>
              {{ total.toFixed(2) }} €
            </b-td>
          </b-tr>
        </template>
      </b-table>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "OrderOfferComponent",

  props: {
    offer: Object(),
  },

  data() {
    return {
      items: [],
      itemsOpened: false,
    };
  },

  computed: {
    total() {
      return this.items
          ? this.items.reduce(function (a, c) {
            return a + Number(c.unitPrice * c.unitQty * c.quantity || 0);
          }, 0)
          : 0;
    },

    fields() {
      if (this.offer.status === "ACCEPTED") {
        return [
          {
            key: "productCode",
            label: this.$i18n.t("product.product"),
            sortKey: "productCode",
          },
          {
            key: "description",
            label: this.$i18n.t("product.description"),
          },
          {
            key: "brand",
            label: this.$i18n.t("product.brand"),
          },
          {
            key: "unitPrice",
            label: this.$i18n.t("product.unitPrice"),
            tdClass: "",
          },
          {
            key: "unitQty",
            label: this.$i18n.t("product.unitQty"),
            tdClass: "",
          },
          {
            key: "quantity",
            label: this.$i18n.t("product.quantity"),
          },
          {
            key: "rowTotalPrice",
            label: this.$i18n.t("product.rowTotalPrice"),
            tdClass: " px-10",
          },
        ];
      } else {
        return [
          {
            key: "productCode",
            label: this.$i18n.t("product.product"),
            sortKey: "productCode",
          },
          {
            key: "description",
            label: this.$i18n.t("product.description"),
          },
          {
            key: "brand",
            label: this.$i18n.t("product.brand"),
          },
          {
            key: "unitQty",
            label: this.$i18n.t("product.unitQty"),
            tdClass: "",
          },
          {
            key: "quantity",
            label: this.$i18n.t("product.quantity"),
          },
        ];
      }
    },
  },

  methods: {
    backgroundStyle(offer) {
      if (offer.status === "PENDING") {
        return { 'background-color': '#EFEFED' };
      }
      if (offer.status === "ACCEPTED") {
        return { 'background-color': '#A7D8C0' };
      }
      if (offer.status === "CLOSED") {
        return { 'background-color': '#A7D8C0' };
      }
      if (offer.status === "REFUSED") {
        return { 'background-color': '#FF9A5C' };
      }
    },

    getItems() {
      this.items = [];
      for (let [sortOrder, item] of this.offer.items.entries()) {
        this.$store
            .dispatch("getProduct", Object.assign(item, {sortOrder: sortOrder}))
            .then((res) => this.items.push(res));
      }
    },

    requestOrder() {
      this.$store
          .dispatch("checkoutOfferAsOrder", this.offer.id)
          .then(() => this.$router.push("/checkout/order"))
          .catch((error) => this.$emit("fail", error));
    },

    retryCheckoutOffer() {
      this.$store
          .dispatch("retryCheckoutOffer", this.offer.id)
          .then(() => this.$router.go(0))
          .catch((error) => this.$emit("fail", error));
    },

    formatStringDate(dateString) {
      if (dateString) {
        const [year, month, day] = dateString.split("-");
        const date = new Date(year, month - 1, day);
        return ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear();
      } else {
        return '-';
      }
    },
  },

  created() {
    this.getItems();
  },

  watch: {
    offer() {
      this.getItems();
    }
  },
};
</script>
