export const formUser = {
  "gender": {
     "mr": "Herr",
     "mrs": "Frau",
     "select": "Herr/Frau"
  },
  "name": "Name",
  "firstName": "Vorname",
  "lastName": "Nachname",
  "country": "Land",
  "zipCode": "PLZ",
  "province": "Province",
  "sectorInterest": "Interessenbereich",
  "private": "Privatperson",
  "company": "Firma",
  "telephone": "Telefon",
  "phonePrefix": "Vorwahl",
  "repeatPasswod": "Passwort wiederholen",
  "authorizeGdpr": "Ich bin einverstanden mit der Datenverarbeitung gemäß „Rechtshinweis zur Verarbeitung personenbezogener Daten von Kunden und Lieferanten",
  "textLinkGdpr": "gemäß Art. 13 ital. Verordnung D.Lgs. Nr. 196/2003 und Verordnung (EG) Nr. 679/2016“",
  "subscribeToNewsletter": "Ich möchte Neues von Alcea erfahren und abonniere den Newsletter",
  "textLinkNewsletterGdpr": "gemäß Art. 13 Verordnung (EG) Nr. 679/2016.",
  "authorizeCommercial": "Ich bin einverstanden mit der Verarbeitung meiner personenbezogenen Daten zu Marktforschungszwecken",
  "notificationMail": "Sie haben die E-Mail-Zugangsbenachrichtigungen noch nicht aktiviert!\n<br/>Möchten Sie sie jetzt aktivieren?<br/><br/><b>Hinweis: Sie können diese Einstellung jederzeit über Ihr Profil ändern</b>",
  "businessName": "Firmenname",
  "businessWebsite": "Website",
  "businessType": "Geschäftsart",
  "businessRole": "Geschäftsfunktion",
  "businessDepartment": "Geschäftsabteilung",
  "personalInformation": "Daten zur Person",
  "companyInformation": "Firmendaten",
  "notificationMailSwitch": "E-Mail-Benachrichtigung bei jeder Anmeldung erhalten",
  "email": "E-Mail-Adresse",
  "repeatEmail": "E-Mail-Adresse wiederholen",
  "intersted": "Interesse",
  "null": {
     country: "Land",
     intersted: "Interesse",
     businessRole: "Funktion"
  }
};
