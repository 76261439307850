<template>
  <div class="login-background bx">
    <div class="container login-container">
      <section class="row justify-content-center">

        <form-password
            :invalidQuery="invalidQuery"
            :feedbackStatus="feedbackStatus"
            :feedbackMessage="feedbackMessage"
            :title="title"
            :errorMsg="errorMsg"
            @send="postConfirm">
        </form-password>

      </section>
    </div>
  </div>
</template>

<script>
import formPassword from "../components/form-password.vue"
import ErrorManager from "../services/errors-service/errors-manager"

export default {
  components: {
    formPassword
  },

  data() {
    return {
      invalidQuery: false,
      feedbackMessage: "",
      feedbackStatus: null,
      errorMsg: "",
      title: "confirmRegistration",
    }
  },

  mounted() {
    if (this.$route.query.link) {
      this.invalidQuery = true
    }
  },

  methods: {
    async postConfirm(user) {
      await this.$store.dispatch("confirmRegister", user).then((response) => {
        console.log(response)
        this.$router.push("/login?confirmed=" + user.username)
      }).catch((error) => {
        this.feedbackStatus = "FailDismissable"
        this.feedbackMessage = ErrorManager.feedback(error) == 'Unauthorized' || ErrorManager.feedback(error) == 'BadRequest' ? 'UnauthorizedOrInvalid' : ErrorManager.feedback(error)
      })
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!to.query.username || !to.query.token) {
      next((vm) => {
        vm.invalidQuery = true
        vm.feedbackStatus = "Fail"
        vm.feedbackMessage = vm.$i18n.t("UnauthorizedOrInvalid")
      })
    } else {
      next()
    }
  },
}
</script>
