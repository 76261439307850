<template>
  <div>
    <b-container fluid>
      <b-row class="ps-selector">
        <b-col cols="12">
          <div class="title text-center">
            <h5>Video Tutorial</h5>
          </div>
        </b-col>
        <b-col>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block class="text-left" v-b-toggle.demo-1 variant="primary"><b-icon-youtube /> <span v-html="$t('faq.demo1Title')"></span></b-button>
              </b-card-header>
              <b-collapse id="demo-1" visible accordion="demo-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text v-html="$t('faq.demo1Body')">
                  </b-card-text>
                  <b-card-text class="col-md-8 m-auto">
                    <!--b-alert show variant="warning">
                      <h3>Il video sarà presto disponibile.</h3>
                    </b-alert-->
                    <b-embed
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/obkxmmiV1-k?rel=0"
                        title="Alcea"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></b-embed>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card v-if="loggedIn" no-body class="mb-1">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block class="text-left" v-b-toggle.demo-2 variant="primary"><b-icon-youtube /> <span v-html="$t('faq.demo2Title')"></span></b-button>
              </b-card-header>
              <b-collapse id="demo-2" visible accordion="demo-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text v-html="$t('faq.demo2Body')">
                  </b-card-text>
                  <b-card-text class="col-md-8 m-auto">
                    <b-embed
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/ArJKNQmfPYw?rel=0"
                        title="Alcea"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></b-embed>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card  v-if="loggedIn" no-body class="mb-1">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block class="text-left" v-b-toggle.demo-3 variant="primary"><b-icon-youtube /> <span v-html="$t('faq.demo3Title')"></span></b-button>
              </b-card-header>
              <b-collapse id="demo-3" visible accordion="demo-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text v-html="$t('faq.demo3Body')">
                  </b-card-text>
                  <b-card-text class="col-md-8 m-auto">
                    <b-embed
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/nssl0FQr-lc?rel=0"
                        title="Alcea"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></b-embed>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card v-if="isCustomerOrAgent" no-body class="mb-1">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block class="text-left" v-b-toggle.demo-4 variant="primary"><b-icon-youtube /> <span v-html="$t('faq.demo4Title')"></span></b-button>
              </b-card-header>
              <b-collapse id="demo-4" visible accordion="demo-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text v-html="$t('faq.demo4Body')">
                  </b-card-text>
                  <b-card-text class="col-md-8 m-auto">
                    <b-embed
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/Pq50qvh6ySk?rel=0"
                        title="Alcea"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></b-embed>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card v-if="isCustomerOrAgent" no-body class="mb-1">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block class="text-left" v-b-toggle.demo-5 variant="primary"><b-icon-youtube /> <span v-html="$t('faq.demo5Title')"></span></b-button>
              </b-card-header>
              <b-collapse id="demo-5" visible accordion="demo-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text v-html="$t('faq.demo5Body')">
                  </b-card-text>
                  <b-card-text class="col-md-8 m-auto">
                    <b-embed
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/9WdhWTKGpzY?rel=0"
                        title="Alcea"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></b-embed>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card v-if="isCustomerOrAgent" no-body class="mb-1">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block class="text-left" v-b-toggle.demo-6 variant="primary"><b-icon-youtube /> <span v-html="$t('faq.demo6Title')"></span></b-button>
              </b-card-header>
              <b-collapse id="demo-6" visible accordion="demo-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text v-html="$t('faq.demo6Body')">
                  </b-card-text>
                  <b-card-text class="col-md-8 m-auto">
                    <b-embed
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/7u24FHCn8pQ?rel=0"
                        title="Alcea"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></b-embed>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ProductsSearch from '@/views/ProductsSearch/ProductsSearch'
import AdwButton from "@/components/global-components/adw-button";

import { mapState } from 'vuex'

export default {
  name: "Faq",
  components: {
    ProductsSearch,
    AdwButton,
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.status.loggedIn,
      userRole: (state) => state.auth.user.role,
      userType: (state) => state.auth.user.type,
    }),
    isCustomerOrAgent() {
      return this.loggedIn && this.userRole != 'USER';
    }
  }
}
</script>

<style lang="scss">

</style>
