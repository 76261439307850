<template>
  <div>


    <!-- no request present -->
    <template v-if="requestStatus === 'NONE'">
      <div class="p-4 border-bottom text-center">
        <h3 class="text-uppercase">{{ $t('titles.becomeCustomerInvitation') }}</h3>
        <p>{{ $t("requestStatus.becomeCustomerText") }}</p>

        <adw-button class="my-4 mx-3 text-uppercase"
            @button-click="$router.push('/customer-request')">
            <h3>{{ $t("actions.compileRequest") }}</h3>
        </adw-button>
      </div>
    </template>


    <!-- request present -->
    <template v-if="requestStatus !== 'NONE'">
      <div class="p-4 border-bottom text-center">
        <h3 class="text-uppercase">{{ $t('requestStatus.text') }}</h3>

      <!-- in status: draft -->
      <template v-if="requestStatus === 'DRAFT'">

              <p>{{ $t("requestStatus.becomeCustomerText") }}</p>

              <adw-button class="mt-4 mb-2 mx-3 text-uppercase"
                  @button-click="$router.push('/customer-request')">
                  <h3>{{ $t("actions.continue") }}</h3>
              </adw-button>
              <p>oppure</p>
              <adw-button class="mt-2 mb-4 mx-3 black text-uppercase"
                  @button-click="showConfirmNewRequestModal">
                  <h3>{{ $t("actions.newRequest") }}</h3>
              </adw-button>
      </template>

      <!-- in status: pending -->
      <template v-if="requestStatus === 'PENDING'">

        <p>{{ $t("requestStatus.requestStatus") }}: </p>
        <p><b-icon font-scale="0.8" aria-hidden="true" icon="circle-fill" :style="statusClass"></b-icon>
            {{ $t("requestStatus." + requestStatus) }}</p>

      </template>

      <!-- in status: accepted -->
      <template>
        <b-modal
          size="lg"
          scrollable
          hide-header
          hide-footer
          centered
          no-close-on-backdrop
          body-class="p-3"
          ref="acceptedDialog"
          >
        <b-container fluid >
          <!-- header -->
          <b-row class="ps-buy-header bb px-0">
            <!-- title -->
            <b-col class="d-flex justify-content-between ps-buy-title">
                {{ $t('actions.updateToCustomer') }}
            </b-col>
          </b-row>

          <b-row class="ps-buy-header bb" align-h="between">
            <b-col>
              {{ $t("requestStatus.acceptedText1") }} {{ businessName }}{{ $t("requestStatus.acceptedText2") }}
            </b-col>
          </b-row>

          <b-row style="padding-top: 3px">
            <b-col>
              <b-button @click="becomeCustomer">Ok</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      </template>

      <!-- in status: rejected -->
      <template v-if="requestStatus === 'REJECTED'">
          <p>{{ $t("requestStatus.rejectedText") }}</p>

          <adw-button class="my-4 mx-3 black text-uppercase"
              @button-click="deleteRequest">
              <h3>{{ $t("actions.understand") }}</h3>
          </adw-button>
      </template>

    </div>
    </template>


    <!-- link customer -->
    <template v-if="requestStatus === 'NONE' || requestStatus === 'DRAFT'">

      <div class="p-4 border-bottom text-center">
        <h3 class="text-uppercase">{{ $t('requestStatus.customerExisting.title') }}</h3>
        <p>{{ $t("formCustomer.customerCodeText") }}</p>

        <adw-button class="my-4 mx-3 text-uppercase"
            @button-click="showLinkCustomerModal">
            <h3>{{ $t("actions.associateToExisting") }}</h3>
        </adw-button>
      </div>
    </template>

    <!-- new request confirmation modal -->
    <b-modal
        centered
        id="confirmNewRequestModal"
        ref="confirmNewRequestModal"
        :title="$t('titles.confirmNewRequest')"
        @ok="newRequest">
      <p class="my-4" id="notificationMailSubscriptionText">
        {{ $t("requestStatus.confirmNewRequest") }}
      </p>
    </b-modal>

    <!-- link customer modal -->
    <b-modal
        centered
        hide-footer
        id="linkCustomerModal"
        ref="linkCustomerModal"
        :title="$t('requestStatus.customerExisting.titleShort')">
      <customer-association-request></customer-association-request>
    </b-modal>


  </div>
</template>

<script>
import { mapState } from "vuex"
import CustomerAssociationRequest from "@/components/private-area/customer-association-request"
import AdwButton from "@/components/global-components/adw-button";

export default {
  name: "RequestStatus",

  components: {
    CustomerAssociationRequest,
    AdwButton,
  },

  props: [
    'openLinkCustomerModal'
  ],

  computed: {
    ...mapState({
      requestStatus: (state) => state.request.request.status,
      businessName: (state) => state.user.userinfo.businessName
    })
  },

  methods: {
    showConfirmNewRequestModal() {
      this.$refs.confirmNewRequestModal.show()
    },

    showLinkCustomerModal() {
      this.$refs.linkCustomerModal.show()
    },

    newRequest() {
      this.$store.dispatch("newRequest")
          .then(() => this.$router.push("/customer-request"))
          .catch((error) => {
            console.error(error)
          })
    },

    becomeCustomer() {
      this.$store.dispatch("updateToCustomer")
          .then((response) => {
            this.$refs.acceptedDialog.show();
            //console.log(response)
          })
          .catch((error) => {
            console.error(error)
          })
    },

    deleteRequest() {
      this.$store.dispatch("deleteRequest")
          .then((response) => {
            //console.log(response)
          })
          .catch((error) => {
            console.error(error)
          })
    },
  },

  mounted() {
    this.$store.dispatch("getRequest").then(() => {
      if(this.requestStatus === "ACCEPTED") {
        this.$refs.acceptedDialog.show();
      }
    })
  },

  watch: {
    openLinkCustomerModal() {
      if (this.openLinkCustomerModal) {
        this.showLinkCustomerModal()
      }
    }
  }
}
</script>
