import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./module-auth";
import { registrationUser } from "./module-registration";
import { user } from "./module-user";

import { request } from "./module-request";
import { invitations } from "./module-invitations";
import { customer } from "./module-customer";
import createPersistedState from "vuex-persistedstate";
import { orders } from "./module-orders";
import { ordered } from "./module-ordered";
import { cart } from "./module-cart";
import { agent } from "./module-agent";
import { checkout } from "./module-checkout";
import { ordersAndOffers } from "./module-request-orders-offers";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    registrationUser,
    user,
    customer,
    request,
    invitations,
    agent,
    orders,
    cart,
    checkout,
    ordersAndOffers,
    ordered,
  },
  plugins: [createPersistedState()],
});
