export const types ={
    
    metalIndustry: "Industria metallo",
    woodIndustry: "Industria legno",
    coilCoating: "Coil Coating",
    construction: "Construction",
    homeLine: "Linea casa",
    tintometrics: "Tintometria",
    resales:'rivendite',

}