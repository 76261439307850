export const sds = {
    form: {
        site: "SITO",
        allSites: "Tutti i siti",
        serial: "SERIE",
        color: "COLORE",
        product: "PRODOTTO",
        customerCode: "CLIENTE",
        year: "ANNO",
        batch: "LOTTO",
        orderCode: "CODICE ORDINE",
        language: "LINGUA",
        selectLanguage: "Seleziona una lingua",
    },
    results: {
        yearMonth: 'Anno-Mese',
        batch: 'Lotto',
        product: 'Prodotto',
        recipe: 'Ricetta',
        versionOrBarra: 'Versione / Barra',
    }
};
