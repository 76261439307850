<template>
  <div class="bx" style="padding: 20px;">
    <h3>{{ $t("titles.checkoutOrder") }}</h3>
    <br/>

    <feedback-alert
        class="w-100"
        :status="feedbackStatus"
        :message="feedbackMessage"
        @clearStatus="clearStatus"
        @back="toList">
    </feedback-alert>

    <div v-if="feedbackStatus != 'Success'">
      <h4>{{ $t("subtitles.shippingAddress") }}</h4>
      <p>{{ $t("instructions.checkout.shippingAddress") }}</p>
      <div class="mx-2">
        <b-form-group class="px-0" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              class="row"
              v-model="form.address"
              name="address">
            <label
                class="col-12 col-sm-auto"
                v-for="(shippingAddress, index) in addresses"
                :key="index">
              <b-form-radio
                  class="p-0 text-left p-3 d-none card-input-element"
                  :value="shippingAddress"
                  :id="'address' + index"
                  :aria-describedby="ariaDescribedby"
                  @change="form.address = addressSelected = shippingAddress"/>
              <label
                  class="w-100 h-100 mx-0"
                  :for="'address' + index"
                  @click="form.address = addressSelected = shippingAddress">
                <b-card
                    :class="'my-0 h-100 ' + (addressSelection(shippingAddress) ? 'font-weight-bold' : '')"
                    height="150"
                    :bg-variant="addressSelection(shippingAddress) ? 'primary' : ''"
                    :border-variant="addressSelection(shippingAddress) ? 'primary' : ''">
                  <strong v-if="shippingAddress.alceaOrder == 0">{{ $t("formCheckout.businessAddress") }}</strong>
                  <strong v-else>{{ $t("formCheckout.shippingAddress") }} {{ shippingAddress.alceaOrder }}</strong>
                  <br/>
                  <span>{{ shippingAddress.businessName }}, <br/></span>
                  <span>{{ shippingAddress.streetAddress1 }}, <br/><span
                      v-if="shippingAddress.streetAddress2">
                        {{ shippingAddress.streetAddress2 }},<br/>
                  </span>
                      {{ shippingAddress.zipOrPostalCode }}
                    </span>
                  <span>
                      {{ shippingAddress.city }} -
                      {{ shippingAddress.province }}, {{ shippingAddress.country }}<br/></span
                  >
                  </b-card>
              </label>
            </label>
          </b-form-radio-group>
        </b-form-group>
      </div>
      <br/>

      <h4>{{ $t("subtitles.customerOrderCode") }}</h4>
      <p>{{ $t("instructions.checkout.customerOrderCode") }}</p>
      <b-form-group class="col-md-8 px-0">
        <b-form-input
            v-model="form.customerOrderCode"
            :state="validationState('customerOrderCode')"
            maxlength="20"
            :placeholder="$t('formCheckout.customerOrderCode')"/>
        <b-form-text id="input-live-help">Max 20 caratteri tra cui numeri, lettere, spazi, simboli ( _ @ . / # & + - )</b-form-text>
      </b-form-group>
      <br/>

      <h4>{{ $t("subtitles.deliveryDateRequested") }}</h4>
      <p>{{ $t("instructions.checkout.deliveryDateRequested") }}</p>
      <b-form-group class="col-md-5 px-0">
        <b-form-datepicker
            :disabled="offerId != null"
            v-model="form.deliveryDateRequested"
            :min="offerId == null?tomorrow:null"
            :state="validationState('deliveryDateRequested')"
            :locale="$i18n.locale"
            :placeholder="$t('formCheckout.chosenDate')"/>
      </b-form-group>
      <br/>

      <h4>{{ $t("subtitles.acceptMultipleShipping") }}</h4>
      <p>{{ $t("instructions.checkout.acceptMultipleShipping") }}</p>
      <b-form-group class="col-md-8 px-0">
        <b-checkbox
            :disabled="offerId != null"
            v-model="form.acceptMultipleShipping"
            switch
            id="acceptMultipleShipping">{{ $t("formCheckout.acceptMultipleShipping") }}
        </b-checkbox>
      </b-form-group>
      <br/>

      <h4>{{ $t("subtitles.shippingNote") }}</h4>
      <p>{{ $t("instructions.checkout.shippingNote") }}</p>
      <b-form-group class="col-md-8 px-0">
        <b-form-textarea
            v-model="form.shippingNote"
            type="date"
            :placeholder="$t('formCheckout.shippingNote')"
            maxlength="255"/>
      </b-form-group>
      <br/>

      <h4>{{ $t("subtitles.genericNote") }}</h4>
      <p>{{ $t("instructions.checkout.genericNote") }}</p>
      <b-form-group class="col-md-8 px-0">
        <b-form-textarea
            v-model="form.genericNote"
            type="date"
            :placeholder="$t('formCheckout.genericNote')"
            maxlength="255"/>
      </b-form-group>
      <br/>

      <h4 v-if="isUserAgentOrCommercial">{{ $t("subtitles.agentNote") }}</h4>
      <p v-if="isUserAgentOrCommercial">{{ $t("instructions.checkout.agentNote") }}</p>
      <b-form-group v-if="isUserAgentOrCommercial" class="col-md-8 px-0">
        <b-form-textarea
            v-model="form.agentNote"
            type="date"
            :placeholder="$t('formCheckout.agentNote')"
            maxlength="255"/>
      </b-form-group>
      <br/>

      <div class="table-responsive" ref="dataTableWrap">
        <h4>{{ $t("subtitles.summary") }}</h4>
        <b-table
            :items="items"
            :keys="fields"
            :fields="fields"
            hover
            ref="dataTable"
            stacked="sm"
            show-empty>
          <template #empty="">
            <h5 class="text-center">{{ $t("formCheckout.emptyOrder") }}</h5>
          </template>

          <template #cell(productCode)="data">
            <span>{{ data.item.productCode }} <br/>{{ data.item.description }}</span>
          </template>

          <template #cell(finalUnitPrice)="data" @click.stop>
            <span v-if="data.item.finalUnitPrice">{{ data.item.finalUnitPrice.toFixed(2) }} €/{{ data.item.um }}</span>
            <span v-else-if="!data.item.finalUnitPrice">
                {{ $t("product.notAssigned") }}
                <b-icon-info-circle
                    aria-hidden="true"
                    @click="$refs.notAssignedModal.show()"/>
              </span>
          </template>

          <template #cell(unitQty)="data">
            <span> {{ data.item.unitQty }} {{ data.item.um }} </span>
          </template>

          <template #cell(rowWeight)="data">
            <span> {{ calculateRowWeight(data.item) }} Kg. </span>
          </template>

          <template #cell(rowTotalPrice)="data">
            <span v-if="data.item.finalUnitPrice">{{ calculateRowTotal(data.item) }} €</span>
            <span v-else-if="!data.item.finalUnitPrice"> - </span>
          </template>

          <template #custom-foot>
            <b-tr>
              <b-td colspan="4" variant="" class="text-right px-5">
                <strong class="px-2">{{ $t("orderInfo.totalWeight") }}</strong>
              </b-td>
              <b-td>{{ calculateTotalWeight() }} Kg.</b-td>
              <b-td>&nbsp;</b-td>
            </b-tr>
            <b-tr v-if="customer.unconditionalDiscountPct > 0">
              <b-td colspan="5" variant="" class="text-right px-5">
                <strong class="px-2">{{ $t("formCheckout.unconditionalDiscount") }} {{ customer.unconditionalDiscountPct }}%</strong>
              </b-td>
              <b-td class="text-right">- {{ unconditionalDiscountTotal() }} €</b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="5" variant="" class="text-right px-5">
                <strong class="px-2">{{ $t("formCheckout.totalOrder") }}</strong>
              </b-td>
              <b-td class="text-right">{{ total() }} €</b-td>
            </b-tr>
          </template>
        </b-table>

        <span class="d-sm-none">
          <hr/>
          <strong class="px-2">{{ $t("formCheckout.totalOrder") }} </strong>
          {{ total() }} €
        </span>
      </div>

      <div class="row w-100 justify-content-end">
        <adw-button class="link w-50"
                    @button-click="$router.push('/profile-customer/cart')">
          <h3>{{ $t("actions.backToCart") }} </h3>
        </adw-button>
        <adw-button :class="'w-50 ' + (isSendingOrder || items.length === 0 ? 'disabled' : '')"
            @button-click="sendOrder">
            <h3>{{ $t("actions.orderRequest") }} </h3>
        </adw-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import feedbackAlert from "@/components/feedback-alert.vue";
import AdwButton from "@/components/global-components/adw-button";
import errorsManager from "@/services/errors-service/errors-manager";
import Validator from "@/services/validation-service/validator-service";
import { sortShippingAdddresses } from "@/models/customer";

export default {
  name: "CheckoutOrder",

  components: { 
    feedbackAlert,
    AdwButton 
  },

  data() {
    return {
      now: new Date(),
      fields: [
        {
          key: "productCode",
          label: this.$i18n.t("product.product"),
          sortKey: "productCode",
          sortable: true,
        },
        {
          key: "finalUnitPrice",
          label: this.$i18n.t("product.unitPrice"),
          sortable: true,
          tdClass: "",
        },
        {
          key: "unitQty",
          label: this.$i18n.t("product.unitQty"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.$i18n.t("product.quantity"),
          sortable: false,
        },
        {
          key: "rowWeight",
          label: this.$i18n.t("orderInfo.item.rowWeight")
        },
        {
          key: "rowTotalPrice",
          label: this.$i18n.t("product.rowTotalPrice"),
          sortable: false,

          tdClass: " text-right px-10",
        },
      ],
      addressSelected: null,
      validation: {
        invalid: [],
      },
      invalidErrors: [],
      otherError: {name: null, msg: null},
      form: {},
      feedbackStatus: null,
      feedbackMessage: "",
      isSendingOrder: false,
    };
  },
  computed: {
    ...mapState({
      addressesCustomer: (state) =>
          state.customer.customerinfo.addresses,
      items: (state) => state.checkout.order.itemsToCheckout,
      offerId: (state) => state.checkout.order.offer.id,
      offer: (state) => state.checkout.order.offer.info,
      userRole: (state) => state.auth.user.role,
      customer: (state) => state.customer.customerinfo,
    }),
    isUserAgentOrCommercial() {
      return this.userRole == 'AGENT' || this.userRole == 'COMMERCIAL'
    },
    addresses() {
      return sortShippingAdddresses(this.addressesCustomer);
    },
    /*
    form() {
      this.addressSelected = this.addresses[0];
      return {
        offerRequestToCloseId:this.offerId?this.offerId:null,
        items: this.items,
        address: this.addresses[0],
        deliveryDateRequested: this.offerId
          ? this.offer.deliveryDateRequested
          : this.formatDate(this.tomorrow),
        acceptMultipleShipping: this.offerId
          ? this.offer.acceptMultipleShipping
          : false,
        shippingNote: "",
        genericNote: "",
      };
    },
    */

    formToSend() {
      return {
        items: this.form.items,
        address: this.form.address,
        customerOrderCode: this.form.customerOrderCode,
        deliveryDateRequested: this.form.deliveryDateRequested,
        acceptMultipleShipping: this.form.acceptMultipleShipping,
        shippingNote: this.form.shippingNote,
        genericNote: this.form.genericNote,
        agentNote: this.form.agentNote,
        overallDiscount: this.customer.unconditionalDiscountPct || 0,
      };
    },

    formFromOfferToSend() {
      return {
        offerRequestAcceptedId: this.offerId,
        customerOrderCode: this.form.customerOrderCode,
        address: this.form.address,
        shippingNote: this.form.shippingNote,
        genericNote: this.form.genericNote,
        agentNote: this.form.agentNote,
      };
    },

    formToValidate () {
      return {
        customerOrderCode: this.form.customerOrderCode,
        deliveryDateRequested: this.form.deliveryDateRequested,
        address: this.form.address,
      }
    },

    tomorrow() {
      let delay = 2;

      if(this.items) {
        if(this.items.some((item) => !item.inStock)) {
          delay = 3;
        }
      }

      console.log('delay', delay)

      return new Date(
          this.now.getFullYear(),
          this.now.getMonth(),
          this.now.getDate() + delay
      );
    },
  },
  methods: {
    addressSelection(value) {
      console.log("ADDRESS", this.form.address, value);

      return this.addressSelected == value;
    },
    formatDate(date) {
      console.log("DATE", date);
      if (date instanceof Date) {
        return (
            date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2)
        );
      }
    },
    toList() {
      this.$router.push("/profile-customer/request-orders");
    },
    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },
    validationState(contextRef) {
      return this.invalidErrors.find(
          (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
          ? false
          : null;
    },
    validationError(contextRef) {
      if (this.invalidErrors.length > 0) {
        let find =
            this.invalidErrors.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
    sendOrder() {
      
      if(this.isSendingOrder) 
        return;

      this.isSendingOrder = true;

      try {
        let validation = Validator.validateForm(
            "formCheckout",
            this.formToValidate
        );
        this.invalidErrors = validation.invalid;
      } catch (e) {
        console.error(e);
        this.otherError.name = "validation";
        this.otherError.msg = e;
        this.invalidErrors.push(this.otherError);
      }

      console.log(this.invalidErrors)

      if (this.invalidErrors.length > 0 || this.otherError.msg) {
        console.error("possible 409");
        console.error(this.invalidErrors);
        console.error(this.otherError);
        this.feedbackStatus = "FailDismissable";
        this.feedbackMessage = errorsManager.feedback("invalidForm");

        this.isSendingOrder = false;

      } else {
        
        console.log("Order", this.formToSend);
        if (this.offerId != null) {
          this.$store
              .dispatch("checkoutOrderFromOffer", this.formFromOfferToSend)
              .then(() => {
                this.$store.dispatch("getProductsCart");
                this.feedbackStatus = "Success";
                this.feedbackMessage = "SuccessCheckOutOrder";
              })
              .catch((error) => {
                this.feedbackStatus = "FailDismissable";
                this.feedbackMessage = errorsManager.feedback(error);
              }).finally(() => {
                this.isSendingOrder = false;
              });;
        } else {
          this.$store
              .dispatch("checkoutOrder", this.formToSend)
              .then(() => {
                this.$store.dispatch("getProductsCart");
                this.feedbackStatus = "Success";
                this.feedbackMessage = "SuccessCheckOutOrder";
              })
              .catch((error) => {
                this.feedbackStatus = "FailDismissable";
                this.feedbackMessage = errorsManager.feedback(error);
              }).finally(() => {
                this.isSendingOrder = false;
              });
        }
      }
    },
    calculateRowWeight (rowItem) {
      console.log('calculateRowWeight plo', rowItem.plo);
      console.log('multiply', (rowItem.quantity * rowItem.plo))
      return +(Math.round((rowItem.quantity * rowItem.plo) + "e+2") + "e-2");
    },
    calculateTotalWeight () {
      return +(Math.round(this.form.items.reduce (
          (sum, i) => sum + this.calculateRowWeight(i),
          0
      ) + "e+2")  + "e-2");
    },
    calculateRowTotal(rowItem) {
      return +(Math.round((rowItem.finalUnitPrice * rowItem.unitQty * rowItem.quantity) + "e+2")  + "e-2");
    },
    unconditionalDiscountTotal () {
      return +(Math.round(this.totalWithoutDiscount() * (this.customer.unconditionalDiscountPct/100)  + "e+2")  + "e-2");
    },

    totalWithoutDiscount() {
      return this.form.items
          ? +(Math.round(this.form.items.reduce(function (a, c) {
            return a + Number(c.finalUnitPrice * c.unitQty * c.quantity || 0);
          }, 0) + "e+2")  + "e-2")
          : 0;
    },

    total() {
      return !this.customer.unconditionalDiscountPct ? this.totalWithoutDiscount() :
          +(Math.round(this.totalWithoutDiscount() * (1 - (this.customer.unconditionalDiscountPct / 100)) + "e+2")  + "e-2");
    },
  },
  mounted() {
    this.addressSelected = this.addresses.find(a => a.isDefault === true) || this.addresses[0];
    this.form = {
      offerRequestToCloseId: this.offerId ? this.offerId : null,
      items: this.items,
      address: this.addressSelected,
      deliveryDateRequested: this.offerId
          ? this.offer.deliveryDateRequested
          : this.formatDate(this.tomorrow),
      acceptMultipleShipping: this.offerId
          ? this.offer.acceptMultipleShipping
          : false,
      shippingNote: "",
      genericNote: "",
      agentNote: "",
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("ClearCheckoutOrder");
    next();
  },
};
</script>

<style>
.card-body:hover {
  box-shadow: 0;
  cursor: pointer;
}
</style>
