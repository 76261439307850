export const labels = {
  insertUsername:"Inserisci email",
  insertPassword:"Inserisci password",
  itsMe:"Sono io",
  password:"Password",
  repeatPassword:"Conferma Password",
  language: "Lingua",
  italian: "Italiano",
  english: "Inglese",
  spanish: "Spagnolo",
  french: "Francese",
  polish: "Polacco",
  russian: "Russo",
  german: "Tedesco",
  yes: "Si",
  no: "No",
  user: "Utente",
  username: "Nome utente",
  customerCode: "Codice cliente",
  welcome: "Benvenuto",
  adw1:"Alcea S.p.A. via Piemonte 18, 20030 Senago (Milano) IT ",
  adw2:"cap.soc. 3.600.000 euro i.v.",
  adw3:"CF P.IVA e registro imprese IT00718900152 - REA Milano 422086",
  privacylink:'Privacy',
  version:'versione',
  from:'Da',
  to:'A',
  userUpdated: "Utente aggiornato",
  forgotPassword: "Password dimenticata?",
  registerNewAccount: "Non hai un account?",
  needHelp:"Hai bisogno di aiuto?",
  NoInvites:"Non sono presenti inviti",
  NoCustomers:"Non sono registrati clienti per questo agente",
  expireIn:"Giorni alla scadenza",
  days:"gioni",
  perPage:'Elementi per pagina',
  requestAlreadyAcceped:'Hai già una richesta in pending accettata, torna al profilo utente e conferma per diventare cliente',
  editRequestPending:'Richiesta di modifica in attesa di conferma',
  emptyTable:'Non sono presenti dati corrispondenti',
  emptyCart:"il carrello è vuoto, aggiungi prodotti per visualizzarli in quest'area",
  notAssignedText:'Attenzione: il prezzo del prodotto selezionato non è stato assegnato al suo codice cliente, per ordinare questo prodotto è necessario richiedere una richiesta di offerta prima della richiesta di ordine.',
  passwordRequirementsRegistration:"La tua email è stata validata, inserisci una password per completare la registrazione e poter accedere ai servizi ADW. <br/>",
  passwordRequirements: "Ricordiamo che la password dovrà contenere le seguenti vincoli di sicurezza:" +
  "<br/><br/>" +
  " - Lunghezza minima 8 caratteri esclusi spazi bianchi " +
  "<br/>" +
  " - Almeno una lettera minuscola " +
  "<br/>" +
  "- Almeno una lettera maiuscola " +
  "<br/>" +
  "- Almeno un numero " +
  "<br/>" +
  "- Almeno un simbolo tra !$%&@#*",
  gdpr:
    "<b>Informativa sul trattamento dei dati personali per clienti e fornitori ex art. 13 D. Lgs. n. 196/2003 e Regolamento Europeo UE n. 679/2016</b>" +
    "<br/><br/>" +
    " Egregio Cliente o Fornitore," +
    "<br/>" +
    " con la presente comunicazione La/Vi informiamo che Alcea S.p.A. (di seguito Alcea), con sede legale ed operativa in Via Piemonte, n. 18, 20030 Senago, è in possesso di dati personali anagrafici e dati relativi all’attività economica e commerciale (es: ordini, dati fiscali, dati contabili) a Lei/Voi riferiti per l’istaurazione e l’esecuzione dei rapporti contrattuali con Lei/Voi in corso." +
    "<br/>" +
    "Nel rispetto della normativa vigente in materia di protezione dei dati personali, La/Vi informiamo che la nostra Società tratta i suddetti dati, con strumenti elettronici e cartacei." +
    "<br/>" +
    "1) <u>Finalità del trattamento dei dati personali.</u>" +
    "<br/>" +
    "Il conferimento dei dati da parte Sua/Vostra è facoltativo." +
    "Tuttavia, in mancanza di tale conferimento, la nostra Società non potrà gestire il rapporto contrattuale con Lei/Voi instaurato." +
    "Il trattamento dei Suoi/Vostri dati personali avverrà a norma di Legge, secondo i principi di liceità, correttezza e trasparenza nonché in modo da tutelare la Sua/Vostra riservatezza." +
    "<br/>" +
    "2) <u>Destinatari a cui i dati sono comunicati.</u>" +
    "<br/>" +
    "I Suoi/Vostri dati non saranno in alcun modo diffusi né soggetti ad alcun processo decisionale interamente automatizzato, ivi compresa la profilazione, ma potranno essere comunicati ai seguenti soggetti o categorie di soggetti:" +
    "<br/>" +
    "· Istituti di credito, Consulenti del Lavoro, Lavoratori Autonomi che prestano contrattualmente la propria opera per Alcea;" +
    "<br/>" +
    "· Personale dipendente di Alcea quali, ad esempio, dipendenti dell’area amministrativa, commerciale, tecnica-software;" +
    "<br/>" +
    "I Suoi/Vostri dati non saranno in alcun modo oggetto di diffusione al di fuori di quanto sopra indicato. " +
    "<br/>" +
    "3) <u>Diritti dell’interessato.</u>" +
    "<br/>" +
    "La informiamo che Lei/Voi ha/avete il diritto di:" +
    "<br/>" +
    "· conoscere, in ogni momento, di quali dati personali, a Lei/Voi riferiti, la nostra Società sia a conoscenza, la loro origine e come questi vengano utilizzati;" +
    "<br/>" +
    "· fare aggiornare, rettificare, integrare e cancellare i Suoi/Vostri dati nonché ad opporsi a loro trattamento, in tutto o in parte, per motivi legittimi, revocare il consenso in qualsiasi momento;" +
    "<br/>" +
    "· proporre reclamo all’Autorità di Controllo, in caso di elaborazione/divulgazione non autorizzata dei Suoi/Vostri dati, da parte della nostra Società o, comunque, violazione dei diritti qui menzionati." +
    "<br/>" +
    "4) <u>Periodo di conservazione dati.</u>" +
    "<br/>" +
    "I Suoi/Vostri dati personali verranno conservati dalla nostra Società per 10 (dieci) anni dal termine di ogni rapporto contrattuale, salvo Sua/Vostra richiesta di cancellazione. " +
    "<br/>" +
    "5) <u>Titolare e Responsabile del trattamento dati.</u>" +
    "<br/>" +
    "Titolare del trattamento dei dati è, ad ogni effetto di legge, Alcea S.p.A., in persola del Legale rappresentate pro tempore, con sede Via Piemonte, n. 18 – 20030 Senago." +
    "<br/>" +
    "Per l’esercizio di tali diritti oltre che per avere piena chiarezza sulle operazioni anzidette ci si potrà rivolgere a privacy@alcea.com",
  selectedCustomer:'Cliente selezionato',
  products: 'Prodotti',
};
