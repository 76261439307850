import UserService from "../services/api-services/user-service";

const initialState = {
  status: { loggedInPrivateArea: false },
  userinfo: {},
  usertype: undefined,
};

export const user = {
  state: initialState,

  actions: {
    myself({ commit, dispatch, getters }) {
      return UserService.getUserMyself().then(
        (user) => {
          localStorage.setItem("userType", user.type);
          commit("loadIn", user);
          if (getters.getUserRole === "CUSTOMER") {
            dispatch("customerMyself").catch((error) => Promise.reject(error));
            dispatch("getProductsCart").catch((error) => Promise.reject(error));
          }
          return Promise.resolve(user);
        },
        (error) => {
          commit("loadOut");
          return Promise.reject(error);
        }
      );
    },

    updateUserPrivate({ commit }, formUpdated) {
      return UserService.updateUserPrivate(formUpdated).then(
        (response) => {
          let datauser = { form: formUpdated, response: response.data };
          commit("updateSuccess", datauser);
          //commit("updateToken",datauser.response.accessToken, { root: true })
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateFailure");
          return Promise.reject(error);
        }
      );
    },

    updateUserCompany({ commit }, formUpdated) {
      return UserService.updateUserCompany(formUpdated).then(
        (response) => {
          let datauser = { form: formUpdated, response: response.data };
          commit("updateSuccess", datauser);
          //commit("updateToken",datauser.response.accessToken, { root: true })
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateFailure");
          return Promise.reject(error);
        }
      );
    },

    updateToCustomer({ commit, dispatch }) {
      return UserService.updateToCustomer().then(
        (response) => {
          commit("successBecomeCustomer", response.data, { root: true });
          dispatch("refreshToken");
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateFailure");
          return Promise.reject(error);
        }
      );
    },

    deleteRequest({ commit }) {
      return UserService.deleteRequest().then(
        (response) => {
          commit("clearOut");
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateFailure");
          return Promise.reject(error);
        }
      );
    },

    updateInvitation({ commit }, invitation) {
      return UserService.updateInvitation(
        invitation.inviter,
        invitation.status
      ).then(
        (response) => {
          if (invitation.status === "ACCEPTED") {
            commit("updateUserToCustomer", { root: true });
          }
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    loadIn(state, user) {
      state.status.loggedInPrivateArea = true;
      state.userinfo = user;
      state.usertype = user.type;
    },

    loadOut(state) {
      state.status.loggedInPrivateArea = false;
      state.userinfo = {};
      state.usertype = undefined;
    },

    updateSuccess(state, userdata) {
      Object.keys(userdata.form).forEach((key) => {
        state.userinfo[key] != userdata.form[key] ? (state.userinfo[key] = userdata.response[key]) : null;
      });
      state.usertype = userdata.response.type;
    },

    updateFailure() {
    },
  },

  getters: {
    getUserInfo: (state) => state.userinfo,
    getUserProfileType: (state) => state.usertype,
  },
};
