import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store/index';
import { BootstrapVue, BootstrapVueIcons, IconsPlugin } from 'bootstrap-vue';
import { popperOffsets } from '@popperjs/core';
import i18n from './plugins/i18n';
import Vuex from 'vuex';
import IubendaPlugin from './util/iubenda.plugin';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin);
Vue.use(popperOffsets);
Vue.use(Vuex);
Vue.use(IubendaPlugin, {
  "ccpaAcknowledgeOnDisplay":true,
  "consentOnContinuedBrowsing":false,
  "countryDetection":true,
  "enableCcpa":true,
  "enableLgpd":true,
  "floatingPreferencesButtonDisplay":"anchored-center-right",
  "invalidateConsentWithoutLog":true,
  "lgpdAppliesGlobally":false,
  "perPurposeConsent":true,
  "whitelabel":false,
  "siteId":2854052,
  "cookiePolicyId":19802645,
  "lang":"it", 
  "banner": { 
    "acceptButtonDisplay":true,
    "backgroundOverlay":true,
    "closeButtonDisplay":false,
    "customizeButtonDisplay":true,
    "explicitWithdrawal":true,
    "listPurposes":true,
    "position":"bottom",
    "rejectButtonDisplay":true 
  }
});

Vue.config.productionTip = false;
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
