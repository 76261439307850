export const errors = {
  invalidUsrOrPwd: "Invalid username or password",
  disabledUser: "User disabled, please contact aws_alcea@alcea.com",
  fillUpProfile: "Complete your profile by filling in all the mandatory fields",
  missing: "Fill in field",
  invalidField: "The entered value is not valid",
  invalidLenght: "Field too long",
  UnauthorizedOrInvalid: "The link used is invalid",
  NotFoundPage: "page not found",
  Unauthorized: "Not allowed",
  UnauthorizedLink: "You do not have permission to access the link",
  IllegalItemQuantity: "The quantity must follow a multiplier"
};
