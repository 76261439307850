export const errors = {
  invalidUsrOrPwd: "Benutzername oder Passwort ungültig",
  disabledUser: "Benutzer deaktiviert, bitte wenden Sie sich an aws_alcea@alcea.com",
  fillUpProfile: "Ergänzen Sie bitte Ihr Profil, indem Sie alle Pflichtfelder ausfüllen",
  missing: "Feld ausfüllen",
  invalidField: "Der eingegebene Wert ist ungültig",
  invalidLenght: "Zu langes Feld",
  UnauthorizedOrInvalid: "Der verwendete Link ist ungültig",
  NotFoundPage: "Seite nicht gefunden",
  Unauthorized: "Nicht befugt",
  UnauthorizedLink: "Sie sind nicht berechtigt, auf diesen Link zuzugreifen",
  IllegalItemQuantity: "Die Menge muss einem Multiplikator folgen"
};
