export const alert = {
  SuccessRegiatration: "Registrierter Benutzer, in Kürze erhalten Sie eine E-Mail mit einem Link zur Aktivierung des Kontos und Eingabe Ihrer Anmeldedaten",
  sending: "E-Mail wird gesendet...",
  operationSuccess: "Die Tätigkeit wurde erfolgreich abgeschlossen",
  registrationComplete: "Herzlichen Glückwunsch! Die Registrierung ist abgeschlossen",
  newPasswordComplete: "Neues Passwort erfolgreich aktualisiert",
  resetPasswordSuccess: "In Kürze erhalten Sie eine E-Mail mit einem Link zur Eingabe des neuen Passworts",
  notificationMailSuccess: "Anmeldebenachrichtigungen aktualisiert",
  updateSuccess: "Profil aktualisiert",
  sendRequestSuccess: "Ihre Anfrage wird so schnell wie möglich überprüft und Sie erhalten eine E-Mail mit dem Ergebnis. Außerdem können Sie den Status Ihrer Anfrage in Ihrem Benutzerprofil verfolgen.",
  sendInviteSuccess: "Die Einladung wurde erfolgreich gesendet",
  acceptSuccess: "Einladung angenommen",
  refuseSuccess: "Einladung abgelehnt",
  deleteSuccess: "Einladung erfolgreich gelöscht",
  deleteFail: "Der Löschvorgang konnte nicht abgeschlossen werden",
  becomeClientSuccess: "Herzlichen Glückwunsch! Wenn Sie diese Einladung annehmen, können Sie sich bereits als Kunde anmelden",
  productsInCart: "Produkte in den Warenkorb gelegt",
  updateCartSuccess: "Der Warenkorb wurde aktualisiert",
  removeItemSuccess: "Produkt aus dem Warenkorb entfernt",
  removeItemsSuccess: "Produkte aus dem Warenkorb entfernt",
  SuccessCheckOutOffer: "Checkout erfolgreich ausgeführt",
  SuccessCheckOutOrder: "Checkout erfolgreich ausgeführt",
  somethingWrong: "Es ist ein Fehler aufgetreten",
  NetworkError: "Netzfehler",
  UnauthorizedLogin: "Benutzername oder Passwort ungültig",
  Unauthorized: "Sie verfügen nicht über die erforderlichen Berechtigungen",
  BadRequest: "Fehler in der Formulierung Ihrer Anfrage",
  NotFound: "Es wurde keine Übereinstimmung gefunden",
  FailedToAssociate: "Es wurde keine Übereinstimmung mit dem eingegebenen Code gefunden. Wenden Sie sich für weitere Informationen an die Adresse ",
  Conflict: "Sie versuchen, bereits gespeicherte Daten zu senden",
  ServerError: "Es ist ein Serverfehler aufgetreten.",
  UnauthorizedOrInvalid: "Sie verfügen nicht über die erforderlichen Berechtigungen oder der Link ist nicht mehr gültig",
  customerRequestExistingVatTitle: "Achtung",
  customerRequestExistingVat: "Die eingegebene USt-Nummer ist bereits vorhanden, wenn Sie versuchen, eine Anfrage für einen bereits registrierten Kunden zu erstellen, klicken Sie bitte auf „Kundennummer verwenden“, um dem Benutzer einen bereits vorhandenen Kunden unter Verwendung der eingegebenen Kundennummer zuzuordnen",
  agentCustomerRequestExistingVat: "Die eingegebene USt-Nummer gehört einem Kunden, der bereits eine Anfrage gestellt hat",
  editRequestExistingVat: "Achtung: Für diesen Kunden ist bereits eine ausstehende Anfrage vorhanden",
  TdsError: "Beim Herunterladen ist ein Problem aufgetreten!",
  missingTds: "Für dieses Produkt ist kein Datenblatt vorhanden"
};
