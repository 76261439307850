<template>
  <b-container fluid>
    <!-- private area selector -->
    <b-row class="private-area-selector">
      <b-col style="flex-grow: 1" cols="12" md="auto" :class="leftSelected ? 'private-area-selected': ''">
        <!-- link: user profile -->
        <h3 @click.stop="$router.push('/profile-user')">
          {{ $t("titles.profileUser") }}
        </h3>
      </b-col>


      <b-col style="flex-grow: 1" cols="12" md="auto" v-if="isCustomer || isUserAgentOrCommercial" :class="leftSelected ? '': 'private-area-selected'">
        <!-- link: customer section -->
        <h3 @click.stop="$router.push('/profile-customer')" v-if="isCustomer">
          {{ $t("titles.profileCustomer") }}
        </h3>

        <!-- link: agent section -->
        <h3 @click.stop="$router.push('/profile-agent')" v-if="isUserAgentOrCommercial">
          {{ $t("titles.areaAgent") }}
        </h3>
      </b-col>

      <b-col style="flex-grow: 1" cols="12" md="auto" v-if="hasSdsAccess">

        <h3 @click.stop="$router.push('/sds')" >
          {{ $t("titles.sdsSearch") }}
        </h3>
      </b-col>

      <b-col style="flex-grow: 1" cols="12" md="auto" v-if="hasTdsAccess">
        <!-- link: agent section -->
        <h3 @click="openTdsApp">
          {{ $t("titles.tdsApp") }}
        </h3>
      </b-col>

      <b-col style="flex-grow: 1" cols="12" md="auto" v-if="hasDashboardAccess">
        <!-- link: agent section -->
        <h3 @click="openDashboardApp" >
          {{ $t("titles.adminDashboardApp") }}
        </h3>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import conf from "@/util/conf";

export default {
  name: "PrivateAreaSelector",

  props: ['leftSelected'],

  data() {
    return {
      tdsURL: conf.value('VUE_TDS_URL'),
      dashboardURL: conf.value('VUE_DASHBOARD_URL'),
    }
  },

  created() {
    this.tdsURL = conf.value('VUE_TDS_URL');
    this.dashboardURL = conf.value('VUE_DASHBOARD_URL');
  },

  methods: {
    openTdsApp() {
      console.log(this.tdsURL)
      window.open(this.tdsURL, '_blank');
    },
    openDashboardApp() {
      console.log(this.dashboardURL)
      window.open(this.dashboardURL, '_blank');
    }
  },

  computed: {
    isCustomer() {
      return this.$store.getters.getUserRole === 'CUSTOMER'
    },

    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },

    hasTdsAccess() {
      return this.$store.getters.getTdsRole === "USER" || this.$store.getters.getTdsRole === "ADMIN"
    },
    hasDashboardAccess() {
      return this.$store.getters.getUserRole === "IT" || this.$store.getters.getUserRole === "ADMIN"
    },
    hasSdsAccess () {
      return (this.$store.getters.getSdsRole === "EMPLOYEE") || (this.$store.getters.getSdsRole === "AUTO" && this.$store.getters.getUserRole !== "USER" && this.$store.getters.getUserRole !== "CUSTOMER")
    }
  },
};
</script>
