<template>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <h3 class="text-uppercase">{{ $t("titles.anagraphicInfo") }}</h3>
      <div class="border-top">
        <b-form-group :invalid-feedback="validationError('businessName')"
          class="inline-form-group"
          ><label for="businessName"> {{ $t("formUser.businessName") }}</label>
          <b-form-input
            type="text"
            id="name"
            name="name"
            v-model="form.businessName"
            :placeholder="$t('formUser.businessName') + ' *'"
            :state="validationState('businessName')"
            @change="$emit('fieldUpdated')"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :invalid-feedback="validationError('vatNumber')"
          :is-valid="validationState('vatumber')"
        >
          <label for="vatNumber" class="w-100">
            {{ $t("formCustomer.vatNumber") }}
            
        <!-- VatNumber can't be changeed in edit customer-->
            <span v-if="edit" class="text-md-right w-auto ml-md-auto">
            - <b-icon-info-circle aria-hidden="true" />
              {{ $t("instructions.formCustomer.vatNumber") }}</span
            >
          </label>
          <b-input-group
            :class="validationState('vatNumber') ? '' : 'is-invalid'"
          >
            <!-- la selezione del prefisso preseleziona il valore del business contact nation -->
            <b-form-select
              class="form-select col-md-2"
              ref="country"
              v-model="vatNumber.prefix"
              :disabled="edit"
              :state="validationState('vatNumber')"
              @input="
                vatNumber.number
                  ? (form.vatNumber = vatNumber.prefix + vatNumber.number)
                  : (form.vatNumber = '')
              "
              @change="
                form.businessAddress.country =
                  vatNumber.prefix; ifItalian(vatNumber.prefix);
                  $emit('fieldUpdated')
              "
            >
              <b-select-option selected :value="null || ''">
                {{ $t("formCustomer.noPrefix") }}
              </b-select-option>
              <b-select-option
                v-for="country in countries"
                :value="country.value"
                :key="country.key"
              >
                {{ country.label }}
              </b-select-option>
            </b-form-select>
            <b-form-input          
              :disabled="edit"
              type="text"
              id="vatNumber"
              name="vatNumber"
              v-model="vatNumber.number"
              @input="
                vatNumber.number
                  ? (form.vatNumber = vatNumber.prefix + vatNumber.number)
                  : (form.vatNumber = '')
              "
              @change="$emit('fieldUpdated')"
              :placeholder="$t('formCustomer.vatNumber') + ' *'"
              :state="validationState('vatNumber')"
            >
            </b-form-input>
          </b-input-group>
        </b-form-group>
        
        <b-form-group :invalid-feedback="validationError('sdiCode')">
          <label for="sdiCode"> {{ $t("formCustomer.sdiCode") }}</label>
          <b-form-input
            type="text"
            id="sdiCode"
            name="sdiCode"
            v-model="form.sdiCode"
            :placeholder="
              form.businessAddress.country == 'IT'
                ? $t('formCustomer.sdiCode') + ' *'
                : $t('formCustomer.sdiCode')
            "
            @change="$emit('fieldUpdated')"
            :state="validationState('sdiCode')"
          ></b-form-input> </b-form-group
        ><b-form-group :invalid-feedback="validationError('pec')">
          <label for="Pec"> {{ $t("formCustomer.pec") }} </label>
          <b-form-input
            type="text"
            id="Pec"
            name="Pec"
            v-model="form.pec"
            :placeholder="
              form.businessAddress.country == 'IT'
                ? $t('formCustomer.pec') + ' *'
                : $t('formCustomer.pec')
            "
            @change="$emit('fieldUpdated')"
            :state="validationState('pec')"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
  </div>
</template>
<script>
import { CountriesAlpha2 } from "../commons/countries";

export default {
  name: "Anagraphics",
  props: {
    form: Object(),
    value: String(),
    currentStep: Object(),
    completed: Boolean(),
    invalidErrors: Array(),
    edit: Boolean(),
  },
  data() {
    return {
      self: "Anagraphics",
      fields: ["businessName", "vatNumber", "sdiCode", "pec"],
      countries: CountriesAlpha2,
      /*
      vatNumber: {
        prefix: this.form.vatNumber ? this.form.vatNumber.substring(0, 2) : "",
        number: this.form.vatNumber
          ? this.form.vatNumber.substring(2)
          : this.form.vatNumber,
      },
      */
    };
  },

  methods: {
    validationState(contextRef) {
      return this.invalidErrors.find(
        (invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()
      )
        ? false
        : null;
    },

    validationError(contextRef) {
      let find = this.invalidErrors.find(
        (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      );
      return find ? this.$i18n.t(find.error) : null;
    },
    ifItalian(prefix) {
      if (prefix == "IT") {
        this.self = "AnagraphicsIT";
      } else {
        this.self = "Anagraphics";
      }
    },
    handleInput() {
      console.log("emit", this.parseTelephone);
      this.$emit("input", this.parseTelephone);
    },
  },

  computed: {
    vatNumber: {
      get(){
        let vat = {
          prefix: this.form.vatNumber ? this.form.vatNumber.substring(0, 2) : "",
          number: this.form.vatNumber ? this.form.vatNumber.substring(2) : "",
        }
        if (!this.countries.find((c) => c.value === vat.prefix)) {
          vat = { prefix: "", number: this.form.vatNumber };
        }
        return vat;
      },
      set(vatNumber){
        return vatNumber;
      }
    }
  }
};
</script>
