import ProfileAgent from "@/views/ProfileAgent"

export default {
  path: "/profile-agent",
  name: "ProfileAgent",
  component: ProfileAgent,
  meta: {
    sector: 'PrivateArea',
    requiresAuth: true,
    roles: ['USER', 'AGENT', 'COMMERCIAL']
  }
}
