import http from "@/util/http-common";
let guest = localStorage.getItem("guest");

class RegistrationService {
  async register(userform) {
    console.log("Call register")
    const response = await http.post(`users`, userform);
    console.log(response)
    if (response.data) {
      localStorage.setItem("guest", response.data.username);
    }
    return response;
  }
  confirmRegister(user) {
    console.log(user);
    return http.put(`users/${user.username}/confirm-registration`, {
      tempCode: user.tempCode,
      newPassword: user.password.newPassword,
    });
  }
  resetPassword(guestusername) {
    return http
      .patch(`users/${guestusername}/forgot-password`)
      .then((response) => {
        if (response.data) {
          localStorage.setItem("guest", response.data.username);
        } else {
          localStorage.setItem("guest", guestusername);
        }
        return response;
      });
  }
  updatePassword(user) {
    return http.put(
      `users/${user.username}/update-forgotten-password`,
      {
        tempCode: user.tempCode,
        newPassword: user.password.newPassword,
      }
    );
  }
  contactRequest(contactRequest) {
    return http.put("/users/request-contact", contactRequest);
  }
 
}
export default new RegistrationService();
