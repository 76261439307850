export const actions = {
	ok: "OK",
	logout: "Logout (salir)",
	loginToProceed: "Efectuar el login (entrar)",
	loginCompleted: "Login efectuado",
	retrievePassword: "Recuperarla",
	signUp: "Registrarse",
	backToLogin: "Volver a login",
	update: "Actualizar",
	updateProfile: "Editar perfil",
	create: "Crear",
	access: "Acceder",
	send: "Enviar",
	saveDraft: "Salvar Boceto",
	next: "Siguiente",
	back: "Anterior",
	cancel: "Cancelar",
	newPassword: "Enviar",
	confirmRegistration: "Completar Registro",
	confirm: "Confirmar",
	compileRequest: "Rellenar la solicitud",
	continue: "Continuar",
	newRequest: "Nueva solicitud",
	associate: "Asociar",
	associateToExisting: "Clicar aquí",
	inviteNewUser: "Invitar a un nuevo Usuario a convertirse en Cliente",
	updateToCustomer: "Convertirse en Cliente",
	goToClientAssignement: "Usar Código Cliente",
	understand: "Entendido",
	delete: "Eliminar",
	accept: "Aceptar",
	refuse: "Rechazar",
	close: "Cerrar",
	invite: "Enviar invitación",
	showAllCustomer: "Ver todos los clientes",
	edit: "Modificar",
	goToProfile: "ir al perfil",
	search: "Buscar",
	searchProduct: "Buscar producto",
	addSelectedToChart: "Agregar seleccionados al carrito",
	addToChart: "Agregar al carrito",
	clear: "Cancelar",
	deleteSelected: "Eliminar seleccionados",
	updateCart: "Actualizar carrito",
	offerRequest: "Solicitar oferta",
	orderRequest: "Solicitar pedido",
	add: "Agregar",
	buy: "Comprar",
	showProduct: "Visualizar producto",
	itemToCart: "producto seleccionado en el carrito",
	itemsToCart: "productos seleccionados en el carrito",
	requestNewOrder: "Solicitar nuevo pedido",
	requestNewOffer: "Solicitar nueva oferta",
	requestOrder: "Solicitar pedido",
	redoOrderRequest: "Solicitar el mismo pedido",
	backToCart: "Back to cart"
};
