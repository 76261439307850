<template>
  <div>
    <div v-if="showMenu">
      <!-- menu -->
      <b-row class="ps-selector" v-if="show.header">

        <!-- title -->
        <b-col>
          <!-- back + customer -->
          <products-search-customer class="bb"></products-search-customer>

          <!-- title -->
          <b-row class="align-items-center h-50">
            <div class="title">
              <h5>{{ $t("productsSearch.b2bTitle") }}</h5>
              <img src="@/assets/img/arrow-down.jpg" alt="down"/>
            </div>
          </b-row>
        </b-col>

        <b-col class="b2b" @click.stop="goToB2B"></b-col>

      </b-row>


      <!-- search by serial and color -->
      <b-col v-if="show.bySerialAndColor" class="bx">
        <b-row class="ps-sub-header">
          <h2>{{ $t("productsSearch.filters.bySerialAndColor") }}</h2>
        </b-row>

        <b-row class="ps-sub-filter bx bb" style="margin: 0 20%;">
          <b-col>
            <div class="ps-sub-filter-title">{{ $t('productsSearch.filters.serialCode') }}</div>
          </b-col>
          <b-col class="bl">
            <div class="ps-sub-filter-title">{{ $t('productsSearch.filters.colorCode') }}</div>
          </b-col>
        </b-row>

        <b-row class="ps-sub-filter bx" style="margin: 0 20%;">
          <b-col>
            <b-form-input v-model="selected.serialCode" :placeholder="$t('productsSearch.filters.serialCode')"></b-form-input>
          </b-col>
          <b-col class="bl">
            <b-form-input v-model="selected.colorCode" :placeholder="$t('productsSearch.filters.colorCode')"></b-form-input>
          </b-col>
        </b-row>

        <b-row class="ps-sub-filter-search" v-if="show.header">
          <div class="adw-button w-50"
               :class="{ 'disabled': validateProductsBySerialAndColor }"
               @click="searchProductsBySerialAndColor">
            <h3>{{ $t("actions.searchProduct") }}</h3>
          </div>
        </b-row>
      </b-col>
    </div>


    <!-- results -->
    <products-search-b2-b-result v-show="show.results"
                                 :serials-dto="results"
                                 @showDetail="hideAll"
                                 @hideDetail="showAll">
    </products-search-b2-b-result>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProductsSearchService from "@/services/api-services/products-search-service"
import ProductsSearchB2BResult from "@/views/ProductsSearch/ProductsSearchB2BResult";
import ProductsSearchCustomer from "@/components/products-search/products-search-customer";

export default {
  name: "ProductsSearchGuest",

  components: {
    ProductsSearchCustomer,
    ProductsSearchB2BResult
  },

  data() {
    return {
      showMenu: true,
      show: {
        header: true,
        byNameOrCode: true,
        bySerialAndColor: true,
        byFilters: true,

        back: false,
        results: false
      },

      selected: {
        serialCode: null,
        colorCode: null
      },

      results: null
    }
  },

  methods: {
    toggleResults(filter, results) {
      if (filter) {
        Object.keys(this.show).forEach((s) => this.show[s] = false)
        this.show[filter] = true
        this.show.results = results != null || results !== undefined
        this.results = results
      } else {
        this.filtersBack()
      }
    },

    filtersBack() {
      Object.keys(this.show).forEach((s) => this.show[s] = true)
      this.show.results = false
      this.results = null
    },


    searchProductsByNameOrCode() {
      if (!this.validateProductsByNameOrCode) {
        ProductsSearchService.searchProductsByNameOrCode({
          nameOrCode: this.selected.nameOrCode
        })
            .then((response) => {
              this.toggleResults('byNameOrCode', response.data.serialsDto)
            })
            .catch((error) => {
              console.error(error)
              this.toggleResults()
            })
      }
    },

    searchProductsBySerialAndColor() {
      if (!this.validateProductsBySerialAndColor) {
        ProductsSearchService.searchProductsBySerialAndColor({
          serialCode: this.selected.serialCode,
          colorCode: this.selected.colorCode
        })
            .then((response) => {
              this.toggleResults('bySerialAndColor', response.data.serialsDto)
            })
            .catch((error) => {
              console.error(error)
              this.toggleResults()
            })
      }
    },

    searchProductsByFilters() {
      if (!this.validateProductsByFilters) {
        ProductsSearchService.searchProductsByFilters({
          area: this.selected.area,
          typology: this.selected.typology,
          family: this.selected.family,
          dilution: this.selected.dilution,
          drying: this.selected.drying,
          appMethod: this.selected.appMethod
        })
            .then((response) => {
              this.toggleResults('byFilters', response.data.serialsDto)
            })
            .catch((error) => {
              console.error(error)
              this.toggleResults()
            })
      }
    },

    hideAll() {
      this.showMenu = false
    },

    showAll() {
      this.showMenu = true
    }
  },

  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.status.loggedIn,
      userRole: (state) => state.auth.user.role
    }),

    validateProductsBySerialAndColor() {
      return ProductsSearchService.isEmpty(this.selected.serialCode)
          && ProductsSearchService.isEmpty(this.selected.colorCode)
    },
  },

  mounted() {
    const serialToSearch = this.$route.query.serial
    if (!serialToSearch) {
      this.$router.push("/products-search/b2b")
    } else {
      this.selected.serialCode = serialToSearch
      this.selected.colorCode = this.$route.query.color
      this.searchProductsBySerialAndColor()
    }
  }
}
</script>
