export const retailer= {
  metal: "Metall",
  wood: "Holz",
  construction: "Baugewerbe",
  type: "Art des Einzelhändlers",
  bodywork: "Karosseriebau",
  ownership: "Eigenes Geschäft?",
  shopWindows: "Anzahl der Schaufenster",
  surface: "Fläche",
  mq: "m2",
  shop: "Geschäft"
};