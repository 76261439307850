export const orderInfo = {
  order: "Pedido",
  orderRequest: "Solicitud de pedido",
  orderCode: "Código de pedido",
  noOrders: "No hay pedidos en la lista, puede hacer un nuevo pedido directamente desde el carrito",
  isExpired: "El pedido venció el día",
  notValid: "dejó de ser válido",
  expireIn: "Atención: el pedido vencerá el día",
  createdOn: "Fecha de creación",
  deliveryDate: "Fecha de entrega",
  acceptMultipleShipping: "Expedición fraccionable",
  genericNote: "Notas genéricas",
  transportReason: "Motivo del transporte",
  port: "Puerto",
  shippingNote: "Notas de expedición",
  statusFilter: "Filtro Estado",
  totalOrder: "Order total",
  totalWeight: "Total weight",
  text: {
   ACCEPTED: "Su pedido fue aceptado",
   PENDING: "El pedido será evaluado lo antes posible",
   EXPIRED: "es posible hacer un nuevo pedido dentro de 15 días del vencimiento, término en el cual será eliminado.",
   REJECTED: "El pedido fue rechazado"
  },
  requestStatus: {
   PENDING: "Bajo revisión",
   ACCEPTED: "Aceptado",
   REJECTED: "Rechazado",
   CLOSED: "Cerrado",
   EXPIRED: "Vencido"
  },
  "orderStatus": {
   ALL: "All",
   I: "Tomado a cargo",
   A: "Anulado",
   B: "Bloqueado",
   C: "Confirmado",
   E: "Despachado",
   F: "Facturado"
  },
  item: {
   quantityOrdered: "Cant. pedida",
   quantityConfirmed: "Cant. confirmada",
   quantityProcessed: "Cant. procesada",
   discount: "Descuento",
   rowWeight: "Row weight",
      rowTotal: "Totale"
  }
 };