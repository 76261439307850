<template>
  <b-container fluid >
    <!-- header -->
    <b-row class="ps-buy-header bb px-0">
      <!-- title -->
      <b-col class="d-flex justify-content-between ps-buy-title">
        <div style="width: 46px;"></div>

        {{ $t('titles.loginTDSText') }}

        <!-- close modal -->
        <b-button @click.stop="$emit('closeModal')" variant="link">
          <b-icon icon="x"></b-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-row class="ps-buy-header" align-h="between">
      <b-col cols="4">
        <b-btn block variant="warning" @click.stop="$emit('login')">
          {{ $t("actions.access") }}
        </b-btn>
      </b-col>
      <b-col cols="4">
        <b-btn block @click.stop="$emit('closeModal')">
          {{ $t("actions.back") }}
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "LoginRedirect",

}
</script>
