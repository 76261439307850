<template>
    <div  @click.stop="back"
         style="background-color: var(--gray); cursor: pointer;height: 50px;" 
         class="d-flex align-items-center justify-content-between w-100">
      <div class="d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center text-white"
             style="background-color: black; width: 50px; height: 50px; margin-right: 10px;">
             <b-icon-arrow-left-short  font-scale="3" />
        </div>

        <slot name="title"></slot>
      </div>

      <div v-if="customerCode" style="padding-right: 50px">
        {{ $t('formCustomer.customerCodeShort') }}: {{ customerCode }}
      </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'ProductsSearchCustomer',

  props: {
    backFunction: {
      type: Function
    }
  },

  computed: {
    ...mapState({
      customerCode: (state) => state.customer.customerinfo.customerCode
    })
  },

  methods: {
    back() {
      if (this.backFunction) {
        this.backFunction()
      } else {
        this.$router.push('/products-search')
      }
    }
  }
}
</script>
