export const offerInfo = {
  offer: "Offerta",
  offerRequest: "Richiesta offerta",
  noOffers: "Non sono presenti offerte in lista, puoi richiedere una nuova offerta direttamente dal carrello.",
  isExpired: "L’offerta scaduta in data",
  notValid: "non è più valida",
  expireIn: "Attenzione: l’offerta scadrà in data ",
  createdOn: "Data creazione",
  text: {
    ACCEPTED: "L’offerta è stata validata, per accettare l’offerta clicca sul tasto Richiedi Ordine.",
    PENDING: "La richiesta di offerta verrà valutata appena possibile",
    EXPIRED: "è possibile fare la richiesta per una nuova offerta entro 15 giorni dalla scadenza, termine nel quale verrà eliminata.",
    REJECTED: "L'offerta è stata respinta",
    CLOSED: "Una ordine è già stato richiesto da questa offerta"
  },
  requestStatus: {
    PENDING: "In revisione",
    ACCEPTED: "Accettato",
    REJECTED: "Rifiutato",
    CLOSED: "Chiuso",
    EXPIRED: "Scaduto",
  }
}