export const offerInfo = {
  offer: "Oferta",
  offerRequest: "Solicitud de oferta",
  noOffers: "No hay ofertas en la lista, puede solicitar una nueva oferta directamente desde el carrito.",
  isExpired: "La oferta venció el día",
  notValid: "dejó de ser válida",
  expireIn: "Atención: la oferta vencerá el día ",
  createdOn: "Fecha de creación",
  text: {
   ACCEPTED: "La oferta fue validada, para aceptar la oferta clicar la tecla Solicitar Pedido.",
   PENDING: "La solicitud de oferta será evaluada lo antes posible",
   EXPIRED: "es posible elevar la solicitud para una nueva oferta dentro de 15 días del vencimiento, término en el cual será eliminada.",
   REJECTED: "La oferta fue rechazada",
   CLOSED: "Ya fue hecho un pedido desde esta oferta"
  },
  requestStatus: {
   PENDING: "En revisión",
   ACCEPTED: "Aceptado",
   REJECTED: "Rechazado",
   CLOSED: "Cerrado",
   EXPIRED: "Vencido"
  }
}