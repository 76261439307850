export const roles = {
  generalManagement: "Geschäftsleit.",
  property: "Eigentümer",
  director: "Leiter",
  departmentHead: "Abteilungsleiter",
  operator: "Mitarbeiter",
  manager: "Leiter",
  buyer: "Einkäufer",
  officeWorker: "Angestellter",
  designer: "Planer"
};
