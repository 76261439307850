export const businessDepartments =[
    { value:'MNG',  text:'departments.menagement',roles:['GNM','PRP']},//direzione
    { value:'PRC',  text:'departments.purchasing',roles:['DRC','MNG','BYR']},//acquisti
    { value:'ADM',  text:'departments.administration',roles:['DRC','MNG','OFW']},//amministrazione
    { value:'TCH',  text:'departments.technical',roles:['DRC','MNG','DSG']},//tecnico
    { value:'PRD',  text:'departments.production',roles:['DRC','DPH','OPR']},//produzione
    { value:'PNT',  text:'departments.painting',roles:['MNG','DPH','OPR']},//verniciatura
    { value:'MNT',  text:'departments.maintenance',roles:['MNG','DPH','OPR'] },//manutenzione
    { value:'WRH',  text:'departments.wharehouse',roles:['MNG','DPH','OPR'] },//magazzino
]

