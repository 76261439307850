export const errors = {
  invalidUsrOrPwd: "Nome utente o password invalidi",
  disabledUser:
    "Utente disabilitato, si prega di contattare aws_alcea@alcea.com",
  fillUpProfile:
    "Completa il tuo profilo, compilando tutti i campi obbligatori",
    missing:'Compila campo',
    invalidField:"Il valore inserito non è valido",
    invalidLenght:"Campo di lunghezza eccessiva",
    UnauthorizedOrInvalid:'Il link utilizzato non è valido',
    NotFoundPage:'Pagina non trovata',
    Unauthorized:'Non autorizzato',
    UnauthorizedLink:'Non disponi delle autorizzazioni per accedere al link',
    IllegalItemQuantity: "La quantità deve seguire un moltiplicatore",
    checkoutWithNoProducts: "Selezionare almeno un prodotto per procedere."
};
