import SdsSearch from "@/views/SdsSearch.vue";
import SdsSearchWarehouse from "@/views/SdsSearch/SdsSearchWarehouse.vue";
import SdsSearchBatch from "@/views/SdsSearch/SdsSearchBatch.vue";
import SdsSearchCustomer from "@/views/SdsSearch/SdsSearchCustomer.vue";
import SdsSearchDocument from "@/views/SdsSearch/SdsSearchDocument.vue";
import SdsSearchProduct from "@/views/SdsSearch/SdsSearchProduct.vue";
export default {
  path: "/sds",
  name: "Sds",
  component: SdsSearch,

  children: [
    {
      path: "site",
      name: "SdsSearchWarehouse",
      component: SdsSearchWarehouse,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL","ALCEA" ,"IT"],
        rolesSds: ["EMPLOYEE"],
      },
    },
    {
      path: "batch",
      name: "SdsSearchBatch",
      component: SdsSearchBatch,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL","ALCEA" ,"IT"],
        rolesSds: ["EMPLOYEE"],
      },
    },
    {
      path: "customer",
      name: "SdsSearchCustomer",
      component: SdsSearchCustomer,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL","ALCEA" ,"IT"],
        rolesSds: ["EMPLOYEE"],
      },
    },
    {
      path: "document",
      name: "SdsSearchDocument",
      component: SdsSearchDocument,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL","ALCEA" ,"IT"],
        rolesSds: ["EMPLOYEE"],
      },
    },
    {
      path: "product",
      name: "SdsSearchProduct",
      component: SdsSearchProduct,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL","ALCEA" ,"IT"],
        rolesSds: ["EMPLOYEE"],
      },
    },
  ],

  meta: {
    sector: 'PrivateArea',
    requiresAuth: true,
    roles: ["CUSTOMER", "AGENT", "COMMERCIAL","ALCEA" ,"IT"],
    rolesSds: ["EMPLOYEE"],
  },

  beforeEnter: (to, from, next) => {
    return next();
  },
};
