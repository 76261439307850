<template>
  <div class="custom-container-sticky-header">

    <!-- filters -->
    <div class="pc-subtitle pc-sticky-heading">
      <b-container fluid>
        <b-row>

          <!-- status -->
          <b-col>
            <label class="font-weight-bold">{{ $t("requestStatus.requestStatus") }}</label>
            <b-row class="ml-1">
              <b-select
                  name="statusFilter"
                  :options="statuses"
                  v-model="filters.status"
                  @keyup.enter="loadRequests">
              </b-select>
            </b-row>
          </b-col>

          <!-- CreatedOn date range -->
          <b-col cols="4">
            <label class="font-weight-bold">{{ $t("orderInfo.createdOn") }}</label>
            <b-row class="ml-1">
              <b-input-group>
                <b-input-group-prepend is-text>{{ $t("labels.from") }}</b-input-group-prepend>
                <b-form-datepicker
                    reset-button
                    :label-reset-button="$t('actions.clear')"
                    v-model="filters.createdOnFrom"
                    :locale="locale"
                    @context="onInputFrom"
                    :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                    placeholder="">
                </b-form-datepicker>

                <b-input-group-addon is-text>{{ $t("labels.to") }}</b-input-group-addon>
                <b-form-datepicker
                    reset-button
                    :label-reset-button="$t('actions.clear')"
                    :min="filters.createdOnFrom"
                    v-model="filters.createdOnTo"
                    :date-format-options="{
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                    placeholder="">
                </b-form-datepicker>
              </b-input-group>
            </b-row>
          </b-col>

          <!-- ProductCode -->
          <b-col cols="3">
            <label class="font-weight-bold">{{ $t("product.productCode") }}</label>
            <b-row class="ml-1">
              <b-input-group>
                <b-form-input
                    name="productCode"
                    id="serialCode"
                    v-model="filters.serial"
                    maxlength="4"
                    type="search"
                    :placeholder="$t('product.serial')"
                    @keyup.enter="loadRequests">
                </b-form-input>

                <b-form-input
                    id="colorCode"
                    name="productCode"
                    v-model="filters.color"
                    maxlength="4"
                    type="search"
                    :placeholder="$t('product.color')"
                    @keyup.enter="loadRequests">
                </b-form-input>

                <b-form-input
                    id="packaging"
                    name="productCode"
                    v-model="filters.pkg"
                    maxlength="2"
                    type="search"
                    :placeholder="$t('product.pkg')"
                    @keyup.enter="loadRequests">
                </b-form-input>
              </b-input-group>
            </b-row>
          </b-col>

          <!-- description -->
          <b-col>
            <label class="font-weight-bold">{{ $t("product.description") }}</label>
            <b-row class="ml-1">
              <b-form-input
                  name="description"
                  v-model="filters.description"
                  type="search"
                  :placeholder="$t('product.description')"
                  @keyup.enter="loadRequests">
              </b-form-input>
            </b-row>
          </b-col>

          <!-- search -->
          <b-col cols="1">
            <label class="font-weight-bold">&nbsp;</label>
            <b-row class="ml-1">
              <b-button @click="loadRequests">
                {{ $t("actions.search") }}
              </b-button>
            </b-row>
          </b-col>

        </b-row>
      </b-container>
    </div>

    <feedback-alert
        class="w-100"
        :status="feedbackStatus"
        :message="feedbackMessage"
        @clearStatus="clearStatus"
        @back="exit('/profile-customer')">
    </feedback-alert>

    <offer-component
        v-for="(offer, index) in offers"
        :key="index"
        :offer="offer"
        @fail="Fail">
    </offer-component>

    <b-card-text v-if="offers.length === 0" class="oo-no-result">
      {{ $t("offerInfo.noOffers") }}
    </b-card-text>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FeedbackAlert from "@/components/feedback-alert.vue";
import OfferComponent from "@/components/offer-component.vue";
import ErrorManager from "@/services/errors-service/errors-manager";

export default {
  name: "RequestOffers",

  components: {
    FeedbackAlert,
    OfferComponent
  },

  data() {
    return {
      offers: [],
      statuses: [
        {
          text: this.$i18n.t("requestStatus.NULL"),
          value: null
        },
        {
          text: this.$i18n.t("requestStatus.PENDING"),
          value: "PENDING",
        },
        {
          text: this.$i18n.t("requestStatus.ACCEPTED"),
          value: "ACCEPTED",
        },
        {
          text: this.$i18n.t("requestStatus.REJECTED"),
          value: "REJECTED",
        },
        {
          text: this.$i18n.t("requestStatus.EXPIRED"),
          value: "EXPIRED",
        },
      ],
      feedbackStatus: null,
      feedbackMessage: "",
      filters: {
        status: null,
        createdOnFrom: null,
        createdOnTo: null,
        serial: null,
        color: null,
        pkg: null,
        description: null,
      },
    };
  },

  computed: {
    ...mapState({
      userRole: (state) => state.auth.user.role,
      customerCode: (state) => state.customer.customerinfo.customerCode,
      locale: (state) => state.auth.user.lang,
    }),
  },

  methods: {
    onInputFrom(from) {
      if (from > this.filters.createdOnTo) {
        this.filters.createdOnTo = null;
      }
    },

    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },

    Fail(error) {
      this.feedbackStatus = "FailDismissable";
      this.feedbackMessage = ErrorManager.feedback(error);
    },

    loadRequests() {
      this.$store
          .dispatch("loadOffers", this.filters)
          .then((response) => (this.offers = response))
          .catch((e) => (this.offers = []));
    },
  },

  mounted() {
    this.loadRequests();
  },
};
</script>
