<template>
  <div>

    <b-container fluid class="overflow-auto justify-content-center">
      <b-row>
        <feedback-alert class="w-100"
                        :status="feedbackStatus"
                        :message="feedbackMessage"
                        @clearStatus="clearStatus">
        </feedback-alert>
      </b-row>
      <!-- table -->
      <b-row>
      <div class="table-responsive mb-0" ref="dataTableWrap">
        <div class="custom-table-sticky-header">
        <b-table :items="products"
                 :keys="fields"
                 :fields="fields"
                 hover
                 ref="dataTable"
                 :busy="isTableLoading"
                 sort-by="sortOrder"
                 stacked="sm"
                 show-empty
                 :tbody-tr-class="selectedClass">

          <template #empty="">
            <h5 class="text-center">{{ $t("labels.emptyCart") }}</h5>
          </template>

          <!-- A virtual column -->
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #head(selected)="">
            <b-checkbox :disabled="products.length < 1"
                        v-model="selectAll"
                        @click.stop
                        @change="selectAllRows">
            </b-checkbox>
          </template>

          <template #cell(selected)="data">
            <b-checkbox :checked="!!selected.find((i) => i.productCode === data.item.productCode)"
                        @click.stop
                        @change="rowSelected(data.item)">
            </b-checkbox>
          </template>

          <template #cell(productCode)="data">
            <span class="adw-pointer" @click="gotoDetail(data.item)">
              {{ data.item.productCode }}<br/>
              {{ data.item && data.item.findDescriptionPerLang ? data.item.findDescriptionPerLang($i18n.locale, "IT") : data.item.pdtDesc }}
            </span>
          </template>

          
          <template #cell(unitPrice)="data" @click.stop>
              <span v-if="data.item.unitPrice">
                {{ data.item.unitPrice.toFixed(2) }} €/{{ data.item.um }}
              </span>
              <span v-else-if="!data.item.unitPrice">
                {{ $t("product.notAssigned") }}
                <b-icon-info-circle aria-hidden="true" @click="$refs.notAssignedModal.show()"></b-icon-info-circle>
              </span>
            </template>

            <template #cell(priceListCode)="data" @click.stop>
              <span>
                {{ data.item.priceListCode ? $t("product.priceList." + data.item.priceListCode) : '-' }} 
              </span>
            </template>

            <template #cell(discounts)="data" @click.stop>
              <span class="text-nowrap">
                {{ data.item.discountPct1||'0' }}+{{ data.item.discountPct2||'0' }} % 
              </span>
            </template>

          <template #cell(finalUnitPrice)="data" @click.stop>
            <span v-if="data.item.finalUnitPrice">{{ data.item.finalUnitPrice.toFixed(2) }} €/{{ data.item.um }}</span>
            <span v-else-if="!data.item.finalUnitPrice">
              {{ $t("product.notAssigned") }}
              <b-icon-info-circle aria-hidden="true" @click="$refs.notAssignedModal.show()"></b-icon-info-circle>
            </span>
          </template>

          <template #cell(unitQty)="data" @click.stop>
            {{ data.item.unitQty }} {{ data.item.um }}
          </template>

          <template #cell(qta)="data">
            <b-form-group :invalid-feedback="$t('errors.IllegalItemQuantity')">
              <b-form-input :placeholder="data.item.qtyPkgMultiplier ? data.item.qtyPkgMultiplier.toString() : '0'"
                            type="number"
                            style="width: 100px"
                            :min="data.item.qtyPkgMultiplier ? data.item.qtyPkgMultiplier : 1"
                            :step="data.item.qtyPkgMultiplier"
                            v-model="data.item.quantity"
                            :disabled="isTableLoading"
                            @change="updateCartQty"
                            :state="data.item.qtyPkgMultiplier? data.item.quantity % data.item.qtyPkgMultiplier == 0 : null">
              </b-form-input>
            </b-form-group>
          </template>

          <template #cell(rowTotalPrice)="data">
            <span v-if="data.item.finalUnitPrice">{{ (data.item.finalUnitPrice * data.item.unitQty * data.item.quantity).toFixed(2) }} €</span>
            <span v-else-if="!data.item.finalUnitPrice"> - </span>
          </template>

          <template #cell(delete)="data">
            <b-button variant="link"
                      @click.stop
                      @click="deleteSingleSelected(data.item)">
              <b-icon-x-square-fill align="left" aria-hidden="true"/>
            </b-button>
          </template>
        </b-table>
        </div>
      </div>
      </b-row>

      <!-- buttons -->
      <b-row class="justify-content-between py-3 border-top">
        <b-col lg="4">
          <b-container fluid>
            <b-row>
              <span class="col p-0 m-1 m-md-0 mr-md-1">
                <adw-button
                    @button-click="deleteSelected" 
                    id="request-order-action"
                    :class="products.length === 0 ? 'disabled black' : 'black'">
                    <span>{{ $t("actions.deleteSelected") }}</span>
                </adw-button>
              </span>

              <span class="col p-0 m-1 m-md-0 mr-md-1">
                <adw-button
                    @button-click="updateCart" 
                    id="request-order-action"
                    :class="products.length === 0 ? 'disabled black' : 'black'">
                    <span>{{ $t("actions.updateCart") }}</span>
                </adw-button>
              </span>
              
          </b-row>
          </b-container>
        </b-col>

        <b-col lg="4" v-if="customerCode === '001195'">
          <b-container fluid>
            <b-row>
              <span id="request-offer-action-wrap" class="col m-1 m-md-0 mr-md-1 px-0">

                <adw-button
                    @button-click="requestOffer" 
                    id="request-offer-action"
                    :class="selected.length === 0 ? 'disabled' : ''">
                    <span>{{ $t("actions.offerRequest") }}</span>
                </adw-button>
                
          </span>
          
          <span id="request-order-action-wrap" class="col m-1 m-md-0 mr-md-1 px-0">
            <adw-button
                @button-click="requestOrder" 
                id="request-order-action"
                :class="selected.find((p) => !p.finalUnitPrice) || selected.length === 0 ? 'disabled' : ''">
                <span>{{ $t("actions.orderRequest") }}</span>
            </adw-button>
          </span>
          <b-tooltip  v-if="selected.length === 0" target="request-offer-action-wrap" triggers="hover">{{ $t("alert.checkoutWithNoProducts") }}</b-tooltip>
          <b-tooltip  v-if="selected.find((p) => !p.finalUnitPrice) || selected.length === 0" target="request-order-action-wrap" triggers="hover">{{ $t("alert.checkoutWithNoProductsOrNoPrices") }}</b-tooltip>
            </b-row>
          </b-container>
        </b-col>


        <b-col lg="4" v-else >
          <b-container fluid>
            <b-row>
              <span id="request-offer-action-wrap" class="col m-1 m-md-0 mr-md-1 px-0">

                <adw-button
                    id="request-offer-action"
                    class="disabled">
                    <span>{{ $t("actions.offerRequest") }}</span>
                </adw-button>

          </span>

              <span id="request-order-action-wrap" class="col m-1 m-md-0 mr-md-1 px-0">
            <adw-button
                id="request-order-action"
                class="disabled">
                <span>{{ $t("actions.orderRequest") }}</span>
            </adw-button>
          </span>
              <b-tooltip target="request-offer-action-wrap" triggers="hover">Funzionalità momentaneamente non disponibile.</b-tooltip>
              <b-tooltip target="request-order-action-wrap" triggers="hover">Funzionalità momentaneamente non disponibile.</b-tooltip>
            </b-row>
          </b-container>
        </b-col>


      </b-row>
    </b-container>

    <b-modal ref="notAssignedModal"
             id="notAssignedModal"
             centered
             ok-only
             :title="$t('titles.notAssigned')"
             @ok="$refs.notAssignedModal.hide()"
             :ok-title="$t('actions.ok')">
      <p class="my-4" id="notAssignedModalText">
        {{ $t("labels.notAssignedText") }}
      </p>
    </b-modal>


    <!-- modal to open the product detail -->
    <products-search-detail-w ref="productsSearchDetailW" :showBuy="false" origin-page="cart"></products-search-detail-w>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FeedbackAlert from "@/components/feedback-alert.vue";
import AdwButton from "@/components/global-components/adw-button";
import ErrorManager from "@/services/errors-service/errors-manager";
import ProductsSearchDetailW from "@/views/ProductsSearch/ProductsSearchDetailW";
import {customer} from "../../store/module-customer";

export default {
  name: 'ProductCart',

  components: {
    ProductsSearchDetailW,
    FeedbackAlert,
    AdwButton,
  },

  data() {
    return {
      isTableLoading: true,

      customerFields: [
        {
          key: "selected",
          label: "",
          sortable: false,
        },
        {
          key: "productCode",
          label: this.$i18n.t("product.product"),
          sortKey: "productCode",
          sortable: false,
        },
        {
          key: "unitQty",
          label: this.$i18n.t("product.unitQty"),
          sortable: false,
        },
        {
          key: "qta",
          label: this.$i18n.t("product.quantity"),
          sortable: false,
        },
        {
          key: "finalUnitPrice",
          label: this.$i18n.t("product.unitPrice"),
          sortable: false,
          tdClass: "",
        },
        {
          key: "rowTotalPrice",
          label: this.$i18n.t("product.rowTotalPrice"),
          sortable: false,
          tdClass: " px-10",
        },
        {
          key: "delete",
          label: '',
          sortable: false,
        },
      ],
      agentCommercialFields: [
        {
          key: "selected",
          label: "",
          sortable: false,
        },
        {
          key: "productCode",
          label: this.$i18n.t("product.product"),
          sortKey: "productCode",
          sortable: false,
        },
        {
          key: "unitQty",
          label: this.$i18n.t("product.unitQty"),
          sortable: false,
        },
        {
          key: "qta",
          label: this.$i18n.t("product.quantity"),
          sortable: false,
        },
        {
          key: "unitPrice",
          label: this.$i18n.t("product.unitPrice"),
          sortable: false,
          tdClass: "",
        },
        {
          key: "priceListCode",
          label: this.$i18n.t("product.priceListCode"),
          sortable: false,
          tdClass: "",
        },
        {
          key: "discounts",
          label: this.$i18n.t("product.discounts"),
          sortable: false,
          tdClass: "",
        },
        {
          key: "finalUnitPrice",
          label: this.$i18n.t("product.finalUnitPrice"),
          sortable: false,
          tdClass: "",
        },
        {
          key: "rowTotalPrice",
          label: this.$i18n.t("product.rowTotalPrice"),
          sortable: false,
          tdClass: " px-10",
        },
        {
          key: "delete",
          label: '',
          sortable: false,
        },
      ],

      productCodeFilter: {
        serial: null,
        color: null,
        pkg: null,
      },

      filters: {
        serial: null,
        color: null,
        pkg: null,

        brand: null,
        inStock: null,
        status: null,
      },

      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      selected: [],
      selectAll: false,
      feedbackMessage: "",
      feedbackStatus: null,
      invalid: [],
      otherError: { name: null, msg: null },
    };
  },
  computed: {
    ...mapState({
      userRole: (state) => state.auth.user.role,
      customerCode: (state) => state.customer.customerinfo.customerCode,
    }),
    fields() {
      return this.userRole=='CUSTOMER' ? this.customerFields : this.agentCommercialFields;
    },

    products() {
      return this.$store.getters.getProductsInCart;
    },
  },
  methods: {
    selectedClass(item, type) {
      if (item && type === "row") {
        if (!!this.selected.find((i) => i.productCode === item.productCode)) {
          return "b-table-row-selected table-active";
        } else {
          return "";
        }
      } else {
        return null;
      }
    },
    validationState(contextRef) {
      return this.invalid.find(
          (invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()
      )
          ? false
          : null;
    },

    validationError(contextRef) {
      if (this.invalid.length > 0) {
        let find =
            this.invalid.find(
                (invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },

    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },
    Fail(message) {
      this.feedbackStatus = "FailDismissable";
      this.feedbackMessage = message;
    },
    Success(message) {
      this.feedbackStatus = "SuccessDismissable";
      this.feedbackMessage = message;
    },

    loadCart() {
      this.isTableLoading = true;
      this.$store
          .dispatch("getProductsCart")
          .then((response) => {
            console.debug("Products", response);
            //this.products = response;
            this.$refs.dataTable.refresh();
          })
          .catch((error) => {
            console.debug(error);
            this.Fail(ErrorManager.feedback(error));
          })
          .finally(() => {
            if (this.products.length < 1) {
              this.selectAll = this.products.every((element) => {
                return !!this.selected.find(
                    (i) => i.productCode == element.productCode
                );
              });
            } else {
              this.selectAll = false;
            }
            this.isTableLoading = false;
          });
    },

    rowSelected(item) {
      const foundItem = this.selected.find(
          (i) => i.productCode == item.productCode
      );
      console.log("foundItem", foundItem);
      if (foundItem) {
        let index = this.selected.indexOf(foundItem);
        this.selected.splice(index, 1);
      } else {
        this.selected.push(item);
      }
      console.info("this.selected", this.selected);
      this.selectAll = this.selected.length == this.products.length;
      console.info("this.selectAll", this.selectAll);
    },

    selectAllRows() {
      if (this.selectAll) {
        this.selected = [];
        this.products.forEach((or) => this.selected.push(or));
        console.log("this.products", this.products);
        console.log("this.selected", this.selected);
      } else {
        this.selected = [];
      }
    },

    deleteSelected() {
      this.$store
          .dispatch("removeProductsFromCart", this.selected)
          .then(() => {
            this.Success("removeItemSuccess");
            this.$refs.dataTable.refresh();
          })
          .finally(() => {
            this.isTableLoading = this.products.length == 0;
            this.selectAll = this.selected = [];;
          });
    },

    deleteSingleSelected(selected) {
      let toDelete = new Array(selected);
      this.$store
          .dispatch("removeProductsFromCart", toDelete)
          .then(() => {
            this.Success("removeItemsSuccess");
            this.$refs.dataTable.refresh();
          })
          .finally(() => {
            this.isTableLoading = this.products.length == 0;
            this.selectAll = this.products.length == 0 ? false : this.selectAll.filter(i => i.sortOrder != selected.sortOrder);
          });
    },
    updateCartQty () {
      console.log('updateCartQty', this.products);
      this.updateCart();
    },
    updateCart() {
      this.isTableLoading = true;
      if (this.products.every((item) => {
        return item.qtyPkgMultiplier ? item.quantity % item.qtyPkgMultiplier === 0 : true;
      })) {
        console.log('update cart with:', this.products)
        this.$store.dispatch("updateCart", this.products)
            .then((response) => {
              this.Success("updateCartSuccess");
              this.loadCart();
            })
            .catch((error) => {
              console.debug(error);
              this.Fail(ErrorManager.feedback(error));
              if (error.status == 422) {
                this.Fail("IllegalItemQuantity");
              }
            })
            .finally(() => {
              if (this.products.length < 1) {
                this.selectAll = this.products.every((element) => {
                  return !!this.selected.find(
                      (i) => i.productCode == element.productCode
                  );
                });
              } else {
                this.selectAll = false;
              }
              this.isTableLoading = false;
            });
      } else {
        //this.Fail("IllegalItemQuantity");
        this.isTableLoading = false;
      }
    },
    requestOffer() {
      if(this.selected.length === 0) {
        //this.Fail("checkoutWithNoProducts");
        return;
      }

      if (
          this.selected.every((item) => {
            return item.qtyPkgMultiplier
                ? item.quantity % item.qtyPkgMultiplier == 0
                : true;
          })
      ) {
        this.$store.commit("StartCheckoutOffer", this.selected);
        this.$router.push("/checkout/offer");
      } else {
        this.Fail("IllegalItemQuantity");
        this.isTableLoading = false;
      }
    },

    requestOrder() {
      if(this.selected.find((p) => !p.finalUnitPrice) || this.selected.length === 0) {
        //this.Fail("checkoutWithNoProducts");
        return;
      }

      if (
          this.selected.every((item) => {
            return item.qtyPkgMultiplier ? item.quantity % item.qtyPkgMultiplier === 0 : true;
          })
      ) {
        this.$store.commit("StartCheckoutOrder", this.selected);
        this.$router.push("/checkout/order");
      } else {
        this.Fail("IllegalItemQuantity");
        this.isTableLoading = false;
      }
    },

    gotoDetail(product, link) {
      this.$refs.productsSearchDetailW.gotoDetail(product, null, link)
    }
  },
  created() {
    this.loadCart();
  },
};
</script>
<style>
</style>
