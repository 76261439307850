<template>
  <div class="row justify-content-center">
    <!-- TOASK layout precisations -->
    <!-- <b-form id="businessTypology" class="col-md-12">
      <h3>{{ $t("titles.businessTypology") }}</h3>
      <b-form-group :invalid-feedback="validationError('marketSegmentation1')">
        <label for="marketSegmentation1">
          {{
            $t("formCustomer.marketSegmentation") 
          }}
          1 *</label
        >
        <b-form-select
          id="marketSegmentation1"
          v-model="form.marketSegmentation1"
          :state="validationState('marketSegmentation1')"
          @change="
            form.marketSegmentation1 == 'RSL'
              ? retailSegmentation.push('marketSegmentation1')
              : retailSegmentation.splice(
                  retailSegmentation.indexOf('marketSegmentation1'),
                  1
                )
          "
        >
          <b-select-option selected disabled :value="null">
            {{ $t("formCustomer.marketSegmentationPlaceholder") }} *
          </b-select-option>
          <b-select-option
            v-for="segmentation in segmentations"
            :value="segmentation.value"
            :key="segmentation.value"
          >
            {{ $t(segmentation.text) }}
          </b-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group>
        <label for="marketSegmentation2">
          {{ $t("formCustomer.marketSegmentation") }} 2</label
        >
        <b-form-select
          id="marketSegmentation2"
          v-model="form.marketSegmentation2"
        >
          <b-select-option selected disabled :value="null">
            {{ $t("formCustomer.marketSegmentationPlaceholder") }} *
          </b-select-option>
          <b-select-option
            v-for="segmentation in segmentations"
            :value="segmentation.value"
            :key="segmentation.value"
          >
            {{ $t(segmentation.text) }}
          </b-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group>
        <label for="marketSegmentation3">
          {{ $t("formCustomer.marketSegmentation") }} 3</label
        >
        <b-form-select
          id="marketSegmentation3"
          v-model="form.marketSegmentation3"
        >
          <b-select-option selected disabled :value="null">
            {{ $t("formCustomer.marketSegmentationPlaceholder") }} *
          </b-select-option>
          <b-select-option
            v-for="segmentation in segmentations"
            :value="segmentation.value"
            :key="segmentation.value"
          >
            {{ $t(segmentation.text) }}
          </b-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group>
        <label for="marketSegmentation4">
          {{ $t("formCustomer.marketSegmentation") }} 4</label
        >
        <b-form-select
          id="marketSegmentation4"
          v-model="form.marketSegmentation4"
        >
          <b-select-option selected disabled :value="null">
            {{ $t("formCustomer.marketSegmentationPlaceholder") }} *
          </b-select-option>
          <b-select-option
            v-for="segmentation in segmentations"
            :value="segmentation.value"
            :key="segmentation.value"
          >
            {{ $t(segmentation.text) }}
          </b-select-option>
        </b-form-select>
      </b-form-group>
    </b-form> -->
    <b-form
      id="resalesInfo"
      class="col-md-12"
      v-if="retailSegmentation.length > 0"
    >
      <h3>{{ $t("titles.resalesInfo") }}</h3>
      <label class="font-weight-bold" for="resellingType">
        {{ $t("formCustomer.resellingType") }}
      </label>
      <div class="row justify-content-left mx-2 mb-3" id="resellingType">
        <b-form-checkbox class="col-auto" v-model="form.isRetailerOfMetal">
          {{ $t("retailer.metal") }}
        </b-form-checkbox>
        <b-form-checkbox class="col-auto" v-model="form.isRetailerOfWood">
          {{ $t("retailer.wood") }}
        </b-form-checkbox>
        <b-form-checkbox
          class="col-auto"
          v-model="form.isRetailerOfConstruction"
        >
          {{ $t("retailer.construction") }}
        </b-form-checkbox>
        <b-form-checkbox class="col-auto" v-model="form.isRetailerOfBodywork">
          {{ $t("retailer.bodywork") }}
        </b-form-checkbox>
      </div>
      <label class="font-weight-bold" for="isRetailerOfOwnership">
        {{ $t("retailer.ownership") }}
      </label>
      <b-form-group
        id="isRetailerOfOwnership"
      >
        <b-form-radio
          v-model="form.isRetailerOfOwnership"
          name="some-radios"
          :value="true"
          >{{ $t("labels.yes") }}</b-form-radio
        >
        <b-form-radio
          v-model="form.isRetailerOfOwnership"
          name="some-radios"
          :value="false"
          >{{ $t("labels.no") }}</b-form-radio
        >
      </b-form-group>
      <div class="row">
        <b-form-group class="col-sm-4">
          <label class="font-weight-bold" for="retailerShopWindows">
            {{ $t("retailer.shopWindows") }}
          </label>
          <b-form-input
            type="number"
            id="retailerShopWindows"
            name="retailerShopWindows"
            v-model="form.retailerShopWindows"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-sm-4">
          <label class="font-weight-bold" for="retailerSurface">
            {{ $t("retailer.surface") }}
          </label>
          <b-input-group :append="$t('retailer.mq')">
            <b-form-input
              type="number"
              id="retailerSurface"
              name="retailerSurface"
              v-model="form.retailerSurface"
            ></b-form-input
          ></b-input-group>
        </b-form-group>
      </div>
    </b-form>
    <b-form id="outlook" class="col-md-12">
      <h3>{{ $t("titles.outlook") }}</h3>
      <b-form-group :invalid-feedback="validationError('marketCompetitor1')"
        ><label for="marketCompetitor1">
          {{ $t("formCustomer.marketCompetitor") }} 1 *</label
        >
        <b-form-input
          type="text"
          id="marketCompetitor1"
          name="marketCompetitor1"
          v-model="form.marketCompetitor1"
          :placeholder="$t('formCustomer.marketCompetitorPlaceholder')"
          :state="validationState('marketCompetitor1')"
          @change="$emit('fieldUpdated')"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <label for="marketCompetitor2">
          {{ $t("formCustomer.marketCompetitor") }} 2</label
        >
        <b-form-input
          type="text"
          id="marketCompetitor2"
          name="marketCompetitor2"
          v-model="form.marketCompetitor2"
          :placeholder="$t('formCustomer.marketCompetitorPlaceholder')"
          @change="$emit('fieldUpdated')"
        ></b-form-input>
      </b-form-group>
      <div class="row">
        <b-form-group class="col-sm-6">
          <label for="totalConsumption">
            {{ $t("formCustomer.totalConsumption") }}</label
          >
          <b-input-group append="€">
            <b-form-input
              type="number"
              id="totalConsumption"
              name="totalConsumption"
              v-model="form.totalConsumption"
              @change="$emit('fieldUpdated')"
            ></b-form-input
          ></b-input-group>
        </b-form-group>
        <b-form-group class="col-sm-6">
          <label for="alceaPotential">
            {{ $t("formCustomer.alceaPotential") }}</label
          ><b-input-group append="€">
            <b-form-input
              type="number"
              id="alceaPotential"
              name="alceaPotential"
              v-model="form.alceaPotential"
              @change="$emit('fieldUpdated')"
            >
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
    </b-form>
  </div>
</template>
<script>
import { segmentationTypes } from "../commons/segmentations-types";
export default {
  name: "CompanyPerspective",
  props: {
    formRq: Object(),
    currentStep: Object(),
    completed: Boolean(),
    invalidErrors: Array(),
  },
  data() {
    return {
      self: "CompanyPerspective",
      segmentations: segmentationTypes,
      retailSegmentation: [],
    };
  },
  computed: {
    seee() {
      //console.log(this.retailSegmentation);
      return this.retailSegmentation;
    },
    form: {
      get() {
        return this.formRq;
      },
      set(form) {
        this.$emit("input", form);
      },
    },
    fields() {
      if (this.retailSegmentation.length > 0) {
        return [
          "marketSegmentation1",
          "marketSegmentation2",
          "marketSegmentation3",
          "marketSegmentation4",
          "marketCompetitor1",
          "marketCompetitor2",
          "totalConsumption",
          "alceaPotential",
          "isRetailerOfMetal",
          "isRetailerOfWood",
          "isRetailerOfConstruction",
          "isRetailerOfBodywork",
          "isRetailerOfOwnership",
          "retailerretailerShopWindows",
          "retailerSurface",
        ];
      } else {
        return [
          "marketSegmentation1",
          "marketSegmentation2",
          "marketSegmentation3",
          "marketSegmentation4",
          "marketCompetitor1",
          "marketCompetitor2",
          "totalConsumption",
          "alceaPotential",
        ];
      }
    },
  },
  methods: {
    validationError(contextRef) {
      if (this.invalidErrors.invalid.length > 0) {
        let find =
          this.invalidErrors.invalid.find(
            (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
          ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
    validationState(contextRef) {
      return this.invalidErrors.invalid
        .map((invEl) => {
          return invEl.name.toLowerCase();
        })
        .includes(contextRef.toLowerCase())
        ? false
        : null;
    },
  },
  watch: {
    // "form.marketSegmentation1": {
    //   handler() {
    //     $emit(
    //       "update:formRq",
    //       Object.assign(formRq, {
    //         marketSegmentation1: form.marketSegmentation1,
    //       })
    //     );
    //   },
    //   deep: true,
    // },
  },
};
</script>