<template>
  <div class="container">
    <section class="row justify-content-center">
      <b-icon aria-hidden="true" icon="lock-fill">{{ $t('errors.Unauthorized') }}</b-icon>
      <p>{{ $t('errors.UnauthorizedLink') }}</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized'
};
</script>
