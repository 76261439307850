<template>
  <div>

    <feedback-alert
        v-if="feedbackStatus"
        :status="feedbackStatus"
        :message="feedbackMessage"
        :mail="alceaMail"
        @clearStatus="clearStatus"
        @back="closeModal">
    </feedback-alert>

    <div v-if="feedbackStatus !== 'Success'">
      <p>{{ $t("formCustomer.customerCodeText") }}</p>

      <b-form-group :invalid-feedback="validationError('customerCode')">
        <b-form-input
            type="text"
            id="customerCode"
            name="customerCode"
            v-model="form.customerCode"
            :placeholder="$t('formCustomer.customerCode') + ' *'"
            :state="validationState('customerCode')">
        </b-form-input>
      </b-form-group>

      <b-button class="mx-1" @click="associate">{{ $t("actions.associate") }}</b-button>
      <b-button variant="link" class="mx-1" @click="closeModal">{{ $t("actions.close") }}</b-button>
    </div>
  </div>
</template>

<script>
import Validator from "@/services/validation-service/validator-service"
import ErrorManager from "@/services/errors-service/errors-manager"
import feedbackAlert from "@/components/feedback-alert"

export default {
  name: "customer-association-request",

  components: {
    feedbackAlert
  },

  data() {
    return {
      form: {
        customerCode: "",
      },
      feedbackStatus: null,
      feedbackMessage: "",
      invalid: [],
      alceaMail: "adw_alcea@alcea.com"
    }
  },

  methods: {
    clearStatus() {
      this.feedbackStatus = null
      this.feedbackMessage = ""
    },

    validationState(contextRef) {
      return this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) ? false : null
    },

    validationError(contextRef) {
      if (this.invalid.length > 0) {
        let find = this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) || null
        return find ? this.$i18n.t(find.error) : null
      }
    },

    associate() {
      let validation = Validator.validateForm("association", this.form)
      this.invalid = validation.invalid
      if (this.invalid.length <= 0) {
        this.sendRequest()
      }
    },

    async sendRequest() {
      this.$store.dispatch("postExistingCustomerAssociation", this.form)
          .then(() => {
            this.feedbackStatus = "Success"
            this.feedbackMessage = "sendRequestSuccess"
          })
          .catch((error) => {
            console.error(error)
            this.feedbackStatus = "FailDismissable"
            this.feedbackMessage = "FailedToAssociate"
          })
    },

    closeModal() {
      this.$bvModal.hide('linkCustomerModal')
    }
  },
}
</script>
