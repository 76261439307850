export const actions = {
  //actions
  signUp: "Зарегистрироваться",
  loginToProceed: "Пожалуйста, войдите в систему, чтобы продолжить",
  loginCompleted: "Вход завершен",
  backToLogin: "Вернуться к входу в систему",
  update: "Обновить",
  access: "Доступ",
  create: "Создать",
};
