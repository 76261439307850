<template>
  <b-container fluid class="ps-sel-container bx position-relative">

    <!-- title -->
    <b-row class="bb text-center">
      <b-col cols="12" class="pt-2">
        <h3>CERCA IL RIVENDITORE PIU’ VICINO A TE</h3>
      </b-col>
    </b-row>

    <b-row>

      <!-- map -->
      <b-col class="ps-sel-list-map" order-md="2">
        <l-map :zoom="zoom" :center="center" class="ps-sel-list-map" style="z-index: 10">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

          <l-marker v-for="marker of markers"
                    :lat-lng="marker.latLng"
                    :icon="marker.icon"
                    :ref="marker.seller.id"
                    @click="selectMarkerInverse(marker)">
            <l-popup>
              <div>
                <h2>{{ marker.seller.businessName }}</h2>
                <p>
                  {{ marker.seller.street }}, {{ marker.seller.postalCode }} {{ marker.seller.province }}<br/>
                  {{ marker.seller.phone }}<br/>
                  {{ marker.seller.email }}
                </p>
              </div>
            </l-popup>
          </l-marker>
        </l-map>
      </b-col>

      <b-col cols="12" md="4" order-md="1" class="ps-sel-list-wrap">
        <!-- filters -->
        <b-container fluid class="position-sticky" style="top: 0px">
          <b-row class="bb br">
            <b-col cols="6" class="ps-sub-filter-title br" style="text-align: center; padding-top: 5px;">
              {{ $t('formUser.businessName') }}
            </b-col>
            <b-col cols="6" class="ps-no-borders bg-white">
              <b-form-input v-model="filters.businessName" :placeholder="$t('formUser.businessName')" @keyup="filterMarker"></b-form-input>
            </b-col>
          </b-row>

          <b-row class="bb br">
            <b-col cols="6" class="ps-sub-filter-title br" style="text-align: center; padding-top: 5px;">
              {{ $t('formUser.zipCode') }}
            </b-col>
            <b-col cols="6" class="ps-no-borders bg-white">
              <b-form-input v-model="filters.postalCode" :placeholder="$t('formUser.zipCode')" @keyup="filterMarker"></b-form-input>
            </b-col>
          </b-row>
        </b-container>

        <!-- sellers list -->
        <div v-if="markers.length === 0" class="ps-sel-list-item" style="font-size: larger; padding: 20px;">
          Nessun rivenditore trovato
        </div>

        <div v-if="markers.length > 0"
             v-for="marker of markers"
             :ref="'markerItem' + marker.seller.id"
             class="ps-sel-list-item br bb"
             :class="{'ps-marker-selected' : (markerSelected && markerSelected.seller.id === marker.seller.id)}"
             @mouseenter.stop="highlightMarker(marker)"
             @mouseleave.stop="resetHighlightMarker()"
             @click.stop="selectMarker(marker)">
          <h2>{{ marker.seller.businessName }}</h2>
          <div class="d-flex">
            <address>{{ marker.seller.street }}, {{ marker.seller.postalCode }} {{ marker.seller.province }}</address>

            <a class="ml-4" @click="openMap(marker)">
              <b-icon-map></b-icon-map>
              {{ $t('actions.openMap') }}
            </a>
          </div>
          <p class="mb-1">{{ marker.seller.phone }}</p>
          <p class="mb-1">{{ marker.seller.email }}</p>
        </div>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import ProductsSearchService from '@/services/api-services/products-search-service'
import { LIcon, LMap, LMarker, LPopup, LTileLayer } from 'vue2-leaflet'
import { icon } from 'leaflet'

export default {
  name: 'ProductsSearchSellersMap',

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  },

  data() {
    return {
      sellers: [],
      markers: [],
      markerSelected: null,

      filters: {
        businessName: '',
        postalCode: ''
      },

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '',
      zoom: 10,
      center: [45.574949, 9.126329]
    }
  },

  computed: {
    icon() {
      const images = require.context('@/assets/products-search/seller/', false, /\.png$/)
      return {
        default: icon({
          iconUrl: images('./seller.png'),
          iconSize: [30, 30],
          iconAnchor: [15, 0]
        }),

        highlight: icon({
          iconUrl: images('./seller-highlight.png'),
          iconSize: [30, 30],
          iconAnchor: [15, 0]
        }),

        selected: icon({
          iconUrl: images('./seller-selected.png'),
          iconSize: [30, 30],
          iconAnchor: [15, 0]
        })
      }
    }
  },

  methods: {
    initData(sellers) {
      this.sellers = sellers
      this.filterMarker();
    },

    filterMarker() {
      let markers = []

      this.sellers.forEach(seller => {
        if (seller.postalCode && seller.postalCode.startsWith(this.filters.postalCode)
            && seller.businessName && seller.businessName.toLowerCase().includes(this.filters.businessName.toLowerCase())) {
          markers.push({
            latLng: [seller.latitude, seller.longitude],
            icon: this.icon.default,
            seller: seller
          })
        }
      })

      if(markers.length === 0 && this.filters.postalCode.length === 5) {
        this.sellers.forEach(seller => {
          if (seller.postalCode && seller.postalCode.startsWith(this.filters.postalCode.substring(0, 2))
              && seller.businessName && seller.businessName.toLowerCase().includes(this.filters.businessName.toLowerCase())) {
            markers.push({
              latLng: [seller.latitude, seller.longitude],
              icon: this.icon.default,
              seller: seller
            })
          }
        })
      }

      this.markers = markers
    },

    highlightMarker(marker) {
      this.markers.forEach(m => {
        if (m.seller.id === marker.seller.id) {
          m.icon = this.icon.highlight
        } else {
          if (this.markerSelected && this.markerSelected.seller.id === m.seller.id) {
            m.icon = this.icon.selected
          } else {
            m.icon = this.icon.default
          }
        }
      })
    },

    resetHighlightMarker() {
      this.markers.forEach(m => {
        m.icon = this.icon.default
      })

      if (this.markerSelected) {
        this.markerSelected.icon = this.icon.selected
      }
    },

    selectMarker(marker) {
      this.markerSelected = marker;
      this.center = marker.latLng

      this.markers.forEach(m => {
        if (m.seller.id === marker.seller.id) {
          this.$refs[m.seller.id][0].mapObject.openPopup()
          m.icon = this.icon.selected
        } else {
          m.icon = this.icon.default
        }
      })
    },

    selectMarkerInverse(marker) {
      this.markerSelected = marker

      // scroll on the item in the list if outside the current view
      try {
        this.$refs['markerItem' + this.markerSelected.seller.id][0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      } catch (e) {
      }

      // highlight the item in the list
      this.markers.forEach(m => {
        if (m.seller.id === marker.seller.id) {
          m.icon = this.icon.selected
        } else {
          m.icon = this.icon.default
        }
      })
    },

    openMap(marker) {
      if (marker.seller.mapLink) {
        window.open(marker.seller.mapLink)
      } else {
        // if we're on iOS, open in Apple Maps, otherwise use Google
        let prefix;
        if (navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPad') != -1 || navigator.platform.indexOf('iPod') != -1) {
          prefix = 'maps'
        } else {
          prefix = 'https'
        }
        window.open(prefix + '://maps.google.com/maps?daddr=' + marker.latLng[0] + ',' + marker.latLng[1] + '&amp;ll=')
      }
    }
  },

  mounted() {
    ProductsSearchService.getSellers(this.$route.params.priv, this.$route.params.professional).then((response) => {
      this.initData(response.data)
    })
  },
}
</script>