import store from "@/store/index";
import ProfileCustomer from "@/views/ProfileCustomer/ProfileCustomer";
import EditCustomer from "@/views/ProfileCustomer/EditCustomer";
import ProfileCustomerPage from "@/views/ProfileCustomer/ProfileCustomerPage";
import OrderedProducts from "@/views/ProfileCustomer/OrderedProducts";
import ProductCart from "@/views/ProfileCustomer/ProductsCart.vue";
import Orders from "@/views/ProfileCustomer/Orders";
import RequestOffers from "@/views/ProfileCustomer/RequestOffers";
import RequestOrders from "@/views/ProfileCustomer/RequestOrders";
import InviteList from "@/views/ProfileCustomer/InviteList";
import InviteNew from "@/views/ProfileCustomer/InviteNew";
import ContactArea from "@/views/ProfileCustomer/ContactArea";

export default {
  path: "/profile-customer",
  component: ProfileCustomerPage,

  children: [
    {
      path: "",
      name: "ProfileCustomerView",
      component: ProfileCustomer,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
      },
    },

    {
      path: "edit-request",
      name: "EditCustomer",
      component: EditCustomer,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
      },
    },

    {
      path: "ordered-products",
      name: "OrderedProducts",
      component: OrderedProducts,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
      },
    },

    {
      path: "request-offers",
      name: "RequestOffers",
      component: RequestOffers,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.getUserRole === "CUSTOMER") {
          store.dispatch("customerMyself").catch(e => next("/profile-user"))
        }

        if (store.getters.getUserRole === "AGENT" || store.getters.getUserRole === "COMMERCIAL") {
          const myCustomerDispatch = store.getters.getUserRole === "COMMERCIAL" ? "commercialMyCustomers" : "agentMyCustomers"
          if (!store.getters.getAgentCustomersLoaded) {
            store.dispatch(myCustomerDispatch, { page: 0, row: 100 })
          }
          !store.getters.getLoggedInAsCustomer ? next("/profile-agent") : next()
        }

        // from is null
        return next()
      },
    },

    {
      path: "request-orders",
      name: "RequestOrders",
      component: RequestOrders,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.getUserRole === "CUSTOMER") {
          store.dispatch("customerMyself").catch(e => next("/profile-user"))
        }

        if (store.getters.getUserRole === "AGENT" || store.getters.getUserRole === "COMMERCIAL") {
          const myCustomerDispatch = store.getters.getUserRole === "COMMERCIAL" ? "commercialMyCustomers" : "agentMyCustomers"
          if (!store.getters.getAgentCustomersLoaded) {
            store.dispatch(myCustomerDispatch, { page: 0, row: 100 })
          }
          !store.getters.getLoggedInAsCustomer ? next("/profile-agent") : next()
        }

        // from is null
        return next()
      },
    },

    {
      path: "orders",
      name: "Orders",
      component: Orders,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
      },
    },

    {
      path: "cart",
      name: "ProductCart",
      component: ProductCart,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
      },
    },

    {
      path: "invite-list",
      name: "InviteList",
      component: InviteList,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["USER", "CUSTOMER"],
      },
      beforeEnter: (to, from, next) => {
        if (store.getters.getUserType === "C") {
          return next();
        }
        return next("/unauthorized");
      },
    },

    {
      path: "invite-new",
      name: "Invite",
      component: InviteNew,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER"],
      },
    },
    {
      path: '/contact-area',
      name: 'ContactArea',
      component: ContactArea,
      meta: {
        sector: 'PrivateArea',
      },
    }
  ],

  meta: {
    sector: 'PrivateArea',
    requiresAuth: true,
    roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
  },

  beforeEnter: (to, from, next) => {
    if (store.getters.getUserRole === "CUSTOMER") {
      console.log(store.getters.getUserRole, 'SONO IO')
      store.dispatch("customerMyself").catch(e => next("/profile-user"));
    }
    if (store.getters.getUserRole === "AGENT" || store.getters.getUserRole === "COMMERCIAL") {
      if (!store.getters.getAgentCustomersLoaded) {
        const myCustomerDispatch = store.getters.getUserRole === "COMMERCIAL" ? "commercialMyCustomers" : "agentMyCustomers";
        console.log('beforeEnter', myCustomerDispatch)
        store.dispatch(myCustomerDispatch, { page: 0, row: 100 });
      }
      !store.getters.getLoggedInAsCustomer ? next("/profile-agent") : next();
    }

    //from is null
    return next();
  },
};
