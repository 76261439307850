export const instructions = {
  checkout: {
     shippingAddress: "Bitte Lieferadresse für diese Bestellung anwählen.",
     customerOrderCode: "Falls erforderlich, die dieser Anfrage zugeordnete kundenspezifische Bestellnummer angeben.",
     deliveryDateRequested: "Falls erforderlich, ein bevorzugtes Datum für den Empfang der Bestellung angegeben, andernfalls wird die Bestellung zum bestmöglichen Datum geliefert.",
     acceptMultipleShipping: "Bitte angeben, ob die Ware in mehreren Lieferungen versandt werden darf, andernfalls erhalten Sie sie in einer Einzellieferung.",
     shippingNote: "Gegebenenfalls Hinweise zur Lieferung einfügen, diese werden in den Lieferschein eingefügt.",
     genericNote: "Falls erforderlich, allgemeine Hinweise zur Bestellung einfügen."
  },
  formCustomer: {
     vatNumber: "Zur Änderung der USt-IdNr. wenden Sie sich bitte an Alcea."
  },
  filters: "Filter"
};
