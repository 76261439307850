export const alert = {
  SuccessRegiatration: "Utente registrato, A breve riceverai una email con un link per attivare l’account ed inserire le credenziali per l’accesso",
  sending: "Invio in corso...",
  operationSuccess: "L'operazione si è conclusa con successo",
  registrationComplete: "Congratulazioni, la registrazione è conclusa",
  newPasswordComplete: "Nuova Password aggiornata con successo",
  resetPasswordSuccess: "La richiesta è stata effettuata con successo, se presente un account associato alla mail inserita riceverai a breve una email con un link per inserire la nuova password.",
  notificationMailSuccess: "Aggiornato notifiche login",
  updateSuccess: "Aggiornato profilo",
  sendRequestSuccess: "La richiesta verrà esaminata al più presto, riceverai comunicazione via email con l’esito. Inoltre potrai vedere lo stato della richiesta nel proprio profilo utente.",
  sendInviteSuccess: "L'invito è stato spedito con successo",
  acceptSuccess: "Invito Accettato",
  refuseSuccess: "Invito Rifiutato",
  deleteSuccess: 'Invito eliminato correttamente',
  deleteFail: "L'operazione di eliminazione non ha avuto successo",
  becomeClientSuccess: "Congratulazioni accettando l'invito da edesso puoi già accedere come cliente",
  productsInCart: "Prodotti aggiunti al carrello",
  updateCartSuccess: 'Il carrello è stato aggiornato',
  removeItemSuccess: 'Prodotto rimosso dal carrello',
  removeItemsSuccess: 'Prodotti rimossi dal carrello',
  SuccessCheckOutOffer: 'Checkout effettuato correttamente',
  SuccessCheckOutOrder: 'Checkout effettuato correttamente',
  somethingWrong: "Qualcosa è andato storto",
  NetworkError: "Errore di Rete",
  UnauthorizedLogin: "Username o password non validi",
  Unauthorized: "Non disponi delle autorizzazioni necessarie",
  BadRequest: "Errore nella formulazione della richiesta",
  NotFound: "Non è stata trovata una corrispondenza",
  FailedToAssociate: "Non è stata trovata una corrispondenza con il codice inserito, per maggiori informazioni contattare l'indirizzo",
  Conflict: "Stai cercando di inviare un dato già memorizzato",
  ServerError: "Si è verificato un errore nel server",
  UnauthorizedOrInvalid: "Non disponi delle autorizzazioni necessarie o il link non è più valido",
  customerRequestExistingVatTitle: "Attenzione",
  customerRequestExistingVat: "La Partita iva inserita è già esistente, se stai cercando di creare una richiesta per un cliente già registrato clicca si 'Usa Codice Cliente' per associare l'utenza ad un cliente già esistente utilizzando il codice cliente fornito",
  agentCustomerRequestExistingVat: "La Partita iva inserita appartiene già a un cliente che ha fatto richiesta",
  editRequestExistingVat: "Attenzione è già presente una richiesta in attesa per questo customer",
  TdsError: "Si è verificato un errore durante il download",
  missingTds: "Per questo prodotto non è presente una scheda tecnica",
  checkoutWithNoProducts: "Selezionare almeno un prodotto per procedere.",
  checkoutWithNoProductsOrNoPrices: "Selezionare almeno un prodotto con un prezzo assegnato per procedere.",
  successContactForm: "La richiesta è stata inviata correttamente, verrai ricontattado dal Customer Service Alcea.",
  invalidForm: "Verificare che i campi siano tutti compilati correttamente",
  sdsUnableToRetrieveBatch: "Errore nel recupero dei lotti",
  sdsUnableToRetrieveLanguages: "Errore nel recupero delle lingue disponibili",
  sdsSentSuccess: "SDS inviata correttamente",
  sdsSentError: "Errore nell'inivio della scheda di sucurezza",
  sdsDownloadError: "Errore nel Download della scheda di sucurezza"
};
