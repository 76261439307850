import http from "@/util/http-common";
import store from "@/store/index";

class ProductService {
  getUrlNoParam() {

    return store.getters.getUserRole === "IT"
        ? "v1/public/it" :
        store.getters.getUserRole === "ADMIM"
            ? "v1/public/admin" :
            store.getters.getUserRole === "ALCEA"
                ? "v1/public/alcea" :
            store.getters.getUserRole === "COMMERCIAL"
                ? "v1/public/commercial" :
                store.getters.getUserRole === "AGENT"
                    ? "v1/public/agent" :
                    store.getters.getUserRole === "CUSTOMER"
                        ? "v1/public/customer"
                        : "v1/public/user";

  }

  getUrl(url, customer) {
    return store.getters.getUserRole == "COMMERCIAL"
      ? "v1/public/commercial" + `${url}?customerCode=${customer}` :
      store.getters.getUserRole == "AGENT"
        ? "v1/public/agent" + `${url}?customerCode=${customer}`
        : "v1/public/customer" + url;
  }

  getOrderedProducts(page, size, sortBy, sortDir, filters) {
    let url = this.getUrlNoParam() + "/customer-products";
    let call = `${url}?customerCode=${filters.customerCode}`;

    let params = {
      size: size,
      page: page - 1,
      sort: sortBy + "," + sortDir,
    };

    filters.serial ? (params["serial"] = filters.serial) : null;
    filters.color ? (params["color"] = filters.color) : null;
    filters.pkg ? (params["pkg"] = filters.pkg) : null;
    filters.description ? (params["description"] = filters.description) : null;
    filters.brand ? (params["brand"] = filters.brand) : null;
    filters.onlyPreferred ? (params["onlyPreferred"] = filters.onlyPreferred) : null;

    console.info("getOrderedProducts call", call, params);
    return http.get(call, { params });
  }

  togglePreference(item) {
    let customer = store.getters.getCustomerCode;
    let body = {
      customerCode: customer,
      productClass: item.id.productClass,
      serial: item.id.serial,
      color: item.id.color,
      pkg: item.id.pkg,
    };
    return http.patch(this.getUrl("/customer-products/preferred"), body);
  }

  getProduct(productClass, serial, color, pkg) {
    return http.get("/products/" + productClass.replace(new RegExp(" ", 'g'), "-") + "/" + serial.replace(new RegExp(" ", 'g'), "-") + "/" + color.replace(new RegExp(" ", 'g'), "-") + "/" + pkg.replace(new RegExp(" ", 'g'), "-"));
  }

  async getProductsCart(customer) {
    return http.get(this.getUrl("/carts", customer));
  }

  async postNewCart(customer, items) {
    let body = { items: items };
    return http.post(this.getUrl("/carts", customer), body);
  }

  async postProductsInCart(customer, items) {
    console.log("ADD", items);
    return http.post(this.getUrl("/carts/items", customer), items);
  }

  async deleteProductsInCart(customer, items) {
    return http.delete(this.getUrl("/carts/items", customer), {
      data: items,
    });
  }

  async updateProductCart(customer, items) {
    let body = { items: items };
    return http.put(this.getUrl("/carts", customer), body);
  }

  findSdsByFilters(sdsFilters) {
    let url = this.getUrlNoParam() + "/sds/findSdsByFilters?";
    const uriParams = new URLSearchParams(this.removeEmptyProps(sdsFilters)).toString();

    return http.get(url + uriParams);
  }

  findSdsByProduct(sdsFilters) {
    let url = this.getUrlNoParam() + "/sds/findSdsByProduct?";
    const uriParams = new URLSearchParams(this.removeEmptyProps(sdsFilters)).toString();

    return http.get(url + uriParams);
  }

  findSdsByOrder(sdsFilters) {
    let url = this.getUrlNoParam() + "/sds/findSdsByOrder?";
    const uriParams = new URLSearchParams(this.removeEmptyProps(sdsFilters)).toString();

    return http.get(url + uriParams);
  }

  checkLanguages(sdsResult) {
    let url = this.getUrlNoParam() + "/sds/checklanguage?";
    const uriParams = new URLSearchParams(this.removeEmptyProps(sdsResult)).toString();

    return http.get(url + uriParams);
  }

  downloadSds(sdsResult) {
    let url = this.getUrlNoParam() + "/sds/download?";
    const uriParams = new URLSearchParams(this.removeEmptyProps(sdsResult)).toString();

    return http.get(url + uriParams, { responseType: 'arraybuffer' });
  }
  sendSds(sendSdsRequest) {
    let url = this.getUrlNoParam() + "/sds/send?";
    const uriParams = new URLSearchParams(this.removeEmptyProps(sendSdsRequest)).toString();

    return http.get(url + uriParams);
  }
  sendAllSds(sendAllSdsRequest) {
    let url = this.getUrlNoParam() + "/sds/sendAllByOrder?";
    const uriParams = new URLSearchParams(this.removeEmptyProps(sendAllSdsRequest)).toString();

    return http.get(url + uriParams);
  }

  findSdsByBatch(sdsFilters) {
    let url = this.getUrlNoParam() + "/sds/findSdsByBatch?";
    const uriParams = new URLSearchParams(sdsFilters).toString();

    return http.get(url + uriParams);
  }

  autocompleteBatch(batchSearcbString) {
    let url = this.getUrlNoParam() + "/sds/autocompleteBatch?"
    url = this._addParam(url, 'searchString', batchSearcbString)
    return http.get(url);
  }

  _addParam(url, paramName, paramValue) {
    if (url.slice(-1) !== '?') {
      return url + (this.isNotEmpty(paramValue) ? "&" + paramName + "=" + paramValue : "")
    }
    return url + (this.isNotEmpty(paramValue) ? paramName + "=" + paramValue : "")
  }

  isEmpty(val) {
    return !val
  }

  isNotEmpty(val) {
    return !this.isEmpty(val)
  }

  removeEmptyProps(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }
}

export default new ProductService();
