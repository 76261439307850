import CheckoutCartPage from "@/views/CheckoutCart/CheckoutCartPage.vue";
import CheckoutOrder from "@/views/CheckoutCart/CheckoutOrder.vue";
import CheckoutOffer from "@/views/CheckoutCart/CheckoutOffer.vue";
import store from "@/store/index";

export default {
  path: "/checkout",
  name: "Checkout",
  component: CheckoutCartPage,
  children: [
    {
      path: "order",
      name: "CheckoutOrder",
      component: CheckoutOrder,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
      },
      beforeEnter: (to, from, next) => {
        if (!store.state.checkout.order.checkingOut) {
          next("/profile-customer/cart");
        }
        return next();
      },
    },
    {
      path: "offer",
      name: "CheckoutOffer",
      component: CheckoutOffer,
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
      },
      beforeEnter: (to, from, next) => {
        if (!store.state.checkout.offer.checkingOut) {
          next("/profile-customer/cart");
        }
        return next();
      },
    },
    {
      path: "",
      meta: {
        sector: 'PrivateArea',
        requiresAuth: true,
        roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
      },
      beforeEnter: (to, from, next) => {
        if (
          !store.state.checkout.order.checkingOut &&
          !store.state.checkout.offer.checkingOut
        ) {
          next("/profile-customer/cart");
        } else if (store.state.checkout.order.checkingOut) {
          next("/checkout/order");
        } else if (store.state.checkout.offer.checkingOut) {
          next("/checkout/offer");
        }
        return next("/profile-customer/cart");
      },
    },
  ],

  meta: {
    sector: 'PrivateArea',
    requiresAuth: true,
    roles: ["CUSTOMER", "AGENT", "COMMERCIAL"],
  },

  beforeEnter: (to, from, next) => {
    return next();
  },
};
