export const requestStatus = {
  requestStatus: "Stato richiesta",
  agentRequestCustomerText: "Compila la richiesta per un nuovo cliente, avrai accesso a tutti i prodotti dei cataloghi e potrai richiedere ordini e offerte direttamente per esso.",
  becomeCustomerText: "Compila la richiesta per diventare nostro cliente, avrai accesso a tutti i prodotti dei nostri cataloghi e potrai richiedere ordini e offerte direttamente dalla tua area riservata.",
  text: "Richiesta per diventare cliente",
  confirmNewRequest: "Eliminare la richiesta precedente per crearne una nuova?",
  NONE: "nessuna",
  DRAFT: "bozza",
  PENDING: "In attesa",
  ACCEPTED: "Accettato",
  REJECTED: "Rifiutato",
  CLOSED: "Chiuso",
  EXPIRED: "Scaduto",
  NULL: "Seleziona stato",
  null: "Seleziona stato",
  customerExisting: {
    title: "La tua azienda è già cliente alcea?",
    titleShort: "Abbina cliente",
    text: "Per effettuare un associazione diretta tramite codice cliente",
  },
  acceptedText1: "Congratulazioni, ora il tuo utente è abilitato a gestire il profilo cliente per l'azienda ", 
  acceptedText2: ". Nella sezione Profilo Cliente potrai vedere lo storico dei prodotti ordinati, effettuare richieste di ordine o offerta e gestire la tua anagrafica.",
  rejectedText: "Purtroppo la tua richiesta è stata rifiutata. Clicca sul link qui sotto per ritornare allo stato iniziale."
};
