export const product = {
  um: "U.M.",
  status: "Status",
  statusValue: {
     NULL: "All",
     REMOVED: "Removed",
     AVAILABLE: "Available"
  },
  inStock: "In stock",
  tds: "TDS",
  PresentInStock: "Present on stock",
  searchProducts: "Search products",
  productCode: "Product Code ",
  productNameCode: "Name or product code or description",
  brand: "Brand",
  color: "Color",
  serialCode: "Series code",
  colorCode: "Color code",
  packaging: "Packaging",
  serial: "Series",
  pkg: "Pkg.",
  pkgAvailable: "Available on stock",
  area: "Area",
  typology: "Product typology",
  family: "Chemical family",
  dilution: "Dilution",
  dilution_water: "Water Dilution",
  dilution_solvent: "Solvent Dilution ",
  discount: "Discount",
  drying: "Drying",
  dryingIcon: "Drying",
  appMethod: "Application method",
  results: "Back to the search results ",
  noResults: "",
  characteristics: "Characteristics ",
  downloadTds: "Download TDS in PDF format",
  loginToDownloadTds: "Login to download TDS",
  noTdsFound: "TDS not found ",
  code: "Code",
  description: "Description",
  specificWeight: "Specific weight",
  unitValue: "Unity q.ty",
  months: "Months",
  usage: "Usage",
  internal_use: "Internal use",
  external_use: "External use ",
  external_internal_use: "Internal/external use",
  shelf: "Shelf life ",
  squareMeters: "sq. m",
  squareMetersPerLitre: "sq. m/l",
  squareMetersPerKilogram: "sq. m/kg",
  unitPrice: "Unit price ",
  finalUnitPrice: "Final Unit price ",
  discounts: "Discounts",
  priceListCode: "Price liust ",
  unitQty: "Unit quantity ",
  rowTotalPrice: "Row tot. ",
  product: "Product",
  preferred: "Preferred",
  quantity: "Quantity",
  notAssigned: "Not assigned",
  modal: {
     downloadTds: "Choose in which language you want to download the TDS"
  },
  priceList: {
     PL_RIV: "REV",
     PL_CPS: "PS extension",
     PL_IND: "IND",
     PL_SM_PASTE: "SM pastes",
     PL_SM_CONV: "SM conv",
     PL_GE_PASTE: "GE pastes",
     PL_GE_CONV: "GE conv",
     PL_LEG: "LEG",
     PL_9: 9,
     PL_DIL: "DIL",
     PL_IMB: "IMB",
     PL_ATT: "ATTENTION"
  },
  combos: {
     interested: {
        metalIndustry: "Metal industry",
        woodIndustry: "Wood industry",
        coilCoating: "coil coating",
        construction: "Construction",
        homeLine: "home line",
        tintometrics: "Tintometry"
     },
     areas: {
        edil: "BUILDING (WALL)",
        metal: "METAL INDUSTRY",
        wood: "WOOD INDUSTRY",
        edil_metal_wood: "CONSTRUCTION-METAL-WOOD",
        coil: "COIL",
        plastic: "PLASTIC ALL SECTORS",
        paste: "PASTES, CONVERTERS, CATALYST FOR RETAIL",
        corrosion: "ANTICORROSION, NAVAL, ETC"
     },
     typologies: {
        dye: "COLORS, PATINES, INKS, CONCENTRATES for WOOD",
        paste: "COLORING CONCENTRATED PASTES",
        background: "ANTICORROSIVE AND NON ANTICORROSIVE UNDERCOATS AND PRIMERS",
        intermediate: "INTERMEDIATE and BASE COAT",
        washable: "WASHABLE",
        various: "VARIOUS",
        catalyst: "CATALYST",
        thinner: "THINNERS",
        converter: "CONVERTER",
        dust: "THERMOSETTING POWDERS",
        back: "BACK",
        impregnant: "IMPREGNANT",
        coat: "TOP COAT",
        raw_material: "RAW MATERIAL"
     },
     families: {
        acrylic: "ACRYLICS",
        pvdf: "PVDF / FLUORINATED",
        epoxy: "EPOXIES",
        ketone: "KETONES",
        polyester: "POLYESTERS / ISOCYANICS",
        alkyl: "ALKYDS",
        nitrocellulose: "NITROCELLULOSICS",
        unsaturated: "UNSATURATED POLYESTERS",
        clorocianuro: "CHLOROCYANURICS",
        phenolic: "PHENOLIC",
        silicones: "SILICONES",
        urethane: "UREICS",
        vinyl: "VINYLS",
        polyester_melamine: "POLYESTERS / MELAMINE"
     },
     dilutions: {
        water: "WATER BASED",
        dust: "DUST",
        solvent: "SOLVENT",
        noSolvent: "SOLVENT FREE"
     },
     dryings: {
        air: "1K AIR",
        catalyzed: "2K CATALYZED",
        electrophoretic: "ELECTROPHORETIC",
        oven: "OVEN",
        curing: "UV CURING"
     },
     appMethods: {
        brush: "brush",
        roller: "roller",
        sprinkle: "air spray",
        airMix: "air mix",
        airLess: "airless",
        spatula: "knife",
        sponge: "sponge",
        rollerCoating: "roller coating",
        curtainCoater: "uv dryer",
        immersion: "immersion",
        trowel: "trowel",
        cloth: "cloth",
        conventional: "air spray",
        flowCoatings: "flow coatings",
        hplv: "hplv",
        electrostaticSpray: "disc & electrostatics",
        electrophoresisNS: "ns electrophoresis  ",
        anode: "ANODIC ELECTROPHORESIS IMMERSION",
        cathode: "CATHODIC ELECTROPHORESIS IMMERSION"
     }
  }
};
