export const product = {
  um: "U.M.",
  status: "Estado",
  statusValue: {
   NULL: "Todos",
   REMOVED: "Eliminado",
   AVAILABLE: "Adquirible"
  },
  inStock: "Reserva",
  tds: "TDS",
  PresentInStock: "Presente en Recambio",
  searchProducts: "Búsqueda de productos",
  productCode: "Código de producto",
  productNameCode: "Nombre o código de producto o descripción",
  brand: "Marca",
  color: "Color",
  serialCode: "Código de serie",
  colorCode: "Código de color",
  packaging: "Envase",
  serial: "Serie",
  pkg: "Env.",
  pkgAvailable: "DISPONIBLE EN ALMACÉN",
  area: "Sector",
  typology: "Tipo de producto",
  family: "Familia química",
  dilution: "Dilución",
  dilution_water: "Dilución con agua",
  dilution_solvent: "Dilución con solvente",
  discount: "Descuento",
  drying: "Secado",
  dryingIcon: "secado",
  appMethod: "Método Aplicativo",
  results: "VOLVER A LOS RESULTADOS DE LA BÚSQUEDA",
  noResults: "Ningún resultado entre los productos de listín/recambio, pedir información a alcea@alcea.com o ver la página de contactos",
  characteristics: "Características",
  downloadTds: "DESCARGAR LA FICHA TÉCNICA CON FORMATO PDF",
  loginToDownloadTds: "AUTENTICADOS PARA DESCARGAR LA FICHA TÉCNICA",
  noTdsFound: "Ficha técnica ausente",
  code: "Código",
  description: "Descripción",
  specificWeight: "Peso específico",
  unitValue: "Cant. Unitaria",
  months: "meses",
  usage: "uso",
  internal_use: "uso interior",
  external_use: "uso exterior",
  external_internal_use: "uso interior/exterior",
  shelf: "conservabilidad",
  squareMeters: "m2",
  squareMetersPerLitre: "m2/l",
  squareMetersPerKilogram: "m2/kg",
  unitPrice: "Precio unitario",
  finalUnitPrice: "Precio un. final",
  discounts: "Descuentos",
  priceListCode: "Listín",
  unitQty: "Cant. uni.",
  rowTotalPrice: "Tot. renglón",
  product: "Producto",
  preferred: "Preferidos",
  quantity: "Cantidad",
  notAssigned: "No asignado",
  modal: {
   downloadTds: "Elegir en qué idioma se desea descargar la TDS"
  },
  priceList: {
   PL_RIV: "RIV",
   PL_CPS: "CPS",
   PL_IND: "IND",
   PL_SM_PASTE: "SM pastas",
   PL_SM_CONV: "SM conv",
   PL_GE_PASTE: "GE pastas",
   PL_GE_CONV: "GE conv",
   PL_LEG: "LEG",
   PL_9: 9,
   PL_DIL: "DIL",
   PL_IMB: "IMB",
   PL_ATT: "ATT"
  },
  combos: {
   interested: {
    metalIndustry: "Industria metalúrgica",
    woodIndustry: "Industria de la madera",
    coilCoating: "Revestiminto de Bobina",
    construction: "Construcción",
    homeLine: "Línea hogar",
    tintometrics: "Tintometría"
   },
   areas: {
    edil: "CONSTRUCCIÓN (MURO)",
    metal: "INDUSTRIA METALÚRGICA",
    wood: "INDUSTRIA DE LA MADERA",
    edil_metal_wood: "CONSTRUCCIÓN-METAL-MADERA",
    coil: "BOBINA",
    plastic: "PLÁSTICO TODOS LOS SECTORES",
    paste: "PASTAS, CONVERTIDORES, CATALIZADORES PARA REVENTA",
    corrosion: "ANTICORROSIÓN, NAVAL, ETC"
   },
   typologies: {
    dye: "COLORES, PÁTINAS, TINTAS, CONCENTRADOS PARA MADERA",
    paste: "PASTAS CONCENTRADAS COLORANTES",
    background: "FONDOS E IMPRIMACIONES ANTICORROSIVAS Y NO ANTICORROSIVAS",
    intermediate: "INTERMEDIOS y MANO DE FONDO",
    washable: "LAVABLES",
    various: "VARIAS",
    catalyst: "CATALIZADORES",
    thinner: "DILUYENTES",
    converter: "CONVERTIDORES",
    dust: "POLVOS TERMOENDURECIBLES",
    back: "BACK",
    impregnant: "IMPREGNANTES",
    coat: "CAPA SUPERIOR",
    raw_material: "MATERIAS PRIMAS"
   },
   families: {
    acrylic: "ACRÍLICOS",
    pvdf: "PVDF / FLUORURADOS",
    epoxy: "EPOXÍDICOS",
    ketone: "QUETÓNICAS",
    polyester: "POLIÉSTERES / ISOCIÁNICOS",
    alkyl: "ALQUÍDICOS",
    nitrocellulose: "NITROCELULÓSICOS",
    unsaturated: "POLIÉSTERES INSATURADOS",
    clorocianuro: "CLOROCIANÚRICOS",
    phenolic: "FENÓLICAS",
    silicones: "SILICÓNICOS",
    urethane: "URÉICOS",
    vinyl: "VINÍLICOS",
    polyester_melamine: "POLIÉSTERES / MELAMÍNICOS"
   },
   dilutions: {
    water: "AL AGUA",
    dust: "POLVO",
    solvent: "AL DISOLVENTE",
    noSolvent: "SIN SOLVENTE"
   },
   dryings: {
    air: "1K AIRE",
    catalyzed: "2K CATALIZADO",
    electrophoretic: "ELECTROFORÉTICO",
    oven: "DE HORNO",
    curing: "ENDURECIMIENTO UV"
   },
   appMethods: {
    brush: "brocha",
    roller: "rodillo",
    sprinkle: "pulverización con aire",
    airMix: "mezcla con aire",
    airLess: "sin aire",
    spatula: "espátula",
    sponge: "esponja",
    rollerCoating: "revestimiento con rodillo",
    curtainCoater: "barnizadora a cortina",
    immersion: "inmersión",
    trowel: "fratás",
    cloth: "paño",
    conventional: "pulverización con aire",
    flowCoatings: "revestimiento por aspersión",
    hplv: "hplv",
    electrostaticSpray: "disco y electrostática",
    electrophoresisNS: "electroforesis ns",
    anode: "INMERSIÓN ELECTROFORESIS ANÓDICA",
    cathode: "INMERSIÓN ELECTROFORESIS CATÓDICA"
   }
  }
};
