<template>
  <div>

    <!-- new invite -->
    <div class="pc-subtitle">
    </div>

    <b-card>
      <div class="row justify-content-center my-2">
        <b-form-group
            id="name"
            :invalid-feedback="validationError('inviteeFirstName')"
            class="col-md">
          <b-form-input
              class="form-control"
              id="firstname"
              ref="firstname"
              v-model.trim="form.inviteeFirstName"
              :placeholder="$t('formUser.firstName') + ' *'"
              :state="validationState('inviteeFirstName')"
          ></b-form-input>
        </b-form-group>
        <b-form-group
            class="col-md"
            :invalid-feedback="validationError('inviteeLastName')">
          <b-form-input
              class="form-control"
              id="lastName"
              ref="lastName"
              v-model.trim="form.inviteeLastName"
              :placeholder="$t('formUser.lastName') + ' *'"
              :state="validationState('inviteeLastName')"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="row justify-content-center my-2">
        <b-form-group
            class="col-md-12"
            :invalid-feedback="validationError('inviteeEmail')">
          <b-form-input
              class="form-control"
              id="email"
              ref="email"
              v-model.trim="form.inviteeEmail"
              type="email"
              :placeholder="$t('formUser.email') + ' *'"
              :state="validationState('inviteeEmail')"
          ></b-form-input>
        </b-form-group>
      </div>

      <b-button class="mx-1" @click="invite">
        {{ $t("actions.invite") }}
      </b-button>

      <b-button variant='link' class="mx-1" @click="$router.push('/profile-customer/invite-list')">
        {{ $t("actions.close") }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import Validator from "../../services/validation-service/validator-service"

export default {
  name: "Invite",

  data() {
    return {
      form: {
        inviteeEmail: "",
        inviteeFirstName: "",
        inviteeLastName: "",
      },
      invalid: [],
    }
  },

  methods: {
    back() {
      if (this.feedbackStatus === "Success") {
        this.$router.push("/profile-customer/invite-list")
      }
    },

    clearStatus() {
      this.feedbackStatus = null
      this.feedbackMessage = ""
    },

    validationState(contextRef) {
      return this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) ? false : null
    },

    validationError(contextRef) {
      if (this.invalid.length > 0) {
        let find = this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) || null
        return find ? this.$i18n.t(find.error) : null
      }
    },

    invite() {
      let validation = Validator.validateForm("invitation", this.form)
      this.invalid = validation.invalid
      if (this.invalid.length <= 0) {
        this.sendRequest()
      }
    },

    async sendRequest() {
      this.$store.dispatch("postInvite", this.form)
          .then(() => {
            this.$router.push('/profile-customer/invite-list?sendSuccess=true')
          })
          .catch((error) => {
            console.error(error)
            this.$router.push('/profile-customer/invite-list?sendFail=true')
          })
    },
  },
}
</script>
