export const titles = {
  welcome: "¡Bienvenido a ADW!",
  login: "Login",
  faq: "Video Tutorial",
  profile: "Perfil",
  profileCustomer: "Perfil de cliente",
  profileUser: "Perfil de usuario",
  register: "Registro",
  forgotPassword: "Recuperar contraseña",
  newPassword: "Nueva contraseña",
  confirmRegistration: "Confirmar registro",
  privateArea: "Área Privada",
  personalInformation: "Datos Personales",
  companyInformation: "Datos de la Empresa",
  notificationEmail: "Notificaciones de acceso",
  anagraphicInfo: "Datos de identificación",
  addresses: "Direcciones",
  contacts: "Contactos",
  bankInformation: "Datos bancarios",
  confirmRequest: "Confirmar solicitud",
  becomeCustomerInvitation: "¿Quiere ser un cliente Alcea?",
  becomeCustomer: "Conviértase en cliente",
  becomeCustomerText: "Rellenar la solicitud para convertirse en un nuestro cliente",
  confirmNewRequest: " tendrá acceso a todos los productos de nuestros catálogos y podrá solicitar pedidos y ofertas directamente desde su área reservada.",
  invitationsUser: "¿Desea crear una nueva solicitud?",
  invitationsCustomer: "Invitaciones recibidas para convertirse en cliente",
  newInvite: "Invitaciones enviadas",
  invitations: "Nueva Invitación",
  selectClient: "Invitaciones",
  newRequest: "Seleccionar un cliente para administrar pedidos y ofertas",
  businessTypology: "Solicitudes de nuevos clientes",
  resalesInfo: "Tipo de Empresa",
  outlook: "Información de reventa",
  areaAgent: "Previsiones",
  editCustomer: "Mis clientes",
  orderedProducts: "Solicitud de Modificación de Perfil",
  customerRequests: "Productos pedidos",
  backToAgent: "Solicitud de pedidos / ofertas",
  orders: "Cambiar cliente",
  cart: "Pedidos",
  notAssigned: "Carrito",
  checkoutOrder: "Precio no asignado",
  checkoutOffer: "Abandonar solicitud de pedido",
  productSearch: "Abandonar solicitud de oferta",
  contactUs: "Buscar productos"
};
