<template>
  <section>
    <div class="private-area-row-150 text-uppercase bb">
      <h3>{{ $t("formUser.personalInformation") }}</h3>
    </div>

    <div class="private-area-box">
      <b-form-group label-cols-md="3"
                    label-for="name"
                    :label="$t('formUser.firstName') + (editMode ? ' *' : '')"
                    :invalid-feedback="validationError('firstName')">
        <b-form-input v-if="editMode"
                      type="text"
                      id="name"
                      name="name"
                      v-model.trim="form.firstname"
                      :state="validationState('firstname')">
        </b-form-input>
        <label class="col-form-label" v-else>{{ form.firstname }}</label>
      </b-form-group>

      <b-form-group label-cols-md="3"
                    label-for="lastName"
                    :label="$t('formUser.lastName') + (editMode ? ' *' : '')"
                    :invalid-feedback="validationError('lastname')">
        <b-form-input v-if="editMode"
                      type="text"
                      id="lastName"
                      name="lastName"
                      v-model.trim="form.lastname"
                      :state="validationState('lastname')">
        </b-form-input>
        <label class="col-form-label" v-else>{{ form.lastname }}</label>
      </b-form-group>

      <b-form-group label-cols-md="3"
                    label-for="telephone"
                    :label="$t('formUser.telephone') + (editMode ? ' *' : '')">
        <telephone-component v-if="editMode"
                             class="m-0"
                             id="telephone"
                             ref="telephone"
                             :formType="form.type"
                             :invalid="invalid"
                             :telephoneProp="telephoneProp"
                             v-model="form.telephone">
        </telephone-component>
        <label class="col-form-label" v-else>{{ form.telephone }}</label>
      </b-form-group>

      <b-form-group label-cols-md="3"
                    label-for="country"
                    :label="$t('formUser.country') + (editMode ? ' *' : '')"
                    :invalid-feedback="validationError('country')">
        <b-form-select v-if="editMode"
                       v-model.trim="form.country"
                       id="country"
                       name="country"
                       :state="validationState('country')">
          <b-select-option selected disabled :value="null">
            {{ $t("formUser.country") }} *
          </b-select-option>
          <b-select-option v-for="country in countries"
                           :value="country.value"
                           :key="country.key">
            {{ country.label }}
          </b-select-option>
        </b-form-select>
        <label class="col-form-label" v-else>{{ getText(countries, form.country, 'label') }}</label>
      </b-form-group>

      <b-form-group label-cols-md="3"
                    label-for="zipCode"
                    :label="$t('formUser.zipCode') + (editMode ? ' *' : '')"
                    :invalid-feedback="validationError('zipCode')">
        <b-form-input v-if="editMode"
                      type="text"
                      id="zipCode"
                      name="zipCode"
                      v-model.trim="form.zipCode"
                      :state="validationState('zipCode')">
        </b-form-input>
        <label class="col-form-label" v-else>{{ form.zipCode }}</label>
      </b-form-group>

      <b-form-group label-cols-md="3"
                    label-for="interested"
                    style="border-bottom: none"
                    :label="$t('formUser.intersted') + (editMode ? ' *' : '')">
        <b-form-select v-if="editMode"
                       v-model.trim="form.interested"
                       required
                       id="interested"
                       name="interested"
                       :state="validationState('interested')">
          <b-select-option selected disabled value="null">
            {{ $t("formUser.intersted") }} *
          </b-select-option>
          <b-select-option v-for="sector in sectors" :value="sector.value" :key="sector.value">
            {{ $t(sector.text) }}
          </b-select-option>
        </b-form-select>
        <label class="col-form-label" v-else>{{ getText(sectors, form.interested) }}</label>
      </b-form-group>
    </div>
  </section>
</template>

<script>
import TelephoneComponent from "./global-components/telephone-component.vue";

export default {
  name: "PrivateAreaPrivate",

  components: {
    TelephoneComponent
  },

  props: {
    formProp: Object(),
    user: Object(),
    countries: Array(),
    sectors: Array(),
    invalidlist: Array(),
    telephoneProp: Object(),
    editMode: Boolean,
  },

  computed: {
    telephone: {
      get() {
        return this.$refs.telephone.telephone;
      },
    },

    invalid: {
      get() {
        return this.invalidlist;
      },
      set(invalidated) {
        this.$emit("input", invalidated);
      },
    },

    form: {
      get() {
        return this.formProp;
      },
      set(form) {
        this.$emit("input", form);
      }
    }
  },

  methods: {
    getText(list, value, field) {
      if (value) {
        let item = list.filter((l) => l.value.toLowerCase() === value.toLowerCase());
        if (item.length > 0) {
          return this.$t(item[0][field ? field : 'text']);
        }
      }
      return '';
    },

    validationState(contextRef) {
      return this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) ? false : null;
    },

    validationError(contextRef) {
      if (this.invalid.length > 0) {
        let find = this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    }
  }
};
</script>
