import router from "../../router";
import store from "../../store/index";

const unauthStatus = [401, 403];
class ErrorInterceptor {
  //he interceppt
  isUnAuth(error) {
    return unauthStatus.includes(error.status);
  }
  refeshToken(error) {
    if (this.isUnAuth(error)) {
      store.dispatch("logout");
      router.push("/login");
    }
    return error;
  }
  helper(error) {
    if (error.response) {
      if (error.response.status === 401) {
        console.log("UNAUTHORIZED 401");
      }
      if (error.response.status === 403) {
        //icaso di temptCode sbagliato
        console.log("FORBIDDEN 403");
      }
      if (error.response.status === 400) {
        //validazione sbagliata
        console.log("BAD REQUEST 400");
      }
      if (error.response.status === 404) {
        console.log("NOT FOUND 404");
      }
      if (error.response.status === 409) {
        console.log("CONFLICT 409");
      }
      if (error.response.status === 500) {
        //TODO intercept when error:GENERAL & repeat request
        console.log("SERVER ERROR 500", error.response.data.message);
      }
      return error.response;
    }
    if (error.message == "Network Error") {
      return "NetworkError";
    }
    if (error.message.includes('TypeError')) {
      return "TypeError";
    }
    return error.message;
  }
}

export default new ErrorInterceptor();
