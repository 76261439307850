import RegistrationService from "../services/api-services/registration-service";

const user = localStorage.getItem("user");
const guest = localStorage.getItem("guest");
const initialState = user
  ? { status: { registered: true }, guest: user.username, resetPassword: false }
  : { status: { registered: false }, guest: guest, resetPassword: false };

export const registrationUser = {
  state: initialState,
  actions: {
    forgotPassword({ commit }, guest) {
    return  RegistrationService.resetPassword(guest).then(
        (response) => {
          guest = localStorage.getItem("guest");
          commit("resetPassword", guest);
          return Promise.resolve(response);
        },
        (error) => {
          console.log(error);
          return Promise.reject(error);
        }
      )
    },
    register({ commit }, userform) {
      console.log(userform);
      return RegistrationService.register(userform).then(
        (response) => {
          commit("registerSuccess");
          console.log("registerSuccess");
          console.log(response.data);
          return Promise.resolve(response);
        },
        (error) => {
          console.log(error);
          commit("registerFailure");
          console.log("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    confirmRegister({ commit }, user) {
      return RegistrationService.confirmRegister(user).then(
        (response) => {
          commit("registerCompleted");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    newPassword({ commit }, user) {
      return RegistrationService.updatePassword(user).then(
        (response) => {
          commit("resetPasswordComplete");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("resetPassword");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    registerSuccess(state) {
      state.status.registered = true;
      state.status.resetPassword = true;
    },
    registerFailure(state) {
      state.status.registered = false;
    },
    registerCompleted(state) {
      state.status.registered = true;
      state.status.resetPassword = false;
    },
    resetPassword(state, guest) {
      state.guest = guest;
      state.status.resetPassword = true;
    },
    resetPasswordComplete(state) {
      state.status.resetPassword = false;
    },
  },
};
