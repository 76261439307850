export const instructions = {
  checkout: {
    shippingAddress: "Selezionare l’indirizzo di spedizione per questo ordine.",
    customerOrderCode: "Indicare se necessario un codice ordine personalizzato associato a questa richiesta.",
    deliveryDateRequested:
      "Indicare se necessario una data preferibile di evasione dell'ordine, se non definita l’ordine verrà consegnato nella data migliore possibile.",
    acceptMultipleShipping:
      "Indicare se accettare più spedizioni in acconto, in caso contrario si riceverà l’ordine in una sola volta.",
    shippingNote:
      "Inserire se utile le note sulla spedizione, queste note saranno inserite nel documento di trasporto.",
    genericNote:
      "Inserire se necessario delle note generiche relative all’ordine.",
    agentNote:
      "Note del commerciale (saranno visibili solo al personale Alcea).",
    offerNote:
      "Motivazione della richiesta di offerta (es. prezzo mancante, quantità particolari)",
  },
  formCustomer:{
    vatNumber:'Per cambiare la P.IVA contattare Alcea.'
  },
  filters:"Filtri"
};
