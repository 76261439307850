export const actions = {
  ok: "OK",
  logout: "Logout",
  loginToProceed: "Login to proceed",
  loginCompleted: "Login completed",
  retrievePassword: "Retrieve it",
  signUp: "Sign up",
  backToLogin: "Back to login",
  update: "Update",
  updateProfile: "Modify profile",
  create: "Create",
  access: "Access",
  send: "Send",
  saveDraft: "Save draft",
  next: "Next",
  back: "Back",
  cancel: "Cancel",
  newPassword: "Send",
  confirmRegistration: "Complete Registration",
  confirm: "Confirm",
  compileRequest: "Fill in request",
  continue: "Continue",
  newRequest: "New request",
  associate: "Associate",
  associateToExisting: "Click here",
  inviteNewUser: "Invite a new user to become a customer",
  updateToCustomer: "Become a Customer",
  goToClientAssignement: "Use Customer Code",
  understand: "I understand",
  delete: "Delete",
  accept: "Accept",
  refuse: "Refuse",
  close: "Close",
  invite: "Send invitation",
  showAllCustomer: "See all customers",
  edit: "Edit",
  goToProfile: "Go to profile",
  search: "Search",
  searchProduct: "Search Product",
  addSelectedToChart: "Add selected items to cart",
  addToChart: "Add to cart",
  clear: "Clear",
  deleteSelected: "Deleted selected items",
  updateCart: "Update cart",
  offerRequest: "Request offer",
  orderRequest: "Request order",
  add: "Add",
  buy: "Buy",
  showProduct: "Show product",
  itemToCart: "Item selected to cart",
  itemsToCart: "Items selected to cart",
  requestNewOrder: "Request new order",
  requestNewOffer: "Request new offer",
  requestOrder: "Request order",
  redoOrderRequest: "Redo order request",
  backToCart: "Back to cart",
  showFilters: "Show search filters",
  hideFilters: "Hide search filters",
};
