<template>
  <div class="container">
    <form-password
        :invalidQuery="invalidQuery"
        :feedbackStatus="feedbackStatus"
        :feedbackMessage="feedbackMessage"
        :title="title"
        @send="postConfirm">
    </form-password>
  </div>
</template>

<script>
import FeedbackAlert from "../components/feedback-alert.vue";
import formPassword from "../components/form-password.vue";
import ErrorManager from "../services/errors-service/errors-manager";

export default {
  components: { formPassword, FeedbackAlert },
  data() {
    return {
      invalidQuery: false,
      feedbackStatus: '',
      feedbackMessage: '',
      title: 'newPassword'
    };
  },

  mounted() {
    if (this.$route.query.link) {
      this.invalidQuery = true;
    }
  },

  methods: {
    async postConfirm(user) {

      this.feedbackStatus = "Sending";
      this.feedbackMessage = '';
      await this.$store
          .dispatch("newPassword", user)
          .then((response) => {
            console.log(response);
            this.$router.push("/login?newPassword=" + user.username);
          })
          .catch((error) => {
            this.feedbackStatus = "FailDismissable";
            this.feedbackMessage = ErrorManager.feedback(error) == 'Unauthorized' || ErrorManager.feedback(error) == 'BadRequest' ? 'UnauthorizedOrInvalid' : ErrorManager.feedback(error);

          });
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!to.query.username || !to.query.token) {
      next((vm) => {
        vm.invalidQuery = true;
        vm.feedbackStatus = "Fail";
        vm.feedbackMessage = vm.$i18n.t("UnauthorizedOrInvalid");
      });
    } else {
      next();
    }
  },
};
</script>
