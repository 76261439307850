export const retailer= {
  metal: "Metal",
  wood: "Wood",
  construction: "Construction Industry",
  type: "Resale type",
  bodywork: "Body shop",
  ownership: "owned-shop",
  shopWindows: "Number of windows",
  surface: "Area ",
  mq: "sqm",
  shop: "Shop "
};