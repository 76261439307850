export const requestStatus = {
  requestStatus: "Status Anfrage",
  agentRequestCustomerText: "Füllen Sie die Anfrage für Neukunden aus, um Zugriff auf alle Produkte in den Katalogen zu erhalten und Bestellungen und Angebote direkt für diese anzufordern.",
  becomeCustomerText: "Füllen Sie den Antrag aus, um unser Kunde zu werden, Zugriff auf alle Produkte in unseren Katalogen zu erhalten und Bestellungen und Angebote direkt aus Ihrem Kundenbereich anzufordern.",
  text: "Erforderlich, um Kunde zu werden",
  confirmNewRequest: "Möchten Sie die alte Anfrage löschen und eine neue erstellen?",
  NONE: "keine",
  DRAFT: "Entwurf",
  PENDING: "Ausstehend",
  ACCEPTED: "Angenommen:",
  REJECTED: "Abgewiesen",
  CLOSED: "Geschlossen",
  EXPIRED: "Abgelaufen",
  NULL: "Status wählen",
  null: "Status wählen",
  customerExisting: {
     title: "Ist Ihr Unternehmen bereits Kunde von Alcea?",
     titleShort: "Kunden zuordnen",
     text: "Zur Ausführung der direkten Zuordnung über die Kundennummer"
  },
  acceptedText: "Herzlichen Glückwunsch, Ihre Anfrage wurde angenommen. Bitte auf den Link unten klicken, um den Vorgang abzuschließen.",
  rejectedText: "Ihre Anfrage wurde leider abgewiesen. Bitte auf den Link unten klicken, um zum Anfangsstatus zurückzukehren."
};
