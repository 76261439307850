import UserService from "../services/api-services/user-service";
import AgentService from "../services/api-services/agent-service";
import CommercialService from "../services/api-services/commercial-service";
import { getCustomerForm } from "../models/customer";

const initialState = {
  request: { status: "NONE", info: null },
};

export const request = {
  state: initialState,

  actions: {
    updateCustomerRequest({ commit }, formUpdated) {
      return UserService.putNewCustomerRequestDraft(formUpdated).then(
        (response) => {
          commit("requestUpdateSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestUpdateFailure");
          return Promise.reject(error);
        }
      );
    },

    postCustomerRequest({ commit }, formCustomer) {
      return UserService.postNewCustomerRequestPending(formCustomer).then(
        (response) => {
          commit("requestUpdateSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestUpdateFailure");
          return Promise.reject(error);
        }
      );
    },

    postExistingCustomerAssociation({ commit }, formRequest) {
      return UserService.postExistingCustomerRequestPending(
        formRequest
      ).then(
        (response) => {
          commit("requestUpdateSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestUpdateFailure");
          return Promise.reject(error);
        }
      );
    },

    newRequest({ commit }) {
      return UserService.putNewCustomerRequestDraft(
        getCustomerForm(true, null, true)
      ).then(
        (response) => {
          commit("requestUpdateSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestUpdateFailure");
          return Promise.reject(error);
        }
      );
    },

    getRequest({ commit }) {
      return UserService.getMyRequest().then(
        (response) => {
          commit("requestUpdateSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          if (error.status === 404) {
            commit("clearOut");
            return Promise.resolve(error);
          } else {
            commit("requestUpdateFailure");
            return Promise.reject(error);
          }
        }
      );
    },

    agentUpdateCustomerRequest({ commit }, formUpdated) {
      return AgentService.draftCustomerRequest(formUpdated).then(
        (response) => {
          commit("requestUpdateSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestUpdateFailure");
          return Promise.reject(error);
        }
      );
    },

    commercialUpdateCustomerRequest({ commit }, formUpdated) {
      return CommercialService.draftCustomerRequest(formUpdated).then(
        (response) => {
          commit("requestUpdateSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestUpdateFailure");
          return Promise.reject(error);
        }
      );
    },

    agentPostCustomerRequest({ commit }, formCustomer) {
      return AgentService.postCustomerRequest(formCustomer).then(
        (response) => {
          commit("clearOut");
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestUpdateFailure");
          return Promise.reject(error);
        }
      );
    },

    commercialPostCustomerRequest({ commit }, formCustomer) {
      return CommercialService.postCustomerRequest(formCustomer).then(
        (response) => {
          commit("clearOut");
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestUpdateFailure");
          return Promise.reject(error);
        }
      );
    },

    agentDeleteRequest({ commit }, id) {
      return AgentService.deleteCustomerRequest(id).then(
        (response) => {
          commit("clearOut");
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestUpdateFailure");
          return Promise.reject(error);
        }
      );
    },

    commercialDeleteRequest({ commit }, id) {
      console.log(id);

      return CommercialService.deleteCustomerRequest(id).then(
        (response) => {
          commit("clearOut");
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestUpdateFailure");
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    clearOut(state) {
      state.request.info = null;
      state.request.status = "NONE";
    },

    requestUpdateSuccess(state, userdata) {
      state.request.status = userdata.status;
      state.request.info = userdata;
    },

    successBecomeCustomer(state, user) {
      state.request.status = "CLOSED";
      state.customerinfo = user;
    },

    updateFailure() {
    },

    requestUpdateFailure() {
    },
  },

  getters: {
    getRequestStatus: (state) => state.request.status,
  },
};
