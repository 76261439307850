<template>
  <b-form-select
      v-model="$i18n.locale"
      @change="updateUserLanguage($event)">
    <b-select-option value="it">{{ $t("labels.italian") }}</b-select-option>
    <b-select-option value="en">{{ $t("labels.english") }}</b-select-option>
    <b-select-option value="de">{{ $t("labels.german") }}</b-select-option>
    <b-select-option value="es">{{ $t("labels.spanish") }}</b-select-option>
    <!-- <b-select-option value="fr">{{ $t("labels.french") }}</b-select-option>
    <b-select-option value="pl">{{ $t("labels.polish") }}</b-select-option>
    <b-select-option value="ru">{{ $t("labels.russian") }}</b-select-option> -->
  </b-form-select>
</template>

<script>
import { mapState } from "vuex"

export default {
  nome: "LocaleSwitcher",

  computed: {
    ...mapState({
      userType: (state) => state.user.userinfo.type,
      loggedIn: (state) => state.auth.status.loggedIn,
    }),
  },

  methods: {
    updateUserLanguage(language) {
      //console.log(this.loggedIn)
      if (!this.loggedIn) {
        return
      }
      let toUpdate = this.userType === "P" ? "updateUserPrivate" : "updateUserCompany"
      this.postForm(toUpdate, language)
    },

    async postForm(toUpdate, language) {
      await this.$store
          .dispatch(toUpdate, { language })
          .then((user) => {
            console.debug("Language updated", user.data.language)
            this.$store.dispatch("refreshToken")
          })
          .catch((error) => {
            console.error("Language update error")
            console.error(error)
          })
    },
  },
}
</script>
