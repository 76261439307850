import http from "@/util/http-common";

class TdsService {
  async getTdsLanguages(serial, color) {
    return http.get(
      `tds/versions/last/available-languages?serial=${serial}&color=${color}`
    );
  }

  async getTds(versionId) {
    return http.get(`tds/pdf/version/${versionId}`, {responseType: 'arraybuffer'});
  }
}
export default new TdsService();
