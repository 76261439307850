<template>
  <b-container fluid tag="footer">
    <b-row class="align-items-center">
      <b-col cols="12" md="4" class="text-md-left text-center">
        <img src="@/assets/img/ADW_Logo_B.jpg" alt="ADW" width="200"/>
      </b-col>
      <b-col md="4" class="adw-footer text-center">
        <address>
          {{ $t("labels.adw1") }}<br/>
          {{ $t("labels.adw2") }}<br/>
          {{ $t("labels.adw3") }}<br/>
          Email: <a href="mailto:info@alcea.com" target="_blank"  class="text-dark" title="Email">info@alcea.com</a> -
          PEC: <a href="mailto:alcea@pec.collabra.it " target="_blank"  class="text-dark" title="PEC">alcea@pec.collabra.it </a><br/>
          WEB: <a href="https://www.alcea.com" target="_blank"  class="text-dark" title="Alcea">www.alcea.com</a>
        </address>
      </b-col>
      <b-col md="4" class="adw-footer text-md-right text-center">
        <a :href="$t('iubenda.linkPrivacy')" target="_blank" class="text-dark" title="Privacy Policy">Privacy Policy</a><br/>
        <a :href="$t('iubenda.linkCookies')" target="_blank" class="text-dark" title="Cookies Policy">Cookies Policy</a><br/>
        <a :href="$t('title.faq')" class="text-dark" title="Faq e Demo">Video Tutorial</a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "app-footer",
  data () {
    return {
    }
  },
  computed: {
    privacyLink () {
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/custom-vars.scss";

// FOOTER

footer {
  padding: 15px 0;
  border-top: var(--border);
}

.adw-footer {
  address {
    font-size: 12px;
  }
}

@include respond-above(sm) {
  footer {
    //min-height: 250px;
    padding: 40px 40px 20px 40px;
    border: var(--border);
  }

  .adw-footer {
    font-size: $font-size-sm;
    address {
      font-size: normal;
    }
  }
}

</style>
