export const formCustomer = {
  vatNumber: "Número de IVA",
  noPrefix: "ningún prefijo",
  sdiCode: "Código SDI",
  pec: "PEC (correo electrónico certificado)",
  streetAddress1: "Dirección Renglón 1",
  streetAddress2: "Dirección Renglón 2",
  city: "Ciudad",
  billing: "Factura",
  transportDocument: "DDT",
  msds: "MSDS",
  testSheets: "Ensayo",
  businessAddress: "Sede administrativa",
  legalAddressSameAsbusinessAddress: "¿La sede legal coincide con la sede administrativa?",
  legalAddress: "Sede legal",
  shippingAddressesSameAsbusinessAddress: "¿La sede de expedición coincide con la sede administrativa?",
  shippingAddresses: "Sede de expedición",
  addAddress: "Agregar dirección",
  purchasingContact: "Contacto Compras",
  administrativeContact: "Contacto Administración",
  documentsToRecive: "Documentos para enviar a este contacto",
  documentsRecipient: "Destinatarios de documentos",
  additionalContact: "Contacto Adicional",
  additionalContacts: "Contactos Adicionales",
  billingRecipient: "Destinatario de factura",
  transportDocumentRecipient: "Destinatario de documentos de transporte",
  msdsRecipient: "Destinatario de fichas de seguridad",
  testSheetsRecipient: "Destinatario de fichas de ensayo",
  bankName: "Banco",
  iban: "IBAN",
  customerCode: "Código de Cliente",
  customerCodeShort: "Cliente",
  customerCodeText: "Si dispone del código de cliente",
  marketSegmentation: " rellene el campo para solicitar ser asociado directamente",
  marketSegmentationPlaceholder: "Segmentación",
  resellingType: "Naturaleza de pertenencia",
  marketCompetitor: "Naturaleza",
  marketCompetitorPlaceholder: "Competidor",
  totalConsumption: "Empresa competidora",
  totalConsumptionPlaceholder: "Consumo total ",
  alceaPotential: "Consumo total en €",
  alceaPotentialPlaceholder: "Potencial Alcea",
  tntNextRequired: "Potencial en € para Alcea"
};
