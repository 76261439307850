export const formUser = {
  gender: {
      mr: "Mr",
      mrs: "Mrs",
      select: "Mr/Ms"
  },
  name: "Name",
  firstName: "First name",
  lastName: "Last name",
  country: "State",
  zipCode: "Postal Code",
  province: "Province",
  sectorInterest: "Sector of interest",
  private: "Private",
  company: "Agency",
  telephone: "Phone",
  phonePrefix: "Prefix",
  repeatPasswod: "Repeat the password",
  authorizeGdpr: "I authorize the processing of data according to the \"Information on the processing of personal data for customers and suppliers",
  textLinkGdpr: "pursuant to art. 13 Legislative Decree no. 196/2003 and EU European Regulation n. 679/2016\"",
  subscribeToNewsletter: "I want to be updated on Alcea news through the newsletter",
  textLinkNewsletterGdpr: "pursuant to art. 13 of EU Regulation 679/2016",
  authorizeCommercial: "I authorize the use of my personal data for market research",
  notificationMail: "You haven't turned on email login notifications yet!<br/>Do you want to activate them now?<br/><br/><b>Note: you can change this setting from your profile at any time</b>",
  businessName: "Business name",
  businessWebsite: "Website",
  businessType: "Business type",
  businessRole: "Function",
  businessDepartment: "Department",
  personalInformation: "Personal informations",
  companyInformation: "Company Information",
  notificationMailSwitch: "Receive email alerts at every login",
  email: "Email address",
  repeatEmail: "Repeat email address",
  intersted: "Interest",
  null: {
      country: "State",
      intersted: "Interest",
      businessRole: "Role"
  }
};
