<template>
  <b-container fluid>
    <template v-if="showMenu">
      <!-- menu -->
      <b-row class="ps-selector">
        <b-col cols="12" md="6" class="px-0">
          <b-breadcrumb :items="bradcrumbItems"></b-breadcrumb>
        </b-col>
        <b-col cols="12" md="6" class="px-0">
          <!-- back + customer -->
          <products-search-customer >
            <template v-slot:title>
              <span class="h2 text-uppercase m-0">{{ $t("actions.back") }}</span>
            </template>
          </products-search-customer>
        </b-col>

        <!-- private (B2C) -->
        <b-col cols="12" class="b2c" @click.stop="goToB2C"></b-col>

        <b-col cols="12" class="px-0" v-if="this.loggedIn && this.userRole === 'IT'">
          <b-container fluid>

            <!-- upload file -->
            <template >
              <b-form-file v-model="file"
                          :state="Boolean(file)"
                          :placeholder='$t("productsSearch.filePlaceholder")'
                          :drop-placeholder='$t("productsSearch.fileDropPlaceholder")'
                          :browse-text='$t("productsSearch.browse")'
                          accept=".xls,.xlsx">
              </b-form-file>

              <!-- send file -->
              <b-button v-if="file" class="my-2" @click.prevent="showCofirmUpload">
                {{ $t("productsSearch.upload") }}
              </b-button>
            </template>
          </b-container>
        </b-col>

        <b-col cols="12">
          <div class="title text-center">
            <h5>{{ $t("productsSearch.b2cTitle") }}</h5>
          </div>
        </b-col>

      </b-row>


      <!-- category -->
      <b-row class="bt">
      <b-col>
        <template v-for="(categoryList, index) in categories">
          <b-row class="bb br">
            <b-col v-for="category in categoryList" :key="category.name"
                   :class="categoryClass(index, categories, categoryList, category)"
                   @click="changeLevel(index, category)">
              <!--
              <template v-if="index === 1">
                <img :src="getPathIcon(category.name)" :alt="category.name" style="height: 100px"/>
              </template>
              -->
              <h5>{{ category.name }}</h5>
            </b-col>
          </b-row>
        </template>
      </b-col>
      </b-row>

    
        <!-- product -->
        <b-row class="p-0">
          <b-col cols="12" sm="6" md="4" lg="3" class="m-0 p-0" v-for="currentProduct in currentProducts" :key="currentProduct.code">
            <div @click="loadDetails(currentProduct)">
              <products-search-item class="ps-product" :productSelected="currentProduct"></products-search-item>
            </div>
          </b-col>
        </b-row>
    </template>


    <!-- modal to open the product detail -->
    <b-row class="p-0">
      <products-search-detail-w ref="productsSearchDetailW"
                                class="ps-detail-frame"
                                view-in-line="true"
                                origin-page="b2c"
                                @closeDetail="closeDetail">
      </products-search-detail-w>
    </b-row>

    
      <confirm-xls ref="confirmXlsModal" @confirm='uploadProductsFile'> </confirm-xls>
  </b-container>
</template>

<script>
import { mapState } from "vuex"
import ProductsSearchService from "@/services/api-services/products-search-service"
import ProductsSearchItem from "@/views/ProductsSearch/ProductsSearchItem"
import ProductsSearchCustomer from "@/components/products-search/products-search-customer";
import ProductsSearchDetailW from "@/views/ProductsSearch/ProductsSearchDetailW";
import ConfirmXls from "@/components/confirm-xls"

export default {
  name: "ProductsSearchB2C",

  components: {
    ProductsSearchDetailW,
    ProductsSearchItem,
    ProductsSearchCustomer,
    ConfirmXls
  },

  data() {
    return {
      showMenu: true,

      file: null,

      categories: [],
      currentProducts: [],
      confirmUploadShow: false,
      confirmUploadLoading: false,
      bradcrumbItems: [
        {
          text: this.$t('breadcrumb.productSearch'),
          to: { path: '/products-search' }
        },
        {
          text: this.$t('breadcrumb.private'),
          to: { path: '/products-search/b2c' }
        }
      ]
    }
  },

  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.status.loggedIn,
      userRole: (state) => state.auth.user.role
    })
  },

  methods: {
    uploadProductsFile() {
      console.log('uploading file')
      this.$refs.confirmXlsModal.loadConfirm(true)

      ProductsSearchService.uploadProductsFile(this.file).then((response) => {
        console.log('file uploaded, status:', response.status)
        this.file = null
        this.initStructure()
      }).catch((e) => {
        console.error(e)
      }).finally(() => {
        this.$refs.confirmXlsModal.loadConfirm(false)
        this.$refs.confirmXlsModal.closeConfirm()
      })

    },

    initStructure() {
      ProductsSearchService.getProducts().then((response) => {
        console.log('getProducts, length:', response.data.length)
        this.initLevel(response.data)
      })
    },

    initLevel(data) {
      this.categories[0] = data
      this.currentProducts = []
    },

    changeLevel(index, selection) {
      console.debug('changeLevel', 'index', index, 'selection.name', selection.name, 'selection.children.length', selection.children.length)

      // reset the selection from index
      this.categories.forEach((categoryList, i) => {
        categoryList.forEach((c) => {
          if (i >= index) {
            c.selected = false
          }
        })
      })

      // mark the current selection
      selection.selected = true

      // reset the tree if the selection is from a past category
      if (index < this.categories.length) {
        this.categories = this.categories.slice(0, index + 1)
      }

      // the selection reaches the products
      if (selection.children[0].children.length === 0) {
        this.currentProducts = selection.children
      }

      // otherwise, load the next level
      else {
        this.categories[this.categories.length] = selection.children
        this.currentProducts = []
      }
    },

    categoryClass(index, categories, categoryList, category) {
      const hasSelected = categoryList.find((c) => c.selected)
      return {
        'ps-category': true,
        'ps-selected': category.selected,
        'ps-not-selected': !category.selected && hasSelected && (index > 0 || hasSelected)
      }
    },

    getPathIcon(name) {
      const images = require.context('@/assets/products-search/b2c/', false, /\.jpg$/)
      try {
        return images("./" + name + ".jpg")
      } catch (err) {
        return ''
      }
    },

    levelBackground(name) {
      try {
        const img = require('@/assets/products-search/b2c/' + name + '.jpg')
        return 'background-image: url(' + img + ')'
      } catch (e) {
        return ''
      }
    },

    loadDetails(p) {
      this.showMenu = false

      const serialAndColor = p.code.replace('/', '-').split('-')
      let product = {
        id: {
          serial: serialAndColor[0],
          color: serialAndColor[1],
          pkg: ""
        }
      }

      this.$refs.productsSearchDetailW.gotoDetail(product, null, p.link)
    },

    closeDetail() {
      this.showMenu = true
    },

    showCofirmUpload() {
      this.$refs.confirmXlsModal.openConfirm()
    },
    closeUploadConfirm () {
      this.$refs.confirmXlsModal.closeConfirm()
    }
  },

  created() {
    this.initStructure()
  }
}
</script>
