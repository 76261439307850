<template>
  <b-container fluid>
    <!-- img selector -->
    <b-row class="ps-selector">
      <!-- private (B2C) -->
      <b-col class="search-heading-wrap" md="6" order-md="1" @click.stop="goToB2C">
        <img class="img-fluid" src="@/assets/products-search/product-search-private.jpg" alt="b2c"/>
      </b-col>

      <!-- private (B2C) -->
      <b-col md="6" order-md="3" class="link" @click.stop="goToB2C">
        <h1 class="h5">{{ $t('productsSearch.b2c') }}</h1>
      </b-col>

      <b-col md="6" order-md="5" class="ps-selector-buttons" v-if="!loggedIn">
        <b-row>
          <b-col cols="12" md="6" class="px-0">
            <adw-button @button-click="$router.push('/registration/P')">
                  <a>{{ $t("actions.signUp") }}</a>
            </adw-button>
          </b-col>

          <b-col cols="12" md="6" class="px-0">
            <adw-button class="w-100"
                  @button-click="$router.push('/login')">
                  <a>{{ $t("actions.access") }}</a>
            </adw-button>
          </b-col>
        </b-row>
      </b-col>

      <!-- professional (B2B) -->
      <b-col class="search-heading-wrap" md="6" order-md="2" @click.stop="goToB2B">
        <img class="img-fluid" src="@/assets/products-search/product-search-professional.jpg" alt="b2c"/>
      </b-col>

      <!-- professional (B2B) -->
      <b-col md="6" order-md="4" class="link" @click.stop="goToB2B">
        <h1 class="h5">{{ $t('productsSearch.b2b') }}</h1>
      </b-col>

      <b-col md="6" order-md="5" class="ps-selector-buttons" v-if="!loggedIn">
        <b-row>
          <b-col cols="12" md="6" class="px-0">
            <adw-button @button-click="$router.push('/registration/C')">
                  <a>{{ $t("actions.signUp") }}</a>
            </adw-button>
          </b-col>

          <b-col cols="12" md="6" class="px-0">
            <adw-button @button-click="$router.push('/login')">
                  <a>{{ $t("actions.access") }}</a>
            </adw-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import AdwButton from "@/components/global-components/adw-button";

export default {
  name: 'ProductsSearch',
  
  components: {
    AdwButton,
  },

  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.status.loggedIn,
      userType: (state) => state.auth.user.type
    })
  },

  methods: {
    goToB2C() {
      if (!this.loggedIn) {
        this.$router.push('/login')
        return
      }

      this.$router.push('/products-search/b2c')
    },

    goToB2B() {
      if (!this.loggedIn) {
        this.$router.push('/login')
        return
      }

      if (this.userType === 'P') {
        alert('Per accedere a questa sezione devi registrarti in ADW con un profilo Azienda')
        return
      }

      this.$router.push('/products-search/b2b')
    },
  },

  mounted() {
    const serialToSearch = this.$route.query.serial;
    const colorToSearch = this.$route.query.color;
    const showTds = this.$route.query.showTds;
    if (serialToSearch) {
      if (!this.loggedIn) {
        this.$router.push({ path: 'products-search/guest', query: { serial: serialToSearch, color: colorToSearch || null } })
      } else {
        this.$router.push({ path: 'products-search/b2b', query: { serial: serialToSearch, color: colorToSearch || null, showTds: showTds || false } })
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/custom-vars.scss";

// PRODUCTS SEARCH

.ps-selector {

  .link {
    text-align: center;
    padding: 0;
    font-size: larger;
    text-transform: uppercase;
    cursor: pointer;
    font-size: $font-size-base * 2;
    font-weight: bold;
    border-bottom: var(--border);
  }

  .search-heading-wrap {
    padding: 0;
    cursor: pointer;

    img {
      max-width: 100%;
      //border-radius: 50% 50% 0 0;
      border-bottom: var(--border);
    }
  }

  .link:hover {
    color: var(--primary);
  }

  .b2c {
    min-height: 300px;
    background-image: url('../../assets/products-search/product-search-private.jpg');
    background-position: center;
    background-size: cover;
  }


  .b2b {
    min-height: 300px;
    background-image: url('../../assets/products-search/product-search-professional.jpg');
    background-position: center;
    background-size: cover;
  }

  .title {
    padding: 20px;
    text-transform: uppercase;
  }
}

.ps-type-select-wrap {
  

  .ps-type-select {
    border-top: var(--border);
    border-bottom: var(--border);
  
    display: flex;
    align-items: center;
    @extend .text-uppercase;
    @extend .text-center;

    &:hover,
    &.selected {
      background-color: var(--yellow);
    }
  }
}

.ps-sub-header {
  background-color: black;
  color: white;
  text-transform: uppercase;
  padding: 10px 0;
  align-content: center;
  justify-content: center;

  h2 {
    font-size: $font-size-sm;
    margin: 0;
  }
}

.ps-sub-filter {
  .col {
    padding: 0 !important;
    margin: 0 !important;
  }

  .row {
    padding: 0 !important;
    margin: 0 !important;
  }

  input, input:focus {
    border: none;
    border-bottom: var(--border);
    border-radius: 0;
    box-shadow: none;
    outline: none;
  }
}

.ps-sub-filter-title {
  background: var(--gray);
  text-transform: uppercase;
  font-size: $font-size-base;
  text-align: center;
}

.ps-sub-filter-search {
  padding: 30px 0;
  justify-content: center;
}


@include respond-above(sm) {
  .ps-selector {


    .link {
      border-left: var(--border);
      border-bottom: none;
    }

    .link:hover {
      color: var(--yellow);
    }

    .search-heading-wrap {
      img {
        border-left: var(--border);
      }
    }

    .b2c {
      background-position: center;
      background-size: cover;
    }


    .b2b {
      background-position: center;
      background-size: cover;
    }
  }

  .ps-type-select-wrap {
    .ps-type-select {
      display: flex;
      align-items: center;
      @extend .text-uppercase;
      @extend .text-center;
      cursor: pointer;

      flex-direction: column;
      justify-content: center;

      border-top: var(--border);
      border-right: var(--border);

      &:last-child {
        border-right: none;
      }

      h5 {
        font-size: 2.3rem;
      }
    }
  }

  .ps-sub-header {
    height: 50px;

    h2 {
      font-size: $h2-font-size;
    }
  }

  .ps-sub-filter {
    
  }

  .ps-sub-filter-title {
    background: var(--gray);
    height: 40px;
    text-transform: uppercase;
    text-align: left;
    font-size: 20px;
    padding: 5px 0 0 10px;
  }

  .ps-sub-filter-search {
    padding: 0 30px 30px;
  }
}


.ps-selector a {
  color: var(--secondary);
}

.ps-selector-buttons {
  margin: 0;
}

.ps-selector-buttons .col {
  padding: 0;
}

.ps-no-borders {
  input, input:focus {
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
  }
}

.ps-category {
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
  cursor: pointer;
  border-left: var(--border);
  border-bottom: var(--border);
}

.ps-selected {
  background-color: var(--yellow);
}

.ps-not-selected {
  color: rgba(0, 0, 0, 0.35);
}

.ps-product {
  flex-grow: 1;
  border-left: 1px SOLID BLACK;
}

.ps-item-container {
  cursor: pointer;
  width: 100%;
  height: 400px;
}

.ps-item-name {
  padding: 20px 10px 0 10px;
}

.ps-item-code {
  padding-left: 10px;
  padding-bottom: 5px;
}

.ps-item-brand {
  padding-top: 5px;
  padding-left: 20px;
  // max-width: 400px;
}

.ps-item-img {
  text-align: center;

  img {
    max-height: 250px;
  }
}

.ps-bottom {
  position: absolute;
  bottom: 0;
}

.ps-item-label {
  padding-top: 10px;
}

.ps-item-button {
  padding: 10px 15px;
}

@include respond-above(sm) {
  .ps-category {
    border-bottom: none;
  }
}

// DETAIL

.ps-detail-frame {
  //margin: 16px;
  width: 100%;
}

.ps-detail-container {
  margin: -1rem !important;
}

.ps-detail-header {
  position: sticky;
  top: -16px;
  padding: 16px;
  background-color: white;
  z-index: 100;
}

.ps-detail-title {
  font-size: 22px;
}

.ps-detail-subtitle {
  font-size: 18px;
  margin-bottom: 5px;
}

.ps-detail-description {
  font-size: 14px;
  padding: 20px;
}

.ps-detail-content-50,
.ps-detail-item-50 {
  //padding: 0 0 0 20px;
  min-height: 50px;
  display: flex;
  align-items: center;

  select {
    //background-size: 30px;
  }

  span,
  select,
  input, input:focus {
    border: none;
    box-shadow: none;
    outline: none;
    padding-left: 0;
  }
}

.ps-detail-content-50 {
  border-left: var(--border);
}

.ps-detail-category {
  border-bottom: var(--border);

  &:nth-child(2n+1) {
    border-right: var(--border);
  }
}

.thead-alcea-color {
  background-color: var(--gray);

  th {

  }
}

.alcea-color .table {
  border-spacing: 0px;
  border-collapse: collapse;
  margin-bottom: 0px;
}

.alcea-color tr,
.alcea-color td {
  vertical-align: middle;

  &.actions-td {
    padding: 0px !important;

    > div {
      width: 100%!important;
      border-bottom: var(--border)
    }
  }
  //padding: 4px !important;
}

@include respond-above(sm) {
  .ps-detail-image-wrap {
    border-left: var(--border);
  }

  .ps-detail-category {
    padding: 20px 20px 2px 20px;
    border-left: none;
    border-bottom: none;
    border-right: var(--border);
  }

  .thead-alcea-color {
    background-color: var(--gray);

    th {
      border: var(--border) !important;
      height: 50px !important;
    }
  }

  .alcea-color tr,
  .alcea-color td {
    border: var(--border) !important;
    height: 50px !important;
    //padding: 5px !important;
  }

  
  .ps-detail-image-wrap {
    border-bottom: var(--border);
  }

  .alcea-color tr,
  .alcea-color td {
    
    &.actions-td {
      > div {
        border-bottom: none
      }
    }
    //padding: 4px !important;
  }

  .ps-detail-content-50,
  .ps-detail-item-50 {
    select {
      background-size: 30px;
    }
  }
}

.ps-detail-select {
  //width: auto !important;
  height: 50px;
  //border-left: var(--border);
}

.ps-detail-img {
  max-width: 100%;
}


.ps-detail-small {
  font-size: 14px;
}

.ps-detail-characteristics {
  padding: 0;
  margin: 0;
  height: 50px;
  display: flex;
  align-items: center;
}

.ps-detail-icon {
  @extend .text-uppercase;
  text-align: center;
  font-size: 15px;
  img {
    max-width: 100%;
    width: 120px;
  }
}

@include respond-above(sm) {
  .ps-detail-icon {
    @extend .text-uppercase;
    text-align: center;
    img {
      max-width: 100%;
      width: 120px;
    }
  }
}


.ps-detail-tds {
  padding: 0;
  margin: 0;
  height: 200px;
}

.ps-buy-container {
}

.ps-buy-header {
  position: sticky;
  top: -16px;
  padding: 16px;
  background-color: white;
  z-index: 100;
}

.ps-buy-title {
  font-size: 22px;
  text-transform: uppercase;
}

.ps-buy-row {

}

.ps-buy-cp {
  border-top: var(--border);
  border-bottom: var(--border);
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;

  text-align: center;
}

@include respond-above(md) {

  .ps-buy-container {
    height: 350px;
  }


  .ps-buy-cp {
    min-height: 280px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;

    border-left: var(--border);
    border-right: var(--border);
    border-top: none;
    border-bottom: none;

    text-align: center;
  }
}

.ps-sel-container {

}

.ps-sel-list-wrap {
  max-height: 350px;
  overflow-y: scroll;
}

.ps-sel-list-map {
  height: 70vh;
}

.ps-marker-selected {
  background-color: lightgray;
}

.ps-sel-list-item {
  padding: 10px 0 0 10px;
  min-height: 150px;
  cursor: pointer;

  h3 {
    padding-bottom: 10px;
  }

  h2 {
    padding-bottom: 5px;
  }
}

.ps-sel-list-item:hover {
  background-color: var(--gray)
}

@include respond-above(md) {
  .ps-sel-list-wrap {
    max-height: 70vh;
    overflow-y: scroll;
    padding: 0;
  }

  .ps-sel-list-map {
    height: 70vh;
    padding: 0;
  }
}

.leaflet-bottom {
  display: none !important;
}
</style>
