<template>
  <div>
    <b-container fluid class="oo-header py-2" :style="backgroundStyle(order)">
      <b-row>
        <!-- title -->
        <b-col>
          <h4 class="text-uppercase">{{ $t("orderInfo.order") }}</h4>
        </b-col>

        <!-- creation date -->
        <b-col>
          {{ $t("orderInfo.createdOn") }}: {{ formatDate(order.requestCreatedOn) }}
        </b-col>

        <!-- delivery date -->
        <b-col>
          {{ $t("orderInfo.deliveryDate") }}: {{ formatDate(order.deliveryDate) }}
        </b-col>

        <!-- status -->
        <b-col class="text-right text-uppercase">
          {{ $t("orderInfo.orderStatus." + order.status) }}
        </b-col>
      </b-row>

      <b-row>
        <!-- id -->
        <b-col>
          <h4><span v-if="order.orderCode">{{ order.orderCode }}</span></h4>
        </b-col>

        <!-- multiple shipping -->
        <b-col>
          {{ $t("orderInfo.acceptMultipleShipping") }}: {{ $t(order.acceptMultipleShipping ? 'labels.yes' : 'labels.no') }}
        </b-col>

        <!-- filler -->
        <b-col>
          <template v-if="order.transportReason">
            {{ $t("orderInfo.transportReason") }}: {{ order.transportReason }}
          </template>
        </b-col>

        <!-- action -->
        <b-col class="text-right">
          <div v-if="order.status === 'C'">
            <b-button @click="redoOrderRequest(order.id)"
                      variant="link"
                      style="margin-right: -15px; box-shadow: none;">
              {{ $t("actions.redoOrderRequest") }}
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <!-- filler -->
        <b-col><h4>&nbsp;</h4></b-col>

        <!-- shipping note -->
        <b-col>
          <template v-if="order.shippingNote && order.shippingNote.length > 0">
            {{ $t("orderInfo.shippingNote") }}: {{ order.shippingNote }}
          </template>
        </b-col>

        <!-- filler -->
        <b-col>
          <template v-if="order.port">
            {{ $t("orderInfo.port") }}: {{ order.port }}
          </template>
        </b-col>

        <!-- filler -->
        <b-col></b-col>
      </b-row>

      <b-row>
        <!-- filler -->
        <b-col></b-col>

        <!-- generic note -->
        <b-col>
          <template v-if="order.genericNote && order.genericNote.length > 0">
            {{ $t("orderInfo.genericNote") }}: {{ order.genericNote }}
          </template>
        </b-col>

        <!-- filler -->
        <b-col>
          <template v-if="order.unconditionalDiscount && order.unconditionalDiscount > 0">
            {{ $t("orderInfo.unconditionalDiscount") }}: {{ order.unconditionalDiscount }} %
          </template>
        </b-col>

        <b-col class="text-right ">
          <b-btn variant="link"  v-b-toggle :href="'#order-items-' + order.id" @click.prevent="itemsOpened = !itemsOpened">{{ $t("labels.products") }}: {{ items.length }} <b-icon-chevron-down :rotate="itemsOpened ? 180 : 0" /></b-btn>
        </b-col>
      </b-row>
    </b-container>
    <b-collapse :id="'order-items-' + order.id" >
    <b-table
        :items="items"
        :keys="fields"
        :fields="fields"
        class="mb-0"
        ref="dataTable"
        stacked="sm"
        show-empty>
      <template #empty="">
        <h5 class="text-center">{{ $t("formCheckout.emptyOrder") }}</h5>
      </template>

      <template #cell(productCode)="data">
        <span>{{ data.item.productCode.serial + "-" + data.item.productCode.color + "-" + data.item.productCode.pkg }}</span>
      </template>

      <template #cell(rowWeight)="data">
        <span>{{ calculateRowWeight(data.item) }}  Kg.</span>
      </template>

      <template #cell(rowTotal)="data">
        <span>{{ calculateRowTotal(data.item) }} €</span>
      </template>

      <template #cell(finalUnitPrice)="data">
        <span v-if="data.item.finalUnitPrice">{{ data.item.finalUnitPrice }} €</span>
        <span v-else-if="!data.item.unitPrice">
          {{ $t("product.notAssigned") }}
        </span>
      </template>
      
      <template #cell(unitQty)="data">
        <span>{{ data.item.unitQty }} {{data.item.um}}</span>
      </template>

      <template #cell(discounts)="data">
        <span>
          {{ data.item.discountPct1||'0' }}+{{ data.item.discountPct2||'0' }} % 
        </span>
      </template>

      <template #custom-foot>
        <b-tr v-if="order.overallDiscount && order.overallDiscount > 0">
          <b-td colspan="8" variant="" class="text-right px-5">
            <strong class="px-2">{{ $t("formCheckout.unconditionalDiscount") }} {{ order.overallDiscount }}%</strong>
          </b-td>
          <b-td variant="" class="text-right px-5">
            - {{ calculateOverallDiscount() }} €
          </b-td>
        </b-tr>
        <b-tr>
          <b-td colspan="3" variant="" class="text-right">
            <strong class="px-2">{{ $t("orderInfo.totalWeight") }}</strong>
          </b-td>
          <b-td variant="" class="">
            {{ calculateTotalWeight() }} Kg.
          </b-td>
          <b-td colspan="4" variant="" class="text-right">
            <strong class="px-2">{{ $t("orderInfo.totalOrder") }}</strong>
          </b-td>
          <b-td variant="" class="">
            {{ calculateTotalPriceWithOverallDiscount() }} €
          </b-td>
        </b-tr>
      </template>
    </b-table>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "OrderOrderComponent",

  props: {
    order: Object(),
  },

  data() {
    return {
      items: [],
      itemsOpened: false,
    };
  },

  computed: {
    total() {
      return this.items
          ? this.items.reduce(function (a, c) {
            return a + Number(c.unitPrice * c.quantity || 0);
          }, 0)
          : 0;
    },

    fields() {
      return [
        {
          key: "productCode",
          label: this.$i18n.t("product.product"),
          sortKey: "productCode",
        },
        {
          key: "description",
          label: this.$i18n.t("product.description"),
          sortKey: "description",
        },
        {
          key: "finalUnitPrice",
          label: this.$i18n.t("product.unitPrice"),
          tdClass: "",
        },
        /*{
          key: "unitQty",
          label: this.$i18n.t("product.unitQty"),
          tdClass: "",
        },*/
        {
          key: "rowWeight",
          label: this.$i18n.t("orderInfo.item.rowWeight")
        },
        {
          key: "quantityOrdered",
          label: this.$i18n.t("orderInfo.item.quantityOrdered"),
        },
        {
          key: "quantityConfirmed",
          label: this.$i18n.t("orderInfo.item.quantityConfirmed"),
        },
        {
          key: "quantityProcessed",
          label: this.$i18n.t("orderInfo.item.quantityProcessed"),
        },
        {
          key: "discounts",
          label: this.$i18n.t("orderInfo.item.discount")
        },
        {
          key: "rowTotal",
          label: this.$i18n.t("orderInfo.item.rowTotal")
        },
      ];
    },
  },

  methods: {
    backgroundStyle(order) {
      if (order.status === "I") {
        return { 'background-color': '#EFEFED' };
      }
      if (order.status === "E") {
        return { 'background-color': '#EFEFED' };
      }
      if (order.status === "C") {
        return { 'background-color': '#A7D8C0' };
      }
      if (order.status === "B") {
        return { 'background-color': '#FFE45C' };
      }
      if (order.status === "F") {
        return { 'background-color': '#A7D8C0' };
      }
      if (order.status === "A") {
        return { 'background-color': '#FF9A5C' };
      }
    },

    getItems() {
      return (this.items = this.order.items);
    },

    requestNewOrder() {
      this.$store.commit("StartCheckoutOrder", this.items);
      this.$router.push("/checkout/order");
    },

    formatDate(dateString) {
      if (dateString) {
        const date = new Date(dateString);
        return ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear();
      } else {
        return '-';
      }
    },

    redoOrderRequest(id) {
      //console.log('redoOrderRequest, id:', id);

      // phase 1: add order's items at the cart
      this.$store.dispatch('redoOrderRequest', id)
          .then((response) => this.redoOrderRequestPhase2(response.data.items))
          .catch((error) => this.$emit('fail', error));
    },

    redoOrderRequestPhase2(orderItems) {
      //console.log('redoOrderRequestPhase2, orderItems:', orderItems);

      // phase 2: reload card
      this.$store.dispatch('getProductsCart')
          .then(() => this.redoOrderRequestPhase3(orderItems))
          .catch((error) => this.$emit('fail', error));
    },

    redoOrderRequestPhase3(orderItems) {
      //console.log('redoOrderRequestPhase3, orderItems:', orderItems);

      // phase 3: select items and move to checkout
      const productsInCart = this.$store.getters.getProductsInCart
      this.$store.commit('StartCheckoutOrder', productsInCart);
      this.$router.push('/checkout/order');
    },
    calculateRowWeight (rowItem) {
      return +(Math.round((rowItem.quantityOrdered * rowItem.product.plo) + "e+2") + "e-2");
    },
    calculateTotalWeight () {
      return +(Math.round(this.order.items.reduce (
          (sum, i) => sum + this.calculateRowWeight(i),
          0
      ) + "e+2")  + "e-2");
    },
    calculateRowTotal(rowItem) {
      return +(Math.round((rowItem.unitPrice * rowItem.quantityOrdered * rowItem.product.qta * (1 - rowItem.discountPct1/100) * (1 - rowItem.discountPct2/100)) + "e+2")  + "e-2");
    },
    calculateTotalPrice() {
      return +(Math.round(this.order.items.reduce(
          (sum, i) => sum + this.calculateRowTotal(i),
          0
      ) + "e+2")  + "e-2");
    },
    calculateOverallDiscount () {
      return +(Math.round(this.calculateTotalPrice() * (this.order.overallDiscount / 100) + "e+2")  + "e-2");
    },

    calculateTotalPriceWithOverallDiscount() {
      return +(Math.round(this.calculateTotalPrice() - this.calculateOverallDiscount() + "e+2")  + "e-2");
    },
  },

  created() {
    this.getItems();
  },

  watch: {
    order() {
      this.getItems();
    }
  },
};
</script>
