export const productsSearchB2BCombos = {
  filters: {
    areas: [
      {
        text: 'productsSearch.combos.areas.edil',
        value: '3S'
      },
      {
        text: 'productsSearch.combos.areas.metal',
        value: '3M'
      },
      {
        text: 'productsSearch.combos.areas.wood',
        value: '3W'
      },
      {
        text: 'productsSearch.combos.areas.edil_metal_wood',
        value: '3EFL'
      },
      {
        text: 'productsSearch.combos.areas.coil',
        value: '3C'
      },
      {
        text: 'productsSearch.combos.areas.plastic',
        value: '3P'
      },
      {
        text: 'productsSearch.combos.areas.paste',
        value: '3H'
      },
      {
        text: 'productsSearch.combos.areas.corrosion',
        value: '3A'
      },
      {
        text: 'productsSearch.combos.areas.techinal_management',
        value: '3D'
      },
      {
        text: 'productsSearch.combos.areas.tintometry',
        value: '3T'
      },
      {
        text: 'productsSearch.combos.areas.removal',
        value: '3Z'
      }
    ],
    typologies: [
      {
        text: 'productsSearch.combos.typologies.dye',
        value: '3N'
      },
      {
        text: 'productsSearch.combos.typologies.paste',
        value: '3P'
      },
      {
        text: 'productsSearch.combos.typologies.background',
        value: '3F'
      },
      {
        text: 'productsSearch.combos.typologies.washable',
        value: '3LA'
      },
      {
        text: 'productsSearch.combos.typologies.catalyst',
        value: '3HI'
      },
      {
        text: 'productsSearch.combos.typologies.thinner',
        value: '3D'
      },
      {
        text: 'productsSearch.combos.typologies.converter',
        value: '3C'
      },
      {
        text: 'productsSearch.combos.typologies.hardeners',
        value: '3HE'
      },
      {
        text: 'productsSearch.combos.typologies.back',
        value: '3B'
      },
      {
        text: 'productsSearch.combos.typologies.impregnant',
        value: '3R'
      },
      {
        text: 'productsSearch.combos.typologies.coat',
        value: '3T'
      },
      {
        text: 'productsSearch.combos.typologies.raw_material',
        value: '3MP'
      },
      {
        text: 'productsSearch.combos.typologies.wp_floors_resins',
        value: '3PR'
      },
      {
        text: 'productsSearch.combos.typologies.additives',
        value: '3A'
      },
      {
        text: 'productsSearch.combos.typologies.auxiliaries',
        value: '3AA'
      }
    ],
    families: [
      {
        text: 'productsSearch.combos.families.acrylic',
        value: '3AC'
      },
      {
        text: 'productsSearch.combos.families.pvdf',
        value: '3D'
      },
      {
        text: 'productsSearch.combos.families.epoxy',
        value: '3E'
      },
      {
        text: 'productsSearch.combos.families.ketone',
        value: '3H'
      },
      {
        text: 'productsSearch.combos.families.isocyanates',
        value: '3HI'
      },
      {
        text: 'productsSearch.combos.families.polyester',
        value: '3PE'
      },
      {
        text: 'productsSearch.combos.families.alkyl',
        value: '3L'
      },
      {
        text: 'productsSearch.combos.families.nitrocellulose',
        value: '3N'
      },
      {
        text: 'productsSearch.combos.families.unsaturated',
        value: '3P'
      },
      {
        text: 'productsSearch.combos.families.clorocianuro',
        value: '3R'
      },
      {
        text: 'productsSearch.combos.families.phenolic',
        value: '3F'
      },
      {
        text: 'productsSearch.combos.families.silicones',
        value: '3S'
      },
      {
        text: 'productsSearch.combos.families.urethane',
        value: '3U'
      },
      {
        text: 'productsSearch.combos.families.vinyl',
        value: '3V'
      },
      {
        text: 'productsSearch.combos.families.polyester_melamine',
        value: '3M'
      },
      {
        text: 'productsSearch.combos.families.additives',
        value: '3A'
      },
      {
        text: 'productsSearch.combos.families.auxiliaries',
        value: '3AA'
      }
    ],
    dilutions: [
      {
        text: 'productsSearch.combos.dilutions.water',
        value: '3W'
      },
      {
        text: 'productsSearch.combos.dilutions.dust',
        value: '3P'
      },
      {
        text: 'productsSearch.combos.dilutions.solvent',
        value: '3S'
      },
      {
        text: 'productsSearch.combos.dilutions.noSolvent',
        value: '3N'
      },
      {
        text: 'productsSearch.combos.dilutions.liquid_uv',
        value: '3u'
      }
    ],
    dryings: [
      {
        text: 'productsSearch.combos.dryings.air',
        value: '1'
      },
      {
        text: 'productsSearch.combos.dryings.catalyzed',
        value: '2'
      },
      {
        text: 'productsSearch.combos.dryings.electrophoretic',
        value: 'E'
      },
      {
        text: 'productsSearch.combos.dryings.oven',
        value: 'O'
      },
      {
        text: 'productsSearch.combos.dryings.curing',
        value: 'U'
      }
    ],
    appMethods: [
      {
        text: 'productsSearch.combos.appMethods.brush',
        value: 'P'
      },
      {
        text: 'productsSearch.combos.appMethods.roller',
        value: 'R'
      },
      {
        text: 'productsSearch.combos.appMethods.sprinkle',
        value: 'S'
      },
      {
        text: 'productsSearch.combos.appMethods.airMix',
        value: 'SM'
      },
      {
        text: 'productsSearch.combos.appMethods.airLess',
        value: 'SL'
      },
      {
        text: 'productsSearch.combos.appMethods.spatula',
        value: 'T'
      },
      {
        text: 'productsSearch.combos.appMethods.sponge',
        value: 'G'
      },
      {
        text: 'productsSearch.combos.appMethods.rollerCoating',
        value: 'C'
      },
      {
        text: 'productsSearch.combos.appMethods.curtainCoater',
        value: 'V'
      },
      {
        text: 'productsSearch.combos.appMethods.immersion',
        value: 'I'
      },
      {
        text: 'productsSearch.combos.appMethods.trowel',
        value: 'Z'
      },
      {
        text: 'productsSearch.combos.appMethods.cloth',
        value: 'A'
      },
      {
        text: 'productsSearch.combos.appMethods.anode',
        value: 'IEA'
      },
      {
        text: 'productsSearch.combos.appMethods.cathode',
        value: 'IEC'
      }
    ]
  },

  categories: {
    areas: {
      '3S': 'productsSearch.combos.areas.edil',
      '3M': 'productsSearch.combos.areas.metal',
      '3W': 'productsSearch.combos.areas.wood',
      '3EFL': 'productsSearch.combos.areas.edil_metal_wood',
      '3C': 'productsSearch.combos.areas.coil',
      '3P': 'productsSearch.combos.areas.plastic',
      '3H': 'productsSearch.combos.areas.paste',
      '3A': 'productsSearch.combos.areas.corrosion',
      '3D': 'productsSearch.combos.areas.techinal_management',
      '3T': 'productsSearch.combos.areas.tintometry',
      '3Z': 'productsSearch.combos.areas.removal',
    },
    typologies: {
      '3N': 'productsSearch.combos.typologies.dye',
      '3P': 'productsSearch.combos.typologies.paste',
      '3F': 'productsSearch.combos.typologies.background',
      '3LA': 'productsSearch.combos.typologies.washable',
      '3HI': 'productsSearch.combos.typologies.catalyst',
      '3D': 'productsSearch.combos.typologies.thinner',
      '3C': 'productsSearch.combos.typologies.converter',
      '3HE': 'productsSearch.combos.typologies.hardeners',
      '3B': 'productsSearch.combos.typologies.back',
      '3R': 'productsSearch.combos.typologies.impregnant',
      '3T': 'productsSearch.combos.typologies.coat',
      '3MP': 'productsSearch.combos.typologies.raw_material',
      '3PR': 'productsSearch.combos.typologies.wp_floors_resins',
      '3A': 'productsSearch.combos.typologies.additives',
      '3AA': 'productsSearch.combos.typologies.auxiliaries',
    },
    families: {
      '3AC': 'productsSearch.combos.families.acrylic',
      '3D': 'productsSearch.combos.families.pvdf',
      '3E': 'productsSearch.combos.families.epoxy',
      '3H': 'productsSearch.combos.families.ketone',
      '3HI': 'productsSearch.combos.families.isocyanates',
      '3PE': 'productsSearch.combos.families.polyester',
      '3L': 'productsSearch.combos.families.alkyl',
      '3N': 'productsSearch.combos.families.nitrocellulose',
      '3P': 'productsSearch.combos.families.unsaturated',
      '3R': 'productsSearch.combos.families.clorocianuro',
      '3F': 'productsSearch.combos.families.phenolic',
      '3S': 'productsSearch.combos.families.silicones',
      '3U': 'productsSearch.combos.families.urethane',
      '3V': 'productsSearch.combos.families.vinyl',
      '3M': 'productsSearch.combos.families.polyester_melamine',
      '3A': 'productsSearch.combos.families.additives',
      '3AA': 'productsSearch.combos.families.auxiliaries',
    },
    dilutions: {
      '3W': 'productsSearch.combos.dilutions.water',
      '3P': 'productsSearch.combos.dilutions.dust',
      '3S': 'productsSearch.combos.dilutions.solvent',
      '3N': 'productsSearch.combos.dilutions.noSolvent',
      '3u': 'productsSearch.combos.dilutions.liquid_uv',
    },
    dryings: {
      '31K': 'productsSearch.combos.dryings.air',
      '32K': 'productsSearch.combos.dryings.catalyzed',
      '3E': 'productsSearch.combos.dryings.electrophoretic',
      '3O': 'productsSearch.combos.dryings.oven',
      '3U': 'productsSearch.combos.dryings.curing'
    }
  },

  dilutionIcons: {
    '3W': {
      icon: 'DILUIZIONE_acqua',
      desc: 'productsSearch.dilution_water'
    },
    '3S': {
      icon: 'DILUIZIONE_solvente',
      desc: 'productsSearch.dilution_solvent'
    },
  },

  characteristicsCols: [
    {
      key: 'code',
      label: 'productsSearch.code'
    },
    {
      key: 'desc',
      label: 'productsSearch.color'
    },
    {
      key: 'specWeight',
      label: 'productsSearch.specificWeight'
    },
    {
      key: 'unitValue',
      label: 'productsSearch.unitValue'
    },
    {
      key: 'actions',
      label: 'productsSearch.actions',
      tdClass: 'actions-td'
    }
  ],

  intExtIcons: {
    I: {
      icon: 'USO_interno',
      desc: 'productsSearch.internal_use'
    },
    E: {
      icon: 'USO_esterno',
      desc: 'productsSearch.external_use'
    },
    IE: {
      icon: 'USO_esterno_interno',
      desc: 'productsSearch.external_internal_use'
    }
  },

  applicationIcons: {
    P: {
      icon: 'APPLICAZIONI_pennello',
      desc: 'productsSearch.combos.appMethods.brush'
    },
    R: {
      icon: 'APPLICAZIONI_rullo',
      desc: 'productsSearch.combos.appMethods.roller'
    },
    S: {
      icon: 'APPLICAZIONI_airspray',
      desc: 'productsSearch.combos.appMethods.sprinkle'
    },
    SA: {
      icon: 'APPLICAZIONI_airspray',
      desc: 'productsSearch.combos.appMethods.conventional'
    },
    SM: {
      icon: 'APPLICAZIONI_airless',
      desc: 'productsSearch.combos.appMethods.airMix'//
    },
    SL: {
      icon: 'APPLICAZIONI_airless',
      desc: 'productsSearch.combos.appMethods.airLess'
    },
    T: {
      icon: 'APPLICAZIONI_spatola',
      desc: 'productsSearch.combos.appMethods.spatula'
    },
    G: {
      icon: 'APPLICAZIONI_spugna',
      desc: 'productsSearch.combos.appMethods.sponge'
    },
    C: {
      icon: 'APPLICAZIONI_Roller_Coating',
      desc: 'productsSearch.combos.appMethods.rollerCoating'
    },
    V: {
      icon: 'APPLICAZIONI_velatrice',
      desc: 'productsSearch.combos.appMethods.curtainCoater'
    },
    I: {
      icon: 'APPLICAZIONI_immersione',
      desc: 'productsSearch.combos.appMethods.immersion'
    },
    Z: {
      icon: 'APPLICAZIONI_frattazzo',
      desc: 'productsSearch.combos.appMethods.trowel'
    },
    A: {
      icon: 'APPLICAZIONI_straccio',
      desc: 'productsSearch.combos.appMethods.cloth'
    },
    F: {
      icon: 'APPLICAZIONI_flow_coatings',
      desc: 'productsSearch.combos.appMethods.flowCoatings'
    },
    SH: {
      icon: 'APPLICAZIONI_hplv',
      desc: 'productsSearch.combos.appMethods.hplv'
    },
    SD: {
      icon: 'APPLICAZIONI_disco_elettrostatica',
      desc: 'productsSearch.combos.appMethods.electrostaticSpray'
    },
    IEA: {
      icon: 'APPLICAZIONI_Elettroforesi_NS',
      desc: 'productsSearch.combos.appMethods.electrophoresisNS'
    },
    IEC: {
      icon: 'APPLICAZIONI_Elettroforesi_NS',
      desc: 'productsSearch.combos.appMethods.electrophoresisNS'
    }
  }
}
