export const product = {
  searchProducts: "Szukaj produktów",
  productCode: "Kod produktu",
  productNameCode: "Nazwa lub kod produktu albo opis",
  brand: "Marka",
  color: "Kolor",
  serialCode: "Kod serii",
  colorCode: "Kod koloru",
  packaging: "Opakowanie",
  pkgAvailable: "DOSTĘPNY W MAGAZYNIE",
  area: "Sektor",
  typology: "Rodzaj",
  family: "Grupa",
  dilution: "Rozcieńczenie",
  dilution_water: "Rozcieńczenie wodne",
  dilution_solvent: "Rozcieńczenie rozpuszczalnikowe",
  drying: "Schnięcie",
  dryingIcon: "suszenie",
  appMethod: "Metoda nakł.",
  results: "WRÓĆ DO WYNIKÓW WYSZUKIWANIA",
  noResults:
    "Twoje wyszukiwanie nie dało żadnych wyników. Jeśli potrzebujesz informacji lub masz pytanie, skontaktuj się z działem obsługi klienta: alcea@alcea.com",
  characteristics: "Parametry",
  downloadTds: "POBIERZ KARTĘ TECHNICZNĄ W FORMACIE PDF",
  loginToDownloadTds: "Zaloguj się, aby pobrać arkusz danych technicznych",
  noTdsFound: "Nie znaleziono tds",
  code: "Кod",
  description: "Opis",
  specificWeight: "Ciężar właściwy",
  unitValue: "Ilość jednostkowa",
  months: "miesiące",
  usage: "użycie",
  internal_use: "wykorzystanie wewnętrzne",
  external_use: "wykorzystanie zewnętrzne",
  external_internal_use: "wykorzystanie zew./wew",
  shelf: "przydatność",
  squareMeters: "m2",
  squareMetersPerLitre: "m2/l",
  squareMetersPerKilogram: "m2/kg",
  buttons: {
    downloadTds: "Wybierz język, w którym chcesz pobrać TDS",
  },
  combos: {
    interested: {
      metalIndustry: "Metal Industry",
      woodIndustry: "Wood industry",
      coilCoating: "Coil Coating",
      construction: "Construction",
      homeLine: "Home line",
      tintometrics: "Tintometrics",
    },
    areas: {
      edil: "BUDOWNICTWO (ŚCIANA MUROWANA)",
      metal: "PRZEMYSŁ METALOWY",
      wood: "PRZEMYSŁ DRZEWNY",
      edil_metal_wood: "BUILDING-METAL-WOOD",
      coil: "POWLEKANIE BLACH",
      plastic: "TWORZYWA SZTUCZNE WSZYSTKIE BRANŻE",
      paste: "PASTY, BAZY, KATALIZATORY X SPRZEDAŻ DETALICZNA",
      corrosion: "ŚRODKI ANTYKOROZYJNE, PRZEMYSŁ OKRĘTOWY ITP.",
    },
    typologies: {
      dye: "FARBY, PATYNY, KONCENTRATY DO DREWNA, BEJCE",
      paste: "SKONCENTROWANE PASTY PIGMENTOWE",
      background: "PODKŁADY I GRUNTY ANTYKOROZYJNE",
      intermediate: "WARSTWY POŚREDNIE",
      washable: "ZMYWALNE",
      various: "RÓŻNE",
      catalyst: "KATALIZATORY",
      thinner: "ROZCIEŃCZALNIKI",
      converter: "KONWERTERY",
      dust: "PROSZKI TERMOUTWARDZALNE",
      back: "BACK",
      impregnant: "IMPREGNATY",
      coat: "TOP COAT",
      raw_material: "RAW MATERIALS",
      wp_floors_resins: "ŻYWICE DO POSADZEK, PRODUKTY BARIEROWE-WODOODPORNE",
    },
    families: {
      acrylic: "AKRYLOWE",
      pvdf: "PVDF",
      epoxy: "EPOKSYDOWE",
      ketone: "KETONOWE",
      polyester: "POLIESTRY/IZOCJANOWE",
      alkyl: "ALKIDOWE",
      nitrocellulose: "NITROCELULOZOWE",
      unsaturated: "POLIESTRY NIENASYCONE",
      clorocianuro: "CHLOROCYJANOWE",
      phenolic: "FENOLOWE",
      silicones: "SILIKONOWE",
      urethane: "URETANOWE",
      vinyl: "WINYLOWE",
      polyester_melamine: "POLYESTER/MELAMINE",
    },
    dilutions: {
      water: "WODNE",
      dust: "PROSZEK",
      solvent: "ROZPUSZCZALNIKOWY",
      noSolvent: "BEZROZPUSZCZALNIKOWY",
    },
    dryings: {
      air: "1K POWIETRZE",
      catalyzed: "2K KATALIZOWANY",
      electrophoretic: "ELEKTROFORETYCZNY",
      oven: "PIECOWY",
      curing: "UV CURING",
    },
    appMethods: {
      brush: "pędzel",
      roller: "wałek",
      sprinkle: "pistolet kubkowy",
      airMix: "natrys pneumatyczny",
      airLess: "natrysk hydrodynamiczny",
      spatula: "szpachelka",
      sponge: "gąbka",
      rollerCoating: "powlekanie na walcach",
      curtainCoater: "polewarka kurtynowa",
      immersion: "powlekanie zanurzeniowe",
      trowel: "kielnia",
      cloth: "bawełniana szmatka",
      conventional: "natrysk pneumatyczny",
      flowCoatings: "lakierowanie zanurzeniowe",
      hplv: "hplv",
      electrostaticSpray: "elektrostatyka",
      electrophoresisNS: "elektroforeza ns",
      anode: "ZANURZENIE ELEKTROFORETYCZNE ANODOWE",
      cathode: "ZANURZENIE ELEKTROFORETYCZNE KATODOWE",
    },
  },
};
