import NewPassword from "@/views/NewPassword.vue";

export default {
  path: "/new-password",
  name: "NewPassword",
  component: NewPassword,
  meta: {
    sector: 'PrivateArea',
  },
}
