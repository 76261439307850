export const errors = {
  invalidUsrOrPwd: "Nombre de usuario o contraseña no válidos",
  disabledUser: "Usuario inhabilitado, contactar aws_alcea@alcea.com",
  fillUpProfile: "Complete su perfil, rellenando todos los campos obligatorios",
  missing: "Rellene el campo",
  invalidField: "El valor ingresado no es válido",
  invalidLenght: "Longitud del campo demasiado grande",
  UnauthorizedOrInvalid: "El enlace utilizado no es válido",
  NotFoundPage: "Página no encontrada",
  Unauthorized: "No autorizado",
  UnauthorizedLink: "No dispone de las autorizaciones para acceder al enlace",
  IllegalItemQuantity: "La cantidad debe seguir un multiplicador"
};
