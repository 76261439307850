export const subtitles = {
    welcome: "Alcea's digital platform",
    requestOrders: "Order requests",
    requestOffers: "Offer requests",
    shippingAddress: "Shipping address",
    customerOrderCode: "Custom order code",
    deliveryDateRequested: "Delivery times",
    acceptMultipleShipping: "Divisible shipping",
    shippingNote: "Shipping notes",
    genericNote: "Generic notes",
    summary: "Summary"
  }
;
