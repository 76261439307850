import http from "@/util/http-common";

class TokenService {
  async getAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const body = { username: user.username, refreshToken: user.refreshToken };
      return http
        .post("/auth/token-refreshing", body)
        .then((response) => {
          if (response.data) {
            this.updateLocalAccessToken(response.data);
          }
          return Promise.resolve(response);
        })
        .catch((error) => Promise.reject(error));
    } else {
      //TODO to recreate this error
      let error = "User not logged in";
      return Promise.reject(error);
    }
  }
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.accessToken;
  }
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
}
export default new TokenService();
