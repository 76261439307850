import OrderService from "../services/api-services/orders-service";
import { getProductOrderFromDto } from "@/models/product";
import i18n from "@/plugins/i18n";

const initialState = {
  orders: {
    info: {
      page: 1,
      row: 100,
      totalElements: 0,
      totalPages: 0,
      first: true,
      last: false,
      empty: true,
      sortDirection: "desc",
      sortBy: "orderCode",
      sortDesc: false,
    },
  },
};

export const orders = {
  state: initialState,
  actions: {
    getOrders({ commit, dispatch, getters }, filters) {
      const customer = getters.getCustomerCode;
      let paginationInfo = getters.getPaginationInfoOrders;
      filters = { ...filters, ...{ customerCode: customer } };
      return OrderService.getOrders(
        paginationInfo.page,
        paginationInfo.row,
        paginationInfo.sortBy, paginationInfo.sortDirection,
        filters,
        null
      ).then(
        (res) => {
          console.log(res);
          console.log(paginationInfo.row);
          const response = res.data;
          let info = {
            page: paginationInfo.page,
            //size: page.row,//test
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            row: paginationInfo.row,
            fist: response.first,
            last: response.last,
            sortDirection: paginationInfo.sortDirection,
            sortBy: paginationInfo.sortBy,
            sortDesc: paginationInfo.sortDirection == "desc",
            empty: response.empty,
          };
          commit("updatePaginationInfoOrders", info);
          // let content = response.content.map((rc) => {
          //   let item = getProductOrderFromDto(rc);          
          //   return item;
          // });
          return Promise.resolve(response.content);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updatePaginationInfoOrders({ commit }, newPaginations) {
      commit("updatePaginationPages", newPaginations);
    },
  },
  mutations: {
    updatePaginationInfoOrders(state, paginationInfo) {
      state.orders.info = paginationInfo;
    },
    updatePaginationPages(state, newPaginations) {
      (state.orders.info.page = newPaginations.page),
        (state.orders.info.row = newPaginations.row),
        (state.orders.info.sortDirection = newPaginations.sortDirection),
        (state.orders.info.sortBy = newPaginations.sortBy),
        (state.orders.info.sortDesc = newPaginations.sortDesc);

      console.log(state.orders.info.row);
    },
    clearOrders(state) {
      state.orders.info = {
        page: 1,
        row: 10,
        totalElements: 0,
        totalPages: 0,
        first: true,
        last: false,
        empty: true,
        sortDirection: "desc",
        sortBy: "orderCode",
        sortDesc: false,
      };
    },
  },
  getters: {
    getPaginationInfoOrders: (state) => state.orders.info,
  },
};
