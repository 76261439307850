import http from "@/util/http-common";

class CustomerService {
  async getMyselfUserCustomer() {
    return http.get(`v1/public/customer/customer-profiles`);
  }

  async postInvite(form) {
    console.log(form);
    return http.post(`v1/public/customer/customer-invitations/pendings`, form);
  }
  async postEditRequest(form) {
    return http.post(
      `v1/public/customer/customer-profiles/customer-requests/update-customer-requests/pendings`,
      form
    );
  }
  async deleteInvite(invitee) {
    return http.delete(
      `v1/public/customer/customer-invitations/pendings`, {
        params: {
          inviteeEmail: invitee
        }
      }
    );
  }
  async getInvitationsCustomer(page, size, sortBy,sortDir ) {
    let body = {
      page: page,
      size: size,
      sort: sortBy+'+'+sortDir
    };
    console.log("BODY", body);
    return http.get(`v1/public/customer/customer-invitations`, body);
  }
  checkCustomerEdit(code) {
    return http.head(
      `v1/public/customer/customer-profiles/${code}/customer-requests/update-customer-requests/pendings`
    );
  }
}
export default new CustomerService();
