export const alert = {
  SuccessRegiatration: "User registered. You will shortly receive an email with a link to activate your account and enter your login credentials",
  sending: "Sending...",
  operationSuccess: "The operation was successfully completed",
  registrationComplete: "Congratulations, your registration is complete",
  newPasswordComplete: "New Password successfully updated",
  resetPasswordSuccess: "You will shortly receive an email with a link to enter your new password",
  notificationMailSuccess: "Updated login notifications",
  updateSuccess: "Profile updated",
  sendRequestSuccess: "The request will be examined as soon as possible, you will receive communication via email with the outcome. You will also be able to see the status of the request in your user profile.",
  sendInviteSuccess: "The invitation has been sent successfully",
  acceptSuccess: "Invitation accepted",
  refuseSuccess: "Invitation Declined",
  deleteSuccess: "Invitation deleted successfully",
  deleteFail: "The delete operation was unsuccessful",
  becomeClientSuccess: "Congratulations by accepting the invitation from now you can already log in as a customer",
  productsInCart: "Products added to cart",
  updateCartSuccess: "Your cart has been updated",
  removeItemSuccess: "Item removed from cart",
  removeItemsSuccess: "Items removed from cart   ",
  SuccessCheckOutOffer: "Checkout successful",
  SuccessCheckOutOrder: "Checkout successful",
  somethingWrong: "Something went wrong",
  NetworkError: "Network failure",
  UnauthorizedLogin: "Invalid username or password",
  Unauthorized: "You don't have the required permissions",
  BadRequest: "Error in the formulation of the request",
  NotFound: "No match was found",
  FailedToAssociate: "A match with the code entered was not found, for more information contact the address ",
  Conflict: "You are trying to send data that has already been stored",
  ServerError: "A server error has occurred",
  UnauthorizedOrInvalid: "You don't have the necessary permissions or the link is no longer valid",
  customerRequestExistingVatTitle: "Caution",
  customerRequestExistingVat: "The VAT number entered already exists, if you are trying to create a request for an already registered customer click yes 'Use Customer Code' to associate the user with an existing customer using the customer code provided",
  agentCustomerRequestExistingVat: "The VAT number entered already belongs to a customer who has made the request",
  editRequestExistingVat: "Attention, there is already a pending request for this customer",
  TdsError: "An error occurred while downloading",
  missingTds: "There is no technical data sheet for this product"
};
