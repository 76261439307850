export const faq = {
    demo1Title: "<strong>DEMO 1</strong>  Registrazione ADW – come effettuare la registrazione",
    demo1Body: "Per effettuare la registrazione al sito cliccare su Accedi e poi Registrati",
    demo2Title: "<strong>DEMO 2</strong>  Il catalogo Alcea – la ricerca prodotti",
    demo2Body: "Una volta registrato come azienda puoi ricercare i prodotti Alcea nella sezione PROFESSIONISTA.<br/>Vi sono 3 modalità di ricerca. Per nome o codice prodotto o descrizione, per serie e colore ed in ultimo per caratteristiche. Ti consigliamo comunque la prima modalità di ricerca ovvero per codice anche incompleto:<br/><strong>Es 4830 ( wax )</strong><br/>All'interno della pagina seleziona il prodotto di tuo interesse e si aprirà la scheda prodotto. Attraverso delle semplici immagini ti verrà offerta una descrizione del prodotto, potrai visualizzare i lattaggi disponibili e  scaricare la scheda tecnica in diverse lingue<br/>",
    demo3Title: "<strong>DEMO 3</strong>  Sono un cliente Alcea. Come richiedere l’attivazione del tuo profilo cliente?",
    demo3Body: "Se ti sei già registrato sulla piattaforma Alcea e  sei un nostro cliente vai nella tua area privata e fai richiesta di attivazione cliccando qui . Inserisci  il tuo codice cliente che trovi sulle conferme d’ordine o in fattura e richiedi l’abbinamento.<br/>Via mail  riceverai conferma dell’ attivazione della tua area privata dove troverai lo storico dei prodotti da te acquistati ed il tuo carrello.<br/>",
    demo4Title: "<strong>DEMO 4</strong>  Come posso ordinare?",
    demo4Body: "Una volta che il tuo profilo cliente è stato attivato  potrai accedere allo storico dei prodotti.<br/>Qui trovi tutte le informazioni relativamente ai prodotti da te acquistati . La ricerca è semplice.<br/><ol><li>Puoi scorrere l’elenco prodotti e felggare il prodotto di tuo interesse</li><li>Oppure puoi inserire il codice  qui in alto e cliccare sul bottone <strong>CERCA</strong> a destra  della pagina</li></ol><br/>Per procedere nell’acquisto clicca Il tasto in basso a destra AGGIUNGI AL CARRELLO.  Una volta nel carrello definisci le quantità per ogni articolo e solo selezionando  i prodotti  il tasto  RICHIEDIORDINE in basso a destra si attiva per procedere.<br/>Seleziona qui il tuo indirizzo di consegna, specifica se vuoi in questo campo il tuo numero d’ordine. Seleziona qui la data di evasione preferibile della tua richiesta d’ordine. Ti consigliamo di selezionare sempre spedizione frazionabile. Per comunicare con noi puoi utilizzare i campi note di spedizione relative alla consegna ( per esempio orari , festivita o altro ) e note generiche dove puoi scriverci in merito  ai prodotti acquistati. Se vuoi modificare una quantità puoi tornare al carrello cliccando qui . Ricordati in questo caso di aggiornare il tuo carrello una volta modificato. A conclusione riceverai una mail di conferma della tua richiesta d’ordine e successivamente  ti verrà inviata  conferma d’ordine in formato pdf come avviene abitualmente.",
    demo5Title: "<strong>DEMO 5</strong> Non trovo un prodotto nel carrello. Cosa faccio?",
    demo5Body: "Se all’interno della sezione prodotti ordinati non trovo il prodotto di mio interesse  mi devo spostare in RICERCA PRODOTTI IN ALTO NELLA PAGINA e cliccare la sezione su professionista. Digitare il nome o il codice del prodotto utilizzando il motore di ricerca  Una volta trovato il prodotto, clicca ACQUISTA  per aggiungerlo al tuo carrello. Il prodotto verrà trasferito in automatico<br/>",
    demo6Title: "<strong>DEMO 6</strong> Richiedi offerta per prezzo non assegnato",
    demo6Body: "<strong>Parte 1</strong><br/>Mentre stai formalizzando un ordine potrai vedere  alcuni prodotti per i quali  non ti  è stato assegnato un prezzo. Non preoccuparti. Metti in ogni caso  il  prodotto nel carrello, seleziona la quantità, flegga il prodotto e procedi con l’offerta compilando i campi obbligatori e cliccando qui Ti risponderemo quanto prima.<br/><strong>Parte 2</strong><br/>Può capitarti  di non trovare nel tuo storico il prodotto di tuo interesse, consulta il nostro catalogo cliccando su ricerca prodotti in alto nella pagina, Individua il prodotto di tuo interesse, clicca acquista e aggiungilo al carrello. Come vedrai sarà possibile da qui solo la funzione richiedi offerta e compila i campi obbligatori e procedi qui<br/><strong>Parte 3</strong><br/>Per consultare le tue richieste d’offerta clicca qui. Una volta che  la tua richiesta è stata ACCETATA potrai procedere direttamente con il tuo ordine cliccando su richiedi ordine qui<br/>",
};