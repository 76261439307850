import ProductsSearch from '@/views/ProductsSearch/ProductsSearch.vue';
import ProductsSearchSellersMap from '@/views/ProductsSearch/ProductsSearchSellersMap';
import ProductsSearchB2B from "@/views/ProductsSearch/ProductsSearchB2B";
import ProductsSearchB2C from "@/views/ProductsSearch/ProductsSearchB2C";
import ProductsSearchGuest from "@/views/ProductsSearch/ProductsSearchGuest";
import ProductsSearchPage from "@/views/ProductsSearch/ProductsSearchPage";

export default {
  path: '/products-search',
  component: ProductsSearchPage,

  children: [
    {
      path: '',
      name: 'ProductsSearch',
      component: ProductsSearch,
      meta: {
        sector: 'ProductsSearch'
      }
    },

    {
      path: "b2c",
      name: "ProductsSearchB2C",
      component: ProductsSearchB2C,
      meta: {
        sector: 'ProductsSearch',
        kind: 'private'
      }
    },

    {
      path: "b2b",
      name: "ProductsSearchB2B",
      component: ProductsSearchB2B,
      meta: {
        sector: 'ProductsSearch',
        kind: 'professional'
      }
    },

    {
      path: "guest",
      name: "ProductsSearchGuest",
      component: ProductsSearchGuest,
      meta: {
        sector: 'ProductsSearch',
      }
    },

    {
      path: 'sellersMap/:priv/:professional',
      name: 'ProductsSearchSellersMap',
      component: ProductsSearchSellersMap,
      meta: {
        sector: 'ProductsSearch'
      }
    },
  ]
}
