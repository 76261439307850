export default {
    install(Vue, options) {
      Vue.mixin({
        created() {
          /* eslint-disable-next-line */
          window._iub = window._iub || {};
          /* eslint-disable-next-line */
          _iub.csConfiguration = options;
          /*
          const iubenda = document.createElement('script');
          iubenda.setAttribute('id', 'iubendaScript')
          iubenda.setAttribute('src', '//cdn.iubenda.com/cs/iubenda_cs.js');
          iubenda.setAttribute('charset', 'UTF-8');
          iubenda.setAttribute('async', true);
          document.head.appendChild(iubenda);
          */
        },
        methods: {
          initIubendaBanner(lang, siteId, cookiePolicyId) {
            window._iub = window._iub || {};
            /* eslint-disable-next-line */
            _iub.csConfiguration = Object.assign(_iub.csConfiguration, {lang, siteId, cookiePolicyId});

            const iubendaOld = document.getElementById('iubendaScript');
            if(iubendaOld) {
              iubendaOld.parentNode.removeChild( iubendaOld );
            }

            const iubenda = document.createElement('script');
            iubenda.setAttribute('id', 'iubendaScript')
            iubenda.setAttribute('src', '//cdn.iubenda.com/cs/iubenda_cs.js');
            iubenda.setAttribute('charset', 'UTF-8');
            iubenda.setAttribute('async', true);
            document.head.appendChild(iubenda);
          }
        }
      });
    }
}