import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import home from './routes/home'
import faq from './routes/faq'
import login from './routes/login'
import registration from './routes/registration'
import confirmRegistration from './routes/confirm-registration'
import profileUser from './routes/profile-user'
import CustomerRequest from './routes/costumer-request'
import newPassword from './routes/new-password'
import forgotPassword from './routes/forgot-password'
import unauthorized from './routes/unauthorized'
import notFound from './routes/not-found'
import profileCustomer from './routes/profile-customer'
import agent from './routes/profile-agent'
import checkout from './routes/checkout'
import ProductsSearch from './routes/products-search'
import SdsSearch from './routes/sds-search'
//import contactArea from './routes/contact-area'

Vue.use(VueRouter)

const routes = [
  home,
  faq,
  login,
//  contactArea,
  registration,
  confirmRegistration,
  profileUser,
  CustomerRequest,
  newPassword,
  forgotPassword,
  unauthorized,
  notFound,
  profileCustomer,
  agent,
  checkout,
  ProductsSearch,
  SdsSearch,

  // for now default path is login then it will be home
  { path: '*', redirect: '/' },
  { path: '', redirect: '/' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  store,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const user = store.getters.getUser || JSON.parse(localStorage.getItem('user'))
    if (!user || !user.accessToken) {
      next({path: '/login'})
    } else if (!to.meta.roles.includes(user.role) && (to.meta.rolesSds && !to.meta.rolesSds.includes(user.roleSds))) {
      next({ path: '/unauthorized' })
    } else {
      next()
    }
  } else {
    next()
  }
})

const TITLE_PREFIX = 'ADW - Alcea Digital Web';
const DEFAULT_TITLE = TITLE_PREFIX;
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title ? TITLE_PREFIX + to.meta.title : DEFAULT_TITLE;
    });
});

export default router
