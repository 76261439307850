<template>
  <div>
    <b-container fluid v-if="showMenu">
      <!-- menu -->
      
      <b-row class="ps-selector">

        <b-col cols="12" md="6" class="px-0">
          <b-breadcrumb :items="bradcrumbItems"></b-breadcrumb>
        </b-col>
        <b-col cols="12" md="6" class="px-0">
          <!-- back + customer -->
          <products-search-customer class="bb" :back-function="show.header ? null : filtersBack">
            <template v-slot:title>
              <span class="h2 text-uppercase m-0">{{ $t("actions.back") }}</span>
            </template>
          </products-search-customer>
        </b-col>
      </b-row>
        
      <b-row class="ps-selector" v-if="show.header">
        <b-col cols="12" class="b2b" @click.stop="goToB2B"></b-col>

        <b-col cols="12">
          <div class="title text-center">
            <h5>{{ $t("productsSearch.b2bTitle") }}</h5>
          </div>
        </b-col>

      </b-row>


      <b-row class="ps-type-select-wrap">
        
        <!-- search by name or code -->
        <b-col cols="12" md="4" order-md="1" ref="byNameOrCode" :class="'ps-type-select ' + (show.byNameOrCode ? 'selected' : '')" @click="selectFilterType('byNameOrCode')">
          <h5>{{ $t("productsSearch.filters.byNameOrCode") }}</h5>
        </b-col>
        <b-col cols="12"  order-md="4" v-if="show.byNameOrCode" class="bx">

          <b-row class="ps-sub-filter bx bb">
            <b-col>
              <div class="ps-sub-filter-title">{{ $t('productsSearch.productCode') }} / {{ $t('product.description') }}</div>
            </b-col>
          </b-row>

          <b-row class="ps-sub-filter bx">
            <b-form-input v-model="selected.nameOrCode" @keyup.enter="searchProductsByNameOrCode" :placeholder="$t('productsSearch.filters.productNameCode')"></b-form-input>
          </b-row>

          <b-row class="ps-sub-filter-search">
            <adw-button class="col px-0 col-md-6 col-12"
                  :class="{ 'disabled': validateProductsByNameOrCode }"
                   @button-click="searchProductsByNameOrCode">
              <h3>{{ $t("actions.searchProduct") }}</h3>
            </adw-button>
          </b-row>
        </b-col>


        <!-- search by serial and color -->
        <b-col cols="12" md="4"  order-md="2" ref="bySerialAndColor" :class="'ps-type-select ' + (show.bySerialAndColor ? 'selected' : '')" @click="selectFilterType('bySerialAndColor')">
          <h5>{{ $t("productsSearch.filters.bySerialAndColor") }}</h5>
        </b-col>
        <b-col cols="12"  order-md="5" v-if="show.bySerialAndColor" class="bx">

          <b-row class="ps-sub-filter bx bb">
            <b-col>
              <div class="ps-sub-filter-title">{{ $t('productsSearch.filters.serialCode') }}</div>
            </b-col>
            <b-col class="bl">
              <div class="ps-sub-filter-title">{{ $t('productsSearch.filters.colorCode') }}</div>
            </b-col>
          </b-row>

          <b-row class="ps-sub-filter bx">
            <b-col>
              <b-form-input v-model="selected.serialCode" @keyup.enter="searchProductsBySerialAndColor" :placeholder="$t('productsSearch.filters.serialCode')"></b-form-input>
            </b-col>
            <b-col class="bl">
              <b-form-input v-model="selected.colorCode" @keyup.enter="searchProductsBySerialAndColor" :placeholder="$t('productsSearch.filters.colorCode')"></b-form-input>
            </b-col>
          </b-row>

          <b-row class="ps-sub-filter-search">
            <adw-button class="col px-0 col-md-6 col-12"
                  :class="{ 'disabled': validateProductsBySerialAndColor }"
                  @button-click="searchProductsBySerialAndColor">
              <h3>{{ $t("actions.searchProduct") }}</h3>
            </adw-button>
          </b-row>
        </b-col>

        <!-- search by filters -->
        <b-col cols="12" md="4"  order-md="3" ref="byFilters" :class="'ps-type-select ' + (show.byFilters ? 'selected' : '')" @click="selectFilterType('byFilters')">
          <h5>{{ $t("productsSearch.filters.byFilters") }}</h5>
        </b-col>
        <b-col cols="12"  order-md="6" v-if="show.byFilters" class="bx">

          <b-row class="ps-sub-filter bx">
            <!-- filter: area -->
            <b-col cols="12" md="6" class="px-0">
              <div class="ps-sub-filter-title">{{ $t('productsSearch.filters.areas') }}</div>
              <b-form-select v-model="selected.area" >
                <b-form-select-option value="">{{ $t('productsSearch.filters.areas') }}</b-form-select-option>
                <b-form-select-option v-for="area in filters.areas" :value="area.value">
                  {{ $t(area.text) }}
                </b-form-select-option>
              </b-form-select>
            </b-col>

            <!-- filter: typology -->
            <b-col cols="12" md="6" class="px-0 bl">
              <div class="ps-sub-filter-title">{{ $t('productsSearch.filters.typologies') }}</div>
              <b-form-select v-model="selected.typology" >
                <b-form-select-option value="">{{ $t('productsSearch.filters.typologies') }}</b-form-select-option>
                <b-form-select-option v-for="typology in filters.typologies" :value="typology.value">
                  {{ $t(typology.text) }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>

          <b-row class="ps-sub-filter bx">
            <!-- filter: family -->
            <b-col cols="12" md="6" class="px-0">
              <div class="ps-sub-filter-title">{{ $t('productsSearch.filters.families') }}</div>
              <b-form-select v-model="selected.family" >
                <b-form-select-option value="">{{ $t("productsSearch.filters.families") }}</b-form-select-option>
                <b-form-select-option v-for="family in filters.families" :value="family.value">
                  {{ $t(family.text) }}
                </b-form-select-option>
              </b-form-select>
            </b-col>

            <!-- filter: dilution -->
            <b-col cols="12" md="6" class="px-0 bl">
              <div class="ps-sub-filter-title">{{ $t('productsSearch.filters.dilutions') }}</div>
              <b-form-select v-model="selected.dilution" >
                <b-form-select-option value="">{{ $t("productsSearch.filters.dilutions") }}</b-form-select-option>
                <b-form-select-option v-for="dilution in filters.dilutions" :value="dilution.value">
                  {{ $t(dilution.text) }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>

          <b-row class="ps-sub-filter bx">
            <!-- filter: drying -->
            <b-col cols="12" md="6" class="px-0">
              <div class="ps-sub-filter-title">{{ $t('productsSearch.filters.dryings') }}</div>
              <b-form-select v-model="selected.drying" >
                <b-form-select-option value="">{{ $t("productsSearch.filters.dryings") }}</b-form-select-option>
                <b-form-select-option v-for="drying in filters.dryings" :value="drying.value">
                  {{ $t(drying.text) }}
                </b-form-select-option>
              </b-form-select>
            </b-col>

            <!-- filter: appMethod -->
            <b-col cols="12" md="6" class="px-0 bl">
              <div class="ps-sub-filter-title">{{ $t('productsSearch.filters.appMethods') }}</div>
              <b-form-select v-model="selected.appMethod" >
                <b-form-select-option value="">{{ $t("productsSearch.filters.appMethods") }}</b-form-select-option>
                <b-form-select-option v-for="appMethod in filters.appMethods" :value="appMethod.value">
                  {{ $t(appMethod.text) }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>

          <b-row class="ps-sub-filter-search">
            <adw-button class="col px-0 col-md-6 col-12"
                  :class="{ 'disabled': validateProductsByFilters }"
                  @button-click="searchProductsByFilters">
              <h3>{{ $t("actions.searchProduct") }}</h3>
            </adw-button>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container ref="result-container" fluid>
      <!-- results -->
      <products-search-b2-b-result v-show="show.results"
                                   :serials-dto="results"
                                   :show-tds.sync="selected.showTds"
                                   @showDetail="hideAll"
                                   @hideDetail="showAll">
      </products-search-b2-b-result>

    </b-container>
  </div>
</template>

<script>
import { productsSearchB2BCombos } from "@/commons/products-search-b2b-combos"
import ProductsSearchService from "@/services/api-services/products-search-service"
import ProductsSearchB2BResult from "@/views/ProductsSearch/ProductsSearchB2BResult";
import ProductsSearchCustomer from "@/components/products-search/products-search-customer";
import AdwButton from "@/components/global-components/adw-button";

export default {
  name: "ProductsSearchB2B",

  components: {
    ProductsSearchCustomer,
    ProductsSearchB2BResult,
    AdwButton,
  },

  data() {
    return {
      showMenu: true,
      show: {
        header: true,
        byNameOrCode: false,
        bySerialAndColor: false,
        byFilters: false,

        back: false,
        results: false
      },

      filters: productsSearchB2BCombos.filters,

      selected: {
        nameOrCode: null,

        serialCode: null,
        colorCode: null,

        area: "",
        typology: "",
        family: "",
        dilution: "",
        drying: "",
        appMethod: ""
      },

      results: null,
      bradcrumbItems: [
        {
          text: this.$t('breadcrumb.productSearch'),
          to: { path: '/products-search' }
        },
        {
          text: this.$t('breadcrumb.professional'),
          to: { path: '/products-search/b2b' }
        }
      ]
    }
  },

  methods: {
    toggleResults(filter, results) {
      if (filter) {
        Object.keys(this.show).forEach((s) => this.show[s] = false)
        this.show[filter] = true
        this.show.results = results != null || results !== undefined
        this.results = results
      } else {
        this.filtersBack()
      }
    },

    filtersBack() {
      this.show.header = true;
      //Object.keys(this.show).forEach((s) => this.show[s] = true)
      this.show.results = false
      this.results = null
    },

    selectFilterType(filterType) {
      this.show.byNameOrCode = false;
      this.show.bySerialAndColor = false;
      this.show.byFilters = false;
      this.show.header = true;
      this.show.results = false
      this.results = null
      this.show[filterType] = true;

      this.scrollMeTo(filterType)
    },


    searchProductsByNameOrCode() {
      if (!this.validateProductsByNameOrCode) {
        ProductsSearchService.searchProductsByNameOrCode({
          nameOrCode: this.selected.nameOrCode
        })
            .then((response) => {
              this.toggleResults('byNameOrCode', response.data.serialsDto)
            })
            .catch((error) => {
              console.error(error)
              this.toggleResults()
            }).finally(() => {
              this.scrollMeTo('result-container')
            })
      }
    },

    searchProductsBySerialAndColor() {
      if (!this.validateProductsBySerialAndColor) {
        ProductsSearchService.searchProductsBySerialAndColor({
          serialCode: this.selected.serialCode,
          colorCode: this.selected.colorCode
        })
            .then((response) => {
              this.toggleResults('bySerialAndColor', response.data.serialsDto)

              if(this.selected.showTds) {
                
              }
            })
            .catch((error) => {
              console.error(error)
              this.toggleResults()
            }).finally(() => {
              this.scrollMeTo('result-container')
            })
      }
    },

    searchProductsByFilters() {
      if (!this.validateProductsByFilters) {
        ProductsSearchService.searchProductsByFilters({
          area: this.selected.area,
          typology: this.selected.typology,
          family: this.selected.family,
          dilution: this.selected.dilution,
          drying: this.selected.drying,
          appMethod: this.selected.appMethod
        })
            .then((response) => {
              this.toggleResults('byFilters', response.data.serialsDto)
            })
            .catch((error) => {
              console.error(error)
              this.toggleResults()
            }).finally(() => {
              this.scrollMeTo('result-container')
            })
      }
    },

    hideAll() {
      this.showMenu = false
    },

    showAll() {
      this.showMenu = true
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      this.$root.$emit('scrollToElement', top);
    }
  },

  computed: {
    validateProductsByNameOrCode() {
      return ProductsSearchService.isEmpty(this.selected.nameOrCode)
    },

    validateProductsBySerialAndColor() {
      return ProductsSearchService.isEmpty(this.selected.serialCode)
          && ProductsSearchService.isEmpty(this.selected.colorCode)
    },

    validateProductsByFilters() {
      return ProductsSearchService.isEmpty(this.selected.area)
          && ProductsSearchService.isEmpty(this.selected.typology)
          && ProductsSearchService.isEmpty(this.selected.family)
          && ProductsSearchService.isEmpty(this.selected.dilution)
          && ProductsSearchService.isEmpty(this.selected.drying)
          && ProductsSearchService.isEmpty(this.selected.appMethod)
    }
  },

  mounted() {
    const serialToSearch = this.$route.query.serial
    if (serialToSearch) {
      const colorToSearch = this.$route.query.color
      this.selected.serialCode = serialToSearch
      this.selected.colorCode = colorToSearch

      if(colorToSearch) {
        this.selected.showTds = this.$route.query.showTds === 'true'
      } else {
        this.selected.showTds = false
      }

      console.log('showTds', this.selected.showTds)

      this.searchProductsBySerialAndColor()
    }
  }
}
</script>
