import http from "@/util/http-common";

class AuthService {
   login(username, password) {
    return http
      .post("/auth/", {
        username: username,
        password: password,
      })
      .then((response) => {
        if (response.data.accessToken) {           
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response;
      });
  }
  
  logout() {
    localStorage.clear()
  }

}
export default new AuthService();
