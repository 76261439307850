export const offerInfo = {  
  offer: "Angebot",
  offerRequest: "Angebotsanfrage",
  noOffers: "Keine Angebote in der Liste vorhanden. Sie können ein neues Angebot direkt aus Ihrem Warenkorb anfordern.",
  isExpired: "Das Angebot mit Ablaufdatum",
  notValid: "nicht mehr gültig",
  expireIn: "Achtung: Ablaufdatum dieses Angebots: ",
  createdOn: "Erstellungsdatum",
  text: {
    ACCEPTED: "Das Angebot wurde überprüft. Um das Angebot anzunehmen, klicken Sie bitte auf die Schaltfläche Bestellung Anfordern.",
    PENDING: "Ihre Angebotsanfrage wird schnellstmöglich überarbeitet",
    EXPIRED: "Sie können innerhalb von 15 Tagen nach Ablauf der Frist ein neues Angebot beantragen, anschließend wird es gelöscht.",
    REJECTED: "Das Angebot wurde abgewiesen",
    CLOSED: "Für dieses Angebot wurde bereits eine Bestellung angefordert"
  },
  requestStatus: {
    PENDING: "Ausstehend",
    ACCEPTED: "Angenommen:",
    REJECTED: "Abgewiesen",
    CLOSED: "Geschlossen",
    EXPIRED: "Abgelaufen"
  }
}