<template>
  <section>
    <private-area-selector></private-area-selector>

    <div class="pc-container">
      <div class="item-2">
        <sds-sidebar></sds-sidebar>
      </div>
      <div class="item">
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import PrivateAreaSelector from "@/components/private-area/private-area-selector";
import SdsSidebar from "@/views/SdsSearch/SdsSearchSidebar.vue";

export default {
  name: "SdsSearch",

  components: {
    SdsSidebar,
    PrivateAreaSelector
  },

  data() {
    return {

    };
  },
  computed: {
    ...mapState({
      userRole: (state) => state.auth.user.role,
    }),
  },

  methods: {
    search(search) {
      search.preventDefault();
      if (this.searchValue !== '') {
        this.$store
            .dispatch('searchCustomerAgent', this.searchValue)
            .then((c) => {
              this.customers = c.content;
            })
            .catch((e) => {
              this.customers = [];
            });
      } else {
        this.loadAgentData();
      }
    },


  },

  mounted() {

  },
};
</script>
