<template>
  <div class="bx" style="padding: 20px;">
    <h3>{{ $t("titles.checkoutOffer") }}</h3>
    <br/>

    <feedback-alert
        class="w-100"
        :status="feedbackStatus"
        :message="feedbackMessage"
        @clearStatus="clearStatus"
        @back="toList">
    </feedback-alert>

    <div v-if="feedbackStatus != 'Success'">
      <h4>{{ $t("subtitles.deliveryDateRequested") }}</h4>
      <p>{{ $t("instructions.checkout.deliveryDateRequested") }}</p>
      <b-form-group class="col-md-5 px-0">
        <b-form-datepicker
            v-model="form.deliveryDateRequested"
            :min="tomorrow"
            :placeholder="$t('formCheckout.chosenDate')"
            :locale="$i18n.locale">
        </b-form-datepicker>
      </b-form-group>
      <br/>

      <h4>{{ $t("subtitles.acceptMultipleShipping") }}</h4>
      <p>{{ $t("instructions.checkout.acceptMultipleShipping") }}</p>
      <b-form-group class="col-md-8 px-0">
        <b-checkbox
            v-model="form.acceptMultipleShipping"
            switch
            id="acceptMultipleShipping">
          {{ $t("formCheckout.acceptMultipleShipping") }}
        </b-checkbox>
      </b-form-group>
      <br/>

      <h4>{{ $t("subtitles.offerNote") }}</h4>
      <p>{{ $t("instructions.checkout.offerNote") }}</p>
      <b-form-group class="col-md-8 px-0">
        <b-form-textarea
            v-model="form.note"
            type="date"
            :placeholder="$t('formCheckout.offerNote')"
            maxlength="255">
        </b-form-textarea>
      </b-form-group>
      <br/>

      <div class="table-responsive" ref="dataTableWrap">
        <h4>{{ $t("subtitles.summary") }}</h4>
        <b-table
            :items="items"
            :keys="fields"
            :fields="fields"
            ref="dataTable"
            stacked="sm"
            show-empty>
          <template #empty="">
            <h5 class="text-center">{{ $t("formCheckout.emptyOffer") }}</h5>
          </template>

          <template #cell(productCode)="data">
            <span>{{ data.item.productCode }} <br/>{{ data.item.description }}</span>
          </template>

          <template #cell(finalUnitPrice)="data" @click.stop>
            <span v-if="data.item.finalUnitPrice">{{ data.item.finalUnitPrice.toFixed(2) }} €/{{ data.item.um }}</span>
            <span v-else-if="!data.item.finalUnitPrice">
              {{ $t("product.notAssigned") }}
              <b-icon-info-circle aria-hidden="true" @click="$refs.notAssignedModal.show()"></b-icon-info-circle>
            </span>
          </template>

          <template #cell(unitQty)="data">
            <span> {{ data.item.unitQty }} {{ data.item.um }} </span>
          </template>

          <template #cell(rowWeight)="data">
            <span> {{ calculateRowWeight(data.item) }} Kg. </span>
          </template>

          <template #cell(rowTotalPrice)="data">
            <span v-if="data.item.finalUnitPrice">{{ calculateRowTotal(data.item) }} €</span>
            <span v-else-if="!data.item.finalUnitPrice"> - </span>
          </template>

          <template #custom-foot>
            <b-tr>
              <b-td colspan="4" variant="" class="text-right px-5">
                <strong class="px-2">{{ $t("orderInfo.totalWeight") }}</strong>
              </b-td>
              <b-td>{{ calculateTotalWeight() }} Kg.</b-td>
              <b-td>&nbsp;</b-td>
            </b-tr>
            <!--b-tr>
              <b-td colspan="5" variant="" class="text-right px-5">
                <strong class="px-2">{{ $t("formCheckout.totalOrder") }}</strong>
              </b-td>
              <b-td>{{ total() }} €</b-td>
            </b-tr-->
          </template>
        </b-table>

        <!--span class="d-sm-none">
          <hr/>
          <strong class="px-2">{{ $t("formCheckout.totalOrder") }} </strong>
          {{ total() }} €
        </span-->
      </div>

      <div class="row w-100 justify-content-end">
        <adw-button class="link w-50"
                    @button-click="$router.push('/profile-customer/cart')">
          <h3>{{ $t("actions.backToCart") }} </h3>
        </adw-button>
        <adw-button :class="'w-50' + (items.length === 0 ? 'disabled' : '')"
            @button-click="sendOffer">
            <h3>{{ $t("actions.offerRequest") }}</h3>
        </adw-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import feedbackAlert from "@/components/feedback-alert.vue";
import AdwButton from "@/components/global-components/adw-button";
import errorsManager from "@/services/errors-service/errors-manager";

export default {
  name: "CheckoutOffer",

  components: { 
    feedbackAlert,
    AdwButton,
  },

  data() {
    return {
      now: new Date(),
      fields: [
        {
          key: "productCode",
          label: this.$i18n.t("product.product"),
          sortKey: "productCode",
          sortable: true,
        },
        {
          key: "finalUnitPrice",
          label: this.$i18n.t("product.unitPrice"),
          sortable: true,
          tdClass: "",
        },
        {
          key: "unitQty",
          label: this.$i18n.t("product.unitQty"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.$i18n.t("product.quantity"),
          sortable: false,
        },

        {
          key: "rowWeight",
          label: this.$i18n.t("orderInfo.item.rowWeight")
        },

        {
          key: "rowTotalPrice",
          label: this.$i18n.t("product.rowTotalPrice"),
          sortable: false,

          tdClass: " px-10",
        },
      ],
      form: {},
      validation: {
        invalid: [],
      },
      feedbackStatus: null,
      feedbackMessage: "",
    };
  },
  computed: {
    ...mapState({
      addresses: (state) => state.customer.customerinfo.shippingAddresses,
      customer: (state) => state.customer.customerinfo,
    }),
    items() {
      return this.$store.getters.getOfferItems;
    },
    formToSend() {
      return {
        items: this.form.items,
        deliveryDateRequested: this.form.deliveryDateRequested,
        acceptMultipleShipping: this.form.acceptMultipleShipping,
        note: this.form.note,
      };
    },

    tomorrow() {
      let delay = 2;

      if(this.items) {
        if(this.items.some((item) => !item.inStock)) {
          delay = 3;
        }
      }

      return new Date(
          this.now.getFullYear(),
          this.now.getMonth(),
          this.now.getDate() + delay
      );
    },
  },
  methods: {
    formatDate(date) {
      console.log("DATE", date);
      if (date instanceof Date) {
        return (
            date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2)
        );
      }
    },
    toList() {
      this.$router.push("/profile-customer/request-offers");
    },
    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },
    validationState(contextRef) {
      return this.invalidErrors.find(
          (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
          ? false
          : null;
    },
    validationError(contextRef) {
      if (this.invalidErrors.length > 0) {
        let find =
            this.invalidErrors.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
    sendOffer() {
      console.log("Offer", this.formToSend);
      this.$store
          .dispatch("checkoutOffer", this.formToSend)
          .then((response) => {
            this.$store.dispatch("getProductsCart");
            this.feedbackStatus = "Success";
            this.feedbackMessage = "SuccessCheckOutOffer";
          })
          .catch((error) => {
            this.feedbackStatus = "FailDismissable";
            this.feedbackMessage = errorsManager.feedback(error);
          });
    },
    calculateRowWeight (rowItem) {
      console.log('calculateRowWeight plo', rowItem.plo);
      console.log('multiply', (rowItem.quantity * rowItem.plo))
      return +(Math.round((rowItem.quantity * rowItem.plo) + "e+2") + "e-2");
    },
    calculateTotalWeight () {
      return +(Math.round(this.form.items.reduce (
          (sum, i) => sum + this.calculateRowWeight(i),
          0
      ) + "e+2")  + "e-2");
    },
    calculateRowTotal(rowItem) {
      return +(Math.round((rowItem.finalUnitPrice * rowItem.unitQty * rowItem.quantity) + "e+2")  + "e-2");
    },

    total() {
      return "-"
      //return !this.customer.unconditionalDiscountPct ? this.totalWithoutDiscount() :
      //    +(Math.round(this.totalWithoutDiscount() * (1 - (this.customer.unconditionalDiscountPct / 100)) + "e+2")  + "e-2");
    },
  },
  mounted() {
    this.form = {
      items: this.items,
      deliveryDateRequested: this.formatDate(this.tomorrow),
      acceptMultipleShipping: false, 
      note: "",
    }
    this.$refs.dataTable.refresh();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("ClearCheckoutOffer");
    next();
  },
};
</script>
