<template>
  <div>
    <!-- detail in a modal -->
    <b-modal v-if="!viewInLine"
             size="xl"
             scrollable
             hide-header
             hide-footer
             ref="productsSearchDetailModal">
      <products-search-detail :showBuy="showBuy"
                              :productSelected="loadedProduct"
                              :productsList="loadedProductsList"
                              :origin-page="originPage"
                              @closeModal="closeDetail">
      </products-search-detail>
    </b-modal>

    <!-- detail in-line -->
    <products-search-detail v-if="viewInLine && showDetail"
                            :view-in-line="true"
                            :showBuy="showBuy"
                            :productSelected="loadedProduct"
                            :productsList="loadedProductsList"
                            :anchorToTds="anchorToTds"
                            :origin-page="originPage"
                            @closeModal="closeDetail"
                            class="bx">
    </products-search-detail>
  </div>
</template>

<script>
import ProductsSearchService from "@/services/api-services/products-search-service"
import ProductsSearchDetail from "@/views/ProductsSearch/ProductsSearchDetail"

export default {
  name: 'ProductsSearchDetailW',

  components: {
    ProductsSearchDetail
  },

  props: {
    originPage: '',
    asdasd: '',
    product: {},
    viewInLine: {
      required: false,
      default: false
    },
    showBuy: {
      required: false,
      default: true
    }
  },

  data() {
    return {
      showDetail: false,
      loadedProduct: null,
      loadedProductsList: [],
      anchorToTds: false,
    }
  },

  methods: {
    gotoDetail(product, availableColors, link, anchorToTds) {
      // reset the selected product
      this.loadedProduct = null
      this.loadedProductsList = []

      this.anchorToTds = anchorToTds

      // load the product from db
      ProductsSearchService.searchProductsBySerialAndColor({
        serialCode: product.id.serial,
        colorCode: product.id.color
      })
          .then((response) => {
            this.openDetail(product, response.data.serialsDto, availableColors, link)
          })
          .catch((error) => {
            console.error(error)
          })
    },

    openDetail(product, serialsDto, availableColors, link) {
      if (serialsDto.length === 0) {
        this.parseDetailFromXLS(product)
      } else {
        this.parseDetailFromDB(serialsDto, availableColors)
      }

      // add link
      this.loadedProduct.link = link

      // show the detail
      if (this.viewInLine) {
        this.showDetail = true
      } else {
        this.$refs.productsSearchDetailModal.show()
      }
    },

    parseDetailFromXLS(product) {
      // create the product
      this.loadedProduct = product
      this.loadedProduct.itShortDesc = product.description
      this.loadedProduct.enShortDesc = product.description
      this.loadedProduct.deShortDesc = product.description
      this.loadedProduct.frShortDesc = product.description
      this.loadedProduct.esShortDesc = product.description
      this.loadedProduct.plShortDesc = product.description
      this.loadedProduct.ruShortDesc = product.description
      this.loadedProduct.csShortDesc = product.description

      // create the others colors
      this.loadedProductsList = [this.loadedProduct]
    },

    parseDetailFromDB(serialsDto, availableColors) {
      // extract the colors
      const colors = serialsDto[0].colors

      // extract the product
      this.loadedProduct = colors[0].products[0]

      // load the others colors
      if (availableColors) {
        this.loadedProductsList = availableColors
      } else {
        this.loadedProductsList = []
        colors.forEach(color => {
          color.products.forEach(item => this.loadedProductsList.push(item))
        })
      }
    },

    closeDetail() {
      if (this.viewInLine) {
        this.showDetail = false
      } else {
        this.$refs.productsSearchDetailModal.hide()
      }
      this.$emit('closeDetail')
    }
  }
}
</script>
