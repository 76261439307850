<template>
  <b-row class="bx">
    <template v-if="showMenu">
      <!-- no results -->
      <b-col v-if="serials && serials.length < 1" class="ps-result-no-data d-flex justify-content-center bt">
        <p>{{ $t("productsSearch.noResults") }}</p>
      </b-col>

      <!-- otherwise, show serials selection -->
      <b-container fluid v-else-if="showSerials">
        <b-row class="d-flex justify-content-center bt" v-if="serials && serials.length > perPage">
          <b-pagination class="mt-3"
                        @change="updateSerialsPageResults"
                        v-model="currentSerials"
                        :total-rows="serials.length"
                        :per-page="perPage">
          </b-pagination>
        </b-row>

        <b-row style="padding: 0;" v-if="serialsPage && serialsPage.length > 0">
          <b-col cols="12" sm="6" md="4" lg="3" class="m-0 p-0" v-for="product in serialsPage" :key="product.serial">
            <div @click="loadColors(product)">
              <products-search-item2 :productSelected="product"></products-search-item2>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <!-- otherwise, show colors selection -->
      <b-container fluid v-else>
        <b-row class="d-flex justify-content-center bt" v-if="colors.length > perPage">
          <b-pagination class="mt-3"
                        @change="updateColorsPageResults"
                        :v-model="currentColors"
                        :total-rows="colors.length"
                        :per-page="perPage">
          </b-pagination>
        </b-row>

        <b-row class="p-0" v-if="colorsPage && colorsPage.length > 0">
          <b-col cols="12" sm="6" md="4" lg="3" class="m-0 p-0" v-for="product in colorsPage" :key="product.serial + '-' + product.color">
            <div @click="loadDetails(product)">
              <products-search-item2 :productSelected="product"></products-search-item2>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </template>


    <!-- modal to open the product detail -->
    <products-search-detail-w ref="productsSearchDetailW"
                              class="ps-detail-frame"
                              view-in-line="true"
                              origin-page="b2b"
                              @closeDetail="closeDetail">
    </products-search-detail-w>
  </b-row>
</template>

<script>
import ProductsSearchItem2 from "@/views/ProductsSearch/ProductsSearchItem2";
import ProductsSearchDetailW from "@/views/ProductsSearch/ProductsSearchDetailW";
import ProductsSearchService from "@/services/api-services/products-search-service.js"

export default {
  name: "ProductsSearchB2BResult",

  components: {
    ProductsSearchDetailW,
    ProductsSearchItem2
  },

  props: {
    serialsDto: Array,
    showTds: Boolean,
  },

  data() {
    return {
      showMenu: true,

      defaultImage: 'ALC',
      perPage: 12,

      showSerials: false,

      serials: [],
      serialsPage: [],
      currentSerials: 1,

      colors: [],
      colorsPage: [],
      currentColors: 1,

      selectedProduct: null,
      selectedProductsList: []
    }
  },

  watch: {
    serialsDto: function (newVal) {
      this.refreshData(newVal)
    },


  },

  methods: {
    refreshData(serialsDto) {
      // update component's data
      this.initData(serialsDto);

      // jump to color when there is only one result
      if (this.serials && this.serials.length === 1) {
        this.loadColors(this.serials[0])
      } else {
        this.updateSerialsPageResults(1)
      }
    },

    initData(serialsDto) {
      this.showSerials = true

      this.serials = serialsDto
      this.serialsPage = []
      this.currentSerials = 1

      this.colors = []
      this.colorsPage = []
      this.currentColors = 1

      this.selectedProduct = null;
      this.selectedProductsList = [];
    },

    updateSerialsPageResults(newPage) {
      const prodIdx = (newPage - 1) * this.perPage
      this.serialsPage = this.serials ? this.serials.slice(prodIdx, prodIdx + this.perPage) : 0;
    },

    loadColors(p) {
      // reset selected product
      this.selectedProduct = null
      this.selectedProductsList = []

      // set colors
      this.colors = []
      p.colors.forEach(color => {
        color.serial = p.serial
        color.brand = color.products[0].brand
        this.colors.push(color)
      })

      this.updateColorsPageResults(1)
      this.showSerials = false

      if(this.showTds) {
        this.loadDetails(this.colors[0], true);
        this.$emit("update:showTds", false);
      }
    },

    updateColorsPageResults(newPage) {
      const prodIdx = (newPage - 1) * this.perPage
      this.colorsPage = this.colors.slice(prodIdx, prodIdx + this.perPage)
    },

    goToPrevious() {
      if (this.selectedProduct) {
        this.selectedProduct = null
        this.showSerials = true
      }
    },

    loadDetails(p, anchorToTds) {
      this.showMenu = false

      var availableColors = []
      this.colors.forEach(color => {
        color.products.forEach(product => availableColors.push(product))
      })

      const serialAndColor = p.serial + '/' + p.color;
            
      ProductsSearchService.getProductAmazonLink(serialAndColor).then(data =>{
          let link = data.data;
          p.products[0].link = data.data;
          this.$refs.productsSearchDetailW.gotoDetail(p.products[0], availableColors, link, anchorToTds);
        }
      );
      
      this.$emit('showDetail')
    },

    closeDetail() {
      this.showMenu = true

      this.$emit('hideDetail')
    }
  }
}
</script>

<style>
.ps-result-no-data * {
  padding: 10px;
  margin: 0;
}
</style>
