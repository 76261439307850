import {en} from './en/en_index'; 
import {it} from './it/it_index';
import {ru} from './ru/ru_index';
import {pl} from './pl/pl_index';
import {fr} from './fr/fr_index';
import {de} from './de/de_index';
import {es} from './es/es_index';

export const languages ={
    en,fr,it,ru,pl,de,es,
}
/*TOTYDI
add lists for different options
add missing field forms in pl & fr
add node fs get api google translate create same file in different language
*/