<template>
  <div>
    <!-- private area selector -->
    <private-area-selector left-selected="true"></private-area-selector>

    <b-container fluid>
      <b-row class="private-area-content px-0">

        <!-- private area: left section -->
        <b-col cols="12" md="7" class="private-area-left bck px-0">

          <!-- username -->
          <div class="private-area-row-100 px-3 text-uppercase bb">
            <h1 class="private-area-username">{{ user.username }}</h1>
          </div>

          <div class="private-area-row-100 px-3 bb justify-content-between">
            <h2>
              <b-form-checkbox v-if="loggedInPrivateArea && !isUserAgentOrCommercial && editMode"
                               switch
                               id="notificationMail"
                               v-model.trim="form.notificationMail"
                               name="notificationMail"
                               class="adw-pointer">
                {{ $t("formUser.notificationMailSwitch") }}
              </b-form-checkbox>

              <template v-else>
                <b-icon-check-circle-fill v-if="form.notificationMail"/>
                <b-icon-x-circle-fill v-else-if="!form.notificationMail"/>
                {{ $t("formUser.notificationMailSwitch") }}
              </template>
            </h2>

            <a v-if="loggedInPrivateArea && !isUserAgentOrCommercial"
               @click="toggleEditMode"
               style="cursor: pointer; text-decoration: underline; font-size: large;"
               class="pr-5">
              {{ $t(editMode ? "actions.cancel" : "actions.updateProfile") }}
            </a>
          </div>

          <!-- detail when private user -->
          <profile-user-private v-if="loggedInPrivateArea && userType === 'P' && !isUserAgentOrCommercial"
                                :formProp="form"
                                :user.sync="user"
                                :telephoneProp="null"
                                :countries="countries"
                                :sectors="sectors"
                                :invalidlist="invalid"
                                :ref="userType === 'P' ? 'user' : ''"
                                :editMode="editMode">
          </profile-user-private>

          <!-- detail when company -->
          <private-area-company v-if="loggedInPrivateArea && userType === 'C' && !isUserAgentOrCommercial"
                                :formProp="form"
                                :user.sync="user"
                                :telephoneProp="null"
                                :countries="countries"
                                :sectors="sectors"
                                :types="types"
                                :departments="departments"
                                :roles="roles"
                                :invalidlist="invalid"
                                :isInternalUser="isInternalUser"
                                :ref="userType === 'C' ? 'user' : ''"
                                :editMode="editMode">
          </private-area-company>

          <!-- detail when agent -->
          <profile-user-agent v-if="loggedInPrivateArea && isUserAgentOrCommercial"
                              :formProp="form"
                              :user.sync="user"
                              :telephoneProp="null"
                              :countries="countries"
                              :sectors="sectors"
                              :types="types"
                              :departments="departments"
                              :roles="roles"
                              :invalidlist="invalid"
                              :isInternalUser="isInternalUser"
                              :ref="isUserAgentOrCommercial ? 'user' : ''">
          </profile-user-agent>

          <!-- update button-->
          <div class="private-area-action-box" v-if="!isUserAgentOrCommercial && editMode">
            <div class="private-area-action">
              <adw-button class="w-100 border-0"
                  @button-click="update">
                  <h3>{{ $t("actions.updateProfile") }}</h3>
              </adw-button>
            </div>

            <div class="private-area-action">
              <adw-button class="w-100 border-0 black"
                  @button-click="toggleEditMode">
                  <h3>{{ $t("actions.cancel") }}</h3>
              </adw-button>
            </div>
          </div>

          <!-- feedback -->
          <feedback-alert class="w-100"
                          :status="feedbackStatus"
                          :message="feedbackMessage"
                          @clearStatus="clearStatus">
          </feedback-alert>
        </b-col>

        <!-- private area: left section -->
        <b-col class="private-area-right px-0">
          <!-- FIXME customer image -->
          <div class="private-area-background bb"></div>

          <!-- invite received -->
          <invite-received-list v-if="userType === 'C' && userRole === 'USER'"></invite-received-list>

          <!-- pending request status -->
          <request-status-component v-if="userType === 'C' && userRole === 'USER'"
                                    :open-link-customer-modal="openLinkCustomerModal">
          </request-status-component>

          <div v-if="userRole !== 'USER'" class="p-4 border-bottom text-center">
            <h3 class="text-uppercase">{{ $t("titles.startProductSearch") }}</h3>
            <p>{{ $t("subtitles.startProductSearch") }}</p>

            <adw-button class="my-4 mx-3 text-uppercase"
                @button-click="$router.push('/products-search/b2b')">
                <h3>{{ $t("actions.startProductSearch") }}</h3>
            </adw-button>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- notification for the mail subscription -->
    <b-modal centered
             ref="notificationMailSubscription"
             id="notificationMailSubscription"
             :title="$t('titles.notificationEmail')"
             @ok="submitNotificationMailSubscription(true)"
             @cancel="submitNotificationMailSubscription(false)"
             @close="submitNotificationMailSubscription(false)">
      <p class="my-4" id="notificationMailSubscriptionText"></p>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue"
import { mapState } from "vuex"
import PrivateAreaSelector from "@/components/private-area/private-area-selector"
import ProfileUserPrivate from "@/components/profile-user-private"
import PrivateAreaCompany from "@/components/profile-user-company"
import FeedbackAlert from "@/components/feedback-alert"
import RequestStatusComponent from "@/components/private-area/request-status-component"
import ProfileUserAgent from "@/components/profile-user-agent"
import countries from "@/commons/countries"
import { interestedPrivate } from "@/commons/interested-private"
import { businessDepartments } from "@/commons/business-departments"
import { businessTypes } from "@/commons/business-types"
import { businessRole } from "@/commons/business-roles"
import Validator from "@/services/validation-service/validator-service"
import ErrorManager from "@/services/errors-service/errors-manager"
import InviteReceivedList from "@/components/invite/invite-received-list";
import AdwButton from "@/components/global-components/adw-button";

export default {
  name: "ProfileUser",

  components: {
    InviteReceivedList,
    PrivateAreaSelector,
    ProfileUserPrivate,
    PrivateAreaCompany,
    FeedbackAlert,
    RequestStatusComponent,
    ProfileUserAgent,
    AdwButton,
  },

  data() {
    return {
      sectors: interestedPrivate,
      countries: countries,
      types: businessTypes,
      departments: businessDepartments,
      form: {
        businessDepartment: "",
      },
      feedbackMessage: "",
      feedbackStatus: null,
      invalid: [],
      otherError: { name: null, msg: null },
      notificationMail: null,
      openLinkCustomerModal: false,
      editMode: false,
      formPreChanges: null
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.user.userinfo,
      userRole: (state) => state.auth.user.role,
      userType: (state) => state.user.userinfo.type,
      loggedInPrivateArea: (state) => state.user.status.loggedInPrivateArea,
      loggedIn: (state) => state.auth.status.loggedIn,
      firstLogin: (state) => state.auth.status.firstlogin,
      isInternalUser: (state) => state.user.userinfo.isInternalUser
    }),

    isUserAgentOrCommercial() {
      return this.$store.getters.getUserRole === "AGENT" || this.$store.getters.getUserRole === "COMMERCIAL"
    },

    businessDepartment() {
      return this.form.businessDepartment
    },

    roles() {
      return businessRole.filter((r) => {
        let dep = businessDepartments.find(
            (d) => d.value === this.form.businessDepartment
        )
        return dep ? dep.roles.includes(r.value) : null
      })
    },
  },

  methods: {
    setForm(u) {
      this.form = {
        gender: u.gender,
        notificationMail: u.notificationMail,
        firstname: u.firstname,
        lastname: u.lastname,
        telephone: u.telephone,
        country: u.country,
        zipCode: u.zipCode,
        interested: u.interested,
        businessName: u.businessName,
        businessWebsite: u.businessWebsite,
        businessType: u.businessType,
        businessDepartment: u.businessDepartment,
        businessRole: u.businessRole,
      }
    },

    encodedUrl() {
      if (this.form.businessWebsite === "" || this.form.businessWebsite == null) {
        return null
      } else {
        try {
          return new URL(this.form.businessWebsite).origin
        } catch (e) {
          return e
        }
      }
    },

    showModal() {
      this.$refs.notificationMailSubscription.show()
    },

    hideModal() {
      this.$refs.notificationMailSubscription.hide()
    },

    clearStatus() {
      this.feedbackStatus = null
      this.feedbackMessage = ""
    },

    async submitNotificationMailSubscription(value) {
      let toUpdate
      if (this.userType === "P") {
        toUpdate = "updateUserPrivate"
      }
      if (this.userType === "C") {
        toUpdate = "updateUserCompany"
      }
      let submit = { notificationMail: value }
      await this.$store
          .dispatch(toUpdate, submit)
          .then(() => {
            this.form.notificationMail = value
            this.notificationMail = value
            this.$store.dispatch("refreshToken")
            this.feedbackStatus = "SuccessDismissable"
            this.feedbackMessage = "notificationMailSuccess"
          })
          .catch((error) => {
            this.submitted = true
            console.error(error)
            this.otherError.name = "updating"
            this.otherError.msg = error
            this.feedbackStatus = "FailDismissable"
            this.feedbackMessage = ErrorManager.feedback(error)
          })
    },

    update(event) {
      this.invalid = []
      this.otherError.name = null
      this.otherError.msg = null
      event.preventDefault()
      let url = this.form.businessWebsite

      let extravalues = {
        telephone: {
          name: "telephonePrefix",
          value: this.$refs.user.telephone,
        },
        zipCode: { name: "country", value: this.form.country },
        businessWebsite: {
          name: "encodedUrl",
          value: url,
        },
      }
      let formType
      let toUpdate
      if (this.userType === "P") {
        formType = "formUserPrivate"
        toUpdate = "updateUserPrivate"
      }
      if (this.userType === "C") {
        formType = "formUserCompany"
        toUpdate = "updateUserCompany"
      }
      try {
        let validation = Validator.validateForm(
            formType,
            this.form,
            extravalues
        )
        this.invalid = validation.invalid
      } catch (e) {
        console.error(e)
        this.otherError.name = "validation"
        this.otherError.msg = e
        this.invalid.push(this.otherError)
      }
      if (this.invalid.length > 0 || this.otherError.msg) {
        console.error("possible 409")
        console.error(this.invalid)
        console.error(this.otherError)
      } else {
        this.postForm(toUpdate)
      }
    },

    async postForm(toUpdate) {
      await this.$store
          .dispatch(toUpdate, this.form)
          .then(() => {
            this.$store.dispatch("refreshToken")
            this.feedbackStatus = "SuccessDismissable"
            this.feedbackMessage = "updateSuccess"
          })
          .catch((error) => {
            console.error(error)
            this.otherError.name = "updating"
            this.otherError.msg = error
            this.feedbackStatus = "FailDismissable"
            this.feedbackMessage = ErrorManager.feedback(error)
          })
          .finally(() => {
            this.editMode = false;
          })
    },

    toggleEditMode() {
      if (this.editMode) {
        this.form = this.formPreChanges;
        console.log(2, this.formPreChanges.firstname);
        console.log(2, this.form.firstname);
      } else {
        this.formPreChanges = (Vue.util.extend({}, this.form));
        console.log(1, this.formPreChanges.firstname);
        console.log(1, this.form.firstname);
      }
      this.editMode = !this.editMode;
    }
  },

  created() {
    if (this.loggedIn && !this.loggedInPrivateArea) {
      this.$store
          .dispatch("myself")
          .then((u) => {
            this.setForm(u)
            this.notificationMail = u.notificationMail
          })
          .catch((e) => console.error(e))
    } else {
      this.notificationMail = this.user.notificationMail
      this.setForm(this.user)
    }
  },

  watch: {
    loggedIn(logged, old) {
      if (old === false && logged === true) {
        this.$store.dispatch("myself")
            .then((u) => this.setForm(u))
            .catch((e) => console.error(e))
      }
    },

    notificationMail(newN, oldN) {
      if (this.firstLogin && newN === false) {
        this.showModal()
        Vue.nextTick(() => {
          document.getElementById("notificationMailSubscriptionText").innerHTML = this.$i18n.t("formUser.notificationMail")
        })
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.becomeClientSuccess) {
      next((vm) => {
        vm.feedbackStatus = "SuccessDismissable"
        vm.feedbackMessage = "becomeClientSuccess"
        vm.$router.replace({ query: {} })
      })
    } else {
      next({ query: {} })
    }
  },

  mounted() {
    if (this.$route.query.openLinkCustomerModal) {
      this.openLinkCustomerModal = true
    }
  }
}
</script>
