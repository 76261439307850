import ProductService from "../services/api-services/product-service";
import { getProductCartFromDto, buildProductDtoAddToCart } from "@/models/product";
import i18n from "@/plugins/i18n";

const initialState = {
  cart: {
    info: {
      id: null,
      customerCode: null,
      username: null,
      empty: true,
      totalElements: 0,
    },
    products: [],
  },
};

export const cart = {
  state: initialState,
  actions: {
    getCart({ commit, getters }) {
      let customer = getters.getCustomerCode;
      return ProductService.getProductsCart(customer).then(
        (response) => {
          let cartInfo = {
            id: response.data.id,
            customerCode: response.data.customerCode,
            username: response.data.username,
            empty: response.data.items.length === 0,
            totalElements: response.data.items.length,
          };
          console.debug(cartInfo);
          commit("LoadCartSuccess", cartInfo);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getProductsCart({ commit, dispatch, getters }) {
      let customer = getters.getCustomerCode;
      return dispatch("getCart").then(
        (response) => {
          let products = response.data.items.map((rc) => {
            let product = getProductCartFromDto(rc);
            console.log(product)
            if (product.findDescriptionPerLang) {
              product.description = product.findDescriptionPerLang(
                getters.getUser.language,
                i18n.fallbackLocale
              );
            }
            product.statusLabel = i18n.t("product.statusValue." + product.status);
            return product;
          });
          commit("LoadProductsSuccess", products);
          return Promise.resolve(products);
        },
        (error) => {
          if (error.status == 404) {
            commit("LoadCartEmpty", customer);
            return Promise.resolve([]);
          }
          return Promise.reject(error);
        }
      );
    },

    addProductsCart({ commit, dispatch, getters, state }, items) {
      console.log("module-cart.js addProductsCart", items);

      let customer = getters.getCustomerCode;
      let itemMaxSortOrder = getters.getMaxSortOrder;
      let itemsDtos = items.map((p) => {
        console.log("itemMaxSortOrder", itemMaxSortOrder);
        p.sortOrder=++itemMaxSortOrder;
        return buildProductDtoAddToCart(p)
      });

      console.log("addProductsCart before send", itemsDtos)
      return ProductService.postProductsInCart(customer, itemsDtos).then(
        (response) => {
          let cartInfo = {
            id: response.data.id,
            customerCode: response.data.customerCode,
            username: response.data.username,
            empty: response.data.items.length === 0,
            totalElements: response.data.items.length,
          };
          let items = response.data.items.map((rc) => {
            let product = getProductCartFromDto(rc);
            console.log(product)
            if (product.findDescriptionPerLang) {
              product.description = product.findDescriptionPerLang(
                getters.getUser.language,
                i18n.fallbackLocale
              );
            }
            product.statusLabel = i18n.t("product.statusValue." + product.status);
            return product;
          });

          commit("LoadCartSuccess", cartInfo);
          commit("LoadProductsSuccess", items);
          return Promise.resolve(items);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeProductsFromCart({ commit, dispatch, getters, state }, items) {
      let customer = getters.getCustomerCode;
      let itemsDtos = items.map((p) => p.id);
      console.log("REMOVE", itemsDtos);
      return ProductService.deleteProductsInCart(customer, itemsDtos).then(
        (response) => {
          console.debug(response.data);
          if (response.data) {
            let cartInfo = {
              id: response.data.id,
              customerCode: response.data.customerCode,
              username: response.data.username,
              empty: response.data.items.length == 0,
              totalElements: response.data.items.length,
            };
            let itemsData = response.data.items.map((rc) => {
              let product = getProductCartFromDto(rc);
              console.log(product)
              if (product.findDescriptionPerLang) {
                product.description = product.findDescriptionPerLang(
                  getters.getUser.language,
                  i18n.fallbackLocale
                );
              }
              product.statusLabel = i18n.t("product.statusValue." + product.status);
              return product;
            });
            console.debug(cartInfo);
            commit("LoadCartSuccess", cartInfo);
            commit("LoadProductsSuccess", itemsData);
          } else {
            commit("LoadCartEmpty", customer);
          }
          return Promise.resolve(items);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateCart({ commit, dispatch, getters }, items) {
      let customer = getters.getCustomerCode;
      let itemsDtos = items.map((p) => buildProductDtoAddToCart(p));
      console.log("UPDATE", itemsDtos);
      return ProductService.updateProductCart(customer, itemsDtos).then(
        (response) => {
          console.debug(response.data);
          let cartInfo = {
            id: response.data.id,
            customerCode: response.data.customerCode,
            username: response.data.username,
            empty: response.data.items.length === 0,
            totalElements: response.data.items.length,
          };
          //the items in response data have only productCode and quantity properties
          commit("LoadProductsSuccess", items);
          commit("LoadCartSuccess", cartInfo);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    LoadCartSuccess(state, cartInfo) {
      state.cart.info = cartInfo;
      console.log('LoadCartSuccess', state.cart.info);
    },
    LoadProductsSuccess(state, products) {
      state.cart.products = products;
      console.log('LoadProductsSuccess', state.cart.products);
    },
    LoadCartEmpty(state, customer) {
      state.cart = {
        info: {
          id: null,
          customerCode: null,
          username: null,
          empty: true,
          totalElements: 0,
        },
        products: [],
      };
    },
  },

  getters: {
    getProductsInCart: (state) => state.cart.products,
    getTotalProductsInCart: (state) => state.cart.info.totalElements,
    getMaxSortOrder: (state) => state.cart.products.length ? Math.max(...state.cart.products.map(p => p.sortOrder)) : 0,
  },
};
