export function createGetParams(params) {
  const getParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      params[key].forEach((value) => {
        if (value !== null && value !== undefined) {
          getParams.append(key, value);
        }
      });
    } else if (params[key] !== null && params[key] !== undefined) {
      getParams.append(key, params[key]);
    }
  });
  
  return getParams.toString();
}