<template>
  <div class="card">
    <div class="card-content">
      <div v-if="hasTds">{{ $t('productsSearch.tds.notFound') }}</div>

      <b-overlay :show="isLoading" rounded="sm">
        <b-row>
          <figure v-if="availableLanguages['it']"
                  class="tds-flag-image"
                  @click="downloadTds(availableLanguages['it'], 'IT')"
                  v-b-tooltip.hover v-b-tooltip.bottom title="IT">
            <img :src="getPathImg('IT_out')" class="flag-out"/>
            <img :src="getPathImg('IT_over')" class="flag-over"/>
          </figure>

          <figure v-if="availableLanguages['en']"
                  class="tds-flag-image"
                  @click="downloadTds(availableLanguages['en'], 'EN')"
                  v-b-tooltip.hover v-b-tooltip.bottom title="EN">
            <img :src="getPathImg('EN_out')" class="flag-out"/>
            <img :src="getPathImg('EN_over')" class="flag-over"/>
          </figure>

          <figure v-if="availableLanguages['fr']"
                  class="tds-flag-image"
                  @click="downloadTds(availableLanguages['fr'], 'FR')"
                  v-b-tooltip.hover v-b-tooltip.bottom title="FR">
            <img :src="getPathImg('FR_out')" class="flag-out"/>
            <img :src="getPathImg('FR_over')" class="flag-over"/>
          </figure>

          <figure v-if="availableLanguages['pl']"
                  class="tds-flag-image"
                  @click="downloadTds(availableLanguages['pl'], 'PL')"
                  v-b-tooltip.hover v-b-tooltip.bottom title="PL">
            <img :src="getPathImg('PL_out')" class="flag-out"/>
            <img :src="getPathImg('PL_over')" class="flag-over"/>
          </figure>

          <figure v-if="availableLanguages['es']"
                  class="tds-flag-image"
                  @click="downloadTds(availableLanguages['es'], 'ES')"
                  v-b-tooltip.hover v-b-tooltip.bottom title="ES">
            <img :src="getPathImg('ES_out')" class="flag-out"/>
            <img :src="getPathImg('ES_over')" class="flag-over"/>
          </figure>

          <figure v-if="availableLanguages['cs']"
                  class="tds-flag-image"
                  @click="downloadTds(availableLanguages['cs'], 'CS')"
                  v-b-tooltip.hover v-b-tooltip.bottom title="CS">
            <img :src="getPathImg('CS_out')" class="flag-out"/>
            <img :src="getPathImg('CS_over')" class="flag-over"/>
          </figure>

          <figure v-if="availableLanguages['de']"
                  class="tds-flag-image"
                  @click="downloadTds(availableLanguages['de'], 'DE')"
                  v-b-tooltip.hover v-b-tooltip.bottom title="DE">
            <img :src="getPathImg('DE_out')" class="flag-out"/>
            <img :src="getPathImg('DE_over')" class="flag-over"/>
          </figure>

          <figure v-if="availableLanguages['ru']"
                  class="tds-flag-image"
                  @click="downloadTds(availableLanguages['ru'], 'RU')"
                  v-b-tooltip.hover v-b-tooltip.bottom title="RU">
            <img :src="getPathImg('RU_out')" class="flag-out"/>
            <img :src="getPathImg('RU_over')" class="flag-over"/>
          </figure>
        </b-row>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import ProductsSearchService from "@/services/api-services/products-search-service"

export default {
  name: 'productsSearchTdsChoice',

  props: ['langs'],

  data() {
    return {
      availableLanguages: {},
      hasTds: false,
      isLoading: false
    }
  },

  created() {
    this.langs.forEach(l => {
      if (this.availableLanguages[l.lang] === undefined) {
        this.availableLanguages[l.lang] = {id: l.id, code: l.code}
      }
    })
    console.log('availableLanguages:', this.availableLanguages)

    this.hasTds = Object.keys(this.availableLanguages).length === 0
    console.log('hasTds:', this.hasTds)
  },

  methods: {
    getPathImg(name) {
      try {
        const images = require.context('@/assets/products-search/tds/', false, /\.png$/)
        return images("./" + name + ".png")
      } catch (err) {
        return ''
      }
    },

    downloadTds(version, lang) {
      console.log('downloadTds', version, lang)

      // start the loader
      this.isLoading = true

      ProductsSearchService.downloadTDS({
        id: version.id
      })
          .then((response) => {
            console.log('downloadTds DONE', response)

            // create the link containing the pdf
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            link.download = 'TDS-' + version.code.substr(0, 4).trim() + '-' + version.code.substr(4, 9).trim() + '-' + lang + '.pdf'
            document.body.appendChild(link)

            // click the link to download the pdf
            link.click()

            // remove the link
            document.body.removeChild(link)

            // stop the loader
            this.isLoading = false
          })
          .catch((error) => {
            console.log('downloadTds ERROR', error)

            // stop the loader
            this.isLoading = false
          })
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  cursor: pointer;
}

figure {
  width: 50px;
  margin: 20px 10px 20px 40px;
  text-align: center;

  &.tds-flag-image {
    .flag-out {
      display: block;
    }

    .flag-over {
      display: none;
    }

    &:hover {
      .flag-out {
        display: none;
      }

      .flag-over {
        display: block;
      }
    }
  }
}

div.card {
  box-shadow: 0 2px 3px rgba(255, 198, 3, 1), 0 0 0 1px rgba(255, 198, 3, 1)
}
</style>
