export const orderInfo = {
  order: "Bestellung",
  orderRequest: "Bestellungsanfrage",
  orderCode: "Bestellnummer",
  noOrders: "Keine Bestellungen in der Liste vorhanden. Sie können eine neue Bestellung direkt aus Ihrem Warenkorb anfordern.",
  isExpired: "Die Bestellung mit Ablaufdatum",
  notValid: "nicht mehr gültig",
  expireIn: "Achtung: Ablaufdatum Ihrer Bestellung ",
  createdOn: "Erstellungsdatum",
  deliveryDate: "Lieferdatum",
  acceptMultipleShipping: "Versand in Teillieferungen möglich",
  genericNote: "Allgemeine Hinweise",
  transportReason: "Speditionsgrund",
  port: "Porto",
  shippingNote: "Versandhinweise",
  statusFilter: "Statusfilter",
    totalOrder: "Order total",
    totalWeight: "Total weight",
  text: {
     ACCEPTED: "Ihre Bestellung wurde angenommen",
     PENDING: "Ihre Bestellung wird schnellstmöglich bearbeitet",
     EXPIRED: "Sie können innerhalb von 15 Tagen nach Ablauf der Frist eine neue Bestellung beantragen, anschließend wird sie gelöscht.",
     REJECTED: "Ihre Bestellung wurde abgewiesen"
  },
  requestStatus: {
     PENDING: "Ausstehend",
     ACCEPTED: "Angenommen:",
     REJECTED: "Abgewiesen",
     CLOSED: "Geschlossen",
     EXPIRED: "Abgelaufen"
  },
  orderStatus: {
     ALL: "All",
     I: "Übernommen",
     A: "Abgebrochen",
     B: "Gesperrt",
     C: "Bestätigt",
     E: "Abgeschickt",
     F: "Berechnet"
  },
  item: {
     quantityOrdered: "Bestellte Mge",
     quantityConfirmed: "Bestätigte Mge",
     quantityProcessed: "Verarbeitete Mge",
     discount: "Preisnachlass",
      rowWeight: "Row weight",
      rowTotal: "Total"
  }
};