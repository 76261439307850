<template>
  <b-modal 
             size="lg"
             scrollable
             centered
             body-class="p-3"
             ref="confirmXlsMod"
             :title="'ATTENZIONE!'"
             @ok="$emit('confirm')"
             >
             <b-overlay :show="loading" opacity="0.6">
      <b-container fluid >
        <!-- header -->
        <b-row>
          <!-- title -->
          <b-col>
            {{ $t('titles.upload') }}
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
    </b-modal>
</template>

<script>

export default {
  name: "ConfirmXls",
  data() {
    return {
      loading: false
    }
  },
  methods: {
    openConfirm() {
      this.$refs.confirmXlsMod.show();
    },
    closeConfirm() {
      this.$refs.confirmXlsMod.hide();
    },
    loadConfirm(load) {
      this.loading = load;
    },
  },
}
</script>