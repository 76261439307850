export const actions = {
  ok: "OK",
  logout: "Abmeldung",
  loginToProceed: "Bitte anmelden",
  loginCompleted: "Sie sind angemeldet",
  retrievePassword: "Wiederhergestellt",
  signUp: "Bitte registrieren",
  backToLogin: "Zurück zur Anmeldung",
  update: "Aktualisieren",
  updateProfile: "Profil bearbeiten",
  create: "Erstellen",
  access: "Zugang erhalten",
  send: "Senden",
  saveDraft: "Entwurf speichern",
  next: "Vor",
  back: "Zurück",
  cancel: "Abbrechen",
  newPassword: "Senden",
  confirmRegistration: "Registrierung abschließen",
  confirm: "Bestätigen",
  compileRequest: "Bitte Anfrage ausfüllen",
  continue: "Weiter",
  newRequest: "Neue Anfrage",
  associate: "Zuordnen",
  associateToExisting: "Bitte hier klicken",
  inviteNewUser: "Einen neuen Benutzer auffordern, Kunde zu werden",
  updateToCustomer: "Kunde werden",
  goToClientAssignement: "Kundennummer verwenden",
  understand: "Verstehe",
  delete: "Löschen",
  accept: "Annehmen",
  refuse: "Ablehnen",
  close: "Schließen",
  invite: "Aufforderung senden",
  showAllCustomer: "Alle Kunden anzeigen",
  edit: "Ändern",
  goToProfile: "Wechseln zum Profil",
  search: "Suchen",
  searchProduct: "Produkt suchen",
  addSelectedToChart: "Ausgewählte in den Warenkorb legen",
  addToChart: "In den Warenkorb legen",
  clear: "Löschen",
  deleteSelected: "Ausgewählte löschen",
  updateCart: "Warenkorb aktualisieren",
  offerRequest: "Angebotsanforderung",
  orderRequest: "Bestellungsanforderung",
  add: "Hinzufügen",
  buy: "Kaufen",
  showProduct: "Produkt anzeigen",
  itemToCart: "Ausgewähltes Produkt in den Warenkorb",
  itemsToCart: "Ausgewählte Produkte in den Warenkorb",
  requestNewOrder: "Neue Bestellung anfordern",
  requestNewOffer: "Neues Angebot anfordern",
  requestOrder: "Bestellungsanforderung",
  redoOrderRequest: "Wieder bestellen",
  backToCart: "Back to cart"
};
