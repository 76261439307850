export const sdsLanguages =[
    { value:'IT',  text:'languages.IT'},
    { value:'CN',  text:'languages.CN'},
    { value:'CZ',  text:'languages.CZ'},
    { value:'SR',  text:'languages.SR'},
    { value:'SB',  text:'languages.SB'},
    { value:'DE',  text:'languages.DE'},
    { value:'EL',  text:'languages.EL'},
    { value:'EN',  text:'languages.EN'},
    { value:'ES',  text:'languages.ES'},
    { value:'FR',  text:'languages.FR'},
    { value:'NL',  text:'languages.NL'},
    { value:'PL',  text:'languages.PL'},
    { value:'PT',  text:'languages.PT'},
    { value:'RM',  text:'languages.RM'},
    { value:'RU',  text:'languages.RU'},
    { value:'SK',  text:'languages.SK'},
    { value:'SL',  text:'languages.SL'},
    { value:'TR',  text:'languages.TR'},
    { value:'HU',  text:'languages.HU'},
]


