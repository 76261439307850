export const product = {
  searchProducts: "Поиск продукции",
  productCode: "Код продукта",
  productNameCode: "Название или код продукта или описание",
  brand: "Бренд",
  color: "Цвет",
  serialCode: "Код линии",
  colorCode: "Код цвета",
  packaging: "Размер упаковки",
  pkgAvailable: "В НАЛИЧИИ НА СКЛАДЕ",
  area: "Область",
  typology: "Типология",
  family: "Семейство",
  dilution: "Разбавление",
  dilution_water: "разбавление водой",
  dilution_solvent: "разбавление растворителем",
  drying: "Сушка",
  dryingIcon: "сушка",
  appMethod: "Приложение. Метод",
  results: "РЕЗУЛЬТАТЫ ПОИСКА",
  noResults:
    "Ваш поиск не дал никаких результатов. Если вам нужна информация или у вас есть вопрос, пожалуйста, свяжитесь с отделом обслуживания клиентов: alcea@alcea.com.",
  characteristics: "Характеристики",
  downloadTds: "СКАЧАТЬ ТЕХНИЧЕСКИЙ ПАСПОРТ В ФОРМАТЕ PDF",
  loginToDownloadTds:
    "Пожалуйста, войдите в систему, чтобы скачать технический паспорт",
  noTdsFound: "TDS не найдено",
  code: "Код",
  description: "Описание",
  specificWeight: "Гравитация",
  unitValue: "Единица измерения Количество",
  months: "месяцы",
  usage: "использование",
  internal_use: "внутреннее использование",
  external_use: "внешнее использование",
  external_internal_use: "внешнее/внутреннее использование",
  shelf: "срок годности",
  squareMeters: "с.м.",
  squareMetersPerLitre: "с.м./л",
  squareMetersPerKilogram: "с.м./кг",
  buttons: {
    downloadTds: "Выберите, на каком языке вы хотите загрузить TDS",
  },
  combos: {
    interested: {
      metalIndustry: "Металлургическая промышленность",
      woodIndustry: "Деревообрабатывающая промышленность",
      coilCoating: "Покрытие рулонов",
      construction: "Строительство",
      homeLine: "Бытовая линия",
      tintometrics: "Tintometrics",
    },
    areas: {
      edil: "СТРОИТЕЛЬСТВО (СТЕНЫ)",
      metal: "МЕТАЛЛУРГИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ",
      wood: "ДЕРЕВЯННАЯ ПРОМЫШЛЕННОСТЬ",
      edil_metal_wood: "СТРОИТЕЛЬСТВО-МЕТАЛЛ-ДЕРЕВО",
      coil: "КАТУШКА",
      plastic: "ПЛАСТИК ВСЕ ОТРАСЛИ",
      paste: "ЦВЕТНАЯ ПАСТА, ПРЕОБРАЗОВАТЕЛЬ, ОТВЕРДИТЕЛЬ ДЛЯ ПЕРЕПРОДАЖИ",
      corrosion: "АНТИКОРРОЗИЙНЫЕ, МОРСКИЕ И Т.Д.",
    },
    typologies: {
      dye: "КРАСКИ, ПАТИНЫ, КОНЦЕНТРИРОВАННЫЕ ДЛЯ ДЕРЕВА, ЧЕРНИЛА",
      paste: "КОНЦЕНТРИРОВАННЫЕ ЦВЕТНЫЕ ПАСТЫ",
      background: "ШПАТЛЕВКИ И ГРУНТОВКИ АНТИКОРРОЗИОННЫЕ",
      intermediate: "ИНТЕРМЕДИАТ",
      washable: "МОЮЩИЕСЯ",
      various: "ДРУГОЕ",
      catalyst: "ОТВЕРДИТЕЛИ",
      thinner: "ЗАГУСТИТЕЛИ",
      converter: "КОНВЕРТЕР",
      dust: "ТЕРМОСЕТТИНГ-ПОРОШОК",
      back: "БАК",
      impregnant: "ИМПРЕГНАНТЫ",
      raw_material: "КОНЦЕНТРИРОВАННЫЕ ЦВЕТНЫЕ ПАСТЫ3",
    },
    families: {
      acrylic: "АКРИЛИК",
      pvdf: "ПВДФ",
      epoxy: "ЭПОКСИ",
      ketone: "КЕТОН",
      polyester: "ПОЛИЭСТЕР / ИЗОЦИАНИЛИКИ",
      alkyl: "АЛКИД",
      nitrocellulose: "НИТРОЦЕЛЛЮЛОЗА",
      unsaturated: "НЕНАСЫЩЕННЫЙ ПОЛИЭФИР",
      clorocianuro: "КЛОРОЦИАНУРО",
      phenolic: "ФЕНОЛИК",
      silicones: "СИЛИКОНЫ",
      urethane: "УРЕТАН",
      vinyl: "ВИНИЛ",
      polyester_melamine: "ПОЛИЭСТЕР/МЕЛАМИН",
    },
    dilutions: {
      water: "ВОДНАЯ ОСНОВА",
      dust: "ПУДРА",
      solvent: "ОСНОВА РАСТВОРИТЕЛЯ",
      noSolvent: "БЕЗ РАСТВОРИТЕЛЯ",
    },
    dryings: {
      air: "1К ВОЗДУХ",
      catalyzed: "2К ЗАКАЛЕННЫЙ",
      electrophoretic: "ЭЛЕКТРОФОРЕТИЧЕСКИЙ",
      oven: "ПЕЧЬ",
      curing: "УФ-ОТВЕРЖДЕНИЕ",
    },
    appMethods: {
      brush: "кисть",
      roller: "валик",
      sprinkle: "аэрозоль",
      airMix: "воздушная смесь",
      airLess: "безвоздушный",
      spatula: "шпатель",
      sponge: "губка",
      rollerCoating: "валиковое покрытие",
      curtainCoater: "устройство для покрытия штор",
      immersion: "заглубление",
      trowel: "шпатель",
      cloth: "ткань",
      conventional: "обычный",
      flowCoatings: "поточные покрытия",
      hplv: "гплв",
      electrostaticSpray: "электростатическое распыление",
      electrophoresisNS: "электрофорез нс",
      anode: "АНОДНЫЙ ЭЛЕКТРОФОРЕЗ УГЛУБЛЕНИЕ",
      cathode: "КАТОДНЫЙ ЭЛЕКТРОФОРЕЗ УГЛУБЛЕНИЕ",
    },
  },
};
