<template>
      <div class="row justify-content-center">
    <div class="col-md-12">
      <h3>{{ $t("titles.bankInformation") }}</h3>     
      <b-form-group :invalid-feedback="validationError('bankName')">
        <label for="bankName"> {{ $t("formCustomer.bankName")  }}</label>
        <b-form-input
          type="text"
          id="bankName" 
          name="bankName"
          v-model="form.bankName"
          :placeholder="$t('formCustomer.bankName') +' *'"
          :state="validationState('bankName')"
          @change="$emit('fieldUpdated')"
        ></b-form-input> </b-form-group
      ><b-form-group :invalid-feedback="validationError('iban')">
        <label for="iban"> {{ $t("formCustomer.iban") }}</label>
        <b-form-input
          type="text"
          id="iban"
          name="iban"
          v-model="form.iban"
          :placeholder="$t('formCustomer.iban')+' *'"
          :state="validationState('iban')"
          @change="$emit('fieldUpdated')"
        ></b-form-input>
      </b-form-group>
    </div>
  </div>
</template>
<script>
export default {
    name:'FormBankInformationmation',
     props: {
    form: Object(),
    currentStep: Object(),
    completed: Boolean(),
    invalidErrors: Array(),
  },
  data() {
    return {
      self: "BankInformation",
      fields: ["bankName", "iban"],
    };
  },
    methods: {
    validationState(contextRef) {
      return this.invalidErrors.find(
        (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
        ? false
        : null;
    },

    validationError(contextRef) {
      let find = this.invalidErrors.find(
        (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      );
      return find ? this.$i18n.t(find.error) : null;;
    },
  },
}
</script>