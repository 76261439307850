
import i18n from "../plugins/i18n";
import countries from "i18n-iso-countries"
import {Alpha2Code} from "i18n-iso-countries"
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/it.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));
countries.registerLocale(require("i18n-iso-countries/langs/pl.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

function countriesNames() {
    const lang = i18n.locale.toString();
    const list = countries.getNames(lang, { select: "official" });
    let listMap = Object.keys(list).map((key) => ({
      value: key,
      label: list[key],
    }));
    let maplist= listMap
      .filter((c) => c.label)
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      return maplist;
  }

  function countriesCodes() {
    let listMap = Object.keys(countries.getAlpha2Codes()).map((key) => ({
      value: key,
      label: key,
    }));
    let maplist= listMap
      .filter((c) => c.label)
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      return maplist;
  }
export const CountriesAlpha2 =countriesCodes()
const countriesList =countriesNames()
export default countriesList;