export const subtitles = {
  welcome: "La piattaforma digitale di Alcea",
  homeText: "La nuova piattaforma digitale Alcea permette di scoprire tutti i prodotti e le caratteristiche tecniche.",
  requestOrders: "Richieste ordini",
  requestOffers: "Richieste offerte",
  shippingAddress: 'Indirizzo di spedizione',
  customerOrderCode: "Codice ordine personalizzato",
  deliveryDateRequested: 'Tempistiche consegna',
  acceptMultipleShipping: 'Spedizione Frazionabile',
  shippingNote: 'Note di spedizione',
  genericNote: 'Note generiche',
  agentNote: 'Note Commerciale',
  offerNote: 'Note Offerta',
  summary: 'Riepilogo',
  registrationsWithouWs: "Non hai un sito web o una pagina social? contattaci all'indirizzo ", 
  registrationsWithouWs2: "e verrai ricontattato da un nostro operatore per completare la procedura di registrazione.",
  startProductSearch: "Scopri i nostri prodotti attraverso il nuovo Motore di ricerca Alcea",
};
