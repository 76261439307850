export const subtitles = {
  welcome: "Plataforma digital de Alcea",
  requestOrders: "Solicitud de pedidos",
  requestOffers: "Solicitud de ofertas",
  shippingAddress: "Dirección de expedición",
  customerOrderCode: "Código de pedido personalizado",
  deliveryDateRequested: "Tiempo de entrega",
  acceptMultipleShipping: "Expedición fraccionable",
  shippingNote: "Notas de expedición",
  genericNote: "Notas generales",
  summary: "Sumario"
};
