import Requirements from "./requirements";

class Validator {
  validate(formType, valueField, requirementRef, extraValue) {
    //or slice requirements
    let form = Requirements.forms[formType];
    let nameRef;
    if (formType.includes(".")) {
      form = Requirements.forms;
      let innerform = formType.split(".");
      innerform.forEach((ft) => {
        form = form[ft];
      });
    }
    nameRef = requirementRef;
    let requirements = form[requirementRef];
    let valid = { name: nameRef, value: true, error: "" };
    if (requirementRef && requirements) {
      let required = requirements.required ? this.valid(valueField) : true;
      if (!required) {
        valid = {
          name: nameRef,
          value: false,
          error: "errors.missing",
        };
      } else if (this.valid(valueField)) {
        let maxlenght = requirements.maxlenght
          ? this.validLenght(valueField, requirements.maxlenght)
          : true;
        let patternTest = requirements.patternTest
          ? requirements.patternTest.test(valueField)
          : true;
        let extra = extraValue ? extraValue.value : null;
        let validationMethod = requirements.validation
          ? requirements.validationMethod(valueField, extra)
          : true;
        // TODO create error service con errorsTypes and costructors
        // Error hierarcy : missing, invalidlenght, invalidField
        !validationMethod
          ? (valid = {
              name: nameRef,
              value: false,
              error: "errors.invalidField",
            })
          : null;
        !patternTest
          ? (valid = {
              name: nameRef,
              value: false,
              error: "errors.invalidField",
            })
          : null;
        !maxlenght
          ? (valid = {
              name: nameRef,
              value: false,
              error: "errors.invalidLenght",
            })
          : null;
      }
    }
    //TODO to remove
    valid.error != "" ? console.log("error", valid) : null;
    return valid;
  }
  validateForm(formType, formValues, extraValues) {
    let validated = { invalid: [], valid: [] };
    let extraValue = null;
    let fields = Object.keys(formValues);
    fields.forEach((field) => {
      let fieldName = field;
      let value = formValues[fieldName];
      if (extraValues) {
        extraValue = extraValues[fieldName] ? extraValues[fieldName] : null;
        if (extraValue) {
          let fieldvalue = { name: fieldName, value: formValues[fieldName] };
          let extraValidation = this.validate(
            formType,
            extraValue.value,
            extraValue.name,
            fieldvalue
          );
          extraValidation.value
            ? null
            : validated.invalid.push(extraValidation);
        }
      }
      if (Array.isArray(value)) {
        let arrayValidation = this.validateArray(
          formType,
          fieldName,
          value,
          extraValue
        );
        validated[fieldName]=arrayValidation
        // arrayValidation.forEach((obj) => {
        //   validated[fieldName].push(obj);
        // });
        let fieldValidation = this.validate(
          formType,
          formValues[field],
          fieldName,
          extraValue
        );
        fieldValidation.value
          ? validated.valid.push(fieldValidation)
          : validated.invalid.push(fieldValidation);
      } else if (this.isObject(value)) {
        let objectValidation = this.validateForm(
          formType + "." + fieldName,
          value,
          extraValue
        );
        validated[fieldName] = objectValidation;
      } else {
        let fieldValidation = this.validate(
          formType,
          formValues[field],
          fieldName,
          extraValue
        );
        fieldValidation.value
          ? validated.valid.push(fieldValidation)
          : validated.invalid.push(fieldValidation);
      }
    });
    return validated;
  }
  validateFormField(formType, fieldName, formValues, extraValues) {
    let validated = { invalid: [], valid: [] };
    let extraValue = null;
    let value = formValues[fieldName];
    if (extraValues) {
      extraValue = extraValues[fieldName] ? extraValues[fieldName] : null;
      if (extraValue) {
        let fieldvalue = { name: fieldName, value: formValues[fieldName] };
        let extraValidation = this.validate(
            formType,
            extraValue.value,
            extraValue.name,
            fieldvalue
        );
        extraValidation.value
            ? null
            : validated.invalid.push(extraValidation);
      }
    }
    if (Array.isArray(value)) {
      let arrayValidation = this.validateArray(
          formType,
          fieldName,
          value,
          extraValue
      );
      validated[fieldName]=arrayValidation
      // arrayValidation.forEach((obj) => {
      //   validated[fieldName].push(obj);
      // });
      let fieldValidation = this.validate(
          formType,
          formValues[field],
          fieldName,
          extraValue
      );
      fieldValidation.value
          ? validated.valid.push(fieldValidation)
          : validated.invalid.push(fieldValidation);
    } else if (this.isObject(value)) {
      let objectValidation = this.validateForm(
          formType + "." + fieldName,
          value,
          extraValue
      );
      validated[fieldName] = objectValidation;
    } else {
      let fieldValidation = this.validate(
          formType,
          formValues[field],
          fieldName,
          extraValue
      );
      fieldValidation.value
          ? validated.valid.push(fieldValidation)
          : validated.invalid.push(fieldValidation);
    }
    return validated;
  }
  validateArray(formType, fieldName, value, extraValue) {
    return value.map((element, index) => {
      let validated = { invalid: [], valid: [] };
      if (this.isObject(element)) {
        validated = this.validateForm(
          formType + "." + fieldName + ".objectElement",
          element,
          extraValue[index]
        );
      }
      return  validated ;
    });
  }

  valid(field) {
    if (Array.isArray(field)) {
      console.log("find array");
      return field.length < 1 ? false : field.every((elm) => this.valid(elm));
    } else if (this.isObject(field)) {
      console.log("find obj");
      let keys = Object.keys(field);
      let every = keys.every((el) => this.valid(field[el]));
      return every ? true : false;
    } else if(field !== null && field !== undefined && !(field.hasOwnProperty('length') && field.length === 0) && !isNaN(field)) {
      return true;
    } else {
      return !field || field.length < 1 ? false : true;
    }
  }
  validLenght(field, maxlenght) {
    if (typeof field == "string" && maxlenght) {
      return field.length < maxlenght;
    }
    return true;
  }
  isObject(obj) {
    return obj && !Array.isArray(obj) && typeof obj === "object";
  }
}

export default new Validator();
