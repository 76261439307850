export const roles = {
  generalManagement: "dirección general",
  property: "propietario",
  director: "director",
  departmentHead: "jefe de sección",
  operator: "operador",
  manager: "responsable",
  buyer: "comprador",
  officeWorker: "empleado",
  designer: "proyectista"
};
