export const offerInfo = {
  offer: "Offer ",
  offerRequest: "Offer request",
  noOffers: "There are no offers in the list, you can request a new offer directly from the cart.",
  isExpired: "The offer expired on",
  notValid: "It is no longer valid ",
  expireIn: "Attention: the offer will expire on",
  createdOn: "Creation date",
  text: {
     ACCEPTED: "The offer has been validated, to accept the offer click on the Order Request button.",
     PENDING: "The offer request will be evaluated as soon as possible. ",
     EXPIRED: "It is possible to request a new offer within 15 days from the deadline, after that period it will be eliminated.",
     REJECTED: "The offer has been rejected ",
     CLOSED: "An order has already been requested from this offer "
  },
  requestStatus: {
     PENDING: "Under review",
     ACCEPTED: "Accepted",
     REJECTED: "Rejected",
     CLOSED: "Closed",
     EXPIRED: "Expired"
  }
};