import userService from "../services/api-services/user-service";
import CustomerService from "../services/api-services/customer-service";

// in case di request sent? he can still accept an invitation?
const initialState = {
  invitations: {
    totalElements: 0,
    totalPages: 0,
    size: 0,
    first: true,
    last: false,
    empty: true,
  },
};

export const invitations = {
  state: initialState,
  actions: {
    getInvitationsUser({ commit }, page) {
      return userService
        .getInvitationsUser(page.page, page.row, ["expiration", "asc"])
        .then(
          (response) => {
            console.log(response);
            let invitations = {
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              size: response.size,
              fist: response.first,
              last: response.last,
              empty: response.empty,
            };
            commit("LoadInvitesSuccess", invitations);
            return Promise.resolve(response.content);
          },
          (error) => {
            commit("LoadInvitesFailure");
            return Promise.reject(error);
          }
        );
    },
    getInvitationsCustomer({ commit }, page) {
      return CustomerService.getInvitationsCustomer(page.page, page.row, [
        "expiration",
        "asc",
      ]).then(
        (response) => {
          console.log(response.data);
          let invitations = {
            totalElements: response.data.totalElements,
            totalPages: response.data.totalPages,
            size: response.data.size,
            fist: response.data.first,
            last: response.data.last,
            empty: response.data.empty,
          };
          commit("LoadInvitesSuccess", invitations);
          return Promise.resolve(response.data.content);
        },
        (error) => {
          commit("LoadInvitesFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    LoadInvitesSuccess(state, invitation) {
      state.invitations = invitation;
    },
    clearInvitations(state) {
      state.invitations = {
        totalElements: 0,
        totalPages: 0,
        size: 0,
        first: true,
        last: false,
        empty: true,
      };
    },
  },
  getters: {},
};
