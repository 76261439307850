<template>
  <b-card class="col-md-12">
    <div class="row justify-content-center my-2">
      <b-form-group
        class="col-md-2"
        :invalid-feedback="validationError('gender')"
      >
        <b-form-select
          v-model="contact.gender"
          :disabled="disabled"
          :state="validationState('gender')"
        >
          <b-select-option
            :value="null
            "
            selected
            :disabled="true"
          >
            {{ $t("formUser.gender.select") }}
          </b-select-option>
          <b-select-option v-for="g in genders" :value="g.value" :key="g.value">
            {{ $t("formUser.gender." + g.text) }}
          </b-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        id="name"
        :invalid-feedback="validationError('firstName')"
        class="col-md-5"
      >
        <b-form-input
          class="form-control"
          id="firstname"
          ref="firstname"
          v-model.trim="contact.firstName"
          :placeholder="$t('formUser.firstName') + ' *'"
          :state="validationState('firstName')"
          :disabled="disabled"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        class="col-md-5"
        :invalid-feedback="validationError('lastName')"
      >
        <b-form-input
          class="form-control"
          id="lastName"
          ref="lastName"
          v-model.trim="contact.lastName"
          :placeholder="$t('formUser.lastName') + ' *'"
          :state="validationState('lastName')"
          :disabled="disabled"
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="row justify-content-center my-2">
      <b-form-group
        class="col-md-12"
        :invalid-feedback="validationError('role')"
      >
        <b-form-select v-model="contact.role" :state="validationState('role')">
          <b-select-option
            selected
            disabled
            :value="null
            "
          >
            {{ $t("formUser.businessRole") }} *
          </b-select-option>

          <b-select-option v-for="rl in roles" :value="rl.value" :key="rl.key">
            {{ $t(rl.text) }}
          </b-select-option>
        </b-form-select>
      </b-form-group>
    </div>
    <div class="row justify-content-center my-2">
      <b-form-group
        class="col-md-6"
        :invalid-feedback="validationError('email')"
      >
        <b-form-input
          class="form-control"
          id="username"
          ref="username"
          v-model.trim="contact.email"
          type="email"
          :placeholder="$t('formUser.email') + ' *'"
          :state="validationState('email')"
          :disabled="disabled"
          @input="handleAssigenements"
        ></b-form-input>
      </b-form-group>
      <telephone-component
        class="mr-0 col-md-6"
        ref="telephone"
        :formType="type"
        :invalid="invalidErrors"
        :telephoneProp="null"
        v-model="contact.phoneNumber"
        :disabled="disabled"
      />
    </div>
    <p>{{ $t("formCustomer.documentsToRecive") }}</p>
    <div class="row justify-content-left my-2">
      <b-form-checkbox
        class="mx-2"
        v-model="billing.assigned"
        :value="assignmentValue"
        @change="handleAssigenementsCheckBilling()"
      >
        {{ $t("formCustomer.billing") }}
      </b-form-checkbox>
      <b-form-checkbox
        class="mx-2"
        v-model="transportDocument.assigned"
        :value="assignmentValue"
        @change="handleAssigenementsCheckTransportDocument()"
      >
        {{ $t("formCustomer.transportDocument") }}
      </b-form-checkbox>
      <b-form-checkbox
        class="mx-2"
        v-model="msds.assigned"
        :value="assignmentValue"
        @change="handleAssigenementsCheckMsds()"
      >
        {{ $t("formCustomer.msds") }}
      </b-form-checkbox>
      <b-form-checkbox
        class="mx-2"
        v-model="testSheets.assigned"
        :value="assignmentValue"
        @change="handleAssigenementsCheckTestSheets()"
      >
        {{ $t("formCustomer.testSheets") }}
      </b-form-checkbox>
    </div>
  </b-card>
</template>
<script>
import { mapState } from "vuex";
import countries from "../commons/countries";
import { businessRole } from "../commons/business-roles";
import { businessDepartments } from "../commons/business-departments";
import telephoneComponent from "./global-components/telephone-component.vue";

export default {
  components: { telephoneComponent },
  name: "cardContact",
  props: {
    contact: Object(),
    form: Object(),
    assignmentValue: String(),
    billing: Object(),
    transportDocument: Object(),
    msds: Object(),
    testSheets: Object(),
    invalidErrors: Array(),
    disabled: Boolean(),
  },
  data() {
    return {
      type: "C",
      countries: countries,

      genders: [
        { text: "mrs", value: "F" },
        { text: "mr", value: "M" },
      ],
      dataBilling: this.billing,
      dataTransportDocument: this.transportDocument,
      dataMsds: this.msds,
      datatestSheets: this.testSheets,
      fromBilling: this.form.billingEmail1,
      formTransportDocument: this.form.transportDocumentEmail1,
      formMsds: this.form.msdsEmail1,
      formTestSheets: this.form.testSheetsEmail1,
    };
  },
  computed: {
    roles() {
      let department = null;
      if (this.assignmentValue == "purchasingContact") {
        department = "PRC"; //purchasing
      }
      if (this.assignmentValue == "administrativeContact") {
        department = "ADM"; //administration
      }
      if (this.assignmentValue.includes("additionalContacts")) {
        let index = this.assignmentValue.split(".").pop();
        //console.log(index, this.form.additionalContacts[index]);
        department = this.form.additionalContacts[index].department;
      }
      return department
        ? businessRole.filter((r) =>
            businessDepartments
              .find((d) => d.value == department)
              .roles.includes(r.value)
          )
        : null;
    },
    telephone: {
      get() {
        return this.$refs.telephone.telephone;
      },
    },
  },
  methods: {
    validationState(contextRef) {
      return this.invalidErrors.find(
        (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
        ? false
        : null;
    },
    validationError(contextRef) {
      if (this.invalidErrors.length > 0) {
        let find =
          this.invalidErrors.find(
            (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
          ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
    removeContact() {
      this.$emit("removeContact");
    },
    handleAssigenementsCheckBilling() {
      let newVal = this.contact.email;
      this.billing.assigned == this.assignmentValue
        ? (this.billing.email1 = newVal)
        : (this.billing.email1 = this.fromBilling);
    },
    handleAssigenementsCheckTransportDocument() {
      let newVal = this.contact.email;
      this.transportDocument.assigned == this.assignmentValue
        ? (this.transportDocument.email1 = newVal)
        : (this.transportDocument.email1 = this.formTransportDocument);
    },
    handleAssigenementsCheckMsds() {
      let newVal = this.contact.email;
      this.msds.assigned == this.assignmentValue
        ? (this.msds.email1 = newVal)
        : (this.msds.email1 = this.formMsds);
    },
    handleAssigenementsCheckTestSheets() {
      let newVal = this.contact.email;
      this.testSheets.assigned == this.assignmentValue
        ? (this.testSheets.email1 = newVal)
        : (this.testSheets.email1 = this.formTestSheets);
    },

    handleAssigenements(newVal) {
      this.billing.assigned == this.assignmentValue
        ? (this.billing.email1 = newVal)
        : null;
      this.transportDocument.assigned == this.assignmentValue
        ? (this.transportDocument.email1 = newVal)
        :null;
      this.msds.assigned == this.assignmentValue
        ? (this.msds.email1 = newVal)
        : null;
      this.testSheets.assigned == this.assignmentValue
        ? (this.testSheets.email1 = newVal)
        : null;
    },
  },
  watch: {
    "contact.phoneNumber": {
      handler() {
          //console.log("handler contact.phoneNumber")
          this.$refs.telephone.onChange();
      },
      deep: true
    },
    "contact.email": {
      handler(newVal) {
        if (this.billing.assigned == this.assignmentValue) {
          this.$emit(
            "update:billing",
            Object.assign(this.billing, { email1: newVal })
          );
        }
        if (this.transportDocument.assigned == this.assignmentValue) {
          this.$emit(
            "update:transportDocument",
            Object.assign(this.transportDocument, { email1: newVal })
          );
        }
        if (this.msds.assigned == this.assignmentValue) {
          this.$emit(
            "update:msds",
            Object.assign(this.msds, { email1: newVal })
          );
        }
        if (this.testSheets.assigned == this.assignmentValue) {
          this.$emit(
            "update:testSheets",
            Object.assign(this.testSheets, { email1: newVal })
          );
        }
      },
    },
  },
};
</script>