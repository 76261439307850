export const businessRole =[
    { value:'GNM',  text:'roles.generalManagement'},//direz generale
    { value:'PRP',  text:'roles.property'},//proprietà
    { value:'DRC',  text:'roles.director'},//direttore
    { value:'DPH',  text:'roles.departmentHead'},//caporeparto
    { value:'OPR',  text:'roles.operator'},//operatore
    { value:'MNG',  text:'roles.manager'},//responsabile
    { value:'BYR',  text:'roles.buyer'},//buyer
    { value:'OFW',  text:'roles.officeWorker'},//impiegato
    { value:'DSG',  text:'roles.designer'},//progettista
]


