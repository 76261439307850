<template>
  <div class="ps-item-container bx by">

    <!-- name -->
    <h6 class="ps-item-name">
      {{ getShortDesc(productSelected) }}
      {{ getColorDesc(productSelected) }}
    </h6>

    <div class="ps-item-code">
      <!-- code -->
      {{ productSelected.serial + (productSelected.color ? '-' + productSelected.color : '') }}

      <!-- brand -->
      <span v-if="productSelected.brand != null">{{ productSelected.brand }}</span>
    </div>

    <!-- product image -->
    <div class="ps-item-img"><img :src="getPathImg()" :alt="productSelected.name"/></div>

    <!-- go to detail -->
    <b-col class="ps-bottom bt">
      <b-row>
        <b-col class="ps-item-label text-uppercase">
          <b>{{ $t('actions.showProduct') }}</b>
        </b-col>
        <b-col class="flex-grow-0 bl ps-item-button">
          <span>+</span>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
export default {
  name: 'ProductsSearchItem2',

  props: ['productSelected'],

  data() {
    return {
      defaultImage: 'ALC',
    }
  },

  methods: {
    getPathImg() {
      const images = require.context('@/assets/products-search/product/', false, /\.png$/)
      try {
        return images("./" + this.productSelected.lat + ".png")
      } catch (err) {
        if (this.productSelected.cat3 === 'W') {
          return images("./" + this.defaultImage + "W.png")
        } else if (this.productSelected.cat3 === 'S') {
          return images("./" + this.defaultImage + "S.png")
        } else {
          return images("./" + this.defaultImage + ".png")
        }
      }
    },

    getShortDesc(p) {
      return this.getDesc(p, 'ShortDesc')
    },

    getColorDesc(p) {
      return this.getDesc(p, 'ColorDesc')
    },

    getDesc(p, label) {
      if (p) {
        const desc = p[this.$store.getters.getUser.language + label]
        if (desc) {
          return desc
        }
        return p['it' + label]
      }
      return ''
    }
  }
}
</script>
