import { labels } from "./labels/labels";
import { actions } from "./labels/actions";
import { errors } from "./labels/errors";
import { titles } from "./labels/titles";
import { product } from "./labels/product";
import { faq } from "./labels/faq"

export const fr = {
  labels,
  actions,
  errors,
  titles,
  product,
  faq,
};

