<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex"
import feedbackAlert from "../../components/feedback-alert.vue"

export default {
  name: "CheckoutCartPage",

  components: { feedbackAlert },

  data() {
    return {}
  },

  computed: {
    ...mapState({
      username: (state) => state.auth.user.username,
      loggedInAsCustomer: (state) => state.customer.status.loggedInAsCustomer,
      loggedIn: (state) => state.auth.status.loggedIn
    }),
  },

  created() {
    if (this.loggedIn && !this.loggedInAsCustomer) {
      let dispatchedAction = "customerMyself"
      if (this.$store.getters.getUserRole === "AGENT") {
        dispatchedAction = "customerAgent"
      } else if (this.$store.getters.getUserRole === "COMMERCIAL") {
        dispatchedAction = "customerCommercial"
      }
      this.$store.dispatch(dispatchedAction)
          .then((u) => {
            console.log(u)
          })
          .catch((e) => console.error(e))
    }
  }
}
</script>
