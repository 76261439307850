export const subtitles = {
  welcome: "Die Online-Plattform von Alcea",
  requestOrders: "Bestellungsanfragen",
  requestOffers: "Angebotsanfragen",
  shippingAddress: "Lieferadresse",
  customerOrderCode: "Kundenspezifische Bestellnummer",
  deliveryDateRequested: "Lieferzeiten",
  acceptMultipleShipping: "Versand in Teillieferungen möglich",
  shippingNote: "Versandhinweise",
  genericNote: "Allgemeine Hinweise",
  summary: "Zusammenfassung"
};
