export const formUser = {
  gender: {
    mr: "Señor",
    mrs: "Señora",
    select: "Señor/Señora"
  },
  name: "Nombre",
  firstName: "Nombre",
  lastName: "Apellido",
  country: "Estado",
  zipCode: "C.P.",
  province: "Province",
  sectorInterest: "Sector de interés",
  private: "Particular",
  company: "Empresa",
  telephone: "Teléfono",
  phonePrefix: "Prefijo",
  repeatPasswod: "Repetir la contraseña",
  authorizeGdpr: "Autorizo al tratamiento de mis datos según la \"Informativa sobre el tratamiento de los datos personales para clientes y proveedores",
  textLinkGdpr: "según el art. 13 Decr. Legislativo. n.° 196/2003 y Reglamento Europeo UE n.° 679/2016\"",
  subscribeToNewsletter: "Deseo ser actualizado/a acerca de las novedades Alcea mediante boletín informativo",
  textLinkNewsletterGdpr: "a tenor del art. 13 del Reglamento UE 679/2016",
  authorizeCommercial: "Autorizo al tratamiento de mis datos personales para estudios de mercado",
  notificationMail: "Todavía no ha activado las notificaciones de acceso vía email!\n<br/>¿Desea activarlas ahora?<br/><br/><b>Nota: desde su perfil y en cualquier momento será posible modificar esta opción",
  businessName: "Razón social",
  businessWebsite: "Sitio Web",
  businessType: "Tipo de empresa",
  businessRole: "Función",
  businessDepartment: "Departamento",
  personalInformation: "Datos Personales",
  companyInformation: "Datos Empresariales",
  notificationMailSwitch: "Recibir email de aviso en cada entrada (login)",
  email: "Dirección email",
  repeatEmail: "Repetir la dirección email",
  intersted: "Interés",
  null: {
    country: "Estado",
    intersted: "Interés",
    businessRole: "Función"
  }
};
