export const productsSearch = {
  b2b: "Professional",
  b2bTitle: "PROFESSIONAL PRODUCT RESEARCH",
  b2c: "Private",
  b2cTitle: "SEARCH FOR THE PRODUCT MOST SUITABLE FOR YOU",
  filePlaceholder: "Select a file...",
  fileDropPlaceholder: "Put a file here...",
  browse: "Search files",
  upload: "Upload new structure",
  start: "Start",
  filters: {
     byNameOrCode: "Search by name or product number or description",
     productNameCode: "Product name or code or description",
     bySerialAndColor: "Search by series and color",
     serialCode: "Series code",
     colorCode: "Color code",
     byFilters: "Search by features",
     areas: "Sector",
     typologies: "Product typology",
     families: "Chemical family",
     dilutions: "Dilution",
     dryings: "Drying",
     appMethods: "Application method"
  },
  combos: {
     areas: {
        edil: "CONSTRUCTION (WALL)",
        metal: "METAL INDUSTRY",
        wood: "WOOD INDUSTRY",
        edil_metal_wood: "CONSTRUCTION-METAL-WOOD",
        coil: "COIL",
        plastic: "PLASTIC ALL SECTORS",
        paste: "PASTES, CONVERTERS, CATALYST FOR RETAIL",
        corrosion: "ANTICORROSION, NAVAL, ETC"
     },
     typologies: {
        dye: "COLORS, PATINES, INKS, CONCENTRATES for WOOD",
        paste: "COLORING CONCENTRATED PASTES",
        background: "ANTICORROSIVE AND NON ANTICORROSIVE UNDERCOATS AND PRIMERS",
        intermediate: "INTERMEDIATE and BASE COAT",
        washable: "WASHABLE",
        various: "VARIOUS",
        catalyst: "CATALYST",
        thinner: "THINNERS",
        converter: "CONVERTER",
        dust: "THERMOSETTING POWDERS",
        back: "BACK",
        impregnant: "IMPREGNANT",
        coat: "TOP COAT",
        raw_material: "RAW MATERIAL"
     },
     families: {
        acrylic: "ACRYLICS",
        pvdf: "PVDF / FLUORINATED",
        epoxy: "EPOXIES",
        ketone: "KETONES",
        polyester: "POLYESTERS / ISOCYANICS",
        alkyl: "ALKYDS",
        nitrocellulose: "NITROCELLULOSICS",
        unsaturated: "UNSATURATED POLYESTERS",
        clorocianuro: "CHLOROCYANURICS",
        phenolic: "PHENOLIC",
        silicones: "SILICONES",
        urethane: "UREICS",
        vinyl: "VINYLS",
        polyester_melamine: "POLYESTERS / MELAMINE"
     },
     dilutions: {
        water: "TO WATER",
        dust: "DUST",
        solvent: "SOLVENT",
        noSolvent: "SOLVENT FREE"
     },
     dryings: {
        air: "1K AIR",
        catalyzed: "2K CATALYZED",
        electrophoretic: "ELECTROPHORETIC",
        oven: "OVEN",
        curing: "UV CURING"
     },
     appMethods: {
        brush: "brush",
        roller: "roller",
        sprinkle: "air spray",
        airMix: "air mix",
        airLess: "airless",
        spatula: "knife",
        sponge: "sponge",
        rollerCoating: "roller coating",
        curtainCoater: "un dryer",
        immersion: "immersion",
        trowel: "trowel",
        cloth: "cloth",
        conventional: "air spray",
        flowCoatings: "flow coatings",
        hplv: "hplv",
        electrostaticSpray: "disco & electrostatics",
        electrophoresisNS: "ns electrophoresis",
        anode: "IMMERSION ANODIC ELECTROPHORESIS",
        cathode: "CATHODIC ELECTROPHORESIS IMMERSION"
     }
  },
  filterWarning: "Set at least one search filter",
  results: "RETURN TO SEARCH RESULTS",
  noResults: "No results among the products in the price list/stock, request information from alcea@alcea.com or on the contact page",
  code: "Code",
  productCode: "Product code",
  brand: "Brand name",
  color: "Color",
  packaging: "Packaging",
  characteristics: "Features",
  pkgAvailable: "IN STOCK",
  shelf: "shelf life",
  months: "months",
  squareMetersPerLitre: "m2/litre",
  squareMetersPerKilogram: "m2/kg",
  dryingIcon: "drying",
  specificWeight: "Specific weight",
  unitValue: "Unit Qty",
  actions: "",
  internal_use: "internal use",
  external_use: "external use",
  external_internal_use: "indoor/outdoor use",
  dilution_water: "Water dilution",
  dilution_solvent: "Solvent dilution",
  tds: {
     title: "TDS extension",
     download: "Search for the languages ​​available to download the TDS card",
     availableLanguages: "Languages ​​available",
     notFound: "No language available"
  }
};
