export const product = {
  um: "M.E.",
  status: "Status",
  statusValue: {
     NULL: "Alle",
     REMOVED: "Gelöscht",
     AVAILABLE: "Verfügbar"
  },
  inStock: "Reserve",
  tds: "TDS",
  PresentInStock: "Auf Lager",
  searchProducts: "Produktsuche",
  productCode: "Produktnummer",
  productNameCode: "Produktname, -nummer oder -beschreibung",
  brand: "Marke",
  color: "Farbe",
  serialCode: "Produktreihennnummer",
  colorCode: "Farbnummer",
  packaging: "Packung",
  serial: "Produktreihe",
  pkg: "Konf.",
  pkgAvailable: "AUF LAGER",
  area: "Bereich",
  typology: "Produktart",
  family: "Chemische Gruppe",
  dilution: "Verdünnung",
  dilution_water: "Wasserverdünnung",
  dilution_solvent: "Lösungsmittelverdünnung",
  discount: "Preisnachlass",
  drying: "Trocknung",
  dryingIcon: "Trocknung",
  appMethod: "Auftragsmethode",
  results: "ZU DEN SUCHERGEBNISSEN ZURÜCKKEHREN",
  noResults: "Kein Ergebnis unter den Produkten in Preislisten/Reserve, bitte fragen Sie bei alcea@alcea.com oder auf der Kontaktseite",
  characteristics: "Merkmale",
  downloadTds: "DATENBLATT IM PDF-FORMAT HERUNTERLADEN",
  loginToDownloadTds: "BITTE MELDEN SIE SICH AN, UM DAS DATENBLATT HERUNTERZULADEN",
  noTdsFound: "Datenblatt nicht vorhanden",
  code: "Produktnummer",
  description: "Beschreibung",
  specificWeight: "Spezifisches Gewicht",
  unitValue: "Gebindegröße",
  months: "Monate",
  usage: "Verwendung",
  internal_use: "Innenräume",
  external_use: "Außenbereich",
  external_internal_use: "Innen-/ Außenbereich",
  shelf: "Haltbarkeit",
  squareMeters: "m2",
  squareMetersPerLitre: "m2/l",
  squareMetersPerKilogram: "m2/kg",
  unitPrice: "Stückpreis",
  finalUnitPrice: "Endgültiger Stkpreis",
  discounts: "Preisnachlässe",
  priceListCode: "Preisliste",
  unitQty: "GebindeMge",
  rowTotalPrice: "Insg. Zeile",
  product: "Produkt",
  preferred: "Favoriten",
  quantity: "Menge",
  notAssigned: "Nicht zugewiesen",
  modal: {
     downloadTds: "Wählen Sie die Sprache des herunterzuladenden TDS"
  },
  priceList: {
     PL_RIV: "RIV",
     PL_CPS: "CPS",
     PL_IND: "IND",
     PL_SM_PASTE: "SM Pasten",
     PL_SM_CONV: "SM Conv.",
     PL_GE_PASTE: "GE Pasten",
     PL_GE_CONV: "GE Conv.",
     PL_LEG: "LEG",
     PL_9: 9,
     PL_DIL: "DIL",
     PL_IMB: "IMB",
     PL_ATT: "ATT"
  },
  combos: {
     interested: {
        metalIndustry: "Metallindustrie",
        woodIndustry: "Holzindustrie",
        coilCoating: "Coil Coating",
        construction: "Baugewerbe",
        homeLine: "Produktreihe Wohnen",
        tintometrics: "Farbmetrik"
     },
     areas: {
        edil: "BAU (MAUER)",
        metal: "METALLINDUSTRIE",
        wood: "HOLZINDUSTRIE",
        edil_metal_wood: "BAU-METALL-HOLZ",
        coil: "COIL",
        plastic: "KUNSTSTOFF ALLE BEREICHE",
        paste: "PASTEN, CONVERTER, KATALYSATOREN FÜR EINZELHANDEL",
        corrosion: "KORROSIONSSCHUTZ, SCHIFFBAU USW."
     },
     typologies: {
        dye: "FARBEN, BEIZEN, DRUCKFARBEN, KONZENTRATE FÜR HOLZ",
        paste: "KONZENTRIERTE FARBPASTEN",
        background: "GRUNDIERUNGEN UND HAFTVERMITTLER, MIT und OHNE KORROSIONSSCHUTZ",
        intermediate: "ZWISCHENPRODUKTE und GRUNDLACK",
        washable: "ABWASCHBARE PRODUKTE",
        various: "SONSTIGE",
        catalyst: "KATALYSATOREN",
        thinner: "VERDÜNNUNGEN",
        converter: "CONVERTER",
        dust: "WÄRMEHÄRTENDE PULVER",
        back: "ZURÜCK",
        impregnant: "IMPRÄGNIERUNGEN",
        coat: "DECKLACKE",
        raw_material: "ROHMATERIALIEN"
     },
     families: {
        acrylic: "ACRYLHARZE",
        pvdf: "PVDF / FLUORIERTE PRODUKTE",
        epoxy: "EPOXIDHARZE",
        ketone: "KETONHARZE",
        polyester: "POLYESTER / ISOCYANATE",
        alkyl: "ALKYDPRODUKTE",
        nitrocellulose: "NITROCELLULOSEPRODUKTE",
        unsaturated: "UNGESÄTTIGTE POLYESTER",
        clorocianuro: "CLORCYANPRODUKTE",
        phenolic: "PHENOLPRODUKTE",
        silicones: "SILIKONPRODUKTE",
        urethane: "HARNSTOFFE",
        vinyl: "VINYLHARZE",
        polyester_melamine: "POLYESTER / MELAMINPRODUKTE"
     },
     dilutions: {
        water: "WASSERBASIERT",
        dust: "PULVER",
        solvent: "LÖSUNGSMITTELBASIERT",
        noSolvent: "LÖSUNGSMITTELFREI"
     },
     dryings: {
        air: "1K LUFT",
        catalyzed: "2K KATALYSIERT",
        electrophoretic: "ELEKTROPHORETISCH",
        oven: "OFENTROCKNUNG",
        curing: "UV-HÄRTUNG"
     },
     appMethods: {
        brush: "Pinsel",
        roller: "Rolle",
        sprinkle: "Airspray",
        airMix: "Airmix",
        airLess: "Airless",
        spatula: "Spachtel",
        sponge: "Schwamm",
        rollerCoating: "Rollenbeschichtung",
        curtainCoater: "Vernebelung",
        immersion: "Tauchlackierung",
        trowel: "Spachtel",
        cloth: "Lappen",
        conventional: "Luftspitzpistole",
        flowCoatings: "Flutlackierungen",
        hplv: "Hplv",
        electrostaticSpray: "Hochrotationszerstäubung",
        electrophoresisNS: "unsere Elektrophoreseverfahren",
        anode: "ANODISCHE TAUCHLACKIERUNG",
        cathode: "KATHODISCHE TAUCHLACKIERUNG"
     }
  }
};
