export const productsSearch = {
  b2b: "Profesional",
  b2bTitle: "BÚSQUEDA PRODUCTO PROFESIONAL",
  b2c: "Particular",
  b2cTitle: "BUSQUE EL PRODUCTO QUE MÁS SE ADAPTA A SUS NECESIDADES",
  filePlaceholder: "Seleccionar un fichero...",
  fileDropPlaceholder: "Poner un fichero aquí...",
  browse: "Buscar fichero",
  upload: "Cargar nueva estructura",
  start: "Inicio",
  filters: {
   byNameOrCode: "Buscar por nombre, código de producto o descripción",
   productNameCode: "Nombre, código de producto o descripción",
   bySerialAndColor: "Buscar por serie y color",
   serialCode: "Código de serie",
   colorCode: "Código de color",
   byFilters: "Buscar por características",
   areas: "Sector",
   typologies: "Tipo de producto",
   families: "Familia química",
   dilutions: "Dilución",
   dryings: "Secado",
   appMethods: "Método Aplicativo"
  },
  combos: {
   areas: {
    edil: "CONSTRUCCIÓN (MURO)",
    metal: "INDUSTRIA METALÚRGICA",
    wood: "INDUSTRIA DE LA MADERA",
    edil_metal_wood: "CONSTRUCCIÓN-METAL-MADERA",
    coil: "BOBINA",
    plastic: "PLÁSTICO TODOS LOS SECTORES",
    paste: "PASTAS, CONVERTIDORES, CATALIZADORES PARA REVENTA",
    corrosion: "ANTICORROSIÓN, NAVAL, ETC"
   },
   typologies: {
    dye: "COLORES, PÁTINAS, TINTAS, CONCENTRADOS PARA MADERA",
    paste: "PASTAS CONCENTRADAS COLORANTES",
    background: "FONDOS E IMPRIMACIONES ANTICORROSIVAS y NO ANTICORROSIVAS",
    intermediate: "INTERMEDIOS y CAPA BASE",
    washable: "LAVABLES",
    various: "VARIAS",
    catalyst: "CATALIZADORES",
    thinner: "DILUYENTES",
    converter: "CONVERTIDORES",
    dust: "POLVOS TERMOENDURECIBLES",
    back: "BACK",
    impregnant: "IMPREGNANTES",
    coat: "CAPA SUPERIOR",
    raw_material: "MATERIAS PRIMAS"
   },
   families: {
    acrylic: "ACRÍLICOS",
    pvdf: "PVDF / FLUORURADOS",
    epoxy: "EPOXÍDICOS",
    ketone: "QUETÓNICAS",
    polyester: "POLIÉSTERES / ISOCIÁNICOS",
    alkyl: "ALQUÍDICOS",
    nitrocellulose: "NITROCELULÓSICOS",
    unsaturated: "POLIÉSTERES INSATURADOS",
    clorocianuro: "CLOROCIANÚRICOS",
    phenolic: "FENÓLICAS",
    silicones: "SILICÓNICOS",
    urethane: "URÉICOS",
    vinyl: "VINÍLICOS",
    polyester_melamine: "POLIÉSTERES / MELAMÍNICOS"
   },
   dilutions: {
    water: "AL AGUA",
    dust: "POLVOS",
    solvent: "AL SOLVENTE",
    noSolvent: "SIN SOLVENTE"
   },
   dryings: {
    air: "1K AIRE",
    catalyzed: "2K CATALIZADO",
    electrophoretic: "ELECTROFORÉTICO",
    oven: "DE HORNO",
    curing: "CURADO UV"
   },
   appMethods: {
    brush: "pincel",
    roller: "rodillo",
    sprinkle: "pulverización con aire",
    airMix: "mezcla con aire",
    airLess: "sin aire",
    spatula: "espátula",
    sponge: "esponja",
    rollerCoating: "revestimiento con rodillo",
    curtainCoater: "barnizadora a cortina",
    immersion: "inmersión",
    trowel: "fratás",
    cloth: "paño",
    conventional: "pulverización con aire",
    flowCoatings: "revestimientos por aspersión",
    hplv: "hplv",
    electrostaticSpray: "disco y electrostática",
    electrophoresisNS: "electroforesis ns",
    anode: "INMERSIÓN ELECTROFORESIS ANÓDICA",
    cathode: "INMERSIÓN ELECTROFORESIS CATÓDICA"
   }
  },
  filterWarning: "Configurar al menos un filtro de búsqueda",
  results: "VOLVER A LOS RESULTADOS DE LA BÚSQUEDA",
  noResults: "Ningún resultado entre los productos de listín/recambio, perdir información a alcea@alcea.com o ver la página de contactos",
  code: "Código",
  productCode: "Código de producto",
  brand: "Marca",
  color: "Color",
  packaging: "Envase",
  characteristics: "Características",
  pkgAvailable: "DISPONIBLE EN AMLACÉN",
  shelf: "conservabilidad",
  months: "meses",
  squareMetersPerLitre: "m2/l",
  squareMetersPerKilogram: "m2/kg",
  dryingIcon: "secado",
  specificWeight: "Peso específico",
  unitValue: "Cant. Unitaria",
  actions: "",
  internal_use: "uso interno",
  external_use: "uso externo",
  external_internal_use: "uso interno/externo",
  dilution_water: "Dilución con agua",
  dilution_solvent: "Dilución con solvente",
  tds: {
   title: "TDS",
   download: "Buscar los idiomas a disposición para descargar la ficha TDS",
   availableLanguages: "Idiomas disponibles",
   notFound: "Ningún idioma disponible"
  }
 };
