export const labels = {
  language: "Język",
  italian: "Włoski",
  english: "Angielski",
  french: "Francuski",
  polish: "Polski",
  russian: "Rosyjski",
  german: "German",
  yes: "Yes",
  no: "No",
  welcome: "Witamy",
  forgotPassword: "Zapomniałem hasła",
  userUpdated: "Użytkownik zaktualizowany",

  gdpr:
    "<b>Information on the personal data processing for customers and suppliers in accordance with art.13 of Legislative Decree n° 196/2003 and European Regulation  ER n° 679/2016.</b>" +
    "<br/><br/>" +
    "Dear Customer/Supplier" +
    "<br/><br/>" +
    "We hereby inform you that ALCEA SpA, via Piemonte 18 – 20030 SENAGO (Milano) – Italy is in possession of personal data and data related to your economic and commercial activity (i.e. orders, fiscal data, accounting data) for the establishment and execution of contractual relations in progress with you. " +
    "In compliance with the current legislation on the protection of personal data, we inform you that our company processes the above data with electronic and paper tools. " +
    "<br/><br/>" +
    "1) <u>Purposes of processing personal data</u>" +
    "<br/><br/>" +
    "The provision of data on your part is optional" +
    "However, in the absence of such contributions, our company will not be able to manage the contractual relationship established with you." +
    "Your personal data will be used according to the laws, in accordance with the principles of lawfulness, correctness and transparency, as well as in order to protect your privacy." +
    "<br/><br/>" +
    "2) <u>Recipients to whom the data will be communicated</u>" +
    "<br/><br/>" +
    "Your data will in no way be disseminated or subjected to any fully automated decision making process, including profiling, but may be disclosed to the following subjects or categories of subjects:" +
    "<br/>" +
    "· credit institutions, labour consultants, self-employees  who contractually lend their work to ALCEA SpA" +
    "<br/>" +
    "· ALCEA SpA employee , such as team of the administrative, commercial, software technique area" +
    "personal data will not be in any way subject to disclosure outside of the above" +
    "<br/><br/>" +
    "3) <u>Rights of the interested party</u>" +
    "<br/><br/>" +
    "We inform you that you have the right to:" +
    "<br/>" +
    "· to know, at any time, what personal data referred to you our Company is aware, their origin and how they are used" +
    "<br/>" +
    "· to correct, rectify, integrate and delete your data, as well as to oppose their processing, in whole or in part, for legitimate reasons, withdraw consent at any time" +
    "<br/>" +
    "· propose a complaint to the Supervisory Authority, in case of unauthorized processing / disclosure of your data, by our Company or in any case violation of the rights mentioned here" +
    "<br/><br/>" +
    "4) <u>Data retention period</u>" +
    "<br/><br/>" +
    "Your personal data will be stored by our company for 10 (ten) years from the end of each contractual relationship, unless your request for cancellation" +
    "<br/><br/>" +
    "5) <u>Owner and manager of data processing</u>" +
    "<br/><br/>" +
    'The data holder is, for all legal purposes, is ALCEA SpA, in person of the Legal Representative "pro-tempore" with address Via Piemonte 18,  Senago (Milano)' +
    "<br/><br/>" +
    "For the exercise of these rights, in addition to have full clarity on the transactions above mentioned, it will be possible to contact privacy@alcea.com",
};
