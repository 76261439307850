export const requestStatus = {
  requestStatus: "Estado de solicitud",
  agentRequestCustomerText: "Rellenar la solicitud para un nuevo cliente, tendrá acceso a todos los productos de los catálogos y podrá solicitar pedidos y ofertas directamente por él.",
  becomeCustomerText: "Rellenar la solicitud para convertirse en nuestro cliente, tendrá acceso a todos los productos de nuestros catálogos y podrá solicitar pedidos y ofertas directamente desde su área reservada.",
  text: "Solicitud para convertirse en cliente",
  confirmNewRequest: "¿Eliminar la solicitud anterior para crear una nueva?",
  NONE: "ninguna",
  DRAFT: "borrador",
  PENDING: "En espera",
  ACCEPTED: "Aceptado",
  REJECTED: "Rechazado",
  CLOSED: "Cerrado",
  EXPIRED: "Vencido",
  NULL: "Seleccionar estado",
  null: "Seleccionar estado",
  customerExisting: {
   title: "¿Es su empresa cliente alcea?",
   titleShort: "Combinar cliente",
   text: "Para efectuar una asociación directa mediante código de cliente"
  },
  acceptedText: "Felicitaciones, su solicitud fue aceptada. Para completar el procedimiento, clicar en el enlace de abajo.",
  rejectedText: "Lamentablemente su solicitud fue rechazada. Para volver al estado inicial, clicar en el enlace de abajo."
 };
