import AgentService from "../services/api-services/agent-service";
import CommercialService from "../services/api-services/commercial-service";

const initialState = {
  agentCustomers: {
    info: {
      dataLoaded: false,
      totalElements: 0,
      totalPages: 0,
      size: 0,
      first: true,
      last: false,
      empty: true
    }
  }
};

export const agent = {
  state: initialState,
  actions: {
    async agentMyself({ dispatch, rootGetters }) {
      let result = {
        customers: [],
        requests: []
      };
      const userRole = rootGetters['getUserRole'];

      // load customers
      const customerDispatch = userRole === 'COMMERCIAL' ? 'commercialMyCustomers' : 'agentMyCustomers';
      await dispatch(customerDispatch, { page: 0, row: 100 })
        .then((c) => {
          result.customers = c;
        })
        .catch((e) => {
          result.customers = []
        });

      // load requests
      const requestDispatch = userRole === 'COMMERCIAL' ? 'commercialMyRequest' : 'agentMyRequest';
      await dispatch(requestDispatch, { page: 0, row: 100 })
        .then((response) => {
          result.requests = response;
        })
        .catch((error) => {
          console.error(error)
          result.requests = []
        });
      return result;
    },

    async checkCustomerEditAgent({ commit, dispatch, getters }, customerCode) {
      return AgentService.checkCustomerEditPending(customerCode)
        .then((response) => {
            let ok = response.status === 404;
            commit("customerEditable", ok, { root: true });
            return Promise.resolve(response);
          },
          (error) => {
            console.error(error)
            let ok = error.status === 404;
            if (ok) {
              commit("customerEditable", ok, { root: true });
              return Promise.resolve(error);
            } else {
              commit("customerLoadOut");
              return Promise.reject(error);
            }
          }
        );
    },

    async checkCustomerEditCommercial({ commit, dispatch, getters }, customerCode) {
      return CommercialService.checkCustomerEditPending(customerCode)
        .then((response) => {
            let ok = response.status === 404;
            commit("customerEditable", ok, { root: true });
            return Promise.resolve(response);
          },
          (error) => {
            console.error(error)
            let ok = error.status === 404;
            if (ok) {
              commit("customerEditable", ok, { root: true });
              return Promise.resolve(error);
            } else {
              commit("customerLoadOut");
              return Promise.reject(error);
            }
          }
        );
    },

    searchCustomerAgent({ commit, dispatch, getters }, search) {
      return AgentService.getSearchCustomerProfile(search)
        .then((customer) => {
            commit("customerLoadIn", customer.data);
            return Promise.resolve(customer.data);
          },
          (error) => {
            console.error(error)
            commit("customerLoadOut", { root: true });
            return Promise.reject(error);
          }
        );
    },

    agentMyCustomers({ commit }, page) {
      return AgentService.getMyCustomerProfiles(
        page.page,
        page.row,
        "businessName",
        "asc"
      ).then((response) => {
          let agentCustomers = {
            info: {
              dataLoaded: true,
              totalElements: response.data.totalElements,
              totalPages: response.data.totalPages,
              size: response.data.size,
              fist: response.data.first,
              last: response.data.last,
              empty: response.data.empty,
            },
          };
          commit("agentLoadCustomersSuccess", agentCustomers);
          return Promise.resolve(response.data.content);
        },
        (error) => {
          console.error(error)
          commit("agentLoadCustomersFail");
          return Promise.reject(error);
        }
      );
    },

    commercialMyCustomers({ commit }, page) {
      return CommercialService.getMyCustomerProfiles(
        page.page,
        page.row,
        "businessName",
        "asc"
      ).then((response) => {
          let agentCustomers = {
            info: {
              dataLoaded: true,
              totalElements: response.data.totalElements,
              totalPages: response.data.totalPages,
              size: response.data.size,
              fist: response.data.first,
              last: response.data.last,
              empty: response.data.empty,
            },
          };
          commit("agentLoadCustomersSuccess", agentCustomers);
          return Promise.resolve(response.data.content);
        },
        (error) => {
          console.error(error)
          commit("agentLoadCustomersFail");
          return Promise.reject(error);
        }
      );
    },

    postEditRequestAgent({ commit, dispatch, getters }, form) {
      form = {
        ...form,
        ...{ customerVersion: getters.getCustomerVersion },
        ...{ customerCode: getters.getCustomerCode },
      };
      return AgentService.postEditRequest(form)
        .then((response) => {
            dispatch("checkCustomerEditAgent", getters.getCustomerCode);
            return Promise.resolve(response.data);
          },
          (error) => {
            console.error(error)
            return Promise.reject(error);
          }
        );
    },

    postEditRequestCommercial({ commit, dispatch, getters }, form) {
      form = {
        ...form,
        ...{ customerVersion: getters.getCustomerVersion },
        ...{ customerCode: getters.getCustomerCode },
      };
      return CommercialService.postEditRequest(form).then(
        (response) => {
          dispatch("checkCustomerEditCommercial", getters.getCustomerCode);
          return Promise.resolve(response.data);
        },
        (error) => {
          console.error(error)
          return Promise.reject(error);
        }
      );
    },

    agentMyRequest({ commit }, page) {
      return AgentService.getMyCustomerRequest(
        page.page,
        page.row,
        "status",
        "asc"
      ).then((response) => {
          // FIXME improve to load customer request by ID: remove from here (and other places)
          // load draft if exists
          let draft = response.data.content.find((r) => r.status === "DRAFT");
          if (draft) {
            commit("requestUpdateSuccess", draft, { root: true });
          }

          return Promise.resolve(response.data.content);
        },
        (error) => {
          console.error(error)
          commit("agentLoadRequestsFail");
          return Promise.reject(error);
        }
      );
    },

    commercialMyRequest({ commit }, page) {
      return CommercialService.getMyCustomerRequest(
        page.page,
        page.row,
        "status",
        "asc"
      ).then(
        (response) => {
          // FIXME improve to load customer request by ID: remove from here (and other places)
          // load draft if exists
          let draft = response.data.content.find((r) => r.status === "DRAFT");
          if (draft) {
            commit("requestUpdateSuccess", draft, { root: true });
          }

          return Promise.resolve(response.data.content);
        },
        (error) => {
          console.error(error)
          commit("agentLoadRequestsFail");
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    agentLoadCustomersSuccess(state, agentCustomers) {
      state.agentCustomers = agentCustomers
    },

    agentLoadCustomersFail(state) {
      state.agentCustomers = initialState.agentCustomers
    }
  },

  getters: {
    getAgentCustomersLoaded: (state) => state.agentCustomers.info.dataLoaded
  }
};
