export const retailer= {
  metal: "Metal",
  wood: "Madera",
  construction: "Construcción",
  type: "Tipo de Reventa",
  bodywork: "Carrocería",
  ownership: "¿Tienda de propiedad?",
  shopWindows: "Cantidad de vitrinas",
  surface: "Superficie",
  mq: "m2",
  shop: "Tienda"
};