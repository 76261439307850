export const formCustomer = {
  vatNumber: "USt-IdNr.",
  noPrefix: "ohne Präfix",
  sdiCode: "SDI-Code:",
  pec: "Zertifizierte E-Mail-Adresse",
  streetAddress1: "Adresszeile 1",
  streetAddress2: "Adresszeile 2",
  city: "Ort",
  billing: "Rechnung",
  transportDocument: "DDT",
  msds: "MSDS",
  testSheets: "Abnahme",
  businessAddress: "Verwaltungssitz",
  legalAddressSameAsbusinessAddress: "Ist der Firmensitz auch der Verwaltungssitz?",
  legalAddress: "Firmensitz:",
  shippingAddressesSameAsbusinessAddress: "Ist die Lieferadresse der Verwaltungssitz?",
  shippingAddresses: "Lieferadresse",
  addAddress: "Adresse hinzufügen",
  purchasingContact: "Kaufkontakt",
  administrativeContact: "Verwaltungskontakt",
  documentsToRecive: "An diesen Kontakt zu sendende Dokumente",
  documentsRecipient: "Empfänger Dokumente",
  additionalContact: "Zusätzlicher Kontakt",
  additionalContacts: "Zusätzliche Kontakte",
  billingRecipient: "Rechnungsempfänger",
  transportDocumentRecipient: "Lieferschein-Empfänger",
  msdsRecipient: "Sicherheitsdatenblatt-Empfänger",
  testSheetsRecipient: "Empfänger Datenblatt zur Abnahme",
  bankName: "Bank",
  iban: "IBAN",
  customerCode: "Kundennummer",
  customerCodeShort: "Kunde",
  customerCodeText: "Wenn Sie über ein Kundenkonto verfügen, füllen Sie bitte das Feld aus, um die direkte Zuordnung zu beantragen",
  marketSegmentation: "Marktsegment",
  marketSegmentationPlaceholder: "Art der Zugehörigkeit",
  resellingType: "Händlerart",
  marketCompetitor: "Konkurrent",
  marketCompetitorPlaceholder: "Konkurrenzfirma",
  totalConsumption: "Gesamtverbrauch ",
  totalConsumptionPlaceholder: "Gesamtverbrauch in EUR",
  alceaPotential: "Alcea-Potenzial",
  alceaPotentialPlaceholder: "Alcea-Potenzial in EUR",
  tntNextRequired: "Erfordert TNT NEXT"
};
