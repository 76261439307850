<template>
  <div class="row">
    <h3>{{ $t("titles.contacts") }}</h3>
    <div class="col-md-12">
      <div class="row justify-content-between">
        <h4 class="col">{{ $t("formCustomer.purchasingContact") }}</h4>
        <span class="col-auto d-flex align-items-center">
          <b-icon
            class="ml-auto mr-1"
            id="itsMePurchasingContact"
            aria-hidden="true"
            icon="question-circle"
          ></b-icon>
          <b-tooltip
            target="itsMePurchasingContact"
            title="Tooltip title"
            triggers="hover"
            placement="left"
          >
            {{ $t("tooltips.itsMe") }}
          </b-tooltip>
          <b-checkbox
            v-model="form.purchasingContact"
            :value="userContact('purchasingContact')"
            :unchecked-value="purchasingContactCall()"
            @change="getUserContact('purchasingContactIsUser')"
            >{{ $t("labels.itsMe") }}</b-checkbox
          >
        </span>
      </div>

      <card-contact
        class="mb-2"
        :disabled="purchasingContactIsUser"
        id="purchasingContact"
        ref="purchasingContact"
        :contact="form.purchasingContact"
        :invalidErrors="
          invalidErrors.purchasingContact
            ? invalidErrors.purchasingContact.invalid
            : []
        "
        :assignmentValue="'purchasingContact'"
        :form="form"
        :billing.sync="billing"
        :msds.sync="msds"
        :testSheets.sync="testSheets"
        :transportDocument.sync="transportDocument"
      ></card-contact>
      <div class="row justify-content-between">
        <h4 class="col">{{ $t("formCustomer.administrativeContact") }}</h4>
        <span class="col-auto d-flex align-items-center">
          <b-icon
            class="ml-auto mr-1"
            id="itsMeAdministrativeContact"
            aria-hidden="true"
            icon="question-circle"
          ></b-icon>
          <b-tooltip
            target="itsMeAdministrativeContact"
            title="Tooltip title"
            triggers="hover"
            placement="left"
          >
            {{ $t("tooltips.itsMe") }}
          </b-tooltip>
          <b-checkbox
            v-model="form.administrativeContact"
            :value="userContact('administrativeContact')"
            :unchecked-value="administrativeContactCall()"
            @change="getUserContact('administrativeContactIsUser')"
            >{{ $t("labels.itsMe") }}</b-checkbox
          >
        </span>
      </div>

      <card-contact
        class="mb-2"
        id="administrativeContact"
        ref="administrativeContact"
        :disabled="administrativeContactIsUser"
        :contact="form.administrativeContact"
        :invalidErrors="
          invalidErrors.administrativeContact
            ? invalidErrors.administrativeContact.invalid
            : []
        "
        :assignmentValue="'administrativeContact'"
        :form="form"
        :billing.sync="billing"
        :msds.sync="msds"
        :testSheets.sync="testSheets"
        :transportDocument.sync="transportDocument"
      ></card-contact>
      <h3>{{ $t("formCustomer.documentsRecipient") }}</h3>
      <div id="assignments" class="col-md-12 m-0 p-0">
        <form-contact-assigment
          id="billing"
          :assignment.sync="billing"
          :emailtype="'billing'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
        <form-contact-assigment
          id="transportDocument"
          :assignment.sync="transportDocument"
          :emailtype="'transportDocument'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
        <form-contact-assigment
          id="msds"
          :assignment.sync="msds"
          :emailtype="'msds'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
        <form-contact-assigment
          id="testSheets"
          :assignment.sync="testSheets"
          :emailtype="'testSheets'"
          :form.sync="form"
          :invalidErrors="invalidErrors.invalid"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CardContact from "./card-contact.vue";
import FormContactAssigment from "./form-contact-assigment.vue";
import { Contact } from "../models/customer";

export default {
  components: { CardContact, FormContactAssigment },
  name: "Contacts",
  props: {
    form: Object(),
    curretStep: Object(),
    completed: Boolean(),
    invalidErrors: Array(),
    user: Object(),
  },
  data() {
    return {
      isMounted: false,
      self: "Contacts",
      fields: [
        "purchasingContact",
        "administrativeContact",
        "billingEmails",
        "transportDocumentEmails",
        "msdsEmails",
        "testSheetsEmails",
      ],

      purchasingContactIsUser: false,
      administrativeContactIsUser: false,
      administrativeContact: this.form.administrativeContact,
      purchasingContact: this.form.purchasingContact,
      administrativeTelephone: this.form.administrativeContact.phoneNumber,
      billing: {
        assigned: null,
        emails: this.form.billingEmails
      },
      transportDocument: {
        assigned: null,
        emails: this.form.transportDocumentEmails,
      },
      msds: {
        assigned: null,
        emails: this.form.msdsEmails,
      },
      testSheets: {
        assigned: null,
        emails: this.form.testSheetsEmails,
      },
    };
  },
  computed: {
    purchasingContactTelephone: {
      get() {
        return this.$refs.purchasingContact.telephone;
      },
    },
    administrativeContactTelephone: {
      get() {
        return this.$refs.administrativeContact.telephone;
      },
    },
  },
  methods: {
    getUserContact(disable) {
      this[disable] = !this[disable];
    },
    userContact(contact) {
      let userContact=new Contact();
      if (this.isMounted) {
        userContact = new Contact(false, false, this.user);
        userContact.role = this.form[contact].role;
      }
      return userContact;
    },
    administrativeContactCall() {
      return this.administrativeContact;
    },
    purchasingContactCall() {
      return this.purchasingContact;
    },
  },
  mounted() {
    this.isMounted = true;

    if(!this.form.purchasingContact) {
      this.form.purchasingContact = new Contact(true);
    }
    if(!this.form.administrativeContact) {
      this.form.administrativeContact = new Contact(true);
    }

    this.purchasingContactIsUser =
      Object.entries(this.form.purchasingContact).toString() ==
      Object.entries(this.userContact("purchasingContact")).toString();
    this.administrativeContactIsUser =
      Object.entries(this.form.administrativeContact).toString() ==
      Object.entries(this.userContact("administrativeContact")).toString();
  },
  watch: {
    "billing.assigned": {
      handler() {
        this.billing.emails[0] =
            this.billing.assigned && this.form[this.billing.assigned]
                ? this.form[this.billing.assigned].email
                : this.billing.emails[0];
      },
      deep: true,
    },
    "billing.emails": {
      handler() {
        this.$emit(
            "update:form",
            Object.assign(this.form, {
              billingEmails: this.billing.emails,
            })
        );
      },
      deep: true,
    },
    "transportDocument.assigned": {
      handler() {
        this.transportDocument.emails[0] =
            this.transportDocument.assigned &&
            this.form[this.transportDocument.assigned]
                ? this.form[this.transportDocument.assigned].email
                : this.transportDocument.emails[0];
      },
      deep: true,
    },
    "transportDocument.emails": {
      handler() {
        this.$emit(
            "update:form",
            Object.assign(this.form, {
              transportDocumentEmails: this.transportDocument.emails,
            })
        );
      },
      deep: true,
    },
    "msds.assigned": {
      handler() {
        this.msds.emails[0] =
            this.msds.assigned && this.form[this.msds.assigned]
                ? this.form[this.msds.assigned].email
                : this.msds.emails[0];
      },
      deep: true,
    },
    "msds.emails": {
      handler() {
        this.$emit(
            "update:form",
            Object.assign(this.form, {
              msdsEmails: this.msds.emails,
            })
        );
      },
      deep: true,
    },
    "testSheets.assigned": {
      handler() {
        this.testSheets.emails[0] =
            this.testSheets.assigned && this.form[this.testSheets.assigned]
                ? this.form[this.testSheets.assigned].email
                : this.testSheets.emails[0];
      },
      deep: true,
    },
    "testSheets.emails": {
      handler() {
        this.$emit(
            "update:form",
            Object.assign(this.form, {
              testSheetsEmails: this.testSheets.emails,
            })
        );
      },
      deep: true,
    }
  },
};
</script>