<template>
  <div>
    {{ address.streetAddress1 }}
    <span v-if="address.streetAddress2"> - {{ address.streetAddress2 }}</span>&nbsp;&nbsp;
    <span>{{ address.zipOrPostalCode }}</span>&nbsp;
    <span>{{ address.city }}</span>&nbsp;
    <span>{{ labelCountry }}</span>
  </div>
</template>

<script>
import countries from "../../commons/countries";

export default {
  name: "cardAddress",

  props: {
    address: Object(),
    invalidErrors: Array(),
    disabled: Boolean(),
    dismissable: Boolean(),
  },

  data() {
    return {
      countries: countries,
    };
  },

  computed: {
    labelCountry() {
      return this.address.country ? countries.find((c) => c.value === this.address.country).label : this.$i18n.t("formUser.country");
    },
  },

  methods: {
    validationState(contextRef) {
      return this.invalidErrors.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) ? false : null;
    },

    validationError(contextRef) {
      if (this.invalidErrors.length > 0) {
        let find = this.invalidErrors.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
  },
};
</script>
