export const languages = {
  lang: "Lingua",
  IT: "Italiano",
  EN: "Inglese",
  FR: "Francese",
  PL: "Polacco",
  RU: "Russo",
  it: "Italiano",
  en: "Inglese",
  fr: "Francese",
  pl: "Polacco",
  ru: "Russo",
};
