export const product = {
  um:'U.M.',
  status:'Stato',
  statusValue:{
    NULL:'Tutti',
    REMOVED:'Eliminato',
    UNAVAILABLE:'Non Acquistabile',
    AVAILABLE:'Acquistabile'
  },
  inStock:'Scorta',
  tds:'TDS',
  PresentInStock:'Presente in Scorta',
  searchProducts: "Ricerca prodotti",
  productCode: "Codice prodotto",
  productNameCode: "Nome o codice prodotto o descrizione",
  brand: "Marchio",
  color: "Colore",
  serialCode: "Codice serie",
  colorCode: "Codice colore",
  packaging: "Confezione",
  serial:'Serie',
  pkg:'Conf.',
  pkgAvailable: "DISPONIBILE A MAGAZZINO",
  area: "Settore",
  typology: "Tipologia prodotto",
  family: "Famiglia chimica",
  dilution: "Diluizione",
  dilution_water: "Diluizione acqua",
  dilution_solvent: "Diluizione solvente",
  discount:"Sconto",
  drying: "Essiccazione",
  dryingIcon: "essiccazione",
  appMethod: "Metodo Applicativo",
  results: "TORNARE AI RISULTATI DELLA RICERCA",
  noResults:
    "Nessun risultato tra i prodotti a listino/scorta, richiedi informazioni ad alcea@alcea.com o alla pagina contatti",
  characteristics: "Caratteristiche",
  downloadTds: "SCARICA LA SCHEDA TECNICA IN FORMATO PDF",
  loginToDownloadTds:
    "AUTENTICATI PER EFFETTUARE IL DOWNLOAD DELLA SCHEDA TECNICA",
  noTdsFound: "Scheda tecnica non presente",
  code: "Codice",
  description: "Descrizione",
  specificWeight: "Peso specifico",
  unitValue: "Q.tà Unitaria",
  months: "mesi",
  usage: "uso",
  internal_use: "uso interno",
  external_use: "uso esterno",
  external_internal_use: "uso interno/esterno",
  shelf: "conservabilità",
  squareMeters: "mq",
  squareMetersPerLitre: "mq/l",
  squareMetersPerKilogram: "mq/kg",
  unitPrice:'Prezzo unitario',
  finalUnitPrice:'Prezzo un. Finale',
  discounts: 'Sconti',
  priceListCode: 'Listino',
  unitQty:'Qta uni.',
  rowTotalPrice:'Tot. Riga',
  product:'Prodotto',
  preferred:"Preferiti",
  quantity:'Quantità',
  notAssigned: 'Non assegnato',
  modal: {
    downloadTds: "Scegliere in quale lingua si desidera scaricare la TDS",
  },
  priceList: {
    PL_RIV: "RIV",
    PL_CPS: "CPS",
    PL_IND: "IND",
    PL_SM_PASTE: "SM paste",
    PL_SM_CONV: "SM conv",
    PL_GE_PASTE: "GE paste",
    PL_GE_CONV: "GE conv",
    PL_LEG: "LEG",
    PL_9: "9",
    PL_DIL: "DIL",
    PL_IMB: "IMB",
    PL_ATT: "ATT"
  },
  combos: {
    interested: {
      metalIndustry: "Industria metallo",
      woodIndustry: "Industria legno",
      coilCoating: "Coil Coating",
      construction: "Construction",
      homeLine: "Linea casa",
      tintometrics: "Tintometria",
    },
    areas: {
      edil: "EDILIZIA (CONSTRUCTION)",
      metal: "INDUSTRIA DEL METALLO",
      wood: "INDUSTRIA DEL LEGNO",
      edil_metal_wood: "EDILIZIA-METALLO-LEGNO",
      coil: "COIL",
      plastic: "PLASTICA TUTTI I SETTORI",
      paste: "PASTE, CONVERTER, CATALIZZATORI X RIVENDITA",
      corrosion: "ANTICORROSIONE, NAVALE, ECC",
    },
    typologies: {
      dye: "TINTE, PATINE, INCHIOSTRI, CONCENTRATI x LEGNO",
      paste: "PASTE CONCENTRATE COLORANTI",
      background: "FONDI E PRIMER ANTICORROSIVI e NON",
      intermediate: "INTERMEDI e BASE COAT",
      washable: "LAVABILI",
      various: "VARIE",
      catalyst: "CATALIZZATORI",
      thinner: "DILUENTI",
      converter: "CONVERTER",
      dust: "POLVERI TERMOINDURENTI",
      back: "BACK",
      impregnant: "IMPREGNANTI",
      coat: "TOP COAT",
      raw_material: "MATERIE PRIME",
      wp_floors_resins: "IMPERMEABILIZZANTI, PAVIMENTI, RESINE",
    },
    families: {
      acrylic: "ACRILICI",
      pvdf: "PVDF / FLUORURATI",
      epoxy: "EPOSSIDICI",
      ketone: "CHETONICHE",
      polyester: "POLIESTERI / ISOCIANICI",
      alkyl: "ALCHIDICI",
      nitrocellulose: "NITROCELLULOSICI",
      unsaturated: "POLIESTERI INSATURI",
      clorocianuro: "CLOROCIANURICI",
      phenolic: "FENOLICHE",
      silicones: "SILICONICI",
      urethane: "UREICI",
      vinyl: "VINILICI",
      polyester_melamine: "POLIESTERI / MELAMINICI",
    },
    dilutions: {
      water: "ALL'ACQUA",
      dust: "POLVERE",
      solvent: "A SOLVENTE",
      noSolvent: "SENZA A SOLVENTE",
    },
    dryings: {
      air: "1K ARIA",
      catalyzed: "2K CATALIZZATO",
      electrophoretic: "ELETTROFORETICO",
      oven: "A FORNO",
      curing: "UV CURING",
    },
    appMethods: {
      brush: "pennello",
      roller: "rullo",
      sprinkle: "air-spray",
      airMix: "air-mix",
      airLess: "air-less",
      spatula: "spatola",
      sponge: "spugna",
      rollerCoating: "roller coating",
      curtainCoater: "velatrice",
      immersion: "immersione",
      trowel: "frattazzo",
      cloth: "straccio",
      conventional: "spruzzo ad aria",
      flowCoatings: "flow-coatings",
      hplv: "hplv",
      electrostaticSpray: "disco & elettrostatica",
      electrophoresisNS: "elettroforesi ns",
      anode: "IMMERSIONE ELETTROFORESI ANODICA",
      cathode: "IMMERSIONE ELETTROFORESI CATODICA",
    },
  },
  orderedProducts: {
    serial: "0000",
    color: "0000",
    pkg: "00",
    description: "es. catalizzatore",
    brand: "es. URETAL",
  }
};
