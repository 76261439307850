<template>
  <div class="login-background bx">
    <div class="d-flex justify-content-center login-container">
      <b-overlay :show="isLoading" rounded class="login-overlay">

        <!-- feedback -->
        <feedback-alert
            class="w-100"
            :status="feedbackStatus"
            :message="feedbackMessage"
            @clearStatus="clearStatus">
        </feedback-alert>

        <!-- form -->
        <b-form @submit.prevent="login">
          <!-- upper box -->
          <div class="login-box">
            <h1 class="login-title">Login</h1>

            <b-row>
              <b-col cols="12" md="6" class="login-input br">
                <b-form-group :invalid-feedback="validationError('username')">
                  <b-form-input required
                                id="email"
                                type="text"
                                v-model="form.username"
                                :placeholder="$t('labels.insertUsername')"
                                :state="validationState('username')"
                                @keydown.enter.prevent="login">
                  </b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" class="login-input">
                <b-form-group :invalid-feedback="validationError('password')">
                  <b-form-input required
                                id="password"
                                type="password"
                                v-model="form.password"
                                :placeholder="$t('labels.insertPassword')"
                                :state="validationState('password')"
                                @keydown.enter.prevent="login">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <!-- login button -->
          <adw-button class="mt-4 mb-5"
                @button-click="login">
                <h3>{{ $t("actions.access") }}</h3>
          </adw-button>

          <!-- lower box -->
          <b-row>
            <b-col cols="12" md="6"  order-md="1" class="mb-md-2">
              <span class="login-small">{{ $t("labels.forgotPassword") }}</span>
            </b-col>
            
            <b-col cols="12" md="6"   order-md="3">
              <adw-button class=""
                  @button-click="$router.push('/forgot-password')">
                    <h3>{{ $t("actions.retrievePassword") }}</h3>
              </adw-button>
            </b-col>

            <b-col cols="12" md="6"  order-md="2" class="mb-md-2">
              <span class="login-small">{{ $t("labels.registerNewAccount") }}</span>
            </b-col>

            <b-col cols="12" md="6"  order-md="4">
              <adw-button class=""
                  @button-click="$router.push('/registration/P')">
                  <h3>{{ $t("actions.signUp") }}</h3>
              </adw-button>
            </b-col>
          </b-row>
        </b-form>

      </b-overlay>
    </div>
  </div>
</template>

<script>
import FeedbackAlert from "@/components/feedback-alert.vue"
import AdwButton from "@/components/global-components/adw-button";
import Validator from "@/services/validation-service/validator-service"
import ErrorManager from "@/services/errors-service/errors-manager"

export default {
  name: 'Login',

  components: {
    FeedbackAlert,
    AdwButton,
  },

  data() {
    return {
      isLoading: false,
      form: {
        username: null,
        password: null,
      },
      feedbackMessage: "",
      feedbackStatus: null,
      invalid: [],
      otherError: {
        name: null,
        msg: null
      },
      redirectLink: false
    }
  },

  mounted() {
    this.redirectLink = this.$route.query.redirectLink;
  },

  methods: {
    validationState(contextRef) {
      return this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) ? false : null
    },

    validationError(contextRef) {
      if (this.invalid.length > 0) {
        let find = this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) || null
        return find ? this.$i18n.t(find.error) : null
      }
    },

    clearStatus() {
      this.feedbackStatus = null
      this.feedbackMessage = ""
    },

    login() {
      this.otherError.name = null
      this.otherError.msg = null

      try {
        let validation = Validator.validateForm("login", this.form)
        this.invalid = validation.invalid
      } catch (e) {
        console.error(e)
        this.otherError.name = "validation"
        this.otherError.msg = e
        this.invalid.push(this.otherError)
      }

      if (this.invalid.length > 0 || this.otherError.msg) {
        console.error("possible 409")
        console.error(this.invalid)
        console.error(this.otherError)
      } else {
        this.sendLogin()
      }
    },

    async sendLogin() {
      this.feedbackStatus = null
      this.isLoading = true
      await this.$store.dispatch("login", this.form)
          .then((response) => {
            console.info("logged in:", response.data)
            this.isLoading = false;

            console.log(this.redirectLink);

            if(this.redirectLink){
              this.$router.push(this.redirectLink)
            } else {
              this.$router.push("/profile-user")            
            }
          })
          .catch((error) => {
            console.error("not logged", error)
            this.otherError.name = "login"
            this.otherError.msg = error
            this.feedbackStatus = "FailDismissable"
            this.feedbackMessage = ErrorManager.feedback(error) === 'Unauthorized' ? 'UnauthorizedLogin' : ErrorManager.feedback(error)
            this.isLoading = false;
          })
    },
  },

  beforeRouteEnter(to, from, next) {
    if (from.name !== "Login") {
      if (to.query.confirmed) {
        next((vm) => {
          vm.feedbackStatus = "SuccessDismissable"
          vm.feedbackMessage = "registrationComplete"
          vm.$router.replace({ query: {} })
        })
      } else if (to.query.newPassword) {
        next((vm) => {
          vm.feedbackStatus = "SuccessDismissable"
          vm.feedbackMessage = "newPasswordComplete"
          vm.$router.replace({ query: {} })
        })
      } else {
        next({ query: {} })
      }
    } else {
      next({ query: {} })
    }
  },
}
</script>
