<template>
  <section>
    <div class="private-area-row-150 text-uppercase bb">
      <h3>{{ $t("formUser.personalInformation") }}</h3>
    </div>

    <div class="private-area-box">
      <b-form-group label-cols-md="3"
                    label-for="name"
                    :label="$t('formUser.firstName') + (editMode ? ' *' : '')"
                    :invalid-feedback="validationError('firstName')">
        <b-form-input v-if="editMode"
                      type="text"
                      id="name"
                      name="name"
                      v-model.trim="form.firstname"
                      :state="validationState('firstname')">
        </b-form-input>
        <label class="col-form-label" v-else>{{ form.firstname }}</label>
      </b-form-group>

      <b-form-group label-cols-md="3"
                    label-for="lastName"
                    :label="$t('formUser.lastName') + (editMode ? ' *' : '')"
                    :invalid-feedback="validationError('lastname')">
        <b-form-input v-if="editMode"
                      type="text"
                      id="lastName"
                      name="lastName"
                      v-model.trim="form.lastname"
                      :state="validationState('lastname')">
        </b-form-input>
        <label class="col-form-label" v-else>{{ form.lastname }}</label>
      </b-form-group>

      <b-form-group label-cols-md="3"
                    label-for="telephone"
                    :label="$t('formUser.telephone') + (editMode ? ' *' : '')">
        <telephone-component v-if="editMode"
                             class="m-0"
                             id="telephone"
                             ref="telephone"
                             :formType="form.type"
                             :invalid="invalid"
                             :telephoneProp="telephoneProp"
                             v-model="form.telephone">
        </telephone-component>
        <label class="col-form-label" v-else>{{ form.telephone }}</label>
      </b-form-group>

      <b-form-group label-cols-md="3"
                    label-for="country"
                    :label="$t('formUser.country') + (editMode ? ' *' : '')"
                    :invalid-feedback="validationError('country')">
        <b-form-select v-if="editMode"
                       v-model.trim="form.country"
                       id="country"
                       name="country"
                       :state="validationState('country')">
          <b-select-option selected disabled :value="null">
            {{ $t("formUser.country") }} *
          </b-select-option>
          <b-select-option v-for="country in countries"
                           :value="country.value"
                           :key="country.key">
            {{ country.label }}
          </b-select-option>
        </b-form-select>
        <label class="col-form-label" v-else>{{ getText(countries, form.country, 'label') }}</label>
      </b-form-group>

      <b-form-group label-cols-md="3"
                    label-for="zipCode"
                    style="border-bottom: none"
                    :label="$t('formUser.zipCode') + (editMode ? ' *' : '')"
                    :invalid-feedback="validationError('zipCode')">
        <b-form-input v-if="editMode"
                      type="text"
                      id="zipCode"
                      name="zipCode"
                      v-model.trim="form.zipCode"
                      :state="validationState('zipCode')">
        </b-form-input>
        <label class="col-form-label" v-else>{{ form.zipCode }}</label>
      </b-form-group>
    </div>

    <div class="private-area-row-150 text-uppercase by">
      <h3>{{ $t("formUser.companyInformation") }}</h3>
    </div>

    <div class="private-area-box">
      <b-form-group label-cols-md="3"
                    label-for="businessName"
                    :label="$t('formUser.businessName') + (editMode ? ' *' : '')">
        <b-form-input v-if="editMode"
                      :disabled="requestStatus !== 'NONE' && requestStatus !== 'DRAFT' || userRole === 'CUSTOMER'"
                      class="form-control"
                      id="businessName"
                      name="businessName"
                      v-model="form.businessName"
                      :placeholder="$t('formUser.businessName')"
                      :state="validationState('businessName')">
        </b-form-input>
        <label class="col-form-label" v-else>{{ form.businessName }}</label>
      </b-form-group>

      <b-form-group label-cols-md="3"
                    label-for="website"
                    :label="$t('formUser.businessWebsite')">
        <b-form-input v-if="editMode"
                      class="form-control"
                      id="website"
                      name="website"
                      type="url"
                      v-model="form.businessWebsite"
                      :placeholder="$t('formUser.businessWebsite')"
                      :state="validationState('businessWebsite')">
        </b-form-input>
        <label class="col-form-label" v-else>{{ form.businessWebsite }}</label>
      </b-form-group>

      <b-form-group  v-if="!isInternalUser" label-cols-md="3"
                    label-for="businessType"
                    :label="$t('formUser.businessType') + (editMode ? ' *' : '')">
        <b-form-select v-if="editMode"
                       id="businessType"
                       name="businessType"
                       v-model="form.businessType"
                       :state="validationState('businessType')">
          <b-select-option selected disabled value="null">
            {{ $t("formUser.businessType") }} *
          </b-select-option>
          <b-select-option v-for="type in types" :value="type.value" :key="type.value">
            {{ $t(type.text) }}
          </b-select-option>
        </b-form-select>
        <label class="col-form-label" v-else>{{ getText(types, form.businessType) }}</label>
      </b-form-group>

      <b-form-group v-if="!isInternalUser"
                    label-cols-md="3"
                    label-for="businessDepartment"
                    :label="$t('formUser.businessDepartment')">
        <b-form-select v-if="editMode"
                       id="businessDepartment"
                       name="businessDepartment"
                       v-model="form.businessDepartment"
                       @change="form.businessRole = null"
                       :state="validationState('businessDepartment')">
          <b-select-option selected disabled value="null">
            {{ $t("formUser.businessDepartment") }} *
          </b-select-option>
          <b-select-option v-for="department in departments"
                           :value="department.value"
                           :key="department.value">
            {{ $t(department.text) }}
          </b-select-option>
        </b-form-select>
        <label class="col-form-label" v-else>{{ getText(departments, form.businessDepartment) }}</label>
      </b-form-group>

      <b-form-group v-if="!isInternalUser"
                    label-cols-md="3"
                    label-for="businessRole"
                    style="border-bottom: none"
                    :label="$t('formUser.businessRole') + (editMode ? ' *' : '')">
        <b-form-select v-if="editMode"
                       id="businessRole"
                       name="businessRole"
                       v-model="form.businessRole"
                       :state="validationState('businessRole')">
          <b-select-option selected disabled value="null">
            {{ $t("formUser.businessRole") }} *
          </b-select-option>
          <b-select-option v-for="rl in roles" :value="rl.value" :key="rl.key">
            {{ $t(rl.text) }}
          </b-select-option>
        </b-form-select>
        <label class="col-form-label" v-else>{{ getText(roles, form.businessRole) }}</label>
      </b-form-group>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import TelephoneComponent from "./global-components/telephone-component.vue";

export default {
  name: "PrivateAreaCompany",

  components: {
    TelephoneComponent
  },

  props: {
    formProp: Object(),
    user: Object(),
    countries: Array(),
    sectors: Array(),
    invalidlist: Array(),
    telephoneProp: Object(),
    types: Array(),
    departments: Array(),
    roles: Array(),
    editMode: Boolean,
    isInternalUser: Boolean
  },

  computed: {
    ...mapState({
      userRole: (state) => state.auth.user.role,
      requestStatus: (state) => state.request.request.status
    }),
    telephone: {
      get() {
        return this.$refs.telephone.telephone;
      },
    },

    invalid: {
      get() {
        return this.invalidlist;
      },
      set(invalidated) {
        this.$emit("input", invalidated);
      },
    },

    form: {
      get() {
        return this.formProp;
      },
      set(form) {
        this.$emit("input", form);
      }
    }
  },

  methods: {
    getText(list, value, field) {
      if (value) {
        let item = list.filter((l) => l.value.toLowerCase() === value.toLowerCase());
        if (item.length > 0) {
          return this.$t(item[0][field ? field : 'text']);
        }
      }
      return '';
    },

    validationState(contextRef) {
      return this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) ? false : null;
    },

    validationError(contextRef) {
      if (this.invalid.length > 0) {
        let find = this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    }
  }
};
</script>
