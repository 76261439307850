export const instructions = {
  checkout: {
   shippingAddress: "Seleccionar la dirección de expedición para este pedido",
   customerOrderCode: "Indicar, de ser necesario, un código de pedido personalizado asociado a esta solicitud",
   deliveryDateRequested: "Indicar, de ser necesario, una fecha preferida de entrega del pedido, si no el pedido se entregará eí día más conveniente.",
   acceptMultipleShipping: "Indicar si aceptar varias expediciones a cuenta, si no se recibirá todo el pedido en una sola vez.",
   shippingNote: "Ingresar, de ser útiles, notas sobre la expedición; estas notas figurarán en el documento de transporte.",
   genericNote: "Ingresar, de ser necesarias, notas genéricas con respecto al pedido"
  },
  formCustomer: {
   vatNumber: "Para cambiar el Número de IVA contactar a Alcea."
  },
  filters: "Filtros"
};
