<template>
  <b-container fluid class="p-3">
    <b-row>
      <b-col>
        <feedback-alert
            class="w-100"
            :status="feedbackStatus"
            :message="feedbackMessage"
            @clearStatus="clearStatus">
        </feedback-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3">
        <h3 class="text-uppercase">Contatta il Customer Service</h3>
        <p>
          Per eventuali richieste o chiarimenti su Alcea Digital Web scrivi qui, verrai ricontattato dal nostro Customer Service.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form>
          <b-form-group :label="$t('formContact.name')" :invalid-feedback="validationError('name')" v-if="!loggedIn">
              <b-row>
                <b-col>
                  <b-form-input
                    required
                    placeholder="Nome..." 
                    v-model="form.name"
                    :state="validationState('name')"
                  />
                </b-col>
              </b-row>
          </b-form-group>

          <b-form-group :label="$t('formContact.email')" :invalid-feedback="validationError('email')" v-if="!loggedIn">
              <b-row>
                <b-col>
                  <b-form-input
                    required
                    placeholder="Email..." 
                    v-model="form.email"
                    :state="validationState('email')"
                  />
                </b-col>
              </b-row>
            </b-form-group>

          <b-form-group :label="$t('formContact.requestType')" :invalid-feedback="validationError('requestType')">
            <b-row>
              <b-col>
                <b-form-select
                  required
                  v-model="form.requestType"
                  :state="validationState('requestType')"
                >
                  <b-select-option :value="null" disabled>Seleziona una motivazione</b-select-option>
                  <b-select-option
                    v-for="requestType in requestTypes"
                    :value="requestType.value"
                    :key="requestType.value"
                >
                  {{ requestType.text }}
                </b-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-form-group>
            
            <b-form-group  :label="$t('formContact.request')" :invalid-feedback="validationError('request')">
              <b-row>
                <b-col>
                  <b-textarea
                    style="height: 150px"
                    required
                    placeholder="Scrivi qui il messaggio..."
                    v-model="form.request"
                    no-auto-shrink
                    max-rows="25"
                    :state="validationState('request')"
                  />
                </b-col>
              </b-row>
            </b-form-group>

        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-md-flex flex-row-reverse">
        <adw-button class="my-4 w-50"
                @button-click="sendRequest">
                <h3>{{ $t("actions.send") }}</h3>
            </adw-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import FeedbackAlert from "@/components/feedback-alert.vue";
import AdwButton from "@/components/global-components/adw-button";
import ErrorManager from '@/services/errors-service/errors-manager';
import RegistrationService from '@/services/api-services/registration-service';
import Validator from '@/services/validation-service/validator-service';


export default {
  name: "ContactArea",

  components: {
    FeedbackAlert,
    AdwButton,
  },

  data() {
    return {
      form: {
        name: null,
        email: null,
        request: null,
        requestType: null,
        language: null
      },

      requestTypes: [
        {value: "Richiesta commerciale", text: "Richiesta commerciale"},
        {value: "Informazioni funzionamento piattaforma", text: "Informazioni funzionamento piattaforma"},
        {value: "Altro", text: "Altro"},
      ],

      validation: [],
      feedbackStatus: null,
      feedbackMessage: "",
    }
  },

  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.status.loggedIn,
    })
  },

  created(){
    if(this.loggedIn) {
      this.form.email = this.$store.state.user.userinfo.username;
      this.form.name = this.$store.state.user.userinfo.username;
      this.form.language = this.$store.state.user.userinfo.language;
    }    
  },

  methods: {
    clearStatus() {
      this.feedbackStatus = null;
      this.feedbackMessage = "";
    },
    sendRequest(){
      if(this.validateCurretForm()){
        RegistrationService.contactRequest(this.form)
        .then((resp) => {
          this.resetForm();
          this.feedbackStatus = "SuccessDismissable";
          this.feedbackMessage = "successContactForm";
        }).catch((error) => {
          this.feedbackStatus = "FailDismissable";
          this.feedbackMessage = ErrorManager.feedback(error);
        });
      }
    },

    resetForm(){
      this.form = {
        name: this.loggedIn ? this.$store.state.user.userinfo.firstname + ' ' + this.$store.state.user.userinfo.lastname : null,
        email: this.loggedIn ? this.$store.state.user.userinfo.username : null,
        request: null,
        requestType: null,
        language: this.loggedIn ? this.$store.state.user.userinfo.language : null
      }
    },

    validationState(contextRef) {
      return this.validation.find(
        (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
      )
        ? false
        : null;
    },
    
    validationError(contextRef) {
      console.log();
      if (this.validation.length > 0) {
        let find =
          this.validation.find(
            (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
          ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },

    validateCurretForm() {
      this.validation = Validator.validateForm("contactArea", this.form).invalid;
      console.log(this.validation);
      if (this.validation.length > 0 ||
          Object.keys(this.form).find((f) => {
            return this.validation[f] ? this.checkValidation(f) : false;
          })
      ) {
        return false;
      } else {
        return true;
      }
    },
  }
}
</script>