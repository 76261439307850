export const retailer= {
  metal:'Metallo',
  wood:'Legno',
  construction:'Edilizia',
  type:'Tipologia Rivendita',
  bodywork:'Carrozzeria',
  ownership:'Negozio di proprietà?',
  shopWindows:'Numero vetrine',
  surface:'Superficie',
  mq:'mq',
  shop:'Negozio'

  };