<template>
  <b-container fluid class="ps-buy-container">

    <!-- header -->
    <b-row class="ps-buy-header bb px-0">
      <!-- title -->
      <b-col class="d-flex justify-content-between ps-buy-title">
        <div style="width: 46px;"></div>

        Seleziona il metodo di acquisto

        <!-- close modal -->
        <b-button @click.stop="closeModal" variant="link">
          <b-icon icon="x"></b-icon>
        </b-button>
      </b-col>
    </b-row>

    
    <b-row class="ps-buy-row">
      <!-- buy by store (amazon) -->
      <b-col cols="12" md="4" class="ps-buy-cp">
        <div>Tramite</div>
        <b-btn variant="link" @click="buyFromStore" :disabled="!this.product.link">
          <img src="@/assets/products-search/misc/amazon-logo.png" alt="amazon" width="100px"/>
        </b-btn>
      </b-col>

      <b-col cols="12" md="4" class="ps-buy-cp">
        <!-- buy by ecommerce -->
        <div v-if="cartFeedback != null">{{ $t('alert.' + (cartFeedback ? 'updateCartSuccess' : 'BadRequest')) }}</div>

        <!-- buy by ecommerce -->
        <template v-if="cartFeedback == null">
          <b-btn variant="link" @click="buyFromEcommerce">
            <span>Aggiungi al Carrello ADW</span><br/><br/>
            <b-icon-cart2 scale="1.5" />
          </b-btn>

          <!--
          <div v-if="customers.length > 0">
            Per conto del cliente:
            <select v-model="customerSelected">
              <option v-for="customer in customers" :key="customer.customerCode">{{ customer.customerCode }}</option>
            </select>
          </div>
          -->
        </template>

        <template v-else>
          <b-btn v-if="cartFeedback" variant="link" @click="goToCart">Prosegui al checkout</b-btn>
        </template>
      </b-col>

      <!-- buy by sellers -->
      <b-col cols="12" md="4" class="ps-buy-cp">
        
        <b-btn variant="link" @click="goToSellerMap">
          <span>Trova un Rivenditore</span><br/><br/>
          <b-icon-search scale="1.5" />
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { getProductFromDto } from '@/models/product'
import productsSearchTdsChoice from '@/views/ProductsSearch/ProductsSearchTdsChoice'

export default {
  name: 'ProductsSearchBuy',

  props: ['product'],

  components: {
    productsSearchTdsChoice
  },

  data() {
    return {
      cartFeedback: null,
      customers: [],
      customerSelected: null,
    }
  },

  computed: {
    ...mapState({
      userType: (state) => state.auth.user.type,
      userRole: (state) => state.auth.user.role,
      customerCode: (state) => state.customer.customerinfo.customerCode
    })
  },

  methods: {
    closeModal() {
      this.$emit('closeModal')
    },

    buyFromStore() {
      console.log('buyFromStore', this.product.link)

      window.open(this.product.link, 'adw_amazon')
    },

    buyFromEcommerce() {
      console.log('buyFromEcommerce', this.product)

      // stop if not company
      if (this.userType === 'P') {
        alert('Per accedere a questa sezione devi registrarti in ADW con un profilo Azienda')
        return
      }

      // stop if not customer
      if (this.userRole === 'USER') {
        alert('Per accedere a questa sezione devi diventare un cliente Alcea')
        return
      }

      // add to cart from agent
      if (['AGENT', 'COMMERCIAL'].includes(this.userRole)) {
        // if the agent already impersonate the selected customer => add to cart
        if (this.customerSelected === this.customerCode) {
          this.addToCart();
        }

        // otherwise, impersonate that customer and then add to cart
        else {
          this.$store.dispatch(this.userRole === 'COMMERCIAL' ? 'customerCommercial' : 'customerAgent', this.customerSelected)
              .then(() => {
                console.log('customer impersonation done', this.customerSelected)
                this.addToCart();
              })
              .catch((error) => {
                console.error(error)
                this.cartFeedback = false
              })
        }
      }

      // add to cart from customer
      else {
        this.addToCart()
      }
    },

    addToCart() {
      this.$store.dispatch('addProductsCart', [getProductFromDto(this.product)])
          .then(() => {
            console.log('addProductsCart DONE')
            this.cartFeedback = true
          })
          .catch((error) => {
            console.error('addProductsCart ERROR', error)
            this.cartFeedback = false
          })
    },

    goToCart() {
      this.$router.push('/profile-customer/cart')
    },

    loadAgentData() {
      if (['AGENT', 'COMMERCIAL'].includes(this.userRole)) {
        this.$store.dispatch('agentMyself')
            .then((c) => {
              this.customers = c.customers
              this.customerSelected = this.customerCode ? this.customerCode : this.customers[0].customerCode
            })
            .catch(() => {
              this.customers = []
            })
      }
    },

    goToSellerMap() {
      const kind = this.$route.meta.kind
      this.$router.push('/products-search/sellersMap/' + (kind === 'private') + '/' + (kind === 'professional'))
    }
  },

  mounted() {
    this.loadAgentData()
  },
}
</script>
