import ProductService from "../services/api-services/product-service";
import { getOrderedProductFromDto } from "@/models/product";
import i18n from "@/plugins/i18n";

const initialState = {
  orders: {
    info: {
      page: 1,
      row: 10,
      totalElements: 0,
      totalPages: 0,
      first: true,
      last: false,
      empty: true,
      sortDirection: "desc",
      sortBy: "id.serial",
      sortDesc: false,
    },
  },
};

export const ordered = {
  state: initialState,
  actions: {
    getOrderedProducts({ commit, dispatch, getters }, filters) {
      const customer = getters.getCustomerCode;
      let paginationInfo = getters.getPaginationInfo;
      filters = { ...filters, ...{ customerCode: customer } };
      return ProductService.getOrderedProducts(
        paginationInfo.page,
        paginationInfo.row,
        paginationInfo.sortBy, paginationInfo.sortDirection,
        filters,
        null
      ).then(
        (res) => {
          const response = res.data;
          console.log(paginationInfo.page, response);
          let info = {
            page: response.number ? response.number + 1 : paginationInfo.page,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            row: response.size,
            fist: response.first,
            last: response.last,
            sortDirection: paginationInfo.sortDirection,
            sortBy: paginationInfo.sortBy,
            sortDesc: paginationInfo.sortDirection == "desc",
            empty: response.empty,
          };
          console.log(response.number, info.page);
          commit("updatePaginationInfo", info);
          let content = response.content.map((rc) => {
            let product = getOrderedProductFromDto(rc);
            if (product.findDescriptionPerLang) {
              product.description = product.findDescriptionPerLang(
                getters.getUser.language,
                i18n.fallbackLocale
              );
            }
            product.statusLabel = i18n.t("product.statusValue." + product.status);
            return product;
          });
          return Promise.resolve(content);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updatePaginationInfo({ commit }, newPaginations) {
      commit("updatePaginationPages", newPaginations);
    },
    togglePreference({ commit }, item) {
      ProductService.togglePreference(item).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          console.log(error);
          return Promise.reject(error);
        })
    }
  },
  mutations: {
    updatePaginationInfo(state, paginationInfo) {
      state.orders.info = paginationInfo;
    },
    updatePaginationPages(state, newPaginations) {
      (state.orders.info.page = newPaginations.page),
        (state.orders.info.row = newPaginations.row),
        (state.orders.info.sortDirection = newPaginations.sortDirection),
        (state.orders.info.sortBy = newPaginations.sortBy),
        (state.orders.info.sortDesc = newPaginations.sortDesc);

      console.log(state.orders.info.row);
    },
    clearProductOrdered(state) {
      state.orders.info = {
        page: 1,
        row: 10,
        totalElements: 0,
        totalPages: 0,
        first: true,
        last: false,
        empty: true,
        sortDirection: "desc",
        sortBy: "id.serial",
        sortDesc: false,
      };
    },
  },
  getters: {
    getPaginationInfo: (state) => state.orders.info,
  },
};
