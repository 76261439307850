export const alert = {
  SuccessRegiatration: "Usuario registrado, Dentro de poco recibirá un email con un enlace para activar el account e ingresar las credenciales de acceso",
  sending: "Envío en curso...",
  operationSuccess: "Operación concluida correctamente",
  registrationComplete: "Felicitaciones, registro efectuado",
  newPasswordComplete: "Nueva Contraseña actualizada",
  resetPasswordSuccess: "Dentro de poco recibirá un email con un enlace para ingresar la nueva contraseña",
  notificationMailSuccess: "Actualizado notificaciones login",
  updateSuccess: "Actualizado perfil",
  sendRequestSuccess: "La solicitud será controlada lo antes posible, recibirá una comunicación por email con el resultado del control. Además, podrá ver el estado de la solicitud en su perfil de usuario.",
  sendInviteSuccess: "Invitación enviada sin problemas",
  acceptSuccess: "Invitación Aceptada",
  refuseSuccess: "Invitación Rechazada",
  deleteSuccess: "Invitación eliminada correctamente",
  deleteFail: "Operación de eliminación no llevada a cabo",
  becomeClientSuccess: "Felicitaciones, aceptando la invetación a partir de ahora puede acceder como cliente",
  productsInCart: "Productos agregados en el carrito",
  updateCartSuccess: "Carrito actualizado",
  removeItemSuccess: "Producto quitado del carrito",
  removeItemsSuccess: "Productos quitados del carrito",
  SuccessCheckOutOffer: "Abandono efectuado correctamente",
  SuccessCheckOutOrder: "Abandono efectuado correctamente",
  somethingWrong: "Algo no funcionó como debía",
  NetworkError: "Error de red",
  UnauthorizedLogin: "Nombre de usuario y contraseña no válidos",
  Unauthorized: "No dispone de las debidas autorizaciones",
  BadRequest: "Error en la formulación de la solicitud",
  NotFound: "No se halló ninguna correspondencia",
  Conflict: "Está tratando de enviar un dato ya memorizado",
  ServerError: "Se produjo un error en el servidor",
  UnauthorizedOrInvalid: "No cuenta con las debidas autorizaciones o el enlace dejó de ser válido",
  customerRequestExistingVatTitle: "Atención",
  customerRequestExistingVat: "El Número de IVA que se ingresó ya existe; si está tratando de crear una solicitud para un cliente ya registrado, clicar sí 'Usar Código de Cliente' para asociar el usuario a un cliente que ya existe utilizando el código de cliente proporcionado",
  agentCustomerRequestExistingVat: "El Número de IVA que se ingresó pertenece a un cliente que elevó un pedido",
  editRequestExistingVat: "Atención ya hay una solicitud en espera para este cliente",
  TdsError: "Ocurrió un error durante la descarga",
  missingTds: "Para este producto no hay ninguna ficha técnica"
};
