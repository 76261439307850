import ProfileUser from "@/views/ProfileUser"

export default {
  path: "/profile-user",
  name: "ProfileUser",
  component: ProfileUser,
  meta: {
    sector: 'PrivateArea',
    requiresAuth: true,
    roles: ['USER', 'CUSTOMER', 'AGENT', 'COMMERCIAL', 'IT', 'ALCEA', 'ADMIN']
  }
}
