import axios from "axios";
import store from "../store";
import ErrorInterceptor from "../services/errors-service/errors-interceptor";
import conf from "@/util/conf";

const auth = axios.create({
  baseURL: conf.value('VUE_APP_API_URL'),
  headers: {
    Accept: "application/json"
  }
});

auth.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    // or get it from store
    config.headers.Authorization = "Bearer " + user.accessToken;
  }
  return config;
});
const guestUrl = ["/auth/", "forgot-password", "confirm-registration", "update-forgotten-password"];

auth.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    const originalConfig = error.config;
    console.log(originalConfig.url);
    if (!guestUrl.find(urlString => originalConfig.url.includes(urlString)) && error.response) {
      console.log("reject, access token was expired:", ErrorInterceptor.isUnAuth(error.response));
      if (ErrorInterceptor.isUnAuth(error.response)) {
        if (!originalConfig._retry) {
          originalConfig._retry = true;

          return store
            .dispatch("refreshToken")
            .then((rs) => {
              console.log("Repeat Request", auth.request(originalConfig));
              return Promise.resolve(auth.request(originalConfig));
            })
            .catch((e) => Promise.reject(ErrorInterceptor.refeshToken(e)));
        } else {
          return Promise.reject(ErrorInterceptor.refeshToken(error.response));
        }
      }
    }
    if (
      ErrorInterceptor.helper(error).data &&
      ErrorInterceptor.helper(error).data.message === "GENERAL"
    ) {
      console.log("Repeat Request");
      return Promise.resolve(auth.request(originalConfig));
    }
    return Promise.reject(ErrorInterceptor.helper(error));
  }
);

export default auth;
