import Validator from "../services/validation-service/validator-service";
export class Customer {
  //AnagraphicsInfo fileds
  customerCode;
  businessName;
  vatNumber;
  italianFiscalCode;
  sdiCode;
  pec;
  //CompanyPerspective fields
  marketSegmentation1;
  marketSegmentation2;
  marketSegmentation3;
  marketSegmentation4;
  marketCompetitor1;
  marketCompetitor2;
  totalConsumption;
  alceaPotential;
  isRetailerOfMetal;
  isRetailerOfWood;
  isRetailerOfConstruction;
  isRetailerOfBodywork;
  isRetailerOfOwnership;
  retailerShopWindows;
  retailerSurface;
  tntNextRequired;
  unconditionalDiscountPct;
  //Addresses fields
  legalAddress = new Address(true);
  businessAddress = new Address(false);
  shippingAddresses = sortShippingAdddresses(
    new Array({
      ...new Address(false),
      ...{ code: String() },
    })
  );
  purchasingContact = new Contact();
  administrativeContact = new Contact();
  additionalContacts = sortAdditionalContacts(
    new Array(new AdditionalContact(true))
  );
  billingEmails = [];
  transportDocumentEmails = [];
  msdsEmails = [];
  testSheetsEmails = [];
  //BankInformations fields
  bankName;
  iban;
}

export class Address {
  businessName;
  streetAddress1;
  streetAddress2;
  country;
  city;
  zipOrPostalCode;
  province;
  notes;
  isDefault;
  constructor(isLegal, emptyDraft, dataAddress, formToPostContact) {
    console.log(dataAddress);
    if (emptyDraft) {
      this.businessName= "";
      this.streetAddress1 = "";
      this.streetAddress2 = "";
      this.country = "";
      this.city = "";
      this.zipOrPostalCode = "";
      this.province = "";
      this.notes = "";
      this.isDefault = false;
      this.isShipping = true;
    } else {
      if (dataAddress) {
        this.businessName = dataAddress.businessName;
        this.streetAddress1 = dataAddress.streetAddress1;
        this.streetAddress2 = dataAddress.streetAddress2;
        this.country = dataAddress.country;
        this.city = dataAddress.city;
        this.zipOrPostalCode = dataAddress.zipOrPostalCode;
        this.province = dataAddress.province;
        this.notes = dataAddress.notes;
        this.isDefault = dataAddress.isDefault
        this.isShipping = dataAddress.isShipping
      } else {
        if (formToPostContact) {
          //this.code = Validator.valid(formToPostContact.code) ? formToPostContact.code : null;
          this.streetAddress1 = Validator.valid(
            formToPostContact.streetAddress1
          )
            ? formToPostContact.streetAddress1
            : null;
          this.streetAddress2 = Validator.valid(
            formToPostContact.streetAddress2
          )
            ? formToPostContact.streetAddress2
            : null;
          this.country = Validator.valid(formToPostContact.country)
            ? formToPostContact.country
            : null;
          this.city = Validator.valid(formToPostContact.city)
            ? formToPostContact.city
            : null;
          this.zipOrPostalCode = Validator.valid(
            formToPostContact.zipOrPostalCode
          )
            ? formToPostContact.zipOrPostalCode
            : null;
          this.notes = Validator.valid(formToPostContact.notes)
            ? formToPostContact.notes
            : null;
          this.businessName = Validator.valid(formToPostContact.businessName)
            ? formToPostContact.businessName
            : null;
          this.province = Validator.valid(formToPostContact.province)
            ? formToPostContact.province
            : null;
        }
      }
    }
  }
  equal(address) {
    return (
      address &&
      this.code == address.code &&
      //this.businessName == address.businessAddress &&
      this.streetAddress1 == address.streetAddress1 &&
      this.streetAddress2 == address.streetAddress2 &&
      this.country == address.country &&
      this.city == address.city &&
      this.zipOrPostalCode == address.zipOrPostalCode &&
      this.province == address.province &&
      this.note == address.note
    );
  }
}

export function getLegalAddress (arrayAddresses) {
  const address = arrayAddresses.find(a => "SL" === a.code);

  return new Address(true, false, false, address);

}
export function getBusinessAddress (arrayAddresses) {
  const address = arrayAddresses.find(a => "SA" === a.code);

  return new Address(true, false, false, address);
}
export function sortShippingAdddresses(arrayAddresses) {
  let shippingAddresses = arrayAddresses.filter(a => a.isShipping).map((element, index) => {
    return {
      ...new Address(false,false, false, element),
      ...{
        code: Validator.valid(element.code)
          ? element.code
          : "SD" + ("" + (index + 1)).padStart(3, "0")
      },
    };
  });
  return shippingAddresses.sort(function (a, b) {
    return a.code - b.code;
  });
}
export function sortAdditionalContacts(arrayContacts) {
  let contacts = arrayContacts.map((element, index) => {
    element.code = Validator.valid(element.code)
      ? element.code
        : "C" + ("" + (index + 1)).padStart(3, "0");
    return element;
  });
  return contacts.sort(function (a, b) {
    return a.code - b.code;
  });
}
export class Contact {
  gender;
  firstName;
  lastName;
  role;
  email;
  phoneNumber;
  constructor(emptyDraft, dataContact, userContact, formToPostContact) {
    if (emptyDraft) {
      this.gender = "";
      this.firstName = "";
      this.lastName = "";
      this.role = "";
      this.email = "";
      this.phoneNumber = "";
    } else {
      if (dataContact) {
        this.gender = dataContact.gender;
        this.firstName = dataContact.firstName;
        this.lastName = dataContact.lastName;
        this.role = dataContact.role;
        this.email = dataContact.email;
        this.phoneNumber = dataContact.phoneNumber;
      } else if (userContact) {
        this.gender = userContact.gender;
        this.firstName = userContact.firstname;
        this.lastName = userContact.lastname;
        this.email = userContact.email;
        this.phoneNumber = userContact.telephone;
      } else if (formToPostContact) {
        this.gender = Validator.valid(formToPostContact.gender)
          ? formToPostContact.gender
          : null;
        this.firstName = Validator.valid(formToPostContact.gender)
          ? formToPostContact.firstName
          : null;
        this.lastName = Validator.valid(formToPostContact.gender)
          ? formToPostContact.lastName
          : null;
        this.role = Validator.valid(formToPostContact.gender)
          ? formToPostContact.role
          : null;
        this.email = Validator.valid(formToPostContact.gender)
          ? formToPostContact.email
          : null;
        this.phoneNumber = Validator.valid(formToPostContact.gender)
          ? formToPostContact.phoneNumber
          : null;
      }
    }
  }
}
/*"administrativeContact.department: non deve essere null;  
 purchasingContact.department: non deve essere null"*/
export class AdditionalContact {
  code;
  gender;
  firstName;
  lastName;
  role;
  email;
  phoneNumber;
  department;
  constructor(emptyDraft, dataContact, userContact, formToPostContact) {
    if (emptyDraft) {
      this.code = "";
      this.gender = null;
      this.firstName = "";
      this.lastName = "";
      this.role = null;
      this.email = "";
      this.phoneNumber = null;
      this.department = null;
    } else {
      if (dataContact) {
        this.code = dataContact.code;
        this.gender = dataContact.gender;
        this.firstName = dataContact.firstName;
        this.lastName = dataContact.lastName;
        this.role = dataContact.role;
        this.email = dataContact.email;
        this.phoneNumber = dataContact.phoneNumber;
        this.department = dataContact.department;
      } else if (userContact) {
        this.code = userContact.code;
        this.gender = userContact.gender;
        this.firstName = userContact.firstname;
        this.lastName = userContact.lastname;
        this.department = userContact.department;
        this.role = userContact.businessRole;
        //this.role=null
        this.email = userContact.email;
        this.phoneNumber = userContact.telephone;
      } else if (formToPostContact) {
        this.code = Validator.valid(formToPostContact.code)
          ? formToPostContact.code
          : null;
        this.gender = Validator.valid(formToPostContact.gender)
          ? formToPostContact.gender
          : null;
        this.firstName = Validator.valid(formToPostContact.firstName)
          ? formToPostContact.firstName
          : null;
        this.lastName = Validator.valid(formToPostContact.lastName)
          ? formToPostContact.lastName
          : null;
        this.role = Validator.valid(formToPostContact.role)
          ? formToPostContact.role
          : null;
        this.email = Validator.valid(formToPostContact.email)
          ? formToPostContact.email
          : null;
        this.phoneNumber = Validator.valid(formToPostContact.phoneNumber)
          ? formToPostContact.phoneNumber
          : null;

        this.department = Validator.valid(formToPostContact.department)
          ? formToPostContact.department
          : null;
      }
    }
  }
}
export function getCustomerForm(emptyDraft, dataCustomer, agent) {
  let form;
  if (emptyDraft) {
    form = {
      businessName: "",
      vatNumber: "",
      italianFiscalCode: null,
      sdiCode: null,
      pec: null,
      legalAddress: new Address(true),
      businessAddress: new Address(false),
      shippingAddresses: sortShippingAdddresses(
        new Array({
          ...new Address(false),
          ...{ code: String() },
        })
      ),
      purchasingContact: new Contact(),
      administrativeContact: new Contact(),
      billingEmails: [],
      transportDocumentEmails: [],
      msdsEmails: [],
      testSheetsEmails: [],
      bankName: "",
      iban: "",
    };
    if (agent) {
      form = {
        ...form,
        ...{
          marketSegmentation1: null,
          marketSegmentation2: null,
          marketSegmentation3: null,
          marketSegmentation4: null,
          marketCompetitor1: null,
          marketCompetitor2: null,
          totalConsumption: 0,
          alceaPotential: 0,
          isRetailerOfMetal: false,
          isRetailerOfWood: false,
          isRetailerOfConstruction: false,
          isRetailerOfBodywork: false,
          isRetailerOfOwnership: false,
          retailerShopWindows: 0,
          retailerSurface: 0,
          tntNextRequired: false,
          contacts: sortAdditionalContacts(new Array()),
        },
      };
    }
  } else {
    if (dataCustomer) {
      form = {
        businessName: dataCustomer.businessName,
        vatNumber: dataCustomer.vatNumber,
        italianFiscalCode: dataCustomer.italianFiscalCode,
        sdiCode: dataCustomer.sdiCode,
        pec: dataCustomer.pec,
        legalAddress: getLegalAddress(dataCustomer.addresses),
        businessAddress: getBusinessAddress(dataCustomer.addresses),
        shippingAddresses: sortShippingAdddresses(
          dataCustomer.addresses
        ),
        contacts: sortAdditionalContacts(dataCustomer.contacts),
        billingEmails: dataCustomer.billingEmails,
        transportDocumentEmails: dataCustomer.transportDocumentEmails,
        msdsEmails: dataCustomer.msdsEmails,
        testSheetsEmails: dataCustomer.testSheetsEmails,
        bankName: dataCustomer.bankName,
        iban: dataCustomer.iban,
      };
      if (agent) {
        form = {
          ...form,
          ...{
            marketSegmentation1: dataCustomer.marketSegmentation1,
            marketSegmentation2: dataCustomer.marketSegmentation2,
            marketSegmentation3: dataCustomer.marketSegmentation3,
            marketSegmentation4: dataCustomer.marketSegmentation4,
            marketCompetitor1: dataCustomer.marketCompetitor1,
            marketCompetitor2: dataCustomer.marketCompetitor2,
            totalConsumption: dataCustomer.totalConsumption,
            alceaPotential: dataCustomer.alceaPotential,
            isRetailerOfMetal: dataCustomer.isRetailerOfMetal,
            isRetailerOfWood: dataCustomer.isRetailerOfWood,
            isRetailerOfConstruction: dataCustomer.isRetailerOfConstruction,
            isRetailerOfBodywork: dataCustomer.isRetailerOfBodywork,
            isRetailerOfOwnership: dataCustomer.isRetailerOfOwnership,
            retailerShopWindows: dataCustomer.retailerShopWindows,
            retailerSurface: dataCustomer.retailerSurface,
            tntNextRequired: dataCustomer.tntNextRequired,
            additionalContacts: sortAdditionalContacts(
              dataCustomer.contacts.map((element) => {
                return new AdditionalContact(false, element);
              })
            ),
          },
        };
      }
    }
  }
  return form;
}

export function getCustomerCompleteForm(draft, agent) {
  let form;
  if (draft) {
    form = {
      businessName: Validator.valid(draft.businessName)
        ? draft.businessName
        : null,
      vatNumber: Validator.valid(draft.vatNumber) ? draft.vatNumber : null,
      italianFiscalCode: Validator.valid(draft.italianFiscalCode)
        ? draft.italianFiscalCode
        : null,
      sdiCode: Validator.valid(draft.sdiCode) ? draft.sdiCode : null,
      pec: Validator.valid(draft.pec) ? draft.pec : null,
      legalAddress: getLegalAddress(draft.addresses),
      businessAddress: getBusinessAddress(draft.addresses),
      shippingAddresses: sortShippingAdddresses(
          draft.addresses
      ),
      purchasingContact: new Contact(
        false,
        null,
        null,
        draft.purchasingContact
      ),
      administrativeContact: new Contact(
        false,
        null,
        null,
        draft.administrativeContact
      ),
      billingEmails: Validator.valid(draft.billingEmails)
        ? draft.billingEmails
        : [],
      transportDocumentEmails: Validator.valid(draft.transportDocumentEmails)
        ? draft.transportDocumentEmails
        : [],
      msdsEmails: Validator.valid(draft.msdsEmails) ? draft.msdsEmails : [],
      testSheetsEmails: Validator.valid(draft.testSheetsEmails)
        ? draft.testSheetsEmails
        : [],
      bankName: Validator.valid(draft.bankName) ? draft.bankName : null,
      iban: Validator.valid(draft.iban) ? draft.iban : null,
    };
    if (agent) {
      form = {
        ...form,
        ...{
          marketSegmentation1: Validator.valid(draft.marketSegmentation1)
            ? draft.marketSegmentation1
            : null,
          marketSegmentation2: Validator.valid(draft.marketSegmentation2)
            ? draft.marketSegmentation2
            : null,
          marketSegmentation3: Validator.valid(draft.marketSegmentation3)
            ? draft.marketSegmentation3
            : null,
          marketSegmentation4: Validator.valid(draft.marketSegmentation4)
            ? draft.marketSegmentation4
            : null,
          marketCompetitor1: Validator.valid(draft.marketCompetitor1)
            ? draft.marketCompetitor1
            : null,
          marketCompetitor2: Validator.valid(draft.marketCompetitor2)
            ? draft.marketCompetitor2
            : null,
          totalConsumption: Validator.valid(draft.totalConsumption)
            ? draft.totalConsumption
            : null,
          alceaPotential: Validator.valid(draft.alceaPotential)
            ? draft.alceaPotential
            : null,
          isRetailerOfMetal: Validator.valid(draft.isRetailerOfMetal)
            ? draft.isRetailerOfMetal
            : null,
          isRetailerOfWood: Validator.valid(draft.isRetailerOfWood)
            ? draft.isRetailerOfWood
            : null,
          isRetailerOfConstruction: Validator.valid(
            draft.isRetailerOfConstruction
          )
            ? draft.isRetailerOfConstruction
            : null,
          isRetailerOfBodywork: Validator.valid(draft.isRetailerOfBodywork)
            ? draft.isRetailerOfBodywork
            : null,
          isRetailerOfOwnership: Validator.valid(draft.isRetailerOfOwnership)
            ? draft.isRetailerOfOwnership
            : null,
          retailerShopWindows: Validator.valid(draft.retailerShopWindows)
            ? draft.retailerShopWindows
            : null,
          retailerSurface: Validator.valid(draft.retailerSurface)
            ? draft.retailerSurface
            : null,
          tntNextRequired: Validator.valid(draft.tntNextRequired)
            ? draft.tntNextRequired
            : null,
          additionalContacts: draft.additionalContacts.map((element) => {
            return new AdditionalContact(false, element);
          }),
        },
      };
    }
  }
  return form;
}

export default new Customer();
