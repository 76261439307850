<template>
  <div>
    <b-container fluid class="oo-header py-2" :style="backgroundStyle(order)">
      <b-row>
        <!-- title -->
        <b-col>
          <h4 class="text-uppercase">{{ $t("orderInfo.orderRequest") }}</h4>
        </b-col>

        <!-- creation date -->
        <b-col>
          {{ $t("orderInfo.createdOn") }}: {{ formatStringDate(order.createdOn) }}
        </b-col>

        <!-- delivery date -->
        <b-col>
          {{ $t("orderInfo.deliveryDate") }}: {{ formatStringDate(order.deliveryDateRequested) }}
        </b-col>

        <!-- status -->
        <b-col class="text-right text-uppercase">
          {{ $t("orderInfo.requestStatus." + order.status) }}
        </b-col>
      </b-row>

      <b-row>
        <!-- id -->
        <b-col>
          <h4>{{ order.id }}</h4>
        </b-col>

        <!-- multiple shipping -->
        <b-col>
          {{ $t("orderInfo.acceptMultipleShipping") }}: {{ $t(order.acceptMultipleShipping ? 'labels.yes' : 'labels.no') }}
        </b-col>

        <!-- filler -->
        <b-col></b-col>

        <!-- filler -->
        <b-col class="text-right ">
          <b-btn variant="link"  v-b-toggle :href="'#order-request-items-' + order.id" @click.prevent="itemsOpened = !itemsOpened">{{ $t("labels.products") }}: {{ items.length }} <b-icon-chevron-down :rotate="itemsOpened ? 180 : 0" /></b-btn>
        </b-col>
      </b-row>

    </b-container>
    <b-collapse :id="'order-request-items-' + order.id" >
    <b-table
        :items="items"
        :keys="fields"
        :fields="fields"
        class="mb-0"
        sort-by="sortOrder"
        ref="dataTable"
        stacked="sm"
        show-empty>
      <template #empty="">
        <h5 class="text-center">{{ $t("formCheckout.emptyOrder") }}</h5>
      </template>

      <template #cell(productCode)="data">
        <span>{{ data.item.productCode }}</span>
      </template>

      <template #cell(description)="data">
        <span>{{ data.item && data.item.findDescriptionPerLang ? data.item.findDescriptionPerLang($i18n.locale, "IT") : data.item.pdtDesc }}</span>
      </template>

      <template #cell(unitPrice)="data">
        <span v-if="data.item.unitPrice">{{ data.item.unitPrice.toFixed(2) }} €/{{data.item.um}}</span>
        <span v-else-if="!data.item.unitPrice">
          {{ $t("product.notAssigned") }}
          <b-icon-info-circle aria-hidden="true" @click="$refs.notAssignedModal.show()"></b-icon-info-circle>
        </span>
      </template>

      <template #cell(priceListCode)="data" @click.stop>
        <span>
          {{ data.item.priceListCode ? $t("product.priceList." + data.item.priceListCode) : '-' }} 
        </span>
      </template>
      
      <template #cell(discounts)="data">
        <span>
          {{ data.item.discountPct1||'0' }}+{{ data.item.discountPct2||'0' }} % 
        </span>
      </template>

      <template #cell(unitQty)="data">
        <span>{{ data.item.unitQty }} {{data.item.um}}</span>
      </template>

      
      <template #cell(finalUnitPrice)="data">
        <span v-if="data.item.unitPrice">{{ data.item.finalUnitPrice.toFixed(2) }} €/{{data.item.um}}</span>
        <span v-else-if="!data.item.unitPrice">
          {{ $t("product.notAssigned") }}
          <b-icon-info-circle aria-hidden="true" @click="$refs.notAssignedModal.show()"></b-icon-info-circle>
        </span>
      </template>

      <template #cell(rowTotalPrice)="data">
        <span v-if="data.item.unitPrice">{{ (data.item.unitPrice * data.item.unitQty * data.item.quantity).toFixed(2) }} €</span>
        <span v-else-if="!data.item.unitPrice"> - </span>
      </template>

      <template v-if="order.status === 'ACCEPTED'" #custom-foot>
        <b-tr v-if="order.overallDiscount > 0">
          <b-td :colspan="isUserCustomer ? 4 : 6" style="border-bottom: none;"></b-td>
          <b-td :colspan="isUserCustomer ? 3 : 4" class="text-right px-5 bl text-uppercase">
            <strong class="px-2">{{ $t("formCheckout.unconditionalDiscount") }} {{ order.overallDiscount }}% </strong>
            - {{ unconditionalDiscountTotal.toFixed(2) }} €
          </b-td>
        </b-tr>
        <b-tr>
          <b-td :colspan="isUserCustomer ? 4 : 6" style="border-bottom: none;"></b-td>
          <b-td :colspan="isUserCustomer ? 3 : 4" class="text-right px-5 bl text-uppercase" :style="backgroundStyle(order)">
            <strong class="px-2">{{ $t("formCheckout.totalOrder") }} </strong>
            {{ total.toFixed(2) }} €
          </b-td>
        </b-tr>
      </template>
    </b-table>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OrderOrderComponent",

  props: {
    order: Object(),
  },

  data() {
    return {
      items: [],
      itemsOpened: false,
    };
  },

  computed: {
    ...mapState({
      userRole: (state) => state.auth.user.role,
    }),

    unconditionalDiscountTotal () {
      return this.totalWithoutDiscount * (this.order.overallDiscount/100);
    },

    totalWithoutDiscount() {
      return this.items
          ? this.items.reduce(function (a, c) {
            return a + Number(c.unitPrice * c.unitQty * c.quantity || 0);
          }, 0)
          : 0;
    },

    total() {
      return !this.order.overallDiscount ? this.totalWithoutDiscount :
        this.totalWithoutDiscount * (1 - (this.order.overallDiscount / 100));
    },

    isUserCustomer () {
      return this.userRole=='CUSTOMER';
    },

    fields() {
      
        let fields = [
          {
            key: "productCode",
            label: this.$i18n.t("product.product"),
            sortKey: "productCode",
          },
          {
            key: "description",
            label: this.$i18n.t("product.description"),
          },
          {
            key: "brand",
            label: this.$i18n.t("product.brand"),
          },
          {
            key: "unitQty",
            label: this.$i18n.t("product.unitQty"),
            tdClass: "",
          },
          {
            key: "quantity",
            label: this.$i18n.t("product.quantity"),
          },
        ];

        if (this.order.status === "ACCEPTED") {
          if(this.isUserCustomer) {
            return [...fields, ...[
              {
                key: "finalUnitPrice",
                label: this.$i18n.t("product.unitPrice"),
                tdClass: "",
              },
              {
                key: "rowTotalPrice",
                label: this.$i18n.t("product.rowTotalPrice"),
                tdClass: " px-10",
              },
            ]]
          } else {
            return [...fields, ...[
              {
                key: "unitPrice",
                label: this.$i18n.t("product.unitPrice"),
                sortable: false,
                tdClass: "",
              },
              {
                key: "priceListCode",
                label: this.$i18n.t("product.priceListCode"),
                sortable: false,
                tdClass: "",
              },
              {
                key: "discounts",
                label: this.$i18n.t("product.discounts"),
                sortable: false,
                tdClass: "",
              },
              {
                key: "finalUnitPrice",
                label: this.$i18n.t("product.finalUnitPrice"),
                sortable: false,
                tdClass: "",
              },
              {
                key: "rowTotalPrice",
                label: this.$i18n.t("product.rowTotalPrice"),
                tdClass: " px-10",
              },
            ]]
          }
        }

        return fields;
      }
    },

  methods: {
    backgroundStyle(order) {
      if (order.status === "PENDING") {
        return { 'background-color': '#EFEFED' };
      }
      if (order.status === "ACCEPTED") {
        return { 'background-color': '#A7D8C0' };
      }
      if (order.status === "REFUSED") {
        return { 'background-color': '#FF9A5C' };
      }
    },

    getItems() {
      this.items = [];
      for (let [sortOrder, item] of this.order.items.entries()) {
        this.$store
            .dispatch("getProduct", Object.assign(item, {sortOrder: sortOrder}))
            .then((res) => this.items.push(res));
      }
    },

    requestNewOrder() {
      this.$store.commit("StartCheckoutOrder", this.items);
      this.$router.push("/checkout/order");
    },

    formatStringDate(dateString) {
      if (dateString) {
        const [year, month, day] = dateString.split("-");
        const date = new Date(year, month - 1, day);
        return ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear();
      } else {
        return '-';
      }
    },
  },

  created() {
    this.getItems();
  },

  watch: {
    order() {
      this.getItems();
    }
  },
};
</script>
