export const instructions = {
  checkout: {
     shippingAddress: "Select the shipping address for this order.",
     customerOrderCode: "If necessary, indicate a customer order code associated with this request.",
     deliveryDateRequested: "If necessary, indicate a preferred date of receipt of the order, if not defined, the order will be delivered on the best possible date.",
     acceptMultipleShipping: "Indicate whether to accept multiple shipments on account, otherwise you will receive the order at once.",
     shippingNote: "Insert if useful the notes on the shipment, these notes will be inserted in the transport document.",
     genericNote: "If necessary, enter generic notes relating to the order."
  },
  formCustomer: {
     vatNumber: "To change the VAT number, contact Alcea."
  },
  filters: "Filters"
};
