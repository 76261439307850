export const actions = {
  ok: "OK",
  logout: "Logout",
  loginToProceed: "Effettua la login",
  loginCompleted: "Login effettuato",
  retrievePassword: "Recuperala",
  signUp: "Registrati",
  backToLogin: "Torna alla login",
  update: "Aggiorna",
  updateProfile: "Modifica profilo",
  create: "Crea",
  access: "Accedi",
  send: "Invia",
  saveDraft: "Salva Bozza",
  next: "Avanti",
  back: "Indietro",
  cancel: "Annulla",
  newPassword: "Invia",
  confirmRegistration: "Completa Registrazione",
  confirm: "Conferma",
  compileRequest: "Compila la richiesta",
  continue: "Continua",
  newRequest: "Nuova richiesta",
  associate: "Associa",
  associateToExisting: "Clicca qui",
  inviteNewUser: "Invita un nuovo Utente a diventare Cliente",
  updateToCustomer: "Diventa Cliente",
  goToClientAssignement: "Usa Codice Cliente",
  understand: "Ho capito",
  delete: "Elimina",
  accept: "Accetta",
  refuse: "Rifiuta",
  close: "Chiudi",
  invite: "Invia Invito",
  showAllCustomer: "Vedi tutti i clienti",
  edit: "Modifica",
  goToProfile: "vai al profilo",
  search: "Cerca",
  searchProduct: "Ricerca prodotto",
  addSelectedToChart: "Aggiungi selezionati al carrello",
  addToChart: "Aggiungi al carrello",
  clear: "Cancella",
  deleteSelected: "Elimina selezionati",
  updateCart: "Aggiorna carrello",
  offerRequest: "Richiedi offerta",
  orderRequest: "Richiedi ordine",
  add: "Aggiungi",
  buy: "Acquista",
  showProduct: "Visualizza prodotto",
  itemToCart: "prodotto selezionato al carrello",
  itemsToCart: "prodotti selezionati al carrello",
  requestNewOrder: "Richiedi nuovo ordine",
  requestNewOffer: "Richiedi nuova offerta",
  requestOrder: "Richiedi ordine",
  redoOrderRequest: "Richiedi stesso ordine",
  openMap: "Naviga",
  startProductSearch: "Inizia ricerca prodotti",
  backToCart: "Torna al carrello",
  showFilters: "Mostra filtri di ricerca",
  hideFilters: "Nascondi filtri di ricerca",
};
