<template>
  <div>
    <b-container fluid>
      <b-row>
        <!-- left section -->
        <b-col sm="7" class="px-0">
          <div class="home-bg" >
            <img class="home-bg-img" src="@/assets/img/ADW_Home.jpg" alt="ADW"/>
          </div>
        </b-col>

        <!-- right section -->
        <b-col class="my-5 mx-sm-0 mx-3 d-flex flex-column align-items-bottom">
          <div class="home-heading">
            <h1 class="mt-2 text-uppercase">{{ $t('titles.welcome') }}</h1>
            <h2 class="mt-3 text-uppercase">{{ $t('subtitles.welcome') }}</h2>
          </div>
          <div class="home-action flex-grow-1 d-flex align-items-center py-3">
            <adw-button v-if="loggedIn" class="w-100"
                  @button-click="$router.push('/products-search')">
                  <h3>{{ $t("actions.startProductSearch") }}</h3>
            </adw-button>
            <adw-button v-else class="w-100"
                  @button-click="$router.push('/login')">
                  <h3>{{ $t("actions.access") }}</h3>
            </adw-button>
          </div>
          <div class="home-bottom mt-auto ">
            <p>
              {{ $t('subtitles.homeText') }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ProductsSearch from '@/views/ProductsSearch/ProductsSearch'
import AdwButton from "@/components/global-components/adw-button";

import { mapState } from 'vuex'

export default {
  name: "Home",
  components: {
    ProductsSearch,
    AdwButton,
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.status.loggedIn,
    }),
  }
}
</script>

<style lang="scss">
.home-bg {
  width: 90%;
  height: 100%;
  
  border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  overflow: hidden;
  border: var(--border);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
