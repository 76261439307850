<template>
  <div class="login-background bx">
    <div class="d-flex justify-content-center login-container">
      <b-overlay :show="isLoading" rounded class="login-overlay">

        <!-- feedback -->
        <feedback-alert
            class="w-100"
            :status="feedbackStatus"
            :message="feedbackMessage"
            @clearStatus="clearStatus"
            @back="back">
        </feedback-alert>

        <!-- form -->
        <b-form @submit.prevent="sendRequest" v-if="feedbackStatus !== 'InfoSuccess'">
          <!-- upper box -->
          <div class="login-box">
            <h5 class="login-title">{{ $t("titles.forgotPassword") }}</h5>

            <b-row>
              <b-col class="login-input">
                <b-form-group :invalid-feedback="validationError('username')">
                  <b-form-input required
                                id="email"
                                type="text"
                                v-model="form.username"
                                :placeholder="$t('labels.insertUsername')"
                                :state="validationState('username')">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <!-- login button -->
          <adw-button class="my-4"
            @button-click="sendRequest">
            <h3>{{ $t("actions.send") }}</h3>
          </adw-button>
        </b-form>

      </b-overlay>
    </div>
  </div>
</template>

<script>
import FeedbackAlert from "../components/feedback-alert.vue"
import Validator from "../services/validation-service/validator-service"
import ErrorManager from "../services/errors-service/errors-manager"
import AdwButton from "@/components/global-components/adw-button";


export default {
  name: 'ForgotPassword',

  components: {
    FeedbackAlert,
    AdwButton,
  },

  data() {
    return {
      isLoading: false,
      form: {
        username: null,
      },
      feedbackMessage: "",
      feedbackStatus: null,
      invalid: [],
      otherError: {
        name: null,
        msg: null
      },
    }
  },

  methods: {
    back() {
      if (this.feedbackStatus === "InfoSuccess") {
        this.$router.push("/login")
      }
    },

    validationState(contextRef) {
      return this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) ? false : null
    },

    validationError(contextRef) {
      if (this.invalid.length > 0) {
        let find = this.invalid.find((invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()) || null
        return find ? this.$i18n.t(find.error) : null
      }
    },

    clearStatus() {
      this.feedbackStatus = null
      this.feedbackMessage = ""
    },

    sendRequest() {
      this.otherError.name = null
      this.otherError.msg = null

      try {
        let validation = Validator.validateForm("forgotPassword", this.form)
        this.invalid = validation.invalid
      } catch (e) {
        console.error(e)
        this.otherError.name = "validation"
        this.otherError.msg = e
        this.invalid.push(this.otherError)
      }

      if (this.invalid.length > 0 || this.otherError.msg) {
        console.error("possible 409")
        console.error(this.invalid)
        console.error(this.otherError)
      } else {
        this.send()
      }
    },

    async send() {
      this.feedbackStatus = null
      this.isLoading = true
      await this.$store.dispatch("forgotPassword", this.form.username)
          .then((response) => {
            console.log(response)
            this.isLoading = false;
            this.feedbackStatus = "InfoSuccess"
            this.feedbackMessage = "resetPasswordSuccess"
          })
          .catch((error) => {
            console.error(error)
            this.otherError.name = "forgotpassword"
            this.otherError.msg = error
            this.feedbackStatus = "FailDismissable"
            this.feedbackMessage = ErrorManager.feedback(error)
            this.isLoading = false;
          })
    },
  },
}
</script>
