export const titles = {
  welcome: "Willkommen bei ADW!",
  login: "Anmelden",
  faq: "Video Tutorial",
  profile: "Profil",
  profileCustomer: "Kundenprofil",
  profileUser: "Benutzerprofil",
  register: "Aufzeichnung",
  forgotPassword: "Passwort zurücksetzen",
  newPassword: "Neues Passwort",
  confirmRegistration: "Registrierung bestätigen",
  privateArea: "Kundenbereich",
  personalInformation: "Daten zur Person",
  companyInformation: "Firmendaten",
  notificationEmail: "Zugangsbenachrichtigungen",
  anagraphicInfo: "Stammdateninfo",
  addresses: "Adressen",
  contacts: "Kontakt",
  bankInformation: "Bankleitzahlen",
  confirmRequest: "Anfrage bestätigen",
  becomeCustomerInvitation: "Möchten Sie Alcea-Kunde werden?",
  becomeCustomer: "Kunde werden",
  becomeCustomerText: "Füllen Sie den Antrag aus, um unser Kunde zu werden, Zugriff auf alle Produkte in unseren Katalogen zu erhalten und Bestellungen und Angebote direkt aus Ihrem Kundenbereich anzufordern.",
  confirmNewRequest: "Neue Anfrage erstellen?",
  invitationsUser: "Erhaltene Aufforderungen, Kunde zu werden",
  invitationsCustomer: "Gesendete Aufforderungen",
  newInvite: "Neue Aufforderung",
  invitations: "Aufforderungen",
  selectClient: "Kunden anwählen, um Bestellungen und Angebote zu verwalten",
  newRequest: "Neue Kundenanfragen",
  businessTypology: "Art des Unternehmens",
  resalesInfo: "Infos Einzelhandel",
  outlook: "Aussichten",
  areaAgent: "Meine Kunden",
  editCustomer: "Antrag auf Profilbearbeitung",
  orderedProducts: "Bestellte Produkte",
  customerRequests: "Anfragen nach Bestellungen / Angeboten",
  backToAgent: "Kunden wechseln",
  orders: "Bestellungen",
  cart: "Warenkorb",
  notAssigned: "Preis nicht zugeordnet",
  checkoutOrder: "Checkout Bestellungsanfrage",
  checkoutOffer: "Checkout Angebotsanfrage",
  productSearch: "Produktsuche",
  contactUs: "Kontakt"
};
