import http from "@/util/http-common";

const url = "v1/public/user-company/";

class UserService {
  async getUserMyself() {
    let user = JSON.parse(localStorage.getItem("user"));
    return http.get(`users/${user.username}`).then((response) => {
      return response.data;
    });
  }

  async updateUserPrivate(form) {
    let user = JSON.parse(localStorage.getItem("user"));
    return http
      .patch(`users/privates/${user.username}`, form)
      .then((response) => {
        user.accessToken = response.data.accessToken;
        localStorage.setItem("user", JSON.stringify(user));
        return response;
      });
  }

  async updateUserCompany(form) {
    let user = JSON.parse(localStorage.getItem("user"));
    return http
      .patch(`users/companies/${user.username}`, form)
      .then((response) => {
        user.accessToken = response.data.accessToken;
        localStorage.setItem("user", JSON.stringify(user));
        return response;
      });
  }

  async getMyRequest() {
    return http.get(url + `customer-requests/enter-customer-requests/types/new-and-existing`);
  }

  async putNewCustomerRequestDraft(form) {
    return http.put(url + `customer-requests/enter-customer-requests/types/new/drafts`, form);
  }

  async postNewCustomerRequestPending(form) {
    return http.post(url + `customer-requests/enter-customer-requests/types/new/pendings`, form);
  }

  async updateToCustomer() {
    return http.patch(url + `upgrade/to-customer`);
  }

  async deleteRequest() {
    return http.delete(url + `delete-request`);
  }

  async postExistingCustomerRequestPending(form) {
    return http.post(
      url + `customer-requests/enter-customer-requests/types/existing/pendings`,
      form
    );
  }

  getInvitationsUser(page, size, sort) {
    let body = {
      page: page,
      size: size,
      sort: sort,
    };
    return http.get(url + `customer-invitations`, body).then((response) => {
      return response.data;
    });
  }

  updateInvitation(inviter, status) {
    const body = {
      inviterUsername: inviter,
      status: status,
    };
    console.log(body);
    return http
      .patch(url + `customer-invitations/pendings`, body)
      .then((response) => {
        return response.data;
      });
  }
}

export default new UserService();
