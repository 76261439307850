<template>
  <div v-if="expirationDays >= 1">
    <div class="row justify-content-between">
      <div class="col-md-auto">
        <span class="font-weight-bold">{{ $t("invitation.sentFrom") }}:</span>
        {{ invitation.inviterUsername }}
      </div>

      <div class="col-md-auto">
        <b-icon font-scale="0.8" aria-hidden="true" icon="circle-fill" :style="statusClass"></b-icon>
        {{ $t("requestStatus." + invitation.status) }}
      </div>
    </div>

    <div>
      <span class="font-weight-bold">{{ $t("invitation.Company") }}:</span>
      {{ invitation.businessName }}
    </div>

    <div>
      <span class="font-weight-bold">{{ $t("labels.expireIn") }}:</span>
      {{ expirationDays.toFixed(0) }}
    </div>

    <div class="row justify-content-right mt-2 mx-auto" v-if="requestStatus !== 'ACCEPTED'">
      <b-button variant="outline-success" class="col mx-1" @click="accept(invitation.inviterUsername)">
        <b-icon aria-hidden="true" icon="check"></b-icon>
        {{ $t("actions.accept") }}
      </b-button>

      <b-button variant="outline-danger" class="col mx-1" @click="refuse(invitation.inviterUsername)">
        <b-icon aria-hidden="true" icon="x"></b-icon>
        {{ $t("actions.refuse") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "InviteReceivedItem",

  props: {
    invitation: Object()
  },

  computed: {
    ...mapState({
      requestStatus: (state) => state.request.request.status
    }),

    statusClass() {
      if (this.invitation.status === "PENDING") {
        return { color: "yellow" }
      }
      if (this.invitation.status === "ACCEPTED") {
        return { color: "green" }
      }
      if (this.invitation.status === "REFUSED") {
        return { color: "red" }
      }
    },

    expirationDays() {
      return (
          (new Date(this.invitation.expiration) - new Date()) / (1000 * 3600 * 24)
      )
    }
  },

  methods: {
    accept(inviter) {
      this._action(inviter, 'ACCEPTED')
    },

    refuse(inviter) {
      this._action(inviter, 'REJECTED')
    },

    _action(inviter, status) {
      this.$store.dispatch("updateInvitation", { inviter: inviter, status: status })
          .then(() => {
            this.$emit("loadInvites")
            if (status === 'ACCEPTED') {
              this.$emit("acceptSuccess")
              this.$router.push("/profile-user/?becomeClientSuccess=" + true)
            } else {
              this.$emit("refuseSuccess")
              this.$router.push("/profile-user")
            }
          })
          .catch((err) => {
            if (err.status === 404) {
              this.$emit("notFound")
            }
          })
    }
  }
}
</script>
