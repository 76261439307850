<template>
  <b-form-group
    class="no-mr-0 no-col-md-6"
    :invalid-feedback="validationError('telephone', 'phoneNumber')"
    :is-valid="validationState('telephone', 'phoneNumber')"
  >
    <b-input-group
      :class="validationState('telephone', 'phoneNumber') ? '' : 'is-invalid'"
    >
      <b-form-select
          ref="telephonePrefix"
        v-model="telephone.telephonePrefix"
        :state="validationState('telephone', 'phoneNumber')"
        @input="handleInputPrefix"
        @change="handleInputPrefix"
      >
        <b-select-option selected :value="null">
          {{ $t("formUser.phonePrefix") }}
          <!-- <span v-if="form.type == 'C'">*</span> -->
        </b-select-option>
        <b-select-option
          v-for="country in countriesPrefix"
          :value="country.country"
          :key="country.key"
        >
          {{ country.country + " +" + country.prefix }}
        </b-select-option>
      </b-form-select>
      <b-form-input
        class="form-control w-50"
        id="telefono"
        ref="telephoneNumber"
        v-model.trim="telephone.number"
        type="tel"
        :placeholder="
          formType == 'P' ? phonePlaceholder : phonePlaceholder + ' *'
        "
        :state="validationState('telephone', 'phoneNumber')"
        @input="handleInputNumber"
        @change="handleInputNumber"
      ></b-form-input>
    </b-input-group>
  </b-form-group>
</template>
<script>
import examples from "libphonenumber-js/examples.mobile.json";
import Validator from "../../services/validation-service/validator-service";

import {
  parsePhoneNumber,
  parsePhoneNumberFromString,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
} from "libphonenumber-js";

export default {
  name: "form-telephone",
  props: {
    formType: String(),
    value: String(),
    invalid: Array(),
    telephoneProp: Object(),
  },
  data() {
    return {
      phone: { telephonePrefix: null, number: null },
    };
  },
  computed: {
    invalidList: {
      get() {
        return this.invalid;
      },
      set(invalidated) {
        this.$emit("input", invalidated);
      },
    },
    telephone: {
      get() {
        let phone = this.phone;
        if (this.value) {
          let phoneNumber = parsePhoneNumberFromString(this.value);
          if (phoneNumber) {
            phone.telephonePrefix = phoneNumber.country
              ? phoneNumber.country
              : phone.telephonePrefix;
            phone.number = phoneNumber.number
              ? phoneNumber.number.replace(
                  "+" + phoneNumber.countryCallingCode,
                  ""
                )
              : phone.number;
          }
        } else if (this.value == "" || this.value == null || this.value == undefined) {
          phone.telephonePrefix = null;
          phone.number = null;
        }
        this.phone = phone;
        return phone;
      },
      set(e) {
        let phone = { telephonePrefix: null, number: null };
        if (e) {
          phone = { telephonePrefix: e.telephonePrefix, number: e.number };
          //phone = e;
        }
        if (this.telephoneProp) {
          this.telephoneProp.telephonePrefix = phone.telephonePrefix;
          this.telephoneProp.number = phone.number;
        }
        this.phone = phone;
      },
    },
    parseTelephone() {
      if (
        Validator.valid(this.telephone.number) &&
        Validator.valid(this.telephone.telephonePrefix)
      ) {
        try {
          return parsePhoneNumber(
            this.telephone.number,
            this.telephone.telephonePrefix
          ).format("INTERNATIONAL");
        } catch (e) {
          return (
            "+" +
            getCountryCallingCode(this.telephone.telephonePrefix) +
            this.telephone.number
          );
        }
      } else {
        return null;
      }
    },
    phonePlaceholder() {
      return this.telephone.telephonePrefix
        ? getExampleNumber(
            this.telephone.telephonePrefix,
            examples
          ).format('INTERNATIONAL').replace('+' + getCountryCallingCode(this.telephone.telephonePrefix), '')
        : this.$i18n.t("formUser.telephone");
    },
    countriesPrefix() {
      return getCountries().map((c) => {
        return (c = { country: c, prefix: getCountryCallingCode(c) });
      });
    },
  },
  methods: {
    changeEvent() {
      this.handleInputNumber(this.telephone.number);
      this.handleInputPrefix(this.telephone.telephonePrefix);
    },
    handleInputPrefix(e) {
      this.telephone.telephonePrefix = e;
      this.handleInput();
    },
    handleInputNumber(e) {
      this.telephone.number = e;
      this.handleInput();
    },
    handleInput() {
      this.$emit("input", this.parseTelephone);
    },
    validationError(contextRef, secodndRef) {
      let find = this.invalidList.find((invEl) =>
        invEl.name.toLowerCase() == contextRef.toLowerCase()
          ? invEl.name.toLowerCase() == contextRef.toLowerCase()
          : secodndRef
          ? invEl.name.toLowerCase() == secodndRef.toLowerCase()
          : null
      );
      return find ? this.$i18n.t(find.error) : null;
    },
    //validate onFocus//Blur
    validationState(contextRef, secodndRef) {
      return this.invalidList.find((invEl) => {
        if (secodndRef) {
          secodndRef = secodndRef.toLowerCase();
        }
        return invEl.name.toLowerCase() == contextRef.toLowerCase()
          ? invEl.name.toLowerCase() == contextRef.toLowerCase()
          : invEl.name.toLowerCase() == secodndRef;
      })
        ? false
        : null; //context.$on("focus", this.facused=contextRef)
    },
  },
  watch: {
    telephone: {
      handler() {
        this.changeEvent();
      },
      deep: true
    },
  },
};
</script>