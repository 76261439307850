<template>
  <b-card class="col-md-12 mb-3 p-2 position-relative">
    <span>
      <button
          type="button"
          class="close p-2 position-absolute"
          :style="{ top: '0px', right: '0px' }"
          aria-label="Close"
          v-if="dismissable"
          @click="removeAddress"
      >
        <b-icon aria-hidden="true" icon="x"></b-icon>
      </button>
    </span>
    <div class="row justify-content-left my-2" v-if="isShippingAddress">
    <b-form-group
      class="col-md-12"
      :invalid-feedback="validationError('businessName')"
      >
        <b-form-input
          v-model="address.businessName"
          :placeholder="$t('formUser.businessName') + ' *'"
          :state="validationState('businessName')"
          :disabled="disabled"
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="row justify-content-center my-2">
      <b-form-group
          class="col-md-6"
          :invalid-feedback="validationError('streetAddress1')"
      >
        <b-form-input
            type="text"
            v-model="address.streetAddress1"
            :placeholder="$t('formCustomer.streetAddress1')+ ' *'"
            :disabled="disabled"
            :state="validationState('streetAddress1')"
        ></b-form-input
        >
      </b-form-group>
      <b-form-group
          class="col-md-6"
          :invalid-feedback="validationError('streetAddress2')"
      >
        <b-form-input
            type="text"
            v-model="address.streetAddress2"
            :placeholder="$t('formCustomer.streetAddress2')"
            :disabled="disabled"
            :state="validationState('streetAddress2')"
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="row justify-content-center my-2">
      <b-form-group
          class="col-md-4"
          :invalid-feedback="validationError('country')"
      >
        <b-form-select
            ref="country"
            v-model="address.country"
            required
            :state="validationState('country')"
            :disabled="disabled"
        >
          <b-select-option selected disabled :value="null|| undefined ">
            {{ $t("formUser.country") }} *
          </b-select-option>
          <b-select-option
              v-for="country in countries"
              :value="country.value"
              :key="country.key"
          >
            {{ country.label }}
          </b-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group class="col-md-4" :invalid-feedback="validationError('city')">
        <b-form-input
            type="text"
            v-model="address.city"
            :placeholder="$t('formCustomer.city')+ ' *'"
            :disabled="disabled"
            :state="validationState('city')"

        ></b-form-input>
      </b-form-group>
      <b-form-group
          class="col-md-4"
          :invalid-feedback="validationError('zipOrPostalCode')"
      >
        <b-form-input
            v-model.trim="address.zipOrPostalCode"
            :placeholder="$t('formUser.zipCode') + ' *'"
            :state="validationState('zipOrPostalCode')"
            :disabled="disabled"
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="row justify-content-left my-2">
      <b-form-group
          class="col-md-4"
          :invalid-feedback="validationError('province')"
      >
        <b-form-input
            v-model.trim="address.province"
            :placeholder="$t('formUser.province')"
            :state="validationState('province')"
            :disabled="disabled"
        ></b-form-input>
      </b-form-group>
    </div>
  </b-card>
</template>
<script>
import countries from "../commons/countries";

export default {
  name: "cardAddress",
  props: {
    address: Object(),
    invalidErrors: Array(),
    disabled: Boolean(),
    dismissable: Boolean(),
    isShippingAddress: Boolean(),
  },

  data() {
    return {
      localAddress: this.address,
      countries: countries,
    };
  },

  methods: {
    validationState(contextRef) {
      return this.invalidErrors.find(
          (invEl) => invEl.name.toLowerCase() === contextRef.toLowerCase()
      )
          ? false
          : null;
    },

    validationError(contextRef) {
      if (this.invalidErrors.length > 0) {
        let find =
            this.invalidErrors.find(
                (invEl) => invEl.name.toLowerCase() == contextRef.toLowerCase()
            ) || null;
        return find ? this.$i18n.t(find.error) : null;
      }
    },
    removeAddress() {
      this.$emit("removeAddress");
    },
  },
};
</script>