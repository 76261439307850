export const languages = {
  lang: "Language",
  IT: "Italian",
  EN: "English",
  DE: "German",
  ES: "Spanish",
  FR: "French",
  PL: "Polish",
  RU: "Russian",
  it: "Italian",
  en: "English",
  de: "German",
  es: "Spanish",
  fr: "French",
  pl: "Polish",
  ru: "Russian",
};
