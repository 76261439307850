import Login from "@/views/Login.vue";

export default {
  path: "/login",
  name: "Login",
  component: Login,
  meta: {
    sector: 'PrivateArea',
  },
  props: true
}
